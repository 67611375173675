import { SetStateAction } from "react";
import { isEmpty } from "lodash";
import { ICustomErrorData } from "../../kyps-range-form/range-form";
import { IAddRangeTemperatureFields } from "./add-installation-point-sidebar.utils";
import { t, TFunction } from "i18next";
import { AddIPointFunction } from "../../../interfaces/add-functions";
import { createNotification } from "../../toaster/toaster.helper";

interface IOnSubmitRangeTemperature {
  data: IAddRangeTemperatureFields;
  setCustomErrors: (value: SetStateAction<ICustomErrorData[]>) => void;
  setFormData: (
    value: SetStateAction<{
      nameForm: {};
      rangeForm: {};
    }>
  ) => void;
  t: TFunction<"translations", undefined>;
}

interface IHandleOnAddIPoint {
  addIPoint: AddIPointFunction;
  zoneId: string;
  name: string;
  lowAttention: number;
  lowNormal: number;
  highNormal: number;
  highAttention: number;
}

export const rangeTemperatureCheck = ({
  data,
  setCustomErrors,
  setFormData,
  t,
}: IOnSubmitRangeTemperature) => {
  const { lowAttention, highAttention, lowNormal, highNormal } = data;
  let invalidFields: string[] = [];

  if (+lowAttention >= +lowNormal) invalidFields.push("lowAttention");
  if (+lowNormal >= +highNormal) invalidFields.push("lowNormal");
  if (+highNormal >= +highAttention) invalidFields.push("highNormal");
  if (+highAttention <= +highNormal) invalidFields.push("highAttention");

  if (!isEmpty(invalidFields)) {
    createNotification({
      heading: t("errors.rangeForm"),
      variant: "error",
      message: createIssuesMessage(invalidFields, t),
    });

    const e: ICustomErrorData[] = invalidFields.map((e) => ({ fieldName: e }));
    setCustomErrors(e);
    setFormData((prev) => ({ ...prev, rangeForm: {} }));
  } else {
    setCustomErrors([]);
    setFormData((prev) => ({ ...prev, rangeForm: data }));
  }
};

const createIssuesMessage = (
  data: string[],
  t: TFunction<"translations", undefined>
) => data.map((i) => t("errors.invalidValueWithKey", { key: i }));

export const handleOnAddIPoint = ({
  addIPoint,
  highAttention,
  highNormal,
  lowAttention,
  lowNormal,
  name,
  zoneId,
}: IHandleOnAddIPoint) =>
  addIPoint({
    zoneId,
    iPointData: {
      name,
      temperatureRange: {
        lowYellow: +lowAttention,
        lowGreen: +lowNormal,
        highGreen: +highNormal,
        highYellow: +highAttention,
      },
    },
  })
    .unwrap()
    .then(({ name }) =>
      createNotification({
        message: t("notification.success", {
          action: t("notification.actions.created"),
          entity: `${t("entities.iPoint")} ${name}`,
        }),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
