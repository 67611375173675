import { MouseEvent as MouseEventGeneric, useRef, useState } from "react";
import { LocationUserRoles } from "../../../interfaces/roles";
import { LocationRoleSelectWrapper } from "./location-role-select.styles";
import clsx from "clsx";

interface ILocationRoleSelect {
  selectedOption: LocationUserRoles;
  isDisabled: boolean;
  openClick: (e: MouseEventGeneric<HTMLDivElement>) => void;
}

const LocationRoleSelect = ({
  selectedOption,
  isDisabled,
  openClick,
}: ILocationRoleSelect) => {
  const [selectedValue] = useState<LocationUserRoles>(selectedOption);
  const selectRef = useRef<HTMLDivElement | null>(null);

  return (
    <LocationRoleSelectWrapper
      ref={selectRef}
      onClick={openClick}
      className={clsx(isDisabled && "disabled")}
    >
      {selectedValue.toLowerCase()}
    </LocationRoleSelectWrapper>
  );
};

export default LocationRoleSelect;
