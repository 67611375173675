import { LContainer, SectionContainer } from "../../landing-content.styles";
import SectionDescription from "../../components/section-description/section-description";
import StepItem from "../../components/step-item/step-item";
import { StepsContainer } from "./how-it-works-section.styles";
import { TFunction } from "i18next";
import { createTranslatedStepsData } from "./how-it-works.utils";
import { useMemo } from "react";

const HowItWorksSection = ({ t }: { t: TFunction }) => {
  const renderSteps = useMemo(
    () =>
      createTranslatedStepsData(t).map(
        ({ stepTitle, stepDescription, iconNames, number, variant }) => (
          <StepItem
            stepTitle={stepTitle}
            stepDescription={stepDescription}
            iconNames={iconNames}
            number={number}
            variant={variant}
            key={stepTitle}
          />
        )
      ),
    [t]
  );

  return (
    <LContainer>
      <SectionContainer>
        <SectionDescription
          label={t("landing.howItWorks.label")}
          heading={t("landing.howItWorks.heading")}
          description={[t("landing.howItWorks.text")]}
          anchor="how-it-works"
        />
        <StepsContainer>{renderSteps}</StepsContainer>
      </SectionContainer>
    </LContainer>
  );
};

export default HowItWorksSection;
