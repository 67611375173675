import { useGetIPointByIdQuery } from "../../api/installation-point.api";
import CustomCheckbox from "../kyps-custom-checkbox/custom-checkbox";
import { SensorConfigsWrapper } from "../kyps-custom-checkbox/custom-checkbox.styles";
import RangeForm, { ICustomErrorData } from "../kyps-range-form/range-form";
import { useMemo, useState, useRef, useEffect } from "react";
import { fillRangeFields } from "../kyps-sidebar/add-sensor-sidebar/add-sensor-sidebar.helper";
import { IAddRangeTemperatureFields } from "../kyps-sidebar/add-installation-point-sidebar/add-installation-point-sidebar.utils";
import { rangeTemperatureCheck } from "../kyps-sidebar/add-installation-point-sidebar/add-installation-point-sidebar.helper";
import {
  formDataToNumbers,
  getIsDataChanged,
  newRangeFormDataToNumbers,
} from "../kyps-sidebar/sensor-details-sidebar/sensor-details-sidebar.helper";
import {
  useGetSensorByIdQuery,
  useUpdateSensorMutation,
} from "../../api/sensor.api";
import { isEmpty } from "lodash";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { handleOnEditSensor } from "../kyps-sidebar/edit-sensor-sidebar/edit-sensor-sidebar.helper";
import { ITemperatureRange } from "../../interfaces/dashboard";
import { useTranslation } from "react-i18next";
import { ImperativeHandleRef } from "../kyps-range-form/range-form.utils";

interface ISensorRangeDetails {
  sensorId: string;
  sensorNumber: string;
  sensorName?: string;
  iPointId?: string;
  customRange?: ITemperatureRange;
  isDisabled?: boolean;
}

const SensorRangeDetails = ({
  sensorId,
  sensorNumber,
  iPointId,
  sensorName,
  customRange,
  isDisabled,
}: ISensorRangeDetails) => {
  const rangeFormRef = useRef<ImperativeHandleRef>(null);

  const [customRangeErrors, setCustomRangeErrors] = useState<
    ICustomErrorData[]
  >([]);
  const [formData, setFormData] = useState({
    nameForm: {},
    rangeForm: {},
  });
  const [isCustomConfig, setIsCustomConfig] = useState(false);
  const [showHumidity, setShowHumidity] = useState(false);
  const [isCheckboxesDataChanged, setIsCheckboxesDataChanged] = useState(false);
  const [isFormDataChanged, setIsFormDataChanged] = useState(false);

  const { t } = useTranslation();

  const { data: apiIPointInAllZoneData } = useGetIPointByIdQuery(
    isEmpty(iPointId) || !iPointId ? skipToken : iPointId
  );
  const { data: apiSensorData } = useGetSensorByIdQuery(sensorId);
  const [updateSensor] = useUpdateSensorMutation();

  const iPointInAllZoneData = useMemo(() => {
    if (apiIPointInAllZoneData) return apiIPointInAllZoneData;

    return;
  }, [apiIPointInAllZoneData]);

  useEffect(() => {
    if (apiSensorData?.customConfig)
      setIsCustomConfig(apiSensorData.customConfig);

    if (apiSensorData?.showHumidity)
      setShowHumidity(apiSensorData.showHumidity);
  }, [apiSensorData?.customConfig, apiSensorData?.showHumidity]);

  useEffect(() => {
    if (!isEmpty(formData.rangeForm) && iPointInAllZoneData) {
      if (apiSensorData && isCustomConfig)
        return setIsFormDataChanged(
          getIsDataChanged({
            initialData: formDataToNumbers(apiSensorData.temperatureRange),
            newData: newRangeFormDataToNumbers(formData.rangeForm),
          })
        );

      setIsFormDataChanged(
        getIsDataChanged({
          initialData: formDataToNumbers(iPointInAllZoneData.temperatureRange),
          newData: newRangeFormDataToNumbers(formData.rangeForm),
        })
      );
    }
  }, [
    apiSensorData,
    apiSensorData?.temperatureRange,
    formData.rangeForm,
    iPointInAllZoneData,
    isCustomConfig,
  ]);

  const handleEditSensorFormSubmit = () => rangeFormRef.current?.submit();

  const editRangeFields = useMemo(() => {
    if (isCustomConfig && customRange)
      return fillRangeFields({
        range: customRange,
        t,
      });

    if (apiIPointInAllZoneData)
      return fillRangeFields({
        range: apiIPointInAllZoneData.temperatureRange,
        t,
      });

    return [];
  }, [apiIPointInAllZoneData, customRange, isCustomConfig, t]);

  const onSubmitRangeTemperature = (data: IAddRangeTemperatureFields) => {
    if (data) {
      rangeTemperatureCheck({
        data,
        setCustomErrors: setCustomRangeErrors,
        setFormData,
        t,
      });
      setFormData((prev) => ({
        ...prev,
        rangeForm: data,
      }));
    }
  };

  const checkCheckboxesData = (humidity: boolean, custom: boolean) =>
    setIsCheckboxesDataChanged(
      getIsDataChanged({
        initialData: {
          isCustomConfig: apiSensorData?.customConfig,
          showHumidity: apiSensorData?.showHumidity,
        },
        newData: { isCustomConfig: custom, showHumidity: humidity },
      })
    );

  const rangeData = useMemo(() => {
    if (!isEmpty(formData.rangeForm)) return formData.rangeForm;

    if (isCustomConfig && customRange) {
      const { highGreen, highYellow, lowGreen, lowYellow } = customRange;

      return {
        lowAttention: lowYellow,
        lowNormal: lowGreen,
        highNormal: highGreen,
        highAttention: highYellow,
      };
    }

    if (apiIPointInAllZoneData) {
      const { highGreen, highYellow, lowGreen, lowYellow } =
        apiIPointInAllZoneData.temperatureRange;

      return {
        lowAttention: lowYellow,
        lowNormal: lowGreen,
        highNormal: highGreen,
        highAttention: highYellow,
      };
    }

    return {};
  }, [apiIPointInAllZoneData, customRange, formData.rangeForm, isCustomConfig]);

  const saveChanges = () => {
    handleOnEditSensor({
      formData: {
        rangeForm: rangeData,
        nameForm: { sensorNumber, sensorName },
      },
      isCustomConfig,
      sensorId,
      showHumidity,
      updateSensor,
    });
    handleEditSensorFormSubmit();

    setIsCheckboxesDataChanged(false);
    setIsFormDataChanged(false);
  };

  const onCustom = () => {
    if (isCustomConfig) {
      setFormData({
        ...formData,
        rangeForm: rangeFormRef.current?.defaultData ?? formData.rangeForm,
      });
    }
    rangeFormRef.current?.reset();
    setIsCustomConfig(!isCustomConfig);
  };

  return (
    <RangeForm
      key="RangeForm"
      fields={editRangeFields}
      onSubmit={onSubmitRangeTemperature}
      ref={rangeFormRef}
      checkboxes={
        <SensorConfigsWrapper>
          <CustomCheckbox
            isChecked={showHumidity}
            onCheckboxClick={() => setShowHumidity(!showHumidity)}
            checkboxHeading={t("checkboxes.showHumidity")}
            changeCheckFunction={() =>
              checkCheckboxesData(!showHumidity, isCustomConfig)
            }
            isDisabled={isDisabled}
            key="Show humidity"
          />
          <CustomCheckbox
            isChecked={isCustomConfig}
            onCheckboxClick={onCustom}
            checkboxHeading={t("checkboxes.setupCustomSettings")}
            changeCheckFunction={() =>
              checkCheckboxesData(showHumidity, !isCustomConfig)
            }
            isDisabled={isDisabled}
            key="Setup Custom Settings"
          />
        </SensorConfigsWrapper>
      }
      customErrorsData={customRangeErrors}
      showEmptyMessage={isEmpty(editRangeFields)}
      isDisabled={!isCustomConfig}
      hasPadding
      hasBorder
      hasSaveButton={
        (isCheckboxesDataChanged || isFormDataChanged) &&
        isEmpty(customRangeErrors)
      }
      onSaveButton={saveChanges}
      onInputBlur={handleEditSensorFormSubmit}
    />
  );
};

export default SensorRangeDetails;
