import SectionDescription from "../../components/section-description/section-description";
import { LContainer, SectionContainer } from "../../landing-content.styles";
import {
  NotificationsDescriptionContainer,
  NotificationsSectionWrapper,
} from "./notifications-section.styles";
import ImageDetails from "../../components/image-details/image-details";
import { TFunction } from "i18next";
import { createTranslatedNotificationsData } from "./notifications-section.utils";

const NotificationsSection = ({ t }: { t: TFunction }) => (
  <NotificationsSectionWrapper>
    <SectionContainer>
      <LContainer>
        <NotificationsDescriptionContainer>
          <SectionDescription
            label={t("landing.notification.label")}
            heading={t("landing.notification.heading")}
            description={[t("landing.notification.text")]}
          />
        </NotificationsDescriptionContainer>
        {createTranslatedNotificationsData(t).map(
          ({
            imageSrc,
            imageAlt,
            title,
            description,
            detailsItems,
            isReverse,
            hasPadding,
          }) => (
            <ImageDetails
              imageSrc={imageSrc}
              imageAlt={imageAlt}
              title={title}
              description={description}
              detailsItems={detailsItems}
              isReverse={isReverse}
              hasPadding={hasPadding}
              key={title}
            />
          )
        )}
      </LContainer>
    </SectionContainer>
  </NotificationsSectionWrapper>
);

export default NotificationsSection;
