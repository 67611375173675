import clsx from "clsx";
import { UserIconWrapper } from "./user-icon.styles";
import { getUserInitials } from "./user-icon.helper";

interface IUserIcon {
  userName: string;
  variant?: "large" | "small";
}

const UserIcon = ({ userName, variant = "small" }: IUserIcon) => (
  <UserIconWrapper className={clsx(variant)}>
    {getUserInitials(userName)}
  </UserIconWrapper>
);

export default UserIcon;
