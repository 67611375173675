import { createColumnHelper } from "@tanstack/react-table";
import { IManagerDTO } from "../../../interfaces/managers";
import IconButton from "../../icon-button/icon-button";
import { AttachTableCheckbox } from "../attach-user-table/attach-user-table.styles";
import {
  checkIsAdmin,
  handleOnDetachManager,
} from "./company-managers-table.helper";
import { DetachManagerFromCompanyFunction } from "../../../interfaces/delete-function";
import { CellMaxContent } from "../kyps-table.styles";
import clsx from "clsx";
import { TFunction } from "i18next";
import TooltipCell from "../../kyps-tooltip/tooltip-cell/tooltip-cell";

interface ICreateCompanyManagersColumns {
  companyId: string;
  userId: string;
  onToggleIsAdmin: (selectedManager: IManagerDTO) => void;
  detachManager: DetachManagerFromCompanyFunction;
  t: TFunction<"translations", undefined>;
}

const { accessor, display } = createColumnHelper<IManagerDTO>();
export const createCompanyManagersColumns = ({
  companyId,
  userId,
  onToggleIsAdmin,
  detachManager,
  t,
}: ICreateCompanyManagersColumns) => [
  accessor("name", {
    header: t("tables.name"),
    size: 180,
    cell: ({ getValue }) => <TooltipCell value={getValue()} />,
  }),
  accessor("email", {
    header: t("tables.email"),
    size: 194,
    cell: ({ getValue }) => <TooltipCell value={getValue()} />,
  }),
  accessor("phoneNumber", {
    header: t("tables.phoneNumber"),
    size: 164,
    cell: ({ getValue }) => <CellMaxContent>{getValue()}</CellMaxContent>,
  }),
  accessor("telegramId", {
    header: t("tables.telegramId"),
    size: 124,
    cell: ({ getValue }) => <CellMaxContent>{getValue() || ""}</CellMaxContent>,
  }),
  accessor("companies", {
    header: t("tables.admin"),
    size: 86,
    enableSorting: false,
    cell: ({ getValue, row: { original } }) => {
      const isAdmin = checkIsAdmin({
        companies: getValue(),
        currentCompanyId: companyId as string,
      });
      const isDisabled = userId === original.id;

      return (
        <AttachTableCheckbox
          className={clsx(isDisabled && "disabled")}
          name="emailNotification"
          type="checkbox"
          checked={isAdmin}
          onChange={() =>
            onToggleIsAdmin({
              ...original,
              companies: [{ admin: !isAdmin, companyId }],
            })
          }
          disabled={isDisabled}
        />
      );
    },
  }),
  display({
    header: "",
    size: 54,
    id: "actions",
    cell: ({
      row: {
        original: { id, name },
      },
    }) => (
      <IconButton
        name="detach"
        onClick={() =>
          handleOnDetachManager({
            managerId: id,
            managerName: name,
            companyId,
            detachManager,
          })
        }
        dataName={t("buttons.detach.action")}
        hasZeroMargin
      />
    ),
  }),
];
