import styled from "@emotion/styled";

export const SectionQuoteWrapper = styled.div`
  margin-top: 40px;
  border-left: 4px solid #e5e7eb;
  padding-left: 22px;
  font-size: 16px;
  font-style: italic;
  line-height: 32px;
  text-align: center;
  color: #f3f4f6;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;
