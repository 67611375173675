import { DeleteFunction } from "../../interfaces/delete-function";
import { ISelectOption } from "../kyps-select/kyps-select";
import { Dispatch, SetStateAction } from "react";
import { createNotification } from "../toaster/toaster.helper";

interface IHandleOnDeleteZone {
  id: string;
  name: string;
  deleteFunction: DeleteFunction;
  selectZone?: Dispatch<SetStateAction<ISelectOption>>;
}

interface IOnSetEditedRowsHandler {
  meta: any;
  rowId: string;
}

export const onSetEditedRowsHandler = ({
  meta,
  rowId,
}: IOnSetEditedRowsHandler) => {
  const setEditedRows = () => {
    meta?.setEditedRows((prev: { [key: string]: boolean }) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  setEditedRows();
};

export const handleOnDeleteZone = ({
  id,
  name,
  deleteFunction,
  selectZone,
}: IHandleOnDeleteZone) => {
  selectZone?.({ label: "", value: "" });
  
  deleteFunction(id)
    .unwrap()
    .then(() =>
      createNotification({
        message: `${name} was successfully deleted!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};
