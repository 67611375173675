import {
  ICompleteCompanyStats,
  IGetCompanyFullStats,
  IGetCompanyFullStatsResponse,
  IGetCompanyStatsResponse,
  IGetLocationFullStatsResponse,
  ILocationInstallationPoint,
  ILocationZone,
} from "../interfaces/dashboard";
import { IGetSensorsByState, ISensorByState } from "../interfaces/sensors";
import { kypsApi } from "./kyps.api";

export const dashboardEndpoints = kypsApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompleteStatsByCompanyId: builder.query<ICompleteCompanyStats, string>({
      query: (companyId) => ({
        url: `/dashboard/companies/${companyId}`,
        method: "GET",
      }),
      providesTags: ["Dashboard", "DashboardComplete"],
    }),

    getAllLocationsStatsByCompanyId: builder.query<
      IGetCompanyFullStatsResponse,
      IGetCompanyFullStats
    >({
      query: ({ companyId, pageable = { size: 25, page: 0 } }) => ({
        url: `/dashboard/companies/${companyId}/locations/stats?page=${pageable.page}&size=${pageable.size}`,
        method: "GET",
      }),
      providesTags: ["Dashboard"],
    }),

    getLocationsStatsByCompanyId: builder.query<
      IGetCompanyStatsResponse,
      string
    >({
      query: (companyId) => ({
        url: `/dashboard/companies/${companyId}/stats`,
        method: "GET",
      }),
      providesTags: ["Dashboard"],
    }),

    getLocationSensorsDataByLocationId: builder.query<
      IGetLocationFullStatsResponse,
      string
    >({
      query: (locationId) => ({
        url: `/dashboard/locations/${locationId}`,
        method: "GET",
      }),
      providesTags: ["Dashboard", "Sensors"],
    }),

    getZoneStatsByZoneId: builder.query<ILocationZone, string>({
      query: (zoneId) => ({
        url: `/dashboard/zones/${zoneId}`,
        method: "GET",
      }),
      providesTags: ["Dashboard", "DashboardZone"],
    }),

    getIPointStatsById: builder.query<ILocationInstallationPoint, string>({
      query: (iPointId) => ({
        url: `/dashboard/installationPoints/${iPointId}`,
        method: "GET",
      }),
      providesTags: ["DashboardZone"],
    }),

    getSensorsByState: builder.query<ISensorByState[], IGetSensorsByState>({
      query: ({ companyId, stateVariant }) => ({
        url: `/dashboard/companies/${companyId}/sensors?state=${stateVariant}`,
        method: "GET",
      }),
      providesTags: ["DashboardZone"],
    }),
  }),
});

export const {
  useGetCompleteStatsByCompanyIdQuery,
  useGetAllLocationsStatsByCompanyIdQuery,
  useGetLocationsStatsByCompanyIdQuery,
  useGetLocationSensorsDataByLocationIdQuery,
  useGetZoneStatsByZoneIdQuery,
  useGetIPointStatsByIdQuery,
  useGetSensorsByStateQuery,
} = dashboardEndpoints;
