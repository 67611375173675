import { UserRoles, UserRolesEnum } from "./roles";

export const userRights: UserRoles[] = [
  UserRolesEnum.SUPER_ADMIN,
  UserRolesEnum.ADMIN,
  UserRolesEnum.MANAGER,
  UserRolesEnum.USER,
];

export const managersRights: UserRoles[] = [
  UserRolesEnum.SUPER_ADMIN,
  UserRolesEnum.ADMIN,
  UserRolesEnum.MANAGER,
];

export const adminRights: UserRoles[] = [
  UserRolesEnum.SUPER_ADMIN,
  UserRolesEnum.ADMIN,
];

export const superAdminRights: UserRoles[] = [UserRolesEnum.SUPER_ADMIN];
