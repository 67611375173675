import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useGetAllCompaniesQuery } from "../../api/companies.api";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { ISelectOption } from "../kyps-select/kyps-select";
import {
  LocationIdStatus,
  createCompaniesOptions,
  getIsLocationAllowed,
} from "./company-dropdown.helper";
import { SelectWrapper } from "./company-dropdown.styles";
import CompanySelect from "../kyps-select/company-select/company-select";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import { isEmpty } from "lodash";
import AddCompanySidebar from "../kyps-sidebar/add-company-sidebar/add-company-sidebar";
import EditCompanySidebar from "../kyps-sidebar/edit-company-sidebar/edit-company-sidebar";
import { TFunction } from "i18next";
import keycloak from "../../keycloak";

interface ICompanyDropdown {
  openModal: (modalContent: ReactNode) => void;
  closeModal: () => void;
  onSelectHandler?: () => void;
  t: TFunction<"translations", undefined>;
}

const locationUrlKey = "/dashboard/location=";

const CompanyDropdown = ({
  openModal,
  closeModal,
  onSelectHandler,
  t,
}: ICompanyDropdown) => {
  const { selectCompany, changeLoadingState } = useAppDispatch();
  const { loading, selectedCompany } = useAppSelector(({ company }) => company);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const [storedData, setStoredData] = useLocalStorage("company", null);

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<ISelectOption | null>(
    selectedCompany
  );

  const urlCompanyId = searchParams.get("company");

  const rolesArray: string[] = keycloak.tokenParsed?.user_groups;

  const getLocationIdStatus = useMemo(() => {
    const locationId = pathname.includes(locationUrlKey)
      ? pathname.slice(locationUrlKey.length)
      : null;

    if (locationId && !isEmpty(rolesArray) && urlCompanyId) {
      return getIsLocationAllowed({
        locationId,
        rolesArray,
        companyId: urlCompanyId,
      });
    }

    if (!rolesArray) return LocationIdStatus.LOADING;

    return LocationIdStatus.NO_URL;
  }, [pathname, rolesArray, urlCompanyId]);

  const isLocationUrlAllowed =
    getLocationIdStatus === LocationIdStatus.ALLOWED ||
    getLocationIdStatus === LocationIdStatus.NO_URL ||
    getLocationIdStatus !== LocationIdStatus.NOT_ALLOWED;

  const { data: apiCompanies, isLoading: isCompaniesLoading } =
    useGetAllCompaniesQuery();

  const handleSelectCompany = useCallback(
    (option: ISelectOption, shouldNavigate?: boolean) => {
      selectCompany(option);
      setSearchParams(`company=${option.value}`);
      setCurrentCompany(option);
      setStoredData(option);
      onSelectHandler?.();

      if (loading) changeLoadingState(false);

      if (pathname.includes("dashboard/") && shouldNavigate)
        navigate("dashboard/");
    },
    [
      changeLoadingState,
      loading,
      navigate,
      onSelectHandler,
      pathname,
      selectCompany,
      setSearchParams,
      setStoredData,
    ]
  );

  const baseCompany: ISelectOption | null = useMemo(() => {
    if (apiCompanies && !isEmpty(apiCompanies))
      return { label: apiCompanies[0].fullName, value: apiCompanies[0].id };

    return null;
  }, [apiCompanies]);

  useEffect(() => {
    if (
      !baseCompany ||
      !apiCompanies ||
      isCompaniesLoading ||
      getLocationIdStatus === LocationIdStatus.LOADING
    )
      return;

    const isSelected = !!currentCompany?.label || !!selectedCompany?.label;
    const isCompanyIdUrlAllowed = apiCompanies.some(
      ({ id }) => urlCompanyId === id
    );

    if (isSelected && isCompanyIdUrlAllowed && urlCompanyId) return;

    if (!isEmpty(urlCompanyId)) {
      if (isCompanyIdUrlAllowed) {
        const allowedCompany = apiCompanies.filter(
          ({ id }) => id === urlCompanyId
        )[0];

        if (allowedCompany && isLocationUrlAllowed)
          handleSelectCompany({
            label: allowedCompany.fullName,
            value: allowedCompany.id,
          });

        return;
      }

      handleSelectCompany(baseCompany, !isLocationUrlAllowed);
      return;
    }

    if (isEmpty(urlCompanyId) && !isSelected) {
      if (
        storedData &&
        apiCompanies.some(({ id }) => id === storedData.value)
      ) {
        handleSelectCompany(storedData);
        return;
      }

      handleSelectCompany(baseCompany);
      return;
    }
  }, [
    apiCompanies,
    baseCompany,
    currentCompany?.label,
    handleSelectCompany,
    isCompaniesLoading,
    getLocationIdStatus,
    selectedCompany?.label,
    storedData,
    urlCompanyId,
    isLocationUrlAllowed,
  ]);

  const companiesOptions = useMemo(() => {
    if (apiCompanies) return createCompaniesOptions(apiCompanies);

    return [];
  }, [apiCompanies]);

  const onAddCompany = () => {
    openModal(<AddCompanySidebar closeModal={closeModal} t={t} />);
    onSelectHandler?.();
  };

  const onEditCompany = () => {
    openModal(
      <EditCompanySidebar
        closeModal={closeModal}
        currentCompanyId={selectedCompany?.value ?? ""}
        t={t}
      />
    );
    onSelectHandler?.();
  };

  return (
    <SelectWrapper>
      <CompanySelect
        onSelect={(option: ISelectOption) => handleSelectCompany(option, true)}
        options={companiesOptions}
        placeholder={t("buttons.select.actionWithKey", {
          key: t("entities.company"),
        })}
        selectedOption={currentCompany}
        onAddClick={() => onAddCompany()}
        onEditClick={() => onEditCompany()}
        isSelectOpen={isSelectOpen}
        setIsSelectOpen={setIsSelectOpen}
        isLoading={isCompaniesLoading}
      />
    </SelectWrapper>
  );
};

export default CompanyDropdown;
