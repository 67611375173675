import { kypsApi } from "./kyps.api";
import {
  IAttachManager,
  IDeleteLocationManager,
  IUpdateLocationManager,
} from "../interfaces/location-managers";
import {
  IGetManagerByLocationId,
  ILocationManager,
} from "../interfaces/location-managers";

export const locationManagerEndpoints = kypsApi.injectEndpoints({
  endpoints: (builder) => ({
    getManagersByLocationId: builder.query<ILocationManager[], string>({
      query: (locationId) => ({
        url: `/locations/${locationId}/managers`,
        method: "GET",
      }),
      providesTags: ["LocationManager"],
    }),

    attachManagerByLocationId: builder.mutation<
      ILocationManager,
      IAttachManager
    >({
      query: ({ data, locationId }) => ({
        url: `/locations/${locationId}/managers`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["LocationManager"],
    }),

    updateManagerByLocationId: builder.mutation<
      ILocationManager,
      IUpdateLocationManager
    >({
      query: ({
        locationId,
        managerId,
        notifyEmail,
        notifyTelegram,
        role,
      }) => ({
        url: `/locations/${locationId}/managers/${managerId}`,
        method: "PUT",
        body: { notifyEmail, notifyTelegram, role },
      }),
      invalidatesTags: ["LocationManager"],
    }),

    detachManagerByLocationId: builder.mutation<void, IDeleteLocationManager>({
      query: ({ locationId, managerId }) => ({
        url: `/locations/${locationId}/managers/${managerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LocationManager"],
    }),

    getManagerLocations: builder.query<ILocationManager[], string>({
      query: (managerId) => ({
        url: `/managers/${managerId}/locations`,
        method: "GET",
      }),
      providesTags: ["LocationManager"],
    }),

    getManagerByLocationId: builder.query<
      ILocationManager,
      IGetManagerByLocationId
    >({
      query: ({ locationId, managerId }) => ({
        url: `/locations/${locationId}/managers/${managerId}`,
        method: "GET",
      }),
      providesTags: ["LocationManager"],
    }),
  }),
});

export const {
  useGetManagersByLocationIdQuery,
  useAttachManagerByLocationIdMutation,
  useUpdateManagerByLocationIdMutation,
  useDetachManagerByLocationIdMutation,
  useGetManagerLocationsQuery,
  useGetManagerByLocationIdQuery,
} = locationManagerEndpoints;
