import { UserRoles } from "../../../interfaces/roles";
import { adminRights } from "../../../interfaces/auth-wrapper";
import { ILocation } from "../../../interfaces/locations";
import { Dispatch, SetStateAction } from "react";
import { DeleteFunction } from "../../../interfaces/delete-function";
import {
  EditableLocationFieldsEnum,
  ILocationDetailsTableData,
} from "../../../interfaces/editable-cells";
import { TFunction } from "i18next";
import { createNotification } from "../../toaster/toaster.helper";

interface IHandleOnDeleteLocation {
  locationId: string;
  locationName: string;
  deleteLocation: DeleteFunction;
  closeAndRedirect: () => void;
  setManualSkipToken: Dispatch<SetStateAction<boolean>>;
  setParentManualSkipToken: Dispatch<SetStateAction<boolean>>;
}

interface ICreateLocationTableData extends ILocation {
  userRole: UserRoles;
}

export const handleOnDeleteLocation = ({
  locationId,
  locationName,
  deleteLocation,
  closeAndRedirect,
  setManualSkipToken,
  setParentManualSkipToken,
}: IHandleOnDeleteLocation) => {
  setManualSkipToken(true);
  setParentManualSkipToken(true);

  deleteLocation(locationId)
    .unwrap()
    .then(() => {
      createNotification({
        message: `${locationName} was successfully deleted!`,
        variant: "success",
      });
      closeAndRedirect();
    })
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};

export const createLocationTableData = ({
  id,
  name,
  address,
  comments,
  userRole,
}: ICreateLocationTableData): ILocationDetailsTableData[] => {
  const allowedRoles: UserRoles[] = adminRights;

  const baseProperties: ILocationDetailsTableData[] = [
    {
      name: EditableLocationFieldsEnum.NAME,
      value: name,
      id: id,
    },
    {
      name: EditableLocationFieldsEnum.ADDRESS,
      value: address,
      id: id,
    },
    {
      name: EditableLocationFieldsEnum.COMMENTS,
      value: comments,
      id: id,
    },
  ];

  const tableData: ILocationDetailsTableData[] = allowedRoles.includes(userRole)
    ? [
        ...baseProperties,
        {
          name: EditableLocationFieldsEnum.DELETE,
          id: id,
          value: "delete",
        },
      ]
    : baseProperties;

  return tableData;
};

interface ITranslateCellName {
  t: TFunction<"translations", undefined>;
  cellName: EditableLocationFieldsEnum;
}

export const translateLocationDetailsCellName = ({
  cellName,
  t,
}: ITranslateCellName): string => {
  switch (cellName) {
    case EditableLocationFieldsEnum.ADDRESS:
      return t("tables.address");
    case EditableLocationFieldsEnum.COMMENTS:
      return t("tables.comments");
    case EditableLocationFieldsEnum.LOCATION:
      return t("tables.location");
    case EditableLocationFieldsEnum.NAME:
      return t("tables.name");
    default:
      return cellName;
  }
};
