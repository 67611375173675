export const getUserInitials = (name: string, limit: number = 2) => {
  const words = name.split(" ");

  const initials = words
    .slice(0, limit)
    .map((word) => word.charAt(0).toUpperCase())
    .join("");

  return initials;
};
