import { useState } from "react";

const useGateway = () => {
  const [gatewayNumbers, setGatewayNumbers] = useState<string[]>([]);

  const addGatewayNumber = (newNumber: string) => {
    setGatewayNumbers([...gatewayNumbers, newNumber]);
  };

  const removeGatewayNumber = (selectedNumber: string) => {
    const filteredGatewayNumbers = gatewayNumbers.filter(
      (i) => i !== selectedNumber
    );
    setGatewayNumbers(filteredGatewayNumbers);
  };

  return {
    gatewayNumbers,
    addGatewayNumber,
    removeGatewayNumber,
    setGatewayNumbers,
  };
};

export default useGateway;
