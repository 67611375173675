import { createNotification } from "../components/toaster/toaster.helper";
import keycloak from "../keycloak";

export const downloadCSV = (sensorNumber: string) => {
  const sort = "&sort=time%2Casc";
  const token = keycloak.token;
  const sensorsNumbersUrl = `/main/sensorPayload/csv?sensorNumbers=${sensorNumber}${sort}`;

  if (!token) return;

  fetch(`https://api.kyps.link${sensorsNumbersUrl}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok)
        createNotification({
          message: `Network request failed. HTTP status: ${response.status}`,
          variant: "error",
        });

      return response.text();
    })
    .then((csvData) => {
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${sensorNumber}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => createNotification({ message: error, variant: "error" }));
};
