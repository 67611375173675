import { useMemo, Fragment } from "react";
import PaginationButton from "../pagination-button/pagination-button";
import clsx from "clsx";
import { shouldBeVisible } from "./kyps-table-pagination.helper";
import { PaginationWrapper } from "../pagination-button/pagination-button.styles";

export interface ITablePagination {
  pageCount: number;
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
}

const TablePagination = ({
  pageCount,
  currentPage,
  setCurrentPage,
}: ITablePagination) => {
  const buttons = useMemo(() => {
    const visiblePaginationBtn = [
      0,
      1,
      2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      pageCount - 3,
      pageCount - 2,
      pageCount - 1,
    ];
    const pagesArray = Array(pageCount)
      .fill(null)
      .map((_, i) => i);

    return pagesArray.map((btn) =>
      pagesArray.length > 6 ? (
        <Fragment key={btn}>
          <PaginationButton
            value={btn + 1}
            onClick={() => setCurrentPage(btn)}
            className={clsx(
              currentPage === btn && "active",
              visiblePaginationBtn.some((i) => i === btn) && "visible"
            )}
          />
          <PaginationButton
            className={clsx(
              "empty",
              shouldBeVisible({ btn, currentPage, pageCount }) && "visible"
            )}
            key={btn + "..."}
            value="..."
          />
        </Fragment>
      ) : (
        <PaginationButton
          key={btn}
          value={btn + 1}
          onClick={() => setCurrentPage(btn)}
          className={clsx("visible", currentPage === btn && "active")}
        />
      )
    );
  }, [pageCount, currentPage, setCurrentPage]);

  return <PaginationWrapper>{buttons}</PaginationWrapper>;
};

export default TablePagination;
