import {
  IAddSensor,
  ISensor,
  ISensorFullPayload,
  ISensorHistoryQuery,
  IUpdateSensor,
  IUpdateSensorState,
} from "../interfaces/sensors";
import { kypsApi } from "./kyps.api";

const sensorControllerEndpoints = kypsApi.injectEndpoints({
  endpoints: (builder) => ({
    getSensorsById: builder.query<ISensor[], string>({
      query: (installationPointId) => ({
        url: `/installationPoints/${installationPointId}/sensors`,
        method: "GET",
      }),
      providesTags: ["Sensors"],
    }),

    getSensorByNumber: builder.query<ISensor, string>({
      query: (sensorNumber) => ({
        url: `/sensors/search?sensorNumber=${sensorNumber}`,
        method: "GET",
      }),
      providesTags: ["Sensors"],
    }),

    getSensorById: builder.query<ISensor, string>({
      query: (sensorId) => ({
        url: `/sensors/${sensorId}`,
        method: "GET",
      }),
      providesTags: ["Sensors"],
    }),

    addSensor: builder.mutation<ISensor, IAddSensor>({
      query: ({ sensorData, installationPointId }) => ({
        url: `/installationPoints/${installationPointId}/sensors`,
        method: "POST",
        body: sensorData,
      }),
      invalidatesTags: [
        "Sensors",
        "InstallationPoints",
        "DashboardZone",
        "DashboardComplete",
      ],
    }),

    updateSensor: builder.mutation<ISensor, IUpdateSensor>({
      query: ({ sensorData, sensorId }) => ({
        url: `/sensors/${sensorId}`,
        method: "PUT",
        body: sensorData,
      }),
      invalidatesTags: [
        "Sensors",
        "InstallationPoints",
        "DashboardZone",
        "DashboardComplete",
        "Dashboard",
        "Reports",
      ],
    }),

    deleteSensor: builder.mutation<void, string>({
      query: (sensorId) => ({
        url: `/sensors/${sensorId}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "Sensors",
        "InstallationPoints",
        "DashboardZone",
        "DashboardComplete",
        "Dashboard",
      ],
    }),

    getSensorHistory: builder.query<ISensorFullPayload, ISensorHistoryQuery>({
      query: ({
        sensorNumber,
        from = "1970-01-01T00:00:00.000Z",
        to,
        page,
        size,
      }) => ({
        url: `/main/sensorPayload/search/date?sensorNumber=${sensorNumber}&from=${from}&to=${to}&page=${page}&size=${size}`,
        method: "GET",
      }),
      providesTags: ["Sensors"],
    }),

    updateSensorState: builder.mutation<ISensor, IUpdateSensorState>({
      query: ({ sensorId, state }) => ({
        url: `/sensors/${sensorId}/state`,
        method: "PATCH",
        body: { state },
      }),
      invalidatesTags: ["Sensors"],
    }),
  }),
});

export const {
  useGetSensorsByIdQuery,
  useGetSensorByIdQuery,
  useGetSensorByNumberQuery,
  useAddSensorMutation,
  useUpdateSensorMutation,
  useDeleteSensorMutation,
  useGetSensorHistoryQuery,
  useUpdateSensorStateMutation,
} = sensorControllerEndpoints;
