import { ReactNode } from "react";
import {
  SelectAction,
  SelectActionButton,
  SelectActionsWrapper,
  SelectMenuWrapper,
} from "./custom-menu.styles";
import IconButton from "../../icon-button/icon-button";
import AuthWrapper from "../../../auth/auth-wrapper";
import { superAdminRights } from "../../../interfaces/auth-wrapper";
import { useTranslation } from "react-i18next";

interface CustomMenuProps {
  innerProps: any;
  children: ReactNode;
  onAddClick: () => void;
  onEditClick: () => void;
}

const CustomMenu = ({
  innerProps,
  children,
  onAddClick,
  onEditClick,
}: CustomMenuProps) => {
  const { t } = useTranslation();

  return (
    <SelectMenuWrapper {...innerProps}>
      {children}
      <AuthWrapper allowedRoles={superAdminRights}>
        <SelectActionsWrapper>
          <SelectActionButton onClick={onAddClick}>
            <IconButton name="plus" />
            <SelectAction>{t("buttons.add.action")}</SelectAction>
          </SelectActionButton>
          <SelectActionButton onClick={onEditClick}>
            <IconButton name="pencil" />
            <SelectAction>{t("buttons.edit.action")}</SelectAction>
          </SelectActionButton>
        </SelectActionsWrapper>
      </AuthWrapper>
    </SelectMenuWrapper>
  );
};

export default CustomMenu;
