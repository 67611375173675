import styled from "@emotion/styled";

export const ImageSectionWrapper = styled.div`
  background: linear-gradient(to bottom, #fff 52.6%, #1f2937 47.4%);
  max-height: 600px;
`;

export const StyledLandingImage = styled.img`
  width: 100%;
  height: auto;
`;
