import { TFunction } from "i18next";
import Burger from "../landing-content/components/burger/burger";
import {
  BurgerContainer,
  MobileMenuContainer,
  MobileMenuWrapper,
  ShadowOverlay,
} from "../landing-content/components/header-mobile-menu/header-mobile-menu.styles";
import clsx from "clsx";
import { ReactNode } from "react";

interface IMobileMenuModal {
  isMenuOpen: boolean;
  handleOnMenuOpen: () => void;
  handleCloseMenu: () => void;
  t: TFunction<"translations", undefined>;
  mobileMenuContent: ReactNode;
}

const MobileMenuModal = ({
  handleCloseMenu,
  handleOnMenuOpen,
  isMenuOpen,
  t,
  mobileMenuContent,
}: IMobileMenuModal) => (
  <>
    <BurgerContainer onClick={handleOnMenuOpen}>
      <Burger isMenuOpen={isMenuOpen} t={t} />
    </BurgerContainer>
    <MobileMenuWrapper className={clsx(isMenuOpen && "isOpen")}>
      <MobileMenuContainer>{mobileMenuContent}</MobileMenuContainer>
    </MobileMenuWrapper>
    {isMenuOpen && <ShadowOverlay onClick={handleCloseMenu} />}
  </>
);

export default MobileMenuModal;
