import { SensorStateVariant } from "../../interfaces/sensors";
import {
  StatsDetail,
  StatsHeading,
  StatsValue,
  StyledStatsItem,
} from "./stats-item.styles";

interface IStatsItem {
  variant: SensorStateVariant;
  name: string;
  value: string | number;
  detail?: string | number;
  onClick: (variant: SensorStateVariant) => void;
}

export const StatsItem: React.FC<IStatsItem> = ({
  variant,
  detail,
  name,
  value,
  onClick,
}) => (
  <StyledStatsItem
    className={variant.toLowerCase()}
    onClick={() => onClick(variant)}
  >
    <StatsHeading>{name}</StatsHeading>
    <StatsValue>
      {value}
      {detail && <StatsDetail>{detail}</StatsDetail>}
    </StatsValue>
  </StyledStatsItem>
);
