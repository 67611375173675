import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ISensor } from "../../../interfaces/sensors";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { RefObject, useMemo, useRef, useState } from "react";
import { KypsTable } from "../kyps-table";
import { DetailsTableHeading } from "../location-details-table/location-details-table.styles";
import { Subheading } from "../../../styles/helper.styles";
import { useDeleteSensorMutation } from "../../../api/sensor.api";
import { createEditableSensorTableData } from "./editable-sensor-table.helper";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { IEditableSensorTableData } from "../../../interfaces/editable-cells";
import { createEditableSensorTableColumns } from "./editable-sensor-table.utils";
import useWindowSize from "../../../hooks/useWindowSize";
import useScrollTop from "../../../hooks/useScrollTop";
import useDeleteConfirm from "../../../hooks/useDeleteConfirm";
import DeleteConfirm from "../../delete-confirm/delete-confirm";
import SensorStateSelect from "../../kyps-select/sensor-state-select/sensor-state-select";

interface IEditableSensorTable {
  defaultIPoint: ISelectOption;
  defaultZone: ISelectOption;
  isDisabled: boolean;
  sensorData: ISensor;
  sidebarRef: RefObject<HTMLDivElement>;
  closeModal: () => void;
}

const EditableSensorTable = ({
  defaultIPoint,
  defaultZone,
  isDisabled,
  sensorData,
  sidebarRef,
  closeModal,
}: IEditableSensorTable) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(({ user }) => user.user?.role);

  const [editedRows, setEditedRows] = useState({});
  const [selectedId, setSelectedId] = useState<string>("");
  const tableRef = useRef<HTMLDivElement>(null);

  const [deleteSensor] = useDeleteSensorMutation();

  const { sidebarExtraSpace } = useWindowSize({ sidebarRef });
  const confirmScrollTop = useScrollTop(sidebarRef);
  const {
    closeDeleteConfirm,
    deleteConfirmName,
    deleteFunction,
    isDeleteConfirmOpen,
    openDeleteConfirm,
    openerParams,
  } = useDeleteConfirm(tableRef, sidebarExtraSpace, confirmScrollTop);

  const data: IEditableSensorTableData[] = useMemo(() => {
    if (userRole)
      return createEditableSensorTableData({
        defaultIPoint,
        defaultZone,
        sensorData,
        isDisabled,
      });

    return [];
  }, [defaultIPoint, defaultZone, isDisabled, sensorData, userRole]);

  const table = useReactTable({
    data,
    columns: createEditableSensorTableColumns({
      isConfirmOpen: isDeleteConfirmOpen,
      isDisabled,
      sensorData,
      selectedId,
      closeModal,
      deleteSensor,
      setSelectedId,
      openDeleteConfirm,
      t,
    }),
    getCoreRowModel: getCoreRowModel(),
    state: { columnPinning: { left: ["name"] } },
    meta: {
      editedRows,
      setEditedRows,
    },
    autoResetPageIndex: false,
  });

  return (
    <>
      <KypsTable
        table={table}
        isTheadHidden
        className="details-table"
        hasNoActions
        hidePagination
        hideSorting
        tableHeaderElements={[
          <DetailsTableHeading key="General Information">
            <Subheading>{t("tabs.generalInformation")}</Subheading>
            <SensorStateSelect
              sensorId={sensorData.id}
              sensorState={sensorData.state}
            />
          </DetailsTableHeading>,
        ]}
        tableRef={tableRef}
      />
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
          confirmContainer={tableRef}
        />
      )}
    </>
  );
};

export default EditableSensorTable;
