import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const headerMobileHeight = "58px";
export const headerDesktopHeight = "64px";

export const Header = styled.header`
  ${({ theme: { palette, zIndex } }) => css`
    position: sticky;
    top: 0;
    box-shadow: 0px 1px 3px 0px #0000001a;
    background-color: ${palette.background.default};
    z-index: ${zIndex.header};
  `}
`;

export const Logo = styled.img`
  height: 32px;
  vertical-align: top;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  min-height: ${headerMobileHeight};

  @media (min-width: 768px) {
    min-height: ${headerDesktopHeight};
    padding: 0 32px;
  }
`;

export const AdminPanel = styled.div`
  display: none;

  @media (min-width: 992px) {
    margin: 0 0 0 auto;
    align-items: center;
    justify-content: space-between;
    display: flex;
  }
`;

export const UserEmailWrapper = styled.div`
  color: #111827;
  font-weight: 500;
`;
