import styled from "@emotion/styled";

export const BenefitsDescriptionContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto 56px;
`;

export const BenefitsItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
