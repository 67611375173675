import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const showMobileNavWidth = "768px";

export const NavLinksList = styled.ul`
  margin: 0;
  padding: 0;
  display: none;

  @media (min-width: ${showMobileNavWidth}) {
    margin-left: 24px;
    display: flex;
    align-items: center;
  }
`;

export const NavLinkItem = styled.li`
  ${({ theme }) => css`
    font-weight: 500;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &.landing {
      a {
        border: none;
        color: ${theme.palette.text.primary};
      }
    }

    a {
      display: inline-block;
      padding: 23px 4px 19px;
      border-bottom: 2px solid transparent;

      &:hover {
        border-color: ${theme.palette.border.default};
        color: ${theme.palette.text.primary};
        opacity: 0.8;
      }

      &.active {
        border-color: ${theme.palette.border.default};
        color: ${theme.palette.text.primary};

        &:hover {
          opacity: 1;
        }
      }
    }
  `}
`;
