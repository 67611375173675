import {
  GatewayListHeading,
  GatewayListItem,
  GatewayListWrapper,
  GatewayValue,
} from "./gateway-components.styles";
import IconButton from "../icon-button/icon-button";
import EmptyPage from "../kyps-empty/kyps-empty-page";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IGatewayTable {
  gatewayNumbers: string[];
  onDelete: (arg: string) => void;
}

const GatewayTable = ({ gatewayNumbers, onDelete }: IGatewayTable) => {
  const { t } = useTranslation();

  const gatewayNumbersList = useMemo(
    () =>
      gatewayNumbers.map((gatewayNumber) => (
        <GatewayListItem key={gatewayNumber}>
          <GatewayValue>{gatewayNumber}</GatewayValue>
          <IconButton
            name="bin"
            hoverColor="warning"
            onClick={() => onDelete(gatewayNumber)}
          />
        </GatewayListItem>
      )),
    [gatewayNumbers, onDelete]
  );

  return (
    <GatewayListWrapper>
      {!isEmpty(gatewayNumbers) ? (
        <>
          <GatewayListHeading>
            {t("forms.fieldNames.gatewayNumbers")}
          </GatewayListHeading>
          {gatewayNumbersList}
        </>
      ) : (
        <EmptyPage
          heading={t("emptyMessage.text", {
            entity: t("emptyMessage.entities.gatewayNumber"),
          })}
          iconName="clipboard"
          isSmall
        />
      )}
    </GatewayListWrapper>
  );
};

export default GatewayTable;
