import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const UserIconWrapper = styled.div`
  ${({ theme }) =>
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background-color: #0369a1;
      color: ${theme.palette.background.default};
      font-size: 14px;
      border-radius: 50%;
      font-weight: 800;
      cursor: pointer;
      flex-shrink: 0;

      &.large {
        font-size: 18px;
        width: 48px;
        height: 48px;
        cursor: auto;
      }
    `}
`;
