import { css } from "@emotion/react";
import styled from "@emotion/styled";
import PhoneInput from "react-phone-input-2";
import chevron from "../../assets/Chevron.svg";

export const FormWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 32px;
  `}
`;

export const FormHeader = styled.div`
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const FormLabel = styled.label`
  ${({ theme }) => css`
    color: ${theme.palette.text.dark};
    font-weight: 500;
    display: block;
    padding-bottom: 4px;
    position: relative;
    cursor: pointer;

    &.at-sign::before {
      position: absolute;
      content: "@";
      bottom: -41px;
      left: 1px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 10px 12px;
      width: 38px;
      border-radius: 5px 0 0 5px;
      border-right: 1px solid ${theme.palette.background.disable};
      color: ${theme.palette.text.default};
    }
  `}
`;

export const FormInput = styled.input`
  ${({ theme }) => css`
    width: 100%;
    line-height: 20px;
    font-size: 14px;
    border-radius: 6px;
    padding: 10px 12px;
    border: 1px solid ${theme.palette.background.disable};
    background-color: ${theme.palette.background.default};
    color: ${theme.palette.text.primary};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    outline: none;
    height: 32px;

    @media (min-width: 768px) {
      height: auto;
    }

    &.at-sign {
      padding-left: 50px;
    }
  `}
`;

export const FormInputItem = styled.div`
  ${({ theme }) => css`
    :not(:last-of-type) {
      margin-bottom: 16px;
    }
  `}
`;

export const FormLabelWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
  `}
`;

export const ErrorMessage = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    font-weight: 400;
    margin-left: 10px;
    color: ${theme.palette.error.default};
  `}
`;

export const StyledPhoneInput = styled(PhoneInput)`
  ${({ theme }) => css`
    width: 100%;
    border-radius: 6px;
    border: 1px solid ${theme.palette.background.disable};
    background-color: ${theme.palette.background.default};
    color: ${theme.palette.text.primary};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    outline: none;

    * {
      font-size: 14px !important;
    }

    &.react-tel-input .form-control {
      position: relative;
      height: auto;
      width: 100%;
      padding: 10px 12px 10px 69px;
      border: none;
      border-radius: 6px;
      line-height: 20px;
    }

    &.react-tel-input .flag-dropdown {
      border-radius: 6px 0 0 6px;
      background-color: ${theme.palette.background.default};
      border: none;
    }

    .selected-flag {
      padding: 9px 13px;
      width: 69px;
      border-radius: 6px 0 0 6px;
    }

    &.react-tel-input .flag-dropdown.open .selected-flag {
      border-radius: 6px 0 0 6px;
    }

    .flag .arrow {
      position: absolute;
      top: 0;
      left: 25px;
      bottom: 0;
      margin: auto 0;
      width: 10px;
      height: 6px;
      border: none;
      background-image: url(${chevron});
      background-repeat: no-repeat;
      background-size: auto;
    }

    &.react-tel-input .selected-flag .arrow.up {
      border: none;
      rotate: 180deg;
    }
  `}
`;
