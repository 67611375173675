import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const EditCell = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.border.secondary};
    font-weight: 500;
    flex: 1;
    text-align: end;
    padding-right: 20px;
    cursor: pointer;
  `}
`;
