import { TFunction } from "i18next";

export const formatDateForDisplay = (
  date: Date,
  t: TFunction<"translations", undefined>
) => {
  if (!date) return "";
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return t("time.today");
  } else if (date.toDateString() === yesterday.toDateString()) {
    return t("time.yesterday");
  } else {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
};

export const updateDate = (date: Date, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);

  return newDate;
};
