import {
  PaginationState,
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { IFullStatsContent } from "../../../interfaces/dashboard";
import { KypsTable } from "../kyps-table";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Subheading } from "../../../styles/helper.styles";
import KypsInput from "../../kyps-input/kyps-input";
import { useDeleteLocationByIdMutation } from "../../../api/location.api";
import KypsModal from "../../kyps-modal/kyps-modal";
import useModal from "../../../hooks/useModal";
import { useTranslation } from "react-i18next";
import { createLocationsColumns } from "./locations-table.utils";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { UserRolesEnum } from "../../../interfaces/roles";
import { handleOnDeleteLocation } from "./locations-table.helper";
import { SearchLocationWrapper } from "./locations-table.styles";
import useDeleteConfirm from "../../../hooks/useDeleteConfirm";
import DeleteConfirm from "../../delete-confirm/delete-confirm";

export interface ILocationsTable {
  data: IFullStatsContent[];
  totalPages: number;
  pageIndex: number;
  pageSize: number;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
}

const LocationsTable = ({
  data,
  totalPages,
  pageIndex,
  pageSize,
  setPagination,
}: ILocationsTable) => {
  const userRole = useAppSelector(
    ({ user }) => user.user?.role || UserRolesEnum.USER
  );

  const [sorting, setSorting] = useState<SortingState>([
    { id: "locationName", desc: false },
  ]);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [deleteLocation] = useDeleteLocationByIdMutation();

  const {
    closeDeleteConfirm,
    deleteConfirmName,
    deleteFunction,
    isDeleteConfirmOpen,
    openDeleteConfirm,
    openerParams,
  } = useDeleteConfirm(scrollContainerRef);
  const { isModalOpen, currentModal, closeAndResetModal, openModal } =
    useModal();
  const { t } = useTranslation();

  const onDeleteLocation = (locationId: string, locationName: string) => {
    handleOnDeleteLocation({
      locationId,
      locationName,
      t,
      deleteLocation,
    });
  };

  const tableHeaderElements = [
    <Subheading key="Locations">{t("pages.dashboard.locations")}</Subheading>,
    <SearchLocationWrapper key="SearchLocationWrapper">
      <KypsInput
        inputValue=""
        name="search"
        placeholder={`${t("buttons.search.action")}...`}
        type="search"
        hasIcon
      />
    </SearchLocationWrapper>,
  ];

  const table = useReactTable({
    data,
    columns: createLocationsColumns({
      userRole,
      openModal,
      closeAndResetModal,
      onDeleteLocation,
      openDeleteConfirm,
      t,
    }),
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination: { pageIndex, pageSize },
      columnPinning: { left: ["locationName"] },
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    sortDescFirst: false,
    manualPagination: true,
    pageCount: totalPages,
    autoResetPageIndex: false,
  });

  return (
    <>
      <KypsTable
        table={table}
        tableHeaderElements={tableHeaderElements}
        tableRef={scrollContainerRef}
        isTransparentBg
      />
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
        />
      )}
    </>
  );
};

export default LocationsTable;
