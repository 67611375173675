import { useState, useEffect, RefObject } from "react";
import { debounce, isNumber } from "lodash";

interface IWindowParams {
  width: number;
  height: number;
}

interface WindowSize {
  width: number;
  height: number;
  sidebarExtraSpace: number;
}

interface IUseWindowSize {
  sidebarRef?: RefObject<HTMLDivElement>;
  debounceTime?: number;
}

const useWindowSize = ({
  sidebarRef,
  debounceTime = 100,
}: IUseWindowSize): WindowSize => {
  const [windowSize, setWindowSize] = useState<IWindowParams>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [sidebarExtraSpace, setSidebarExtraSpace] = useState<number>(0);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      if (sidebarRef?.current && isNumber(sidebarRef.current.offsetWidth)) {
        setSidebarExtraSpace(
          window.innerWidth - sidebarRef.current.offsetWidth
        );
      }
    }, debounceTime);
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [debounceTime, sidebarRef]);

  return { ...windowSize, sidebarExtraSpace };
};

export default useWindowSize;
