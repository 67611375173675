import { CSSProperties, SetStateAction } from "react";
import { IPositionCoords } from "../../interfaces/delete-confirm";

interface IFitConfirmPosition {
  confirmProperties: DOMRect;
  setConfirmStyles: (value: SetStateAction<CSSProperties>) => void;
  openerPosition: IPositionCoords;
  shouldBeVisible: boolean;
}

export const fitConfirmPosition = ({
  confirmProperties,
  openerPosition,
  shouldBeVisible,
  setConfirmStyles,
}: IFitConfirmPosition) => {
  const { bottom, height, width } = confirmProperties;
  const visibleScreenHeight = document.documentElement.clientHeight;
  const visibleScreenWidth = document.documentElement.clientWidth;
  const newStyle: CSSProperties = {};

  const appWrapperPadding = visibleScreenWidth >= 768 ? 32 : 16;
  const navBottomHeight = visibleScreenWidth >= 768 ? 0 : 58;

  if (openerPosition.x - width <= 0) {
    newStyle.right = "unset";
    newStyle.left = appWrapperPadding;
  }

  if (
    bottom + height + appWrapperPadding + navBottomHeight >
    visibleScreenHeight
  )
    newStyle.top = openerPosition.y - height;

  setConfirmStyles({
    ...newStyle,
    visibility: shouldBeVisible ? "visible" : "hidden",
    transform: "none",
  });
};
