import { createColumnHelper } from "@tanstack/react-table";
import { ISensorHistoryPayload } from "../../../interfaces/sensors";
import { toLocalTime } from "../../../helpers/check-date.helper";
import { addTemperatureSign } from "./sensor-history-table.helper";
import { checkBatteryLevel, checkSignalLevel } from "../kyps-table.helper";
import { TFunction } from "i18next";
import { getColumnWidth } from "../kyps-table.utils";

interface ICreateSensorHistoryColumns {
  showHumidity: boolean;
  t: TFunction<"translations", undefined>;
}

const { accessor } = createColumnHelper<ISensorHistoryPayload>();

export const createSensorHistoryColumns = ({
  showHumidity,
  t,
}: ICreateSensorHistoryColumns) => {
  const allColumns = [
    accessor("sensorTime", {
      header: t("tables.timeOfMeasurement"),
      size: getColumnWidth("sensorHistory", "sensorTime"),
      cell: ({ getValue }) => <>{toLocalTime(getValue())}</>,
    }),
    accessor("sensorTemp", {
      header: t("tables.temperature"),
      size: getColumnWidth("sensorHistory", "sensorTemp"),
      cell: ({ getValue }) => <>{addTemperatureSign(+getValue().toFixed(1))}</>,
    }),
    accessor("sensorHum", {
      header: t("tables.humidity"),
      size: getColumnWidth("sensorHistory", "sensorHum"),
      cell: ({ getValue }) => <>{getValue().toFixed(1)}%</>,
    }),
    accessor("voltage", {
      header: t("tables.battery"),
      size: getColumnWidth("sensorHistory", "voltage"),
      cell: ({ getValue }) => <>{checkBatteryLevel(getValue())}</>,
    }),
    accessor("currentDbm", {
      header: t("tables.signal"),
      size: getColumnWidth("sensorHistory", "currentDbm"),
      cell: ({ getValue }) => <>{checkSignalLevel(getValue())}</>,
    }),
    accessor("gatewayNumber", {
      header: t("tables.gateway"),
      size: getColumnWidth("sensorHistory", "gatewayNumber"),
    }),
  ];

  return showHumidity
    ? allColumns
    : allColumns.slice(0, 2).concat(allColumns.slice(3));
};
