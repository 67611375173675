import { useCallback, useMemo, useRef } from "react";
import KypsSidebar from "../kyps-sidebar";
import { KypsButton } from "../../kyps-button/kyps-button";
import KypsForm from "../../kyps-form/kyps-form";
import { IAddManager, IManagerFields } from "../../../interfaces/managers";
import { createAddManagerFields } from "../add-manager-sidebar/add-manager-sidebar.utils";
import { useUpdateManagerByIdMutation } from "../../../api/managers.api";
import AttachLocationTable from "../../kyps-tables/attach-location-table/attach-location-table";
import { useGetManagerLocationsQuery } from "../../../api/location-manager.api";
import Loaders from "../../loaders/loaders";
import { onSubmitEditManager } from "./edit-manager.sidebar.utils";
import {
  getValidPhoneValue,
  getValidTelegramId,
} from "../../../helpers/validation.helper";
import { TFunction } from "i18next";

export interface IEditManagerData extends Omit<IAddManager, "status"> {
  id: string;
}

export interface IEditManagerSidebar {
  currentManager: IEditManagerData;
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
}

const EditManagerSidebar = ({
  currentManager,
  closeModal,
  t,
}: IEditManagerSidebar): JSX.Element => {
  const [updateManager] = useUpdateManagerByIdMutation();
  const { data: managerLocations, isLoading } = useGetManagerLocationsQuery(
    currentManager.id,
    {
      skip: !currentManager.id,
    }
  );

  const editFields = createAddManagerFields(t).map((field) => {
    const value = currentManager[field.name as keyof IManagerFields] ?? "";
    if (field.name === "telegramId" && !value) {
      return { ...field, value: "" };
    }

    return { ...field, value };
  });

  const formRef = useRef<HTMLFormElement>(null);
  const handleEditManagerFormSubmit = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmit = useCallback(
    (fieldsData: IEditManagerData) => {
      onSubmitEditManager({
        managerData: {
          data: {
            ...fieldsData,
            phoneNumber: getValidPhoneValue(fieldsData.phoneNumber),
            telegramId: getValidTelegramId(fieldsData.telegramId),
          },
          id: currentManager.id,
        },
        closeModal,
        updateManager,
      });
    },
    [closeModal, currentManager, updateManager]
  );

  const renderSidebarContent = useMemo(() => {
    if (isLoading) return [<Loaders key="Loader" variant="sidebar" />];

    return [
      <KypsForm
        fields={editFields}
        onSubmit={onSubmit}
        ref={formRef}
        key="EditManagerForm"
      />,
      <AttachLocationTable
        managerLocations={managerLocations ?? []}
        currentManager={currentManager.id}
        key="AttachLocationTable"
        t={t}
      />,
    ];
  }, [currentManager.id, editFields, isLoading, managerLocations, onSubmit, t]);

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: t("buttons.edit.actionWithKey", { key: currentManager.name }),
        buttons: [
          <KypsButton
            variant="light"
            placeholder={t("buttons.cancel.action")}
            onClick={closeModal}
            key="Cancel"
          />,
          <KypsButton
            variant="default"
            placeholder={t("buttons.save.action")}
            onClick={handleEditManagerFormSubmit}
            key="Edit manager"
          />,
        ],
      }}
      content={renderSidebarContent}
    />
  );
};

export default EditManagerSidebar;
