import { NavLink } from "react-router-dom";
import IconButton from "../../icon-button/icon-button";
import EditLocationSidebar from "../../kyps-sidebar/edit-location-sidebar/edit-location-sidebar";
import { CellMaxContent, FixedCell } from "../kyps-table.styles";
import { createColumnHelper } from "@tanstack/react-table";
import { IFullStatsContent } from "../../../interfaces/dashboard";
import { checkDate } from "../../../helpers/check-date.helper";
import { ReactNode } from "react";
import AuthWrapper from "../../../auth/auth-wrapper";
import { adminRights, managersRights } from "../../../interfaces/auth-wrapper";
import KypsTooltip from "../../kyps-tooltip/kyps-tooltip";
import { TFunction } from "i18next";
import { UserRolesEnum } from "../../../interfaces/roles";
import { getIsAllowedForManager } from "./locations-table.helper";
import { PagesPathsEnum } from "../../../utils/pages-paths";
import { getColumnWidth } from "../kyps-table.utils";
import { ColoredCell } from "./locations-table.styles";
import { IOpenDeleteConfirm } from "../../../hooks/useDeleteConfirm";
import TooltipCell from "../../kyps-tooltip/tooltip-cell/tooltip-cell";

interface ILocationsTableColumns {
  userRole: UserRolesEnum;
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  onDeleteLocation: (locationId: string, name: string) => void;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  t: TFunction<"translations", undefined>;
}

const { accessor, display } = createColumnHelper<IFullStatsContent>();
export const createLocationsColumns = ({
  userRole,
  openModal,
  closeAndResetModal,
  onDeleteLocation,
  openDeleteConfirm,
  t,
}: ILocationsTableColumns) => [
  accessor("locationName", {
    header: t("tables.title"),
    size: getColumnWidth("locations", "locationName"),
    cell: ({
      getValue,
      row: {
        original: { locationId },
      },
    }) => (
      <KypsTooltip
        tooltipValue={getValue()}
        children={
          <NavLink
            to={`/${PagesPathsEnum.DASHBOARD}/${PagesPathsEnum.LOCATION}=${locationId}`}
          >
            <FixedCell className="link-cell">{getValue()}</FixedCell>
          </NavLink>
        }
      />
    ),
  }),
  accessor("locationAddress", {
    header: t("tables.address"),
    size: getColumnWidth("locations", "locationAddress"),
    cell: ({ getValue }) => <TooltipCell value={getValue()} />,
  }),
  accessor("sensorStats.total", {
    header: t("tables.sensors"),
    size: getColumnWidth("locations", "total"),
    cell: ({ getValue }) => (
      <ColoredCell className="darken">{getValue()}</ColoredCell>
    ),
  }),
  accessor("sensorStats.outRange", {
    header: t("tables.outRange"),
    size: getColumnWidth("locations", "outRange"),
    cell: ({ getValue }) => (
      <ColoredCell className="error">{getValue()}</ColoredCell>
    ),
  }),
  accessor("sensorStats.attention", {
    header: t("tables.attention"),
    size: getColumnWidth("locations", "attention"),
    cell: ({ getValue }) => (
      <ColoredCell className="attention">{getValue()}</ColoredCell>
    ),
  }),
  accessor("sensorStats.offline", {
    header: t("tables.offline"),
    size: getColumnWidth("locations", "offline"),
    cell: ({ getValue }) => <ColoredCell>{getValue()}</ColoredCell>,
  }),
  accessor("managers", {
    header: t("tables.managers"),
    size: getColumnWidth("locations", "managers"),
    cell: ({ getValue }) => <ColoredCell>{getValue()}</ColoredCell>,
  }),
  accessor("sensorStats.lastUpdate", {
    header: t("tables.lastUpdate"),
    size: getColumnWidth("locations", "lastUpdate"),
    cell: ({ getValue }) => (
      <CellMaxContent>{checkDate(getValue(), t)}</CellMaxContent>
    ),
  }),
  display({
    size: getColumnWidth("locations", "actions"),
    cell: ({
      row: {
        original: { locationId, locationName },
      },
    }) => (
      <>
        <AuthWrapper
          allowedRoles={managersRights}
          additionalCheck={getIsAllowedForManager(locationId, userRole)}
        >
          <IconButton
            hoverColor="info"
            name="pencil"
            onClick={() =>
              openModal(
                <EditLocationSidebar
                  closeModal={closeAndResetModal}
                  locationId={locationId}
                />
              )
            }
          />
        </AuthWrapper>
        <AuthWrapper allowedRoles={adminRights}>
          <IconButton
            hoverColor="warning"
            name="bin"
            hasZeroMargin
            onClick={(e) =>
              openDeleteConfirm({
                deleteConfirmName: locationName,
                deleteFunction: () =>
                  onDeleteLocation(locationId, locationName),
                openerParamsProp: e.currentTarget.getBoundingClientRect(),
              })
            }
          />
        </AuthWrapper>
      </>
    ),
    id: "actions",
  }),
];
