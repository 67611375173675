import { isEmpty } from "lodash";
import SensorCard from "../sensor-card/sensor-card";
import { SensorCardsWrapper } from "../sensor-card/sensor-card.styles";
import EmptyPage from "../kyps-empty/kyps-empty-page";
import { ReactNode, useMemo, useRef, useState } from "react";
import Loaders from "../loaders/loaders";
import { ISensorCardWithZone } from "../../interfaces/dashboard";
import { UserRoles } from "../../interfaces/roles";
import { TFunction } from "i18next";
import DropdownMenu from "../dropdown-menu/dropdown-menu";
import useDropdownMenu from "../../hooks/useDropdownMenu";
import DeleteConfirm from "../delete-confirm/delete-confirm";
import useDeleteConfirm from "../../hooks/useDeleteConfirm";

interface ISensorsGrid {
  data: ISensorCardWithZone[];
  isLoading: boolean;
  userRole: UserRoles;
  minDate: Date | null;
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  t: TFunction<"translations", undefined>;
}

const SensorsGrid = ({
  data,
  isLoading,
  minDate,
  userRole,
  closeAndResetModal,
  openModal,
  t,
}: ISensorsGrid) => {
  const [selectedId, setSelectedId] = useState<string>("");
  const gridRef = useRef<HTMLDivElement>(null);

  const {
    dropdownMenuPosition,
    isDropdownMenuOpen,
    menuItems,
    openDropdownMenu,
    setIsDropdownMenuOpen,
  } = useDropdownMenu(gridRef);
  const {
    closeDeleteConfirm,
    deleteConfirmName,
    deleteFunction,
    isDeleteConfirmOpen,
    openDeleteConfirm,
    openerParams,
  } = useDeleteConfirm(gridRef);

  const renderCards = useMemo(
    () =>
      data.map((sensor) => (
        <SensorCard
          sensorData={sensor}
          key={sensor.sensorNumber}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          closeAndResetModal={closeAndResetModal}
          openModal={openModal}
          minDate={minDate}
          openDropdownMenu={openDropdownMenu}
          isMenuOpen={isDropdownMenuOpen}
          openDeleteConfirm={openDeleteConfirm}
          userRole={userRole}
          t={t}
        />
      )),
    [
      closeAndResetModal,
      data,
      isDropdownMenuOpen,
      minDate,
      openDeleteConfirm,
      openDropdownMenu,
      openModal,
      selectedId,
      t,
      userRole,
    ]
  );

  if (isLoading) return <Loaders variant="sensorCards" />;

  if (isEmpty(data))
    return (
      <EmptyPage
        heading={t("emptyMessage.text", {
          entity: t("emptyMessage.entities.sensor"),
        })}
        iconName="clipboard"
      />
    );

  return (
    <>
      <SensorCardsWrapper ref={gridRef}>{renderCards}</SensorCardsWrapper>
      {isDropdownMenuOpen && (
        <DropdownMenu
          menuItems={menuItems}
          position={dropdownMenuPosition}
          isOpen={isDropdownMenuOpen}
          setIsDropdownMenuOpen={setIsDropdownMenuOpen}
        />
      )}
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
        />
      )}
    </>
  );
};

export default SensorsGrid;
