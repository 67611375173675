import {
  useAttachManagerByLocationIdMutation,
  useUpdateManagerByLocationIdMutation,
} from "../api/location-manager.api";
import { useUpdateLocationByIdMutation } from "../api/location.api";
import { useUpdateManagerByIdMutation } from "../api/managers.api";
import { useUpdateSensorMutation } from "../api/sensor.api";
import { useUpdateZoneMutation } from "../api/zone.api";
import { IUpdateFunctions } from "../interfaces/update-functions";

const useUpdateFunctions = () => {
  const [updateZone] = useUpdateZoneMutation();
  const [updateLocation] = useUpdateLocationByIdMutation();
  const [updateManagerNotifications] = useUpdateManagerByLocationIdMutation();
  const [updateSensor] = useUpdateSensorMutation();
  const [updateManager] = useUpdateManagerByIdMutation();
  const [attachManager] = useAttachManagerByLocationIdMutation();

  const updateFunctions: IUpdateFunctions = {
    updateLocation,
    updateZone,
    updateManagerNotifications,
    updateSensor,
    updateManager,
    attachManager,
  };

  return {
    updateFunctions,
  };
};

export default useUpdateFunctions;
