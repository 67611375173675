import {
  useMemo,
  useEffect,
  useCallback,
  useRef,
  useState,
  CSSProperties,
  RefObject,
} from "react";
import { createPortal } from "react-dom";
import { fitConfirmPosition } from "./delete-confirm.helper";
import {
  ConfirmButtons,
  ConfirmMessageNameWrapper,
  ConfirmMessageText,
  DeleteConfirmMessageWrapper,
  DeleteConfirmWrapper,
} from "./delete-confirm.styles";
import IconButton from "../icon-button/icon-button";
import { KypsButton } from "../kyps-button/kyps-button";
import { SelectedDOMRect } from "../../hooks/useDeleteConfirm";
import { t } from "i18next";

interface IDeleteConfirm {
  isDeleteConfirmOpen: boolean;
  deleteConfirmName: string;
  openerParams: SelectedDOMRect;
  closeDeleteConfirm: () => void;
  deleteFunction: () => void;
  confirmContainer?: RefObject<HTMLDivElement>;
  message?: string;
}

const DeleteConfirm = ({
  isDeleteConfirmOpen,
  deleteConfirmName,
  openerParams,
  closeDeleteConfirm,
  deleteFunction,
  confirmContainer,
  message,
}: IDeleteConfirm) => {
  const deleteConfirmRef = useRef<HTMLDivElement>(null);
  const [confirmStyles, setConfirmStyles] = useState<CSSProperties>({
    visibility: "hidden",
  });

  useEffect(() => {
    if (isDeleteConfirmOpen && deleteConfirmRef.current) {
      fitConfirmPosition({
        confirmProperties: deleteConfirmRef.current.getBoundingClientRect(),
        setConfirmStyles,
        openerPosition: { x: openerParams.x, y: openerParams.y },
        shouldBeVisible: !!deleteConfirmRef.current,
      });
    }
  }, [isDeleteConfirmOpen, openerParams.x, openerParams.y]);

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (
        deleteConfirmRef.current &&
        !deleteConfirmRef.current.contains(event.target as Node)
      )
        closeDeleteConfirm();
    },
    [closeDeleteConfirm]
  );

  useEffect(() => {
    if (isDeleteConfirmOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDeleteConfirmOpen, handleOutsideClick]);

  const portalContent = useMemo(
    () => (
      <DeleteConfirmWrapper
        ref={deleteConfirmRef}
        style={{
          left: deleteConfirmRef.current
            ? openerParams.x -
              deleteConfirmRef.current.getBoundingClientRect().width
            : "-100%",
          top: openerParams.y + openerParams.height,
          transform: `translateY(calc(-100% - ${openerParams.height}px))`,
          ...confirmStyles,
        }}
      >
        <IconButton
          hoverColor="none"
          name="warning"
          asIcon
          isLarge
          hasZeroMargin
        />
        <DeleteConfirmMessageWrapper>
          <ConfirmMessageText>{`${
            message ?? t("confirmMessages.delete")
          } `}</ConfirmMessageText>
          <ConfirmMessageNameWrapper>
            {`"${deleteConfirmName}"`}
            <span>?</span>
          </ConfirmMessageNameWrapper>
        </DeleteConfirmMessageWrapper>
        <ConfirmButtons>
          <KypsButton
            placeholder={t("buttons.cancel.action")}
            onClick={closeDeleteConfirm}
            variant="light"
          />
          <KypsButton
            placeholder={t("buttons.delete.action")}
            onClick={() => {
              deleteFunction();
              closeDeleteConfirm();
            }}
            variant="warning"
          />
        </ConfirmButtons>
      </DeleteConfirmWrapper>
    ),
    [
      openerParams.x,
      openerParams.y,
      openerParams.height,
      confirmStyles,
      message,
      deleteConfirmName,
      closeDeleteConfirm,
      deleteFunction,
    ]
  );

  return createPortal(
    portalContent,
    confirmContainer?.current ?? document.body
  );
};

export default DeleteConfirm;
