import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const SensorStatsNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 100%;
`;

export const SensorStatsName = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: baseline;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    line-height: 24px;

    &.clickable {
      > div {
        cursor: pointer;
      }
    }

    &.active {
      color: ${theme.palette.border.secondary};
    }
  `}
`;

export const SensorSubName = styled.div`
  ${({ theme }) => css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    line-height: 100% !important;
    color: ${theme.palette.text.default};
    position: relative;
  `}
`;
