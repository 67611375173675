import styled from "@emotion/styled";
import Arrow from "../../assets/Chevron.svg";
import { css } from "@emotion/react";

export const LanguageSwitcherWrapper = styled.div`
  margin-right: 16px;
  position: relative;
  display: inline-block;
`;

export const SelectedLanguageWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.dark};
    border: 1px solid ${theme.palette.background.light};
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    height: 100%;
    padding: 7px 26px 7px 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 92px;

    &:hover {
      border-color: ${theme.palette.background.dark};
    }

    &.open {
      box-shadow: 0 0 0 1px ${theme.palette.background.light};
    }

    &.open::after,
    &:hover::after {
      filter: brightness(0.5);
    }

    &::after {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 8px;
      margin: auto 0;
      content: "";
      width: 12px;
      height: 6px;
      pointer-events: none;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${Arrow});
    }
  `}
`;

export const LanguagesWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 130%;
    background-color: ${theme.palette.background.default};
    border: 1px solid ${theme.palette.background.light};
    border-radius: 4px;
    overflow: hidden;
    display: none;

    &.open {
      display: block;
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    }
  `}
`;

export const SelectableLanguageItem = styled.div`
  ${({ theme }) => css`
    padding: 7px 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    &:hover {
      background-color: ${theme.palette.background.light};
    }

    &.selected {
      background-color: ${theme.palette.background.disable};
      cursor: auto;
      pointer-events: none;
    }
  `}
`;
