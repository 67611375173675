import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { theme } from "./theme";
import { Global, ThemeProvider } from "@emotion/react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { globalStyles } from "./styles/global";
import { store } from "./store/store";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import "./i18n";
import NotificationHolder from "./components/toaster/toaster";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "check-sso",
        checkLoginIframe: false,
      }}
      LoadingComponent={<></>}
    >
      <React.StrictMode>
        <Global styles={globalStyles} />
        <ReduxProvider store={store}>
          <Router>
            <ThemeProvider theme={theme}>
              <App />
              <NotificationHolder />
            </ThemeProvider>
          </Router>
        </ReduxProvider>
      </React.StrictMode>
    </ReactKeycloakProvider>
  </>
);
