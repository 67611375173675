import ReactGA from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";

export interface ICustomPageViewObj {
  page: string;
  title: string;
  hitType?: string;
}

const useGA = () => {
  const sendGAEvent = (params: UaEventOptions) => {
    ReactGA.event(params);
  };

  const sendGAPageView = ({
    page,
    title,
    hitType = "pageview",
  }: ICustomPageViewObj) => {
    ReactGA.send({ hitType, title, page });
  };

  return { sendGAEvent, sendGAPageView };
};

export default useGA;
