import { SetStateAction } from "react";
import { ISensor } from "../../../interfaces/sensors";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { DeleteFunction } from "../../../interfaces/delete-function";
import { TFunction } from "i18next";
import {
  EditableSensorFieldsEnum,
  IEditableSensorTableData,
} from "../../../interfaces/editable-cells";
import { createNotification } from "../../toaster/toaster.helper";

interface ICreateLocationTableData {
  defaultZone: ISelectOption;
  defaultIPoint: ISelectOption;
  sensorData: ISensor;
  isDisabled: boolean;
}

interface ITranslateCellName {
  t: TFunction<"translations", undefined>;
  cellName: EditableSensorFieldsEnum;
}

interface IOnDeleteSensorHandler {
  sensorData: ISensor;
  closeModal: () => void;
  setSelectedId: (value: SetStateAction<string>) => void;
  deleteSensor: DeleteFunction;
}

export const onDeleteSensorHandler = ({
  sensorData,
  closeModal,
  deleteSensor,
  setSelectedId,
}: IOnDeleteSensorHandler) => {
  closeModal();
  setSelectedId("");

  deleteSensor(sensorData.id)
    .unwrap()
    .then(() =>
      createNotification({
        message: `${sensorData.sensorNumber} was successfully deleted!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};

export const createEditableSensorTableData = ({
  defaultIPoint,
  defaultZone,
  sensorData,
  isDisabled,
}: ICreateLocationTableData): IEditableSensorTableData[] => {
  const baseProperties: IEditableSensorTableData[] = [
    {
      name: EditableSensorFieldsEnum.ZONE,
      value: defaultZone.label,
      id: defaultZone.value,
    },
    {
      name: EditableSensorFieldsEnum.POINT,
      value: defaultIPoint.label,
      id: defaultIPoint.value,
    },
    {
      name: EditableSensorFieldsEnum.SENSOR_ID,
      value: sensorData.sensorNumber,
      id: sensorData.id,
    },
    {
      name: EditableSensorFieldsEnum.SENSOR_NAME,
      value: sensorData.sensorName,
      id: sensorData.sensorName,
    },
  ];

  const tableData: IEditableSensorTableData[] = !isDisabled
    ? [
        ...baseProperties,
        {
          name: EditableSensorFieldsEnum.DELETE,
          id: sensorData.id,
          value: "delete",
        },
      ]
    : baseProperties;

  return tableData;
};

export const translateCellName = ({
  cellName,
  t,
}: ITranslateCellName): string => {
  switch (cellName) {
    case EditableSensorFieldsEnum.ZONE:
      return t("tables.zone");
    case EditableSensorFieldsEnum.POINT:
      return t("tables.iPoint");
    case EditableSensorFieldsEnum.SENSOR_ID:
      return t("tables.sensorId");
    case EditableSensorFieldsEnum.SENSOR_NAME:
      return t("tables.sensorName");
    default:
      return cellName;
  }
};
