import { TFunction } from "i18next";
import { getUserRoles } from "../../../auth/get-user-roles.helper";
import { DeleteFunction } from "../../../interfaces/delete-function";
import { UserRolesEnum } from "../../../interfaces/roles";
import { createNotification } from "../../toaster/toaster.helper";

interface IHandleOnDeleteLocation {
  locationId: string;
  locationName: string;
  t: TFunction<"translations", undefined>;
  deleteLocation: DeleteFunction;
}

export const handleOnDeleteLocation = ({
  locationId,
  locationName,
  t,
  deleteLocation,
}: IHandleOnDeleteLocation) =>
  deleteLocation(locationId)
    .unwrap()
    .then(() =>
      createNotification({
        message: t("notification.success", {
          action: t("notification.actions.deleted"),
          entity: `${t("entities.location")} ${locationName}`,
        }),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({ message: error, variant: "error" })
    );

export const getIsAllowedForManager = (
  locationId: string,
  userRole: UserRolesEnum
) => {
  if (
    userRole === UserRolesEnum.ADMIN ||
    userRole === UserRolesEnum.SUPER_ADMIN
  )
    return true;

  if (getUserRoles({ url: locationId }) === UserRolesEnum.MANAGER) return true;

  return false;
};
