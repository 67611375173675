import { MouseEventHandler } from "react";
import { StyledButton } from "./kyps-button.styles";
import clsx from "clsx";

export interface IKypsButton {
  placeholder: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  variant?: "default" | "warning" | "light" | "active";
  size?: "normal" | "large" | "small";
  hasIcon?: boolean;
  iconType?: "plus" | "download" | "email";
  name?: string;
  isLargerFont?: boolean;
}

export const KypsButton = ({
  placeholder,
  onClick,
  variant = "default",
  size = "normal",
  hasIcon,
  iconType,
  name,
  isLargerFont,
}: IKypsButton): JSX.Element => (
  <StyledButton
    name={name}
    className={clsx(
      variant,
      size,
      hasIcon && "has-icon",
      iconType,
      isLargerFont && "larger"
    )}
    onClick={onClick}
    title={placeholder}
  >
    {placeholder}
  </StyledButton>
);
