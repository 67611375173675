import { MouseEvent, ReactNode } from "react";
import { TreeTableNames, TreeTableNamesEnum } from "../../../utils/icon-names";
import Icon from "../../icon/icon";
import { IMenuItems } from "../../../interfaces/dropdown-menu";
import { ICompleteCompanyStats } from "../../../interfaces/dashboard";
import EditCompanySidebar from "../../kyps-sidebar/edit-company-sidebar/edit-company-sidebar";
import EditLocationSidebar from "../../kyps-sidebar/edit-location-sidebar/edit-location-sidebar";
import AddLocationSidebar from "../../kyps-sidebar/add-location-sidebar/add-location-sidebar";
import AddZoneSidebar from "../../kyps-sidebar/add-zone-sidebar/add-zone-sidebar";
import EditZoneSidebar from "../../kyps-sidebar/edit-zone-sidebar/edit-zone-sidebar";
import AddIPointSidebar from "../../kyps-sidebar/add-installation-point-sidebar/add-installation-point-sidebar";
import EditIPointSidebar from "../../kyps-sidebar/edit-installation-point-sidebar/edit-installation-point-sidebar";
import AddSensorSidebar from "../../kyps-sidebar/add-sensor-sidebar/add-sensor-sidebar";
import { ITreeTableData } from "../../../interfaces/tables";
import EditSensorSidebar from "../../kyps-sidebar/edit-sensor-sidebar/edit-sensor-sidebar";
import { UserRoles, UserRolesEnum } from "../../../interfaces/roles";
import { getUserRoles } from "../../../auth/get-user-roles.helper";
import { Row } from "@tanstack/react-table";
import { IDeleteFunctions } from "../../../interfaces/delete-function";
import { TFunction } from "i18next";
import { createNotification } from "../../toaster/toaster.helper";

interface IGetIsAllowedLocation {
  userRole: UserRoles;
  depth: number;
  id: string;
  getParentRow: () => Row<ITreeTableData> | undefined;
}

interface ICreateTreeDropdownMenuItems {
  id: string;
  depth: number;
  userRole: UserRolesEnum;
  openModal: (modalContent: ReactNode) => void;
  closeModal: () => void;
  onDeleteButton: (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void;
  t: TFunction<"translations", undefined>;
  name?: string;
  parentId?: string;
  parentName?: string;
  grandParentId?: string;
  grandParentName?: string;
}

interface IHandleOnDelete {
  id: string;
  name: string;
  depth: number;
  deleteFunctions: IDeleteFunctions;
}

interface IChooseTooltipValue {
  depthNumber: number;
  value: string;
  t: TFunction<"translations", undefined>;
}

export const chooseTooltipValue = ({
  depthNumber,
  value,
  t,
}: IChooseTooltipValue): string => {
  let translatedValue: string;
  const treeTableName = rowRoles[depthNumber].role;

  switch (treeTableName) {
    case TreeTableNamesEnum.COMPANY:
      translatedValue = t("tables.company");
      break;
    case TreeTableNamesEnum.LOCATION:
      translatedValue = t("tables.location");
      break;
    case TreeTableNamesEnum.ZONE:
      translatedValue = t("tables.zone");
      break;
    case TreeTableNamesEnum.POINT:
      translatedValue = t("tables.iPoint");
      break;
    case TreeTableNamesEnum.SENSOR:
      translatedValue = t("tables.sensor");
      break;
    default:
      translatedValue = treeTableName;
      break;
  }

  return `${translatedValue} ${value}`;
};

export const chooseIcon = (depthNumber: number): JSX.Element =>
  depthNumber >= rowRoles.length ? (
    <></>
  ) : (
    <Icon iconName={rowRoles[depthNumber].role} />
  );

const rowRoles: { role: TreeTableNames }[] = [
  {
    role: TreeTableNamesEnum.COMPANY,
  },
  {
    role: TreeTableNamesEnum.LOCATION,
  },
  {
    role: TreeTableNamesEnum.ZONE,
  },
  {
    role: TreeTableNamesEnum.POINT,
  },
  {
    role: TreeTableNamesEnum.SENSOR,
  },
];

export const createTreeDropdownMenuItems = ({
  id,
  name,
  depth,
  openModal,
  closeModal,
  onDeleteButton,
  t,
  parentId,
  parentName,
  grandParentId,
  grandParentName,
  userRole,
}: ICreateTreeDropdownMenuItems): IMenuItems[] => {
  switch (depth) {
    case 0:
      const companyMenuItems: IMenuItems[] = [
        {
          name: t("buttons.edit.actionWithKey", { key: t("entities.company") }),
          onItemClick: () =>
            openModal(
              <EditCompanySidebar
                closeModal={closeModal}
                currentCompanyId={id}
                t={t}
              />
            ),
        },
        {
          name: t("buttons.add.actionWithKey", { key: t("entities.location") }),
          onItemClick: () =>
            openModal(
              <AddLocationSidebar companyId={id} closeModal={closeModal} />
            ),
        },
      ];
      if (userRole === UserRolesEnum.SUPER_ADMIN)
        companyMenuItems.splice(1, 0, {
          name: t("buttons.delete.actionWithKey", {
            key: t("entities.company"),
          }),
          onItemClick: (e) => onDeleteButton(e),
        });

      return companyMenuItems;
    case 1:
      const locationMenuItems: IMenuItems[] = [
        {
          name: t("buttons.edit.actionWithKey", {
            key: t("entities.location"),
          }),
          onItemClick: () =>
            openModal(
              <EditLocationSidebar closeModal={closeModal} locationId={id} />
            ),
        },
        {
          name: t("buttons.add.actionWithKey", { key: t("entities.zone") }),
          onItemClick: () =>
            openModal(
              <AddZoneSidebar
                currentLocationId={id}
                closeModal={closeModal}
                t={t}
              />
            ),
        },
      ];

      if (userRole !== UserRolesEnum.MANAGER)
        locationMenuItems.splice(1, 0, {
          name: t("buttons.delete.actionWithKey", {
            key: t("entities.location"),
          }),
          onItemClick: (e) => onDeleteButton(e),
        });

      return locationMenuItems;
    case 2:
      return [
        {
          name: t("buttons.edit.actionWithKey", {
            key: t("entities.zone"),
          }),
          onItemClick: () =>
            openModal(<EditZoneSidebar zoneId={id} closeModal={closeModal} />),
        },
        {
          name: t("buttons.delete.actionWithKey", {
            key: t("entities.zone"),
          }),
          onItemClick: (e) => onDeleteButton(e),
        },
        {
          name: t("buttons.add.actionWithKey", { key: t("entities.iPoint") }),
          onItemClick: () =>
            openModal(
              <AddIPointSidebar zoneId={id} closeModal={closeModal} t={t} />
            ),
        },
      ];
    case 3:
      return [
        {
          name: t("buttons.edit.actionWithKey", {
            key: t("entities.iPoint"),
          }),
          onItemClick: () =>
            openModal(
              <EditIPointSidebar
                iPointId={id}
                zoneId={parentId ?? ""}
                closeModal={closeModal}
                t={t}
              />
            ),
        },
        {
          name: t("buttons.delete.actionWithKey", {
            key: t("entities.iPoint"),
          }),
          onItemClick: (e) => onDeleteButton(e),
        },
        {
          name: t("buttons.add.actionWithKey", { key: t("entities.sensor") }),
          onItemClick: () =>
            openModal(
              <AddSensorSidebar
                defaultZone={{ label: parentName ?? "", value: parentId ?? "" }}
                defaultIPoint={{ label: name ?? "", value: id }}
                zonesOptions={[]}
                closeModal={closeModal}
                isFixedValue
                t={t}
              />
            ),
        },
      ];
    case 4:
      return [
        {
          name: t("buttons.edit.actionWithKey", {
            key: t("entities.sensor"),
          }),
          onItemClick: () =>
            openModal(
              <EditSensorSidebar
                closeModal={closeModal}
                sensorId={id}
                zonesOptions={[]}
                defaultZone={{
                  label: grandParentName ?? "",
                  value: grandParentId ?? "",
                }}
                defaultIPoint={{
                  label: parentName ?? "",
                  value: parentId ?? "",
                }}
                t={t}
              />
            ),
        },
        {
          name: t("buttons.delete.actionWithKey", {
            key: t("entities.sensor"),
          }),
          onItemClick: (e) => onDeleteButton(e),
        },
      ];
    default:
      return [];
  }
};

export const onDeleteHelper = ({
  id,
  name,
  depth,
  deleteFunctions,
}: IHandleOnDelete) => {
  const deleteFunction = deleteFunctions[rowRoles[depth].role];

  deleteFunction(id)
    .unwrap()
    .then(() =>
      createNotification({
        message: `${name} was successfully deleted!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};

export const transformTreeTableData = ({
  id,
  fullName,
  locations,
}: ICompleteCompanyStats): ITreeTableData[] => [
  {
    name: { value: fullName },
    id: id,
    subRows: locations.map(({ id, name, zones }) => ({
      name: { value: name },
      id,
      subRows: zones.map(({ name, id, installationPoints }) => ({
        name: { value: name },
        id,
        subRows: installationPoints.map(({ name, id, sensors }) => ({
          name: { value: name },
          id,
          subRows: sensors.map(
            ({
              sensorNumber,
              id,
              customConfig,
              lastTemperature,
              lastHumidity,
              lastUpdateDate,
              state,
              showHumidity,
              lastVoltage,
              lastDbm,
              temperatureRange,
              sensorName,
            }) => {
              const { lowGreen, highGreen } = temperatureRange;
              return {
                name: {
                  value: sensorName
                    ? `${sensorNumber} (${sensorName})`
                    : sensorNumber,
                  isCustom: customConfig,
                },
                id,
                range: `${lowGreen};${highGreen}`,
                lastTemperature: {
                  value: lastTemperature,
                  state,
                },
                lastHumidity: {
                  value: lastHumidity,
                  showHumidity,
                },
                lastBattery: lastVoltage,
                lastSignal: lastDbm,
                lastUpdateDate,
              };
            }
          ),
        })),
      })),
    })),
  },
];

export const getIsAllowedLocation = ({
  userRole,
  depth,
  id,
  getParentRow,
}: IGetIsAllowedLocation) => {
  if (
    userRole === UserRolesEnum.ADMIN ||
    userRole === UserRolesEnum.SUPER_ADMIN
  )
    return true;

  if (depth === 0) return false;

  if (depth === 1) return getUserRoles({ url: id }) === UserRolesEnum.MANAGER;

  if (depth === 2)
    return (
      getUserRoles({
        url: getParentRow()?.original.id as string,
      }) === UserRolesEnum.MANAGER
    );

  if (depth === 3)
    return (
      getUserRoles({
        url: getParentRow()?.getParentRow()?.original.id as string,
      }) === UserRolesEnum.MANAGER
    );

  if (depth === 4)
    return (
      getUserRoles({
        url: getParentRow()?.getParentRow()?.getParentRow()?.original
          .id as string,
      }) === UserRolesEnum.MANAGER
    );

  return false;
};
