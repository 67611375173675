import { LandingIcons } from "../../../../interfaces/landing-icons";
import LandingIcon from "../landing-icon/landing-icon";
import { StepDescription, StepHeading } from "../step-item/step-item.styles";
import {
  DetailDescriptionWrapper,
  DetailIconWrapper,
  DetailWrapper,
} from "./detail-description.styles";

export interface IDetailDescription {
  iconName: LandingIcons;
  title: string;
  description: string;
}

const DetailDescription = ({
  iconName,
  title,
  description,
}: IDetailDescription) => {
  return (
    <DetailWrapper>
      <DetailIconWrapper>
        <LandingIcon name={iconName} />
      </DetailIconWrapper>
      <DetailDescriptionWrapper>
        <StepHeading>{title}</StepHeading>
        <StepDescription>{description}</StepDescription>
      </DetailDescriptionWrapper>
    </DetailWrapper>
  );
};

export default DetailDescription;
