import { useMemo, useState, useCallback, useEffect, useRef } from "react";
import {
  useDeleteManagerByIdMutation,
  useGetManagersQuery,
  useSearchManagerAutocompleteQuery,
} from "../../../api/managers.api";
import { KypsTable } from "../kyps-table";
import {
  useReactTable,
  PaginationState,
  getPaginationRowModel,
  getCoreRowModel,
  SortingState,
} from "@tanstack/react-table";
import KypsModal from "../../kyps-modal/kyps-modal";
import {
  createManagersTableColumns,
  createManagersTableHeader,
} from "./managers-table.utils";
import useModal from "../../../hooks/useModal";
import Loaders from "../../loaders/loaders";
import {
  useDetachManagerFromCompanyMutation,
  useGetCompanyManagersAutocompleteQuery,
  useGetCompanyManagersByCompanyIdQuery,
} from "../../../api/company-manager.api";
import { QueryStatus, skipToken } from "@reduxjs/toolkit/dist/query";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { UserRoles, UserRolesEnum } from "../../../interfaces/roles";
import { isEmpty } from "lodash";
import EmptyPage from "../../kyps-empty/kyps-empty-page";
import { useWindowWidth } from "../../../hooks/useWindowWidth";
import { TFunction } from "i18next";
import { ISortByKeysManager } from "../../../interfaces/managers";
import useDeleteConfirm from "../../../hooks/useDeleteConfirm";
import DeleteConfirm from "../../delete-confirm/delete-confirm";
import {
  handleOnDeleteManager,
  handleOnDeleteManagerFromCompany,
} from "./managers-table.helper";

interface IManagersTable {
  companyId: null | string;
  userRole: UserRoles;
  userId: string;
  t: TFunction<"translations", undefined>;
}

interface IQueryObj {
  page: number;
  size: number;
  sort: {
    property: keyof ISortByKeysManager;
    direction: "ASC" | "DESC";
  }[];
}

const baseSortObj: {
  property: keyof ISortByKeysManager;
  direction: "ASC" | "DESC";
} = { property: "name", direction: "ASC" };

export const ManagersTable = ({
  companyId,
  userRole,
  userId,
  t,
}: IManagersTable) => {
  const pagination = useWindowWidth();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    ...pagination,
  });
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: baseSortObj.property,
      desc: baseSortObj.direction === "DESC",
    },
  ]);
  const [tableSearchValue, setTableSearchValue] = useState<string>("");
  const [searchAll, setSearchAll] = useState<boolean>(
    userRole === UserRolesEnum.SUPER_ADMIN ? true : false
  );

  const options: ISelectOption[] = useMemo(
    () => [
      { label: t("selectZoneOptions.allUsers"), value: "All" },
      { label: t("selectZoneOptions.companyUsers"), value: "Company" },
    ],
    [t]
  );

  const [selectedOption, setSelectedOption] = useState<ISelectOption | null>(
    searchAll ? options[0] : options[1]
  );

  const tableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (pageSize !== pagination.pageSize) setPagination(pagination);
  }, [pageSize, pagination]);

  const {
    closeDeleteConfirm,
    deleteConfirmName,
    deleteFunction,
    isDeleteConfirmOpen,
    openDeleteConfirm,
    openerParams,
  } = useDeleteConfirm(tableRef);
  const { closeAndResetModal, currentModal, isModalOpen, openModal } =
    useModal();

  const queryObj: IQueryObj = useMemo(
    () => ({
      page: pageIndex,
      size: pageSize,
      sort: [
        sorting.length
          ? {
              property: sorting[0].id as keyof ISortByKeysManager,
              direction: sorting[0].desc ? "DESC" : "ASC",
            }
          : baseSortObj,
      ],
    }),
    [pageIndex, pageSize, sorting]
  );

  const [deleteManager] = useDeleteManagerByIdMutation();
  const [deleteManagerFromCompany] = useDetachManagerFromCompanyMutation();

  const { data: apiAllManagers, isLoading: isAllManagersLoading } =
    useGetManagersQuery(searchAll ? queryObj : skipToken);

  const { data: apiManagers, isLoading: isCompanyManagersLoading } =
    useGetCompanyManagersByCompanyIdQuery(
      companyId && !searchAll
        ? {
            ...queryObj,
            companyId,
          }
        : skipToken
    );

  const {
    data: apiSearchCompanyManagers,
    isLoading: isSearchCompanyManagersLoading,
    status: searchInCompanyManagersStatus,
  } = useGetCompanyManagersAutocompleteQuery(
    companyId && !searchAll && tableSearchValue.length > 1
      ? {
          companyId,
          searchValue: tableSearchValue,
          ...queryObj,
        }
      : skipToken
  );

  const {
    data: apiSearchInAllManagers,
    isLoading: isSearchInAllManagersLoading,
    status: searchInAllManagersStatus,
  } = useSearchManagerAutocompleteQuery(
    searchAll && tableSearchValue.length > 1
      ? {
          value: tableSearchValue,
          ...queryObj,
        }
      : skipToken
  );

  const managersResponseData = useMemo(() => {
    if (tableSearchValue.length > 1 && !searchAll && apiSearchCompanyManagers)
      return apiSearchCompanyManagers;

    if (tableSearchValue.length > 1 && searchAll && apiSearchInAllManagers)
      return apiSearchInAllManagers;

    if (!searchAll && apiManagers) return apiManagers;

    if (searchAll && apiAllManagers) return apiAllManagers;

    return null;
  }, [
    apiAllManagers,
    apiManagers,
    apiSearchCompanyManagers,
    apiSearchInAllManagers,
    tableSearchValue.length,
    searchAll,
  ]);

  const onDeleteManager = (id: string, name: string) => {
    if (userRole !== UserRolesEnum.SUPER_ADMIN && companyId)
      return handleOnDeleteManagerFromCompany({
        name,
        companyId,
        managerId: id,
        managersData: managersResponseData,
        deleteManagerFromCompany,
        handleResetPage,
      });

    handleOnDeleteManager({
      id,
      name,
      managersData: managersResponseData,
      deleteManager,
      handleResetPage,
    });
  };

  const handleOnSelect = useCallback(
    (option: ISelectOption | null) => {
      if (option) {
        setSelectedOption(option);

        if (pageIndex !== 0) setPagination({ pageSize, pageIndex: 0 });

        if (option.value === "All") return setSearchAll(true);

        setSearchAll(false);
      }
    },
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: managersResponseData?.content ?? [],
    columns: createManagersTableColumns({
      openModal,
      closeAndResetModal,
      onDeleteManager,
      openDeleteConfirm,
      userId,
      t,
    }),
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination: { pageIndex, pageSize },
      sorting,
      columnPinning: { left: ["name"] },
    },
    onSortingChange: setSorting,
    sortDescFirst: false,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: managersResponseData
      ? managersResponseData.totalPages
      : undefined,
    autoResetPageIndex: false,
    enableSortingRemoval: false,
  });

  const handleResetPage = () => {
    const pageCount = managersResponseData?.totalPages;

    table.setPageIndex(pageCount ? pageCount - 2 : 0);
    setPagination((prev) => {
      return {
        ...prev,
        pageIndex: pageCount ? pageCount - 2 : 0,
      };
    });
  };

  return (
    <>
      <KypsTable
        serverSideSorting
        table={table}
        tableHeaderElements={createManagersTableHeader({
          closeAndResetModal,
          companyId,
          handleOnSelect,
          isLoading:
            isSearchCompanyManagersLoading ||
            isSearchInAllManagersLoading ||
            searchInAllManagersStatus === QueryStatus.pending ||
            searchInCompanyManagersStatus === QueryStatus.pending,
          openModal,
          options,
          selectedOption,
          setTableSearchValue,
          t,
          userRole,
        })}
        isLoading={isAllManagersLoading || isCompanyManagersLoading}
        loader={<Loaders variant="management" />}
        tableRef={tableRef}
        emptyMessage={
          isEmpty(managersResponseData?.content) &&
          !isCompanyManagersLoading &&
          !isAllManagersLoading ? (
            <EmptyPage
              heading={t("emptyMessage.text", {
                entity: t("emptyMessage.entities.user"),
              })}
              iconName="clipboard"
              isSmall
            />
          ) : null
        }
      />
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
        />
      )}
    </>
  );
};
