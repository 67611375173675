import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const TreeCellWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;

  &.clickable {
    cursor: pointer;
  }
`;

export const IconDiv = styled.div`
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 4px;
  flex-shrink: 0;

  &.small {
    height: 20px;
    width: 20px;
  }

  &.zero-margin {
    margin: 0;
  }

  &.light {
    filter: brightness(10);
  }
`;

export const TreeTableNameCell = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.border.secondary};
  `}
`;
