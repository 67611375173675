import { TFunction } from "i18next";
import { IBenefitItem } from "../../components/benefit-item/benefit-item";

export const createTranslatedBenefitsData = (
  t: TFunction<"translations", undefined>
): IBenefitItem[] => [
  {
    title: t("landing.benefits.cards.safety.title"),
    description: t("landing.benefits.cards.safety.description"),
    iconName: "shield",
  },
  {
    title: t("landing.benefits.cards.savings.title"),
    description: t("landing.benefits.cards.savings.description"),
    iconName: "dollar",
  },
  {
    title: t("landing.benefits.cards.reliability.title"),
    description: t("landing.benefits.cards.reliability.description"),
    iconName: "certificate",
  },
  {
    title: t("landing.benefits.cards.flexibility.title"),
    description: t("landing.benefits.cards.flexibility.description"),
    iconName: "arrows",
  },
];
