import { LandingIcons } from "../../../../interfaces/landing-icons";
import { ReactComponent as DollarSvg } from "../../../../assets/landing-images/sack-dollar.svg";
import { ReactComponent as ArrowsSvg } from "../../../../assets/landing-images/arrows-split-up-and-left.svg";
import { ReactComponent as ShieldSvg } from "../../../../assets/landing-images/shield-check.svg";
import { ReactComponent as CertificateSvg } from "../../../../assets/landing-images/file-certificate.svg";
import { ReactComponent as TemperatureSvg } from "../../../../assets/landing-images/temperature-sensor.svg";
import { ReactComponent as HumiditySvg } from "../../../../assets/landing-images/humidity-sensor.svg";
import { ReactComponent as ModemSvg } from "../../../../assets/landing-images/modem.svg";
import { ReactComponent as MonitoringSvg } from "../../../../assets/landing-images/monitoring.svg";
import { ReactComponent as BellSvg } from "../../../../assets/landing-images/bell.svg";
import { ReactComponent as TrendSvg } from "../../../../assets/landing-images/trending_up.svg";
import { ReactComponent as QuotesSvg } from "../../../../assets/landing-images/quotes.svg";
import { ReactComponent as FacebookSvg } from "../../../../assets/landing-images/facebook.svg";
import { ReactComponent as LinkedinSvg } from "../../../../assets/landing-images/linkedin.svg";

import { FunctionComponent, SVGProps } from "react";
import clsx from "clsx";
import { LandingIconWrapper } from "./landing-icon.styles";

interface ILandingIcon {
  name: LandingIcons;
  clickable?: boolean;
}

const icons: Record<
  ILandingIcon["name"],
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  arrows: ArrowsSvg,
  certificate: CertificateSvg,
  dollar: DollarSvg,
  shield: ShieldSvg,
  temperature: TemperatureSvg,
  humidity: HumiditySvg,
  monitoring: MonitoringSvg,
  modem: ModemSvg,
  bell: BellSvg,
  trend: TrendSvg,
  quotes: QuotesSvg,
  facebook: FacebookSvg,
  linkedin: LinkedinSvg,
};

const LandingIcon = ({ name, clickable }: ILandingIcon) => {
  const SelectedIcon = icons[name];
  return (
    <LandingIconWrapper className={clsx(clickable && "clickable")}>
      <SelectedIcon />
    </LandingIconWrapper>
  );
};

export default LandingIcon;
