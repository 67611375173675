import { useMemo } from "react";
import ReportTable from "../kyps-tables/report-table/report-table";
import { useGetReportByLocationIdQuery } from "../../api/reports.api";
import { isEmpty } from "lodash";
import { transformReportData } from "../kyps-tables/report-table/report-table.helper";
import Loaders from "../loaders/loaders";
import EmptyPage from "../kyps-empty/kyps-empty-page";
import { useGetLocationByIdQuery } from "../../api/location.api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { formatDate } from "../../pages/reports/reports.helper";
import { formatDateForDisplay } from "../kyps-datepicker/kyps-date-picker.helper";
import { UserRoles } from "../../interfaces/roles";
import { TFunction } from "i18next";
import { format } from "date-fns";

interface IReportComponent {
  id: string;
  date: Date;
  userRole: UserRoles;
  t: TFunction<"translations", undefined>;
  raw?: boolean;
}

const ReportComponent = ({ id, date, raw, t, userRole }: IReportComponent) => {
  const {
    data: apiReportData,
    isLoading,
    isError,
  } = useGetReportByLocationIdQuery({
    date: formatDate(date),
    id,
    raw,
  });
  const { data: apiLocationData } = useGetLocationByIdQuery(
    isError ? id : skipToken
  );

  const data = useMemo(() => {
    if (apiReportData) return transformReportData(apiReportData);

    return [];
  }, [apiReportData]);

  const content = useMemo(() => {
    if (isError)
      return (
        <EmptyPage
          heading={apiLocationData?.name || ""}
          message={t("emptyMessage.noDataForDate", {
            date: formatDateForDisplay(date, t),
          })}
          iconName="clipboard"
          isSmall
          hasMargin
        />
      );

    if (isLoading) return <Loaders variant="report" />;

    if (!isEmpty(data))
      return (
        <ReportTable
          date={format(date, "yyyy-MM-dd")}
          data={data}
          locationName={apiReportData?.locationName ?? ""}
          userRole={userRole}
          id={id}
          t={t}
        />
      );

    return null;
  }, [
    apiLocationData?.name,
    apiReportData?.locationName,
    data,
    date,
    id,
    isError,
    isLoading,
    t,
    userRole,
  ]);

  return content;
};

export default ReportComponent;
