import { Dispatch, ReactNode, SetStateAction } from "react";
import IconButton from "../../icon-button/icon-button";
import { createColumnHelper } from "@tanstack/react-table";
import { ILocationSensor } from "../../../interfaces/dashboard";
import {
  CellMaxContent,
  CustomLabel,
  TemperatureData,
} from "../kyps-table.styles";
import clsx from "clsx";
import { checkDate } from "../../../helpers/check-date.helper";
import EditSensorSidebar from "../../kyps-sidebar/edit-sensor-sidebar/edit-sensor-sidebar";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { checkBatteryLevel, checkSignalLevel } from "../kyps-table.helper";
import KypsTooltip from "../../kyps-tooltip/kyps-tooltip";
import { checkHumidity } from "../sensors-stats-table/sensors-stats-table.helper";
import { TFunction } from "i18next";
import { SensorNameCell } from "./sensors-table.styles";
import { IOpenDeleteConfirm } from "../../../hooks/useDeleteConfirm";

const { accessor, display } = createColumnHelper<ILocationSensor>();

interface ICreateSensorsTableColumns {
  selectedSensorId: string;
  defaultZone: ISelectOption;
  defaultIPoint: ISelectOption;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  onDeleteSensor: (id: string, name: string) => void;
  setSelectedSensorId: Dispatch<SetStateAction<string>>;
  t: TFunction<"translations", undefined>;
}
export const createSensorsTableColumns = ({
  selectedSensorId,
  defaultZone,
  defaultIPoint,
  openDeleteConfirm,
  openModal,
  closeAndResetModal,
  onDeleteSensor,
  setSelectedSensorId,
  t,
}: ICreateSensorsTableColumns) => [
  accessor("sensorNumber", {
    header: t("tables.sensor"),
    size: 200,
    cell: ({
      getValue,
      row: {
        original: { customConfig },
      },
    }) => (
      <SensorNameCell>
        <KypsTooltip tooltipValue={getValue()} />
        {customConfig && <CustomLabel>Custom</CustomLabel>}
      </SensorNameCell>
    ),
  }),
  accessor("lastTemperature", {
    header: `${t("tables.temperature")} °C`,
    size: 136,
    cell: ({
      row: {
        original: { state },
      },
      getValue,
    }) =>
      getValue() ? (
        <TemperatureData className={clsx(state.toLowerCase())}>
          {getValue().toFixed(1)} °C
        </TemperatureData>
      ) : (
        "-"
      ),
  }),
  accessor("lastHumidity", {
    header: `${t("tables.humidity")} %`,
    size: 112,
    cell: ({
      getValue,
      row: {
        original: { showHumidity },
      },
    }) => showHumidity && checkHumidity(getValue()),
  }),
  accessor("lastVoltage", {
    header: t("tables.battery"),
    size: 86,
    cell: ({ getValue }) => <>{checkBatteryLevel(getValue())}</>,
  }),
  accessor("lastDbm", {
    header: t("tables.signal"),
    size: 90,
    cell: ({ getValue }) => <>{checkSignalLevel(getValue())}</>,
  }),
  accessor("lastUpdateDate", {
    header: t("tables.lastUpdate"),
    size: 154,
    cell: ({ getValue }) => (
      <CellMaxContent>{checkDate(getValue() as string, t)}</CellMaxContent>
    ),
  }),
  display({
    header: "",
    size: 70,
    cell: ({
      row: {
        original: { id, sensorNumber, sensorName },
      },
    }) => (
      <>
        <IconButton
          hoverColor="info"
          name="pencil"
          onClick={() =>
            openModal(
              <EditSensorSidebar
                closeModal={closeAndResetModal}
                sensorId={id}
                defaultZone={defaultZone}
                defaultIPoint={defaultIPoint}
                zonesOptions={[]}
                isSecondSidebar
                t={t}
              />
            )
          }
        />
        <IconButton
          hoverColor="warning"
          name="bin"
          onClick={(e) => {
            e.preventDefault();

            setSelectedSensorId(id);
            openDeleteConfirm({
              deleteConfirmName: sensorName ?? sensorNumber,
              deleteFunction: () => onDeleteSensor(id, sensorNumber),
              openerParamsProp: e.currentTarget.getBoundingClientRect(),
            });
          }}
          // confirmWindow={
          //   <DeleteConfirm
          //     currentId={id}
          //     name={sensorNumber}
          //     onCancel={() => setSelectedSensorId("")}
          //     onDelete={() => onDeleteSensor(id, sensorNumber)}
          //     selectedId={selectedSensorId}
          //     key="Delete sensor"
          //   />
          // }
        />
      </>
    ),
    id: "actions",
  }),
];
