import { useUpdateCompanyByIdMutation } from "../../../api/companies.api";
import { IEditCompany } from "../../../interfaces/companies";
import { createNotification } from "../../toaster/toaster.helper";

type EditCompanyResult = ReturnType<typeof useUpdateCompanyByIdMutation>;
type EditCompanyFunction = EditCompanyResult[0];

interface IHandleOnEditCompany {
  data: IEditCompany;
  editCompany: EditCompanyFunction;
  closeModal: () => void;
}

export const handleOnEditCompany = ({
  data,
  editCompany,
  closeModal,
}: IHandleOnEditCompany) => {
  closeModal();

  editCompany(data)
    .unwrap()
    .then(({ fullName }) =>
      createNotification({
        message: `${fullName} was successfully edited`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};
