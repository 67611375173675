import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Clipboard from "../../assets/Clipboard.svg";
import Chip from "../../assets/Chip.svg";

export const EmptyPageWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.default};
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    flex: 1;

    &.small {
      max-height: 290px;
    }

    &.has-margin {
      margin-bottom: 32px;
    }

    @media (min-width: 992px) {
      flex: unset;
      height: 500px;
    }
  `}
`;

export const MessageContainer = styled.div`
  margin: 8px 0 16px;
`;

export const IconContainer = styled.div`
  padding: 56px 20px 0;
  background: no-repeat center top;
  background-size: 40px;
  text-align: center;
  position: relative;

  &.clipboard {
    background-image: url(${Clipboard});
  }

  &.chip {
    background-image: url(${Chip});
  }

  &.error {
    ::before {
      position: absolute;
      inset: 0;
      content: "404";
      font-size: 60px;
      font-weight: 500;
      pointer-events: none;

      @media (min-width: 768px) {
        font-size: 80px;
      }
    }
  }

  @media (min-width: 768px) {
    background-size: 48px;
    padding: 76px 20px 0;
  }
`;

export const EmptyPageHeading = styled.h3`
  ${({ theme }) => css`
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    color: ${theme.palette.text.dark};
    margin-bottom: 4px;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 28px;
    }
  `}
`;

export const EmptyPageMessage = styled.p`
  ${({ theme }) => css`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.text.secondary};
    margin-bottom: 16px;
  `}
`;
