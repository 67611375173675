import { Dispatch, SetStateAction } from "react";
import {
  AddCompanyManagerFunction,
  AddGlobalManagerFunction,
} from "../../../interfaces/add-functions";
import { ICompany } from "../../../interfaces/companies";
import { IAddManagerDTO } from "../../../interfaces/managers";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { createNotification } from "../../toaster/toaster.helper";

interface IHandleOnSubmitAddGlobalManager {
  data: IAddManagerDTO;
  addGlobalManager: AddGlobalManagerFunction;
}

interface IHandleOnSubmitAddCompanyManager {
  data: IAddManagerDTO;
  companyId: string;
  isAdmin: boolean;
  addCompanyManager: AddCompanyManagerFunction;
}

interface IGetCompaniesOption {
  selectedCompany: ISelectOption | null;
  allCompanies: ICompany[] | undefined;
  setSelectedOption: Dispatch<SetStateAction<ISelectOption | null>>;
}

export const handleOnAddGlobalManager = ({
  data,
  addGlobalManager,
}: IHandleOnSubmitAddGlobalManager) =>
  addGlobalManager({
    ...data,
    telegramId: 0,
    status: "INACTIVE",
  })
    .unwrap()
    .then(({ name }) =>
      createNotification({
        message: `${name} was successfully created!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const handleOnAddCompanyManager = ({
  data,
  companyId,
  isAdmin,
  addCompanyManager,
}: IHandleOnSubmitAddCompanyManager) =>
  addCompanyManager({
    companyId,
    data: {
      ...data,
      telegramId: 0,
      status: "INACTIVE",
      companies: [
        {
          admin: isAdmin,
          companyId,
        },
      ],
    },
  })
    .unwrap()
    .then(({ name }) =>
      createNotification({
        message: `${name} was successfully created!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const getCompaniesOptions = ({
  allCompanies,
  selectedCompany,
  setSelectedOption,
}: IGetCompaniesOption): ISelectOption[] => {
  if (selectedCompany) setSelectedOption(selectedCompany);

  if (allCompanies)
    return allCompanies.map(({ fullName, id }) => ({
      label: fullName,
      value: id,
    }));

  return [];
};
