import { useRef, useMemo, useCallback } from "react";
import KypsSidebar from "../kyps-sidebar";
import { KypsButton } from "../../kyps-button/kyps-button";
import { createAddCompanyFields } from "../add-company-sidebar/add-company-sidebar.utils";
import { IAddCompany, IEditCompany } from "../../../interfaces/companies";
import KypsForm from "../../kyps-form/kyps-form";
import {
  useGetCompanyByIdQuery,
  useUpdateCompanyByIdMutation,
} from "../../../api/companies.api";
import { handleOnEditCompany } from "./edit-company-sidebar.helper";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Loaders from "../../loaders/loaders";
import CompanyManagersTable from "../../kyps-tables/company-managers-table/company-managers-table";
import { UserRolesEnum } from "../../../interfaces/roles";
import { TFunction } from "i18next";

interface IEditCompanySidebar {
  currentCompanyId: string;
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
}

const EditCompanySidebar = ({
  currentCompanyId,
  closeModal,
  t,
}: IEditCompanySidebar): JSX.Element => {
  const userRole = useAppSelector(({ user }) => user.user?.role);

  const [editCompany] = useUpdateCompanyByIdMutation();
  const { data: apiCompanyData, isLoading } =
    useGetCompanyByIdQuery(currentCompanyId);

  const editFields = useMemo(() => {
    if (apiCompanyData)
      return createAddCompanyFields(t).map((field) => {
        const value = apiCompanyData[field.name as keyof IEditCompany] ?? "";

        return {
          ...field,
          value,
          isHidden:
            userRole !== UserRolesEnum.SUPER_ADMIN && field.name === "comment",
        };
      });

    return [];
  }, [apiCompanyData, t, userRole]);

  const formRef = useRef<HTMLFormElement>(null);
  const handleEditCompanyFormSubmit = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmitEditCompany = useCallback(
    ({ fullName, shortName, comment }: IAddCompany) => {
      const companyData: IEditCompany = {
        fullName,
        shortName,
        comment,
        id: currentCompanyId,
      };
      handleOnEditCompany({
        editCompany,
        closeModal,
        data: companyData,
      });
    },
    [closeModal, currentCompanyId, editCompany]
  );

  const sidebarContent = useMemo(() => {
    if (isLoading) return [<Loaders key="Loader" variant="sidebar" />];

    return [
      <KypsForm
        fields={editFields}
        onSubmit={onSubmitEditCompany}
        ref={formRef}
        key="EditCompanyForm"
      />,
      <CompanyManagersTable
        companyId={currentCompanyId}
        key="CompanyManagersTable"
        t={t}
      />,
    ];
  }, [isLoading, editFields, onSubmitEditCompany, currentCompanyId, t]);

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: `${t("buttons.edit.actionWithKey", {
          key: apiCompanyData?.fullName ?? t("entities.company"),
        })}`,
        buttons: [
          <KypsButton
            key="Cancel"
            variant="light"
            placeholder={t("buttons.cancel.action")}
            onClick={closeModal}
          />,
          <KypsButton
            key="Edit company"
            variant="default"
            placeholder={t("buttons.edit.actionWithKey", {
              key: t("entities.company"),
            })}
            onClick={handleEditCompanyFormSubmit}
          />,
        ],
      }}
      content={sidebarContent}
    />
  );
};

export default EditCompanySidebar;
