import React from "react";
import {
  Controller,
  Control,
  DeepMap,
  FieldError,
  RegisterOptions,
} from "react-hook-form";
import {
  ErrorMessage,
  FormInput,
  FormInputItem,
  FormLabel,
  FormLabelWrapper,
} from "./kyps-form.styles";
import { StyledPhoneInput } from "./kyps-form.styles";
import clsx from "clsx";
import { InputIconTypes } from "../../utils/input-icon-types";
import { validatePhoneNumber } from "../../helpers/validation.helper";

export interface InputField<T = string> {
  label: string;
  name: keyof T;
  type: string;
  value?: string | number;
  rules?: RegisterOptions;
  iconName?: InputIconTypes;
  placeholder?: string;
  isHidden?: boolean;
}

export interface InputBlockProps {
  label: string;
  name: string;
  control: Control<any>;
  errors: DeepMap<any, FieldError>;
  type: string;
  value?: string | number;
  rules?: RegisterOptions;
  iconName?: InputIconTypes;
  className?: string;
  placeholder?: string;
}

const InputBlock: React.FC<InputBlockProps> = ({
  label,
  name,
  control,
  rules,
  errors,
  type,
  iconName,
  className,
  value,
  placeholder,
}) => (
  <FormInputItem className={className}>
    <FormLabelWrapper>
      <FormLabel htmlFor={name} className={clsx(iconName)}>
        {label}
      </FormLabel>
      {errors[name] && <ErrorMessage>{errors[name].message}</ErrorMessage>}
    </FormLabelWrapper>
    <Controller
      defaultValue={value ?? ""}
      name={name}
      control={control}
      rules={{
        ...rules,
        validate: (value: string) => validatePhoneNumber({ type, value }),
      }}
      render={({ field }) => (
        <>
          {type === "tel" ? (
            <StyledPhoneInput
              inputProps={{
                name,
                id: name,
                type,
                autoComplete: "true",
              }}
              country="ua"
              placeholder=""
              countryCodeEditable={false}
              value={field.value}
              onChange={field.onChange}
              copyNumbersOnly={false}
            />
          ) : (
            <FormInput
              id={name}
              className={clsx(iconName)}
              type={type}
              autoComplete="true"
              placeholder={placeholder}
              {...field}
            />
          )}
        </>
      )}
    />
  </FormInputItem>
);

export default InputBlock;
