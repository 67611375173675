import { useTranslation } from "react-i18next";
import Flag from "../containers/landing-content/components/flag/flag";
import AboutUsSection from "../containers/landing-content/landing-body/about-us-section/about-us-section";
import BenefitsSection from "../containers/landing-content/landing-body/benefits-section/benefits-section";
import ContactSection from "../containers/landing-content/landing-body/contact-section/contact-section";
import FeedbackSection from "../containers/landing-content/landing-body/feedback-section/feedback-section";
import HeroSection from "../containers/landing-content/landing-body/hero-section/hero-section";
import HowItWorksSection from "../containers/landing-content/landing-body/how-it-works-section/how-it-works-section";
import ImageSection from "../containers/landing-content/landing-body/image-section/image-section";
import NotificationsSection from "../containers/landing-content/landing-body/notifications-section/notifications-section";
import StatsSection from "../containers/landing-content/landing-body/stats-section/stats-section";
import { LandingWrapper } from "../containers/landing-content/landing-content.styles";
import LandingFooter from "../containers/landing-content/landing-footer/landing-footer";
import LandingHeader from "../containers/landing-content/landing-header/landing-header";
import { useEffect } from "react";
import useGA from "../hooks/useGA";

const Home = () => {
  const { t } = useTranslation();
  const { sendGAPageView } = useGA();

  useEffect(() => {
    sendGAPageView({
      page: "/",
      title: "Landing Page",
    });
  }, [sendGAPageView]);

  return (
    <>
      <LandingHeader t={t} />
      <LandingWrapper>
        <Flag t={t} />
        <HeroSection t={t} />
        <ImageSection />
        <StatsSection t={t} />
        <BenefitsSection t={t} />
        <AboutUsSection t={t} />
        <HowItWorksSection t={t} />
        <NotificationsSection t={t} />
        <FeedbackSection t={t} />
        <ContactSection t={t} />
      </LandingWrapper>
      <LandingFooter t={t} />
    </>
  );
};

export default Home;
