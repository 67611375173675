import { KypsButton } from "../kyps-button/kyps-button";
import { GatewayCreatorWrapper } from "./gateway-components.styles";
import KypsInput from "../kyps-input/kyps-input";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IGatewayCreator {
  creatorName: string;
  buttonPlaceholder: string;
  onAddClick: (arg: string) => void;
  gatewayNumbers: string[];
  inputPlaceholder?: string;
}

const GatewayCreator = ({
  creatorName,
  buttonPlaceholder,
  onAddClick,
  gatewayNumbers,
  inputPlaceholder = "",
}: IGatewayCreator) => {
  const [inputValue, setInputValue] = useState("");
  const [inputErrorMessage, setInputErrorMessage] = useState("");

  const { t } = useTranslation();

  const isInputUnique = !gatewayNumbers.includes(inputValue.replace(/\s/g, ""));
  const isInputValid = inputValue.replace(/\s/g, "").length > 2;

  useEffect(() => {
    if (isInputValid && isInputUnique && inputErrorMessage) {
      setInputErrorMessage("");
    }
  }, [inputErrorMessage, inputValue, isInputUnique, isInputValid]);

  const handleOnAddClick = () => {
    if (!isInputValid) {
      setInputErrorMessage(
        t("forms.messages.length", {
          fieldName: t("entities.gatewayNumber"),
          length: 2,
        })
      );
      return;
    }

    if (!isInputUnique) {
      setInputErrorMessage(
        t("forms.messages.unique", { fieldName: t("entities.gatewayNumber") })
      );
      return;
    }

    onAddClick(inputValue);
    setInputValue("");
  };

  const onEnterPress = (code: string) => {
    code === "Enter" && handleOnAddClick();
  };

  return (
    <GatewayCreatorWrapper>
      <KypsInput
        inputValue={inputValue}
        onValueChange={setInputValue}
        onEnterPress={onEnterPress}
        label={creatorName}
        id={creatorName}
        name={creatorName}
        placeholder={inputPlaceholder}
        type="text"
        isLarge
        value={inputValue}
        errorMessage={inputErrorMessage}
        key={creatorName}
      />
      <KypsButton
        placeholder={buttonPlaceholder}
        onClick={handleOnAddClick}
        size="large"
        key={buttonPlaceholder}
      />
    </GatewayCreatorWrapper>
  );
};

export default GatewayCreator;
