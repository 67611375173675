import { LContainer } from "../landing-content.styles";
import {
  CopyrightData,
  LandingFooterContainer,
  SocialsContainer,
} from "./landing-footer.styles";
import LandingIcon from "../components/landing-icon/landing-icon";
import { TFunction } from "i18next";
import useGA from "../../../hooks/useGA";

const LandingFooter = ({ t }: { t: TFunction }) => {
  const { sendGAEvent } = useGA();

  return (
    <>
      <LContainer>
        <LandingFooterContainer>
          <CopyrightData>{`© 2023 ${t(
            "landing.footer.copyright"
          )}`}</CopyrightData>
          <SocialsContainer>
            <a
              href="https://www.facebook.com/kyps.ukraine"
              target="_blank"
              rel="noreferrer"
              aria-label="KYPS on Facebook"
              onClick={() =>
                sendGAEvent({
                  action: "open Facebook",
                  category: "social media",
                })
              }
            >
              <LandingIcon name="facebook" clickable />
            </a>
            <a
              href="https://www.linkedin.com/company/kypsukraine/about/"
              target="_blank"
              rel="noreferrer"
              aria-label="KYPS on Linkedin"
              onClick={() =>
                sendGAEvent({
                  action: "open Linkedin",
                  category: "social media",
                })
              }
            >
              <LandingIcon name="linkedin" clickable />
            </a>
          </SocialsContainer>
        </LandingFooterContainer>
      </LContainer>
    </>
  );
};

export default LandingFooter;
