import { isNumber } from "lodash";
import { BatteryNames } from "../../utils/icon-names";
import Icon from "../icon/icon";
import { ColoredDot } from "../../styles/helper.styles";
import IconButton from "../icon-button/icon-button";

type SignalVariant = "normal" | "attention" | "error";

export const checkBatteryLevel = (
  batteryValue: number | string | undefined
): JSX.Element => {
  if (isNumber(batteryValue) && batteryValue !== 0)
    return (
      <>
        <Icon iconName={batteryRange(batteryValue)} isSmall />
        {batteryValue}%
      </>
    );

  return (
    <>
      <Icon iconName="fullBattery" isSmall />-
    </>
  );
};

const batteryRange = (batteryValue: number): BatteryNames => {
  if (batteryValue === 0) return "fullBattery";
  if (batteryValue <= 25) return "cautionBattery";
  if (batteryValue <= 50) return "lowBattery";
  if (batteryValue <= 75) return "goodBattery";

  return "fullBattery";
};

export const checkSignalLevel = (signalValue: number | undefined) => {
  if (signalValue)
    return (
      <>
        <ColoredDot className={signalRange(signalValue)} />
        <IconButton name="signal" hoverColor="none" asIcon />
        {signalValue}
      </>
    );

  return (
    <>
      <IconButton name="signal" hoverColor="none" asIcon />-
    </>
  );
};

const signalRange = (signalValue: number): SignalVariant => {
  if (signalValue <= -116) return "error";
  if (signalValue <= -101) return "attention";
  return "normal";
};

export const sortingFunction = (valueA: string, valueB: string) => {
  const getNumericValue = (str: string): number => {
    const match = str.match(/^\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  const numericValueA: number = getNumericValue(valueA);
  const numericValueB: number = getNumericValue(valueB);

  if (numericValueA && numericValueB) {
    return numericValueA - numericValueB;
  }

  if (numericValueA && !numericValueB) {
    return -1;
  }

  if (!numericValueA && numericValueB) {
    return 1;
  }

  return valueA.localeCompare(valueB);
};
