import { IGetLocationFullStatsResponse } from "../../../interfaces/dashboard";

export const getEditData = (
  apiLocationData: IGetLocationFullStatsResponse | undefined
) => {
  if (apiLocationData)
    return apiLocationData.zones.map(({ name, id }) => {
      return { name, id };
    });

  return [];
};
