import { SetStateAction } from "react";
import IconButton from "../icon-button/icon-button";
import { ViewCheckboxItem, ViewCheckboxWrapper } from "./view-checkbox.styles";
import clsx from "clsx";

interface IViewCheckbox {
  isActive: boolean;
  setIsActive: (value: SetStateAction<boolean>) => void;
}

const ViewCheckbox = ({ isActive, setIsActive }: IViewCheckbox) => (
  <ViewCheckboxWrapper>
    <ViewCheckboxItem
      className={clsx(isActive && "active")}
      onClick={() => {
        if (!isActive) setIsActive(!isActive);
      }}
      title="Table"
    >
      <IconButton name="list" />
    </ViewCheckboxItem>
    <ViewCheckboxItem
      className={clsx(!isActive && "active")}
      onClick={() => {
        if (isActive) setIsActive(!isActive);
      }}
      title="Grid"
    >
      <IconButton name="grid" />
    </ViewCheckboxItem>
  </ViewCheckboxWrapper>
);

export default ViewCheckbox;
