import styled from "@emotion/styled";

export const HeroHeading = styled.h1`
  color: #111827;
  text-align: center;
  font-size: 32px;
  font-weight: 800;
  line-height: 100%;
  letter-spacing: -1.2px;
  color: var(--gray-900, #111827);
  text-align: center;
  margin-bottom: 24px;

  span {
    color: #4f46e5;
  }

  @media (min-width: 480px) {
    font-size: 36px;
  }

  @media (min-width: 768px) {
    font-size: 42px;
  }

  @media (min-width: 992px) {
    font-size: 48px;
  }
`;

export const HeroDescription = styled.p`
  max-width: 1000px;
  margin: 0 auto;
  color: #6b7280;
  text-align: center;
  font-size: 18px;
  line-height: 140%;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;
