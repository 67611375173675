import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const SvgWrapper = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    justify-content: center;
    align-content: center;
    margin-right: 6px;
    position: relative;

    svg {
      cursor: pointer;
    }

    &.as-icon {
      svg {
        cursor: auto;
      }
    }

    &.large {
      height: 28px;
      width: 28px;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }

    &.expanded {
      svg {
        transform: rotate(-90deg);
      }
    }

    &.light {
      path {
        stroke: ${theme.palette.text.secondary};
      }
    }

    &.error {
      > svg {
        path {
          fill: ${theme.palette.error.secondary};
        }
      }
    }

    &.zero-margin {
      margin: 0;
    }

    svg:hover {
      &.warning {
        path {
          fill: ${theme.palette.error.disable};
        }
      }

      &.info {
        path {
          fill: ${theme.palette.border.default};
        }
      }

      &.darken {
        path {
          fill: ${theme.palette.text.default};
        }
      }
    }
  `}
`;
