import { TFunction } from "i18next";
import { InputField } from "../../kyps-form/kyps-form-input";
import {
  IRangeInputField,
  RangeInputVariantsEnum,
} from "../../kyps-range-form/range-input";
import { IAddIPointFormFields } from "./add-installation-point-sidebar";

export interface IAddRangeTemperatureFields {
  lowAttention: number;
  lowNormal: number;
  highNormal: number;
  highAttention: number;
}

export const createAddIPointFields = (
  t: TFunction<"translations", undefined>
): InputField<IAddIPointFormFields>[] => [
  {
    label: t("tables.name"),
    name: "name",
    type: "text",
    rules: {
      required: t("forms.messages.isRequiredWithKey", {
        labelName: t("tables.name"),
      }),
      minLength: {
        value: 2,
        message: t("forms.messages.length", {
          fieldName: t("tables.name"),
          length: 1,
        }),
      },
    },
    placeholder: `${t("tables.name")}...`,
  },
];

export const createRangeTemperatureFields = (
  t: TFunction<"translations", undefined>
): IRangeInputField<IAddRangeTemperatureFields>[] => [
  {
    name: "lowAttention",
    type: "number",
    rules: {
      required: t("forms.messages.isRequired"),
    },
    variant: RangeInputVariantsEnum.ATTENTION,
    placeholder: "-10.0",
  },
  {
    name: "lowNormal",
    type: "number",
    rules: { required: t("forms.messages.isRequired") },
    variant: RangeInputVariantsEnum.NORMAL,
    placeholder: "-5.0",
  },
  {
    name: "highNormal",
    type: "number",
    rules: { required: t("forms.messages.isRequired") },
    variant: RangeInputVariantsEnum.NORMAL,
    placeholder: "10.0",
  },
  {
    name: "highAttention",
    type: "number",
    rules: { required: t("forms.messages.isRequired") },
    variant: RangeInputVariantsEnum.ATTENTION,
    placeholder: "20.0",
  },
];
