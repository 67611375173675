import { LContainer, SectionContainer } from "../../landing-content.styles";
import { StyledLandingImage } from "../image-section/image-section.styles";
import {
  ContactFormContent,
  ContactFormTextContent,
  ContactSectionContent,
  ContactSectionDescription,
  ContactSectionHeading,
  ContactSectionImageContainer,
  ContactSectionWrapper,
} from "./contact-section.styles";
import dashboard from "../../../../assets/landing-images/dashboard.svg";
import { TFunction } from "i18next";
import Contacts from "../../components/contacts/contacts";
import { useEffect } from "react";
import { useLocation } from "react-router";
import useGA from "../../../../hooks/useGA";

const ContactSection = ({ t }: { t: TFunction }) => {
  const { pathname } = useLocation();
  const { sendGAPageView } = useGA();

  useEffect(() => {
    sendGAPageView({
      page: pathname,
      title: "contactSection",
    });
  }, [pathname, sendGAPageView]);

  return (
    <LContainer>
      <ContactSectionWrapper>
        <SectionContainer>
          <ContactSectionContent className="anchor" id="contact-us">
            <ContactFormContent>
              <ContactFormTextContent>
                <ContactSectionHeading>
                  {t("landing.contact.title")}
                </ContactSectionHeading>
                <ContactSectionDescription>
                  {t("landing.contact.description")}
                </ContactSectionDescription>
              </ContactFormTextContent>
              <Contacts />
            </ContactFormContent>
            <ContactSectionImageContainer>
              <StyledLandingImage
                src={dashboard}
                alt="dashboard"
                width={548}
                height={416}
              />
            </ContactSectionImageContainer>
          </ContactSectionContent>
        </SectionContainer>
      </ContactSectionWrapper>
    </LContainer>
  );
};

export default ContactSection;
