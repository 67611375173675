import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ToastContainer } from "react-toastify";

export const StyledToaster = styled(ToastContainer)`
  ${({ theme }) => css`
    &&&.Toastify__toast-container {
      margin-bottom: 24px;
      bottom: 0;
      padding: 0;

      @media (max-width: 480px) {
        padding: 0 16px;
      }

      @media (min-width: 768px) {
        padding: 0 8px;
      }
    }

    .Toastify__toast--success {
      background-color: ${theme.palette.success.secondary};
    }

    .Toastify__toast--error {
      background-color: ${theme.palette.error.disable};
    }

    .Toastify__toast--info {
      background-color: ${theme.palette.error.disable};
    }

    .Toastify__toast {
      margin-bottom: 4px;

      @media (max-width: 480px) {
        border-radius: unset;
        padding: 8px;
        border-radius: 6px;
      }
    }

    .Toastify__toast-body {
      font-family: "Roboto";
      color: ${theme.palette.text.dark};
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      &.error {
        color: ${theme.palette.border.dark};
      }
    }

    .Toastify__progress-bar {
    }

    .Toastify__progress-bar--success {
      background-color: ${theme.palette.success.dark};
    }

    .Toastify__progress-bar--error {
      background-color: ${theme.palette.error.dark};
    }

    .Toastify__toast-icon {
      align-self: flex-start;
    }
  `}
`;

export const ErrorMessageItem = styled.p`
  display: list-item;
  list-style-position: inside;
  padding-left: 6px;
  margin-top: 8px;
  font-weight: 400;

  &::before {
    content: "";
    margin-left: -10px;
  }
`;
