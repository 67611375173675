import { TFunction } from "i18next";
import {
  RangesDaysOptions,
  RangesDaysOptionsEnum,
} from "../../../hooks/useDays";
import { KypsButton } from "../../kyps-button/kyps-button";
import { RangeWrapper } from "../kyps-date-picker.styles";

interface IDatepickerRangeButtons {
  isRange: boolean;
  onSingleDayHandler: (day: RangesDaysOptions) => void;
  onPreselectedRangeHandler: (days: RangesDaysOptions) => void;
  onCustomRange: () => void;
  t: TFunction<"translations", undefined>;
}

const DatepickerRangeButtons = ({
  isRange,
  onSingleDayHandler,
  onPreselectedRangeHandler,
  onCustomRange,
  t,
}: IDatepickerRangeButtons) => (
  <RangeWrapper>
    <KypsButton
      onClick={() => onSingleDayHandler(RangesDaysOptionsEnum.TODAY)}
      placeholder={t("time.today")}
      variant="light"
    />
    <KypsButton
      onClick={() => onSingleDayHandler(RangesDaysOptionsEnum.YESTERDAY)}
      placeholder={t("time.yesterday")}
      variant="light"
    />
    <KypsButton
      onClick={() => onPreselectedRangeHandler(RangesDaysOptionsEnum.DAYS3)}
      placeholder={t("time.last3Days")}
      variant="light"
    />
    <KypsButton
      onClick={() => onPreselectedRangeHandler(RangesDaysOptionsEnum.DAYS7)}
      placeholder={t("time.last7Days")}
      variant="light"
    />
    <KypsButton
      onClick={onCustomRange}
      placeholder={t("time.customRange")}
      variant={isRange ? "active" : "light"}
    />
  </RangeWrapper>
);

export default DatepickerRangeButtons;
