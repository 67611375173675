import styled from "@emotion/styled";
import { theme } from "../../theme";
import { css } from "@emotion/react";

export const StatsItemHolder = styled.div`
  display: grid;
  margin-bottom: 32px;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  @media (min-width: 992px) {
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const StatsSubheading = styled.h3`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.text.primary};
    margin-bottom: 12px;
  `}
`;

export const StyledStatsItem = styled.div`
  padding: 16px;
  flex: 1;
  border-radius: 8px;
  border: 1px solid ${theme.palette.background.light};
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${theme.palette.background.default};
  cursor: pointer;
  transition: all 0.2s ease;

  @media (min-width: 768px) {
    padding: 24px;
  }

  &.online {
    color: ${theme.palette.success.default};
  }

  &.orange {
    color: ${theme.palette.info.default};
  }

  &.red {
    color: ${theme.palette.error.primary};
  }

  &.off {
    color: ${theme.palette.text.default};
  }

  &:hover {
    background-color: ${theme.palette.background.light};
    transition: all 0.2s ease;
  }
`;

export const StatsHeading = styled.p`
  color: ${theme.palette.text.default};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 4px;
`;

export const StatsValue = styled.div`
  font-size: 28px;
  line-height: 30px;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const StatsDetail = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 8px;
  color: ${theme.palette.text.default};

  &::before {
    content: "/";
  }
`;
