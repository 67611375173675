import { IAddRangeTemperatureFields } from "../add-installation-point-sidebar/add-installation-point-sidebar.utils";
import { SetStateAction } from "react";
import { useUpdateSensorMutation } from "../../../api/sensor.api";
import { IAddSensorFormFields } from "../add-sensor-sidebar/add-sensor-sidebar";
import { ISensor } from "../../../interfaces/sensors";
import { createAddSensorFields } from "../add-sensor-sidebar/add-sensor-sidebar.utils";
import { TFunction } from "i18next";
import { AddSensorFunction } from "../../../interfaces/add-functions";
import { DeleteSensorFunction } from "../../kyps-tables/sensors-table/sensors-table.helper";
import { createNotification } from "../../toaster/toaster.helper";

type UpdateSensorResult = ReturnType<typeof useUpdateSensorMutation>;
type UpdateSensorFunction = UpdateSensorResult[0];

interface IOnUpdateSensor {
  formData: {
    nameForm: {};
    rangeForm: {};
  };
  sensorId: string;
  isCustomConfig: boolean;
  showHumidity: boolean;
  updateSensor: UpdateSensorFunction;
  setIsSubmitting?: (value: SetStateAction<boolean>) => void;
}

interface IOnRelocateSensor {
  formData: {
    nameForm: {};
    rangeForm: {};
  };
  installationPointId: string;
  sensorId: string;
  isCustomConfig: boolean;
  showHumidity: boolean;
  addRelocatedSensor: AddSensorFunction;
  deleteSensor: DeleteSensorFunction;
  setIsSubmitting?: (value: SetStateAction<boolean>) => void;
}

interface IFillEditSensor {
  apiSensorData: ISensor;
  t: TFunction<"translations", undefined>;
}

export const handleOnRelocateSensor = ({
  formData: { nameForm, rangeForm },
  isCustomConfig,
  installationPointId,
  showHumidity,
  sensorId,
  setIsSubmitting,
  addRelocatedSensor,
  deleteSensor,
}: IOnRelocateSensor) => {
  const { sensorNumber, sensorName } = nameForm as IAddSensorFormFields;
  const { lowNormal, highNormal, lowAttention, highAttention } =
    rangeForm as IAddRangeTemperatureFields;
  setIsSubmitting?.(true);
  deleteSensor(sensorId)
    .unwrap()
    .then(() =>
      addRelocatedSensor({
        installationPointId,
        sensorData: {
          customConfig: isCustomConfig,
          sensorNumber,
          showHumidity,
          temperatureRange: {
            lowYellow: +lowAttention,
            lowGreen: +lowNormal,
            highGreen: +highNormal,
            highYellow: +highAttention,
          },
          sensorName,
        },
      })
        .unwrap()
        .then(({ sensorNumber }) =>
          createNotification({
            message: `Sensor ${sensorNumber} was successfully relocated!`,
            variant: "success",
          })
        )
        .catch(({ data: { error } }) =>
          createNotification({
            message: `${error}`,
            variant: "error",
          })
        )
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};

export const handleOnEditSensor = ({
  formData: { nameForm, rangeForm },
  isCustomConfig,
  showHumidity,
  sensorId,
  setIsSubmitting,
  updateSensor,
}: IOnUpdateSensor) => {
  const { sensorNumber, sensorName } = nameForm as IAddSensorFormFields;
  const { lowNormal, highNormal, lowAttention, highAttention } =
    rangeForm as IAddRangeTemperatureFields;
  setIsSubmitting?.(true);

  updateSensor({
    sensorId,
    sensorData: {
      customConfig: isCustomConfig,
      sensorNumber,
      showHumidity,
      temperatureRange: {
        lowYellow: +lowAttention,
        lowGreen: +lowNormal,
        highGreen: +highNormal,
        highYellow: +highAttention,
      },
      sensorName,
    },
  })
    .unwrap()
    .then(({ sensorNumber }) =>
      createNotification({
        message: `Sensor ${sensorNumber} was successfully updated!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};

export const fillEditSensorFields = ({ apiSensorData, t }: IFillEditSensor) =>
  createAddSensorFields(t).map((field) => {
    const value = apiSensorData[field.name as keyof IAddSensorFormFields] ?? "";
    return { ...field, value };
  });
