import { useNavigate } from "react-router-dom";
import EmptyPage from "../components/kyps-empty/kyps-empty-page";
import { KypsButton } from "../components/kyps-button/kyps-button";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <EmptyPage
      heading={t("pages.notFound.name")}
      iconName="error"
      actionButton={
        <KypsButton
          onClick={() => navigate("/dashboard")}
          placeholder={t("buttons.back.actionWithKey", {
            key: t("buttons.back.actionKeys.dashboard"),
          })}
        />
      }
    />
  );
};

export default NotFound;
