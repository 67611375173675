import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const LandingWrapper = styled.main`
  ${({ theme }) => css`
    background-color: #fefeff;
    margin-top: -40px;

    @media (min-width: 768px) {
      margin-top: -58px;
    }
  `}
`;

export const LContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 20px;

    @media (min-width: 768px) {
      padding: 0 40px;
    }

    @media (min-width: 992px) {
      padding: 0 60px;
    }

    @media (min-width: 1240px) {
      padding: 0 104px;
    }
  `}
`;

export const SectionContainer = styled.section`
  padding: 56px 0;

  @media (min-width: 992px) {
    padding: 82px 0;
  }

  @media (min-width: 1240px) {
    padding: 128px 0;
  }

  &.small {
    padding: 56px 0 28px 0;

    @media (min-width: 992px) {
      padding: 82px 0 56px 0;
    }

    @media (min-width: 1240px) {
      padding: 128px 0 56px 0;
    }
  }
`;
