import {
  IAddInstallationPoint,
  IInstallationPoint,
  IUpdateInstallationPoint,
} from "../interfaces/installation-points";
import { kypsApi } from "./kyps.api";

const installationPointControllerEndpoints = kypsApi.injectEndpoints({
  endpoints: (builder) => ({
    getIPointsByZoneId: builder.query<IInstallationPoint[], string>({
      query: (zoneId) => ({
        url: `/zones/${zoneId}/installationPoints`,
        method: "GET",
      }),
      providesTags: ["InstallationPoints"],
    }),
    getIPointById: builder.query<IInstallationPoint, string>({
      query: (iPointId) => ({
        url: `/installationPoints/${iPointId}`,
        method: "GET",
      }),
      providesTags: ["InstallationPoints"],
    }),

    addIPoint: builder.mutation<IInstallationPoint, IAddInstallationPoint>({
      query: ({ zoneId, iPointData }) => ({
        url: `/zones/${zoneId}/installationPoints`,
        method: "POST",
        body: iPointData,
      }),
      invalidatesTags: [
        "InstallationPoints",
        "DashboardZone",
        "DashboardComplete",
        "Dashboard",
      ],
    }),

    updateIPoint: builder.mutation<
      IInstallationPoint,
      IUpdateInstallationPoint
    >({
      query: ({ iPointId, iPointData }) => ({
        url: `/installationPoints/${iPointId}`,
        method: "PUT",
        body: iPointData,
      }),
      invalidatesTags: [
        "InstallationPoints",
        "DashboardZone",
        "DashboardComplete",
        "Dashboard",
      ],
    }),

    deleteIPoint: builder.mutation<void, string>({
      query: (iPointId) => ({
        url: `/installationPoints/${iPointId}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "InstallationPoints",
        "DashboardZone",
        "DashboardComplete",
        "Dashboard",
      ],
    }),
  }),
});

export const {
  useGetIPointsByZoneIdQuery,
  useAddIPointMutation,
  useGetIPointByIdQuery,
  useUpdateIPointMutation,
  useDeleteIPointMutation,
} = installationPointControllerEndpoints;
