import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import {
  PaginationState,
  SortingState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import { KypsButton } from "../../kyps-button/kyps-button";
import {
  useDeleteCompanyByIdMutation,
  useGetPageOfCompaniesQuery,
} from "../../../api/companies.api";
import KypsModal from "../../kyps-modal/kyps-modal";
import AddCompanySidebar from "../../kyps-sidebar/add-company-sidebar/add-company-sidebar";
import KypsSelect from "../../kyps-select/kyps-select";
import useModal from "../../../hooks/useModal";
import { createCompaniesTableColumns } from "./companies-table.utils";
import AuthWrapper from "../../../auth/auth-wrapper";
import { superAdminRights } from "../../../interfaces/auth-wrapper";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Loaders from "../../loaders/loaders";
import { useGetAuthorizedUserDataQuery } from "../../../api/managers.api";
import { UserRolesEnum } from "../../../interfaces/roles";
import { handleOnDeleteCompany } from "./companies-table.helper";
import { useWindowWidth } from "../../../hooks/useWindowWidth";
import { TFunction } from "i18next";
import { ISortByKeysCompany } from "../../../interfaces/companies";
import useDeleteConfirm from "../../../hooks/useDeleteConfirm";
import DeleteConfirm from "../../delete-confirm/delete-confirm";

interface ICompaniesTable {
  t: TFunction<"translations", undefined>;
}

const baseSortObj: {
  property: keyof ISortByKeysCompany;
  direction: "ASC" | "DESC";
} = { property: "shortName", direction: "ASC" };

export const CompaniesTable = ({ t }: ICompaniesTable) => {
  const userRole = useAppSelector(({ user }) => user.user?.role);

  const pagination = useWindowWidth();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    ...pagination,
  });
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: baseSortObj.property,
      desc: baseSortObj.direction === "DESC",
    },
  ]);
  const tableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (pageSize !== pagination.pageSize) setPagination(pagination);
  }, [pageSize, pagination]);

  const { data: companiesData, isLoading: isLoadingCompanies } =
    useGetPageOfCompaniesQuery({
      page: pageIndex,
      size: pageSize,
      sort: [
        sorting.length
          ? {
              property: sorting[0].id as keyof ISortByKeysCompany,
              direction: sorting[0].desc ? "DESC" : "ASC",
            }
          : baseSortObj,
      ],
    });
  const { data: apiAuthorizedUserData } = useGetAuthorizedUserDataQuery();

  const authorizedUserData = useMemo(
    () => apiAuthorizedUserData?.companies ?? [],
    [apiAuthorizedUserData?.companies]
  );

  const {
    deleteConfirmName,
    isDeleteConfirmOpen,
    openerParams,
    closeDeleteConfirm,
    deleteFunction,
    openDeleteConfirm,
  } = useDeleteConfirm(tableRef);
  const { openModal, closeAndResetModal, isModalOpen, currentModal } =
    useModal();

  const tableData = useMemo(
    () => companiesData?.content ?? [],
    [companiesData]
  );

  const [deleteCompany] = useDeleteCompanyByIdMutation();
  const onDeleteCompany = (id: string, name: string) => {
    handleOnDeleteCompany({
      id,
      name,
      deleteCompany,
      companiesData,
      handleResetPage,
    });
  };

  const companiesTableHeader: ReactNode[] = [
    <KypsSelect
      key="select"
      placeholder={`${t("buttons.choose.action")}...`}
      options={[]}
      onSelect={() => {}} // only for now, later here will be logic
      selectedOption={null}
      isDisable
    />,
    <AuthWrapper
      allowedRoles={superAdminRights}
      children={
        <KypsButton
          placeholder={t("buttons.add.actionWithKey", {
            key: t("entities.company"),
          })}
          onClick={() =>
            openModal(
              <AddCompanySidebar closeModal={closeAndResetModal} t={t} />
            )
          }
          size="large"
        />
      }
      key="Add Company"
    />,
  ];

  const table = useReactTable({
    data: tableData,
    columns: createCompaniesTableColumns({
      openModal,
      closeAndResetModal,
      onDeleteCompany,
      userRole: userRole ?? UserRolesEnum.USER,
      authorizedUserData,
      openDeleteConfirm,
      t,
    }),
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination: { pageIndex, pageSize },
      sorting,
      columnPinning: { left: ["shortName"] },
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualPagination: true,
    pageCount: companiesData?.totalPages,
    sortDescFirst: false,
    autoResetPageIndex: false,
    enableSortingRemoval: false,
  });

  const handleResetPage = () => {
    const newLastPage = companiesData ? companiesData.totalPages - 2 : 0;
    table.setPageIndex(newLastPage);
    setPagination((prev) => {
      return {
        ...prev,
        pageIndex: newLastPage,
      };
    });
  };

  const renderContent = () => (
    <>
      <KypsTable
        table={table}
        tableHeaderElements={companiesTableHeader}
        tableRef={tableRef}
        serverSideSorting
      />
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
        />
      )}
    </>
  );

  return isLoadingCompanies ? (
    <Loaders variant="management" />
  ) : (
    renderContent()
  );
};

export default CompaniesTable;
