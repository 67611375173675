import { TFunction } from "i18next";
import SectionDescription from "../../components/section-description/section-description";
import SectionQuote from "../../components/section-quote/section-quote";
import { LContainer, SectionContainer } from "../../landing-content.styles";
import {
  AboutUsDescriptionContainer,
  AboutUsSectionWrapper,
} from "./about-us-section.styles";

const AboutUsSection = ({ t }: { t: TFunction }) => (
  <AboutUsSectionWrapper>
    <SectionContainer>
      <LContainer>
        <AboutUsDescriptionContainer>
          <SectionDescription
            label={t("landing.aboutUs.label")}
            heading={t("landing.aboutUs.heading")}
            description={[
              t("landing.aboutUs.text.firstText"),
              t("landing.aboutUs.text.secondText"),
            ]}
            variant="light"
            anchor="about-us"
          />
          <SectionQuote quoteContent={`"${t("landing.aboutUs.quote")}"`} />
        </AboutUsDescriptionContainer>
      </LContainer>
    </SectionContainer>
  </AboutUsSectionWrapper>
);

export default AboutUsSection;
