import IconButton from "../icon-button/icon-button";
import { Dispatch, SetStateAction } from "react";
import { EditCell } from "./editable-actions-cell.styles";
import useDeleteFunctions from "../../hooks/useDeleteFunctions";
import {
  handleOnDeleteZone,
  onSetEditedRowsHandler,
} from "./editable-actions-cell.helper";
import { ISelectOption } from "../kyps-select/kyps-select";
import AuthWrapper from "../../auth/auth-wrapper";
import { managersRights } from "../../interfaces/auth-wrapper";
import { TFunction } from "i18next";
import { IOpenDeleteConfirm } from "../../hooks/useDeleteConfirm";

interface IEditableActionsCell {
  rowId: string;
  id: string;
  name: string;
  table: any;
  selectedId: string;
  selectIdToDelete: Dispatch<SetStateAction<string>>;
  t: TFunction<"translations", undefined>;
  openDeleteConfirm?: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  selectZone?: Dispatch<SetStateAction<ISelectOption>>;
  hasNoIcons?: boolean;
}

const EditableActionsCell = ({
  rowId,
  table,
  id,
  name,
  selectedId,
  selectIdToDelete,
  t,
  hasNoIcons,
  openDeleteConfirm,
  selectZone,
}: IEditableActionsCell) => {
  const meta = table.options.meta;

  const {
    deleteFunctions: { zone: deleteZone },
  } = useDeleteFunctions();

  if (hasNoIcons)
    return !meta?.editedRows[rowId] ? (
      <AuthWrapper allowedRoles={managersRights}>
        <EditCell onClick={() => onSetEditedRowsHandler({ meta, rowId })}>
          {t("buttons.edit.action")}
        </EditCell>
      </AuthWrapper>
    ) : null;

  return !meta?.editedRows[rowId] ? (
    <>
      <IconButton
        name="pencil"
        hoverColor="info"
        onClick={() => onSetEditedRowsHandler({ meta, rowId })}
      />
      <IconButton
        name="bin"
        hoverColor="warning"
        hasZeroMargin
        onClick={(e) => {
          e.preventDefault();

          openDeleteConfirm?.({
            deleteConfirmName: name,
            deleteFunction: () =>
              handleOnDeleteZone({
                deleteFunction: deleteZone,
                id,
                name,
                selectZone,
              }),

            openerParamsProp: e.currentTarget.getBoundingClientRect(),
          });
          selectIdToDelete(id);
        }}
      />
    </>
  ) : null;
};

export default EditableActionsCell;
