import { TFunction } from "i18next";
import { IStepItem } from "../../components/step-item/step-item";

export const createTranslatedStepsData = (t: TFunction): IStepItem[] => [
  {
    stepTitle: t("landing.howItWorks.cards.wirelessSensor.title"),
    stepDescription: t("landing.howItWorks.cards.wirelessSensor.description"),
    number: 1,
    iconNames: ["temperature", "humidity"],
    variant: "two-icons",
  },
  {
    stepTitle: t("landing.howItWorks.cards.modem.title"),
    stepDescription: t("landing.howItWorks.cards.modem.description"),
    number: 2,
    iconNames: ["modem"],
    variant: "reverse-arrows",
  },
  {
    stepTitle: t("landing.howItWorks.cards.monitoring.title"),
    stepDescription: t("landing.howItWorks.cards.monitoring.description"),
    number: 3,
    iconNames: ["monitoring"],
  },
];
