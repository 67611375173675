import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../icon/icon";
import {
  AppLanguages,
  AppLanguagesEnum,
  LanguageIcons,
  LanguageNames,
} from "../../utils/languages";
import {
  LanguageSwitcherWrapper,
  LanguagesWrapper,
  SelectableLanguageItem,
  SelectedLanguageWrapper,
} from "./language-switcher.styles";
import clsx from "clsx";
import { isEmpty } from "lodash";

const LanguageSwitcher = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);

  const {
    i18n: {
      changeLanguage,
      resolvedLanguage,
      options: { resources },
    },
  } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<AppLanguages>(
    resolvedLanguage ? (resolvedLanguage as AppLanguages) : AppLanguagesEnum.EN
  );

  const languages: AppLanguages[] = useMemo(() => {
    if (resources)
      return Object.keys(resources).map((lang) => lang as AppLanguages);

    return [];
  }, [resources]);

  useEffect(() => {
    const handleOutsideClick = ({ target }: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(target as Node)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const languagesSelectOptions = useMemo(() => {
    const onSelectLanguage = (language: AppLanguages) => {
      if (language === selectedLanguage) return;

      changeLanguage(language);
      setSelectedLanguage(language);
    };

    if (!isEmpty(languages))
      return languages.map((lang) => (
        <SelectableLanguageItem
          key={lang}
          className={clsx(selectedLanguage === lang && "selected")}
          onClick={() => onSelectLanguage(lang)}
        >
          <Icon iconName={LanguageIcons[lang]} hasZeroMargin />
          {LanguageNames[lang]}
        </SelectableLanguageItem>
      ));

    return null;
  }, [changeLanguage, languages, selectedLanguage]);

  return (
    <LanguageSwitcherWrapper
      ref={selectRef}
      onClick={() => setIsOpen(!isOpen)}
      className={clsx(isOpen && "open")}
    >
      <SelectedLanguageWrapper className={clsx(isOpen && "open")}>
        <Icon iconName={LanguageIcons[selectedLanguage]} hasZeroMargin />
        {selectedLanguage.toUpperCase()}
      </SelectedLanguageWrapper>
      <LanguagesWrapper className={clsx(isOpen && "open")}>
        {languagesSelectOptions}
      </LanguagesWrapper>
    </LanguageSwitcherWrapper>
  );
};

export default LanguageSwitcher;
