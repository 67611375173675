import styled from "@emotion/styled";

export const ManagersTableHeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px 24px;
  width: 100%;
`;

export const ManagersTableActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 48px;
`;

export const ManagersTableHeaderLeft = styled.div`
  display: flex;
  gap: 12px;
  margin-right: auto;
  flex-wrap: wrap;
  align-items: center;
`;

export const ManagersTableHeaderRight = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-left: auto;
  align-items: center;
`;
