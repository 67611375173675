import { useState, ReactNode } from "react";

interface IUseModal {
  isModalOpen: boolean;
  currentModal: ReactNode | null;
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
}

const useModal = (): IUseModal => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<ReactNode | null>(null);

  const openModal = (modalContent: ReactNode) => {
    setCurrentModal(modalContent);
    setIsModalOpen(true);
  };

  const closeAndResetModal = () => {
    setCurrentModal(null);
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    currentModal,
    openModal,
    closeAndResetModal,
  };
};

export default useModal;
