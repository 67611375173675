import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const SearchInputLabel = styled.label`
  ${({ theme }) => css`
    display: block;
    color: ${theme.palette.text.dark};
    font-weight: 500;
    position: relative;

    &.has-loader {
      input {
        padding-right: 24px;
      }
    }
  `}
`;
