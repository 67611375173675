import { css } from "@emotion/react";
import styled from "@emotion/styled";
import SearchIcon from "../../assets/Search.svg";

export const StyledLabel = styled.label`
  ${({ theme }) => css`
    display: block;
    color: ${theme.palette.text.dark};
    font-weight: 500;
    position: relative;

    &.range {
      position: absolute;
    }

    &.large {
      width: 100%;
    }

    &.visible {
      p {
        padding-bottom: 4px;
      }
    }

    &.disabled {
      pointer-events: none;
      background-color: ${theme.palette.background.secondary};

      .styled-select__control {
        pointer-events: none;
        background-color: #ffffff00;
      }
    }

    &.has-loader {
      input {
        padding-right: 24px;
      }
    }
  `}
`;

export const LabelValue = styled.p`
  ${({ theme }) => css`
    cursor: pointer;
    display: inline-flex;
    color: inherit;
    font: inherit;

    &.bottom {
      position: absolute;
      left: 0;
      bottom: -28px;
      width: max-content;
      padding: 2px 6px;
      background: ${theme.palette.background.disable};
      border-radius: 4px;

      span {
        padding: 0;
        margin: 0;
      }
    }
  `}
`;

export const StyledInput = styled.input`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    height: 32px;
    background-color: ${theme.palette.background.default};
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.background.disable};
    padding: 6px 9px;
    border-radius: 6px;
    line-height: 18px;
    font-size: 12px;
    font-weight: 400;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    &::-webkit-search-cancel-button {
      cursor: pointer;
    }

    &.large {
      padding: 10px 12px;
      width: 100%;
    }

    &:focus-visible {
      outline: none;
    }

    ::placeholder {
      color: ${theme.palette.text.default};
    }

    &.with-icon {
      padding-left: 41px;
      background-image: url(${SearchIcon});
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: left 13px center;
    }

    @media (min-width: 768px) {
      padding: 9px 13px;
      line-height: 20px;
      font-size: 14px;
      height: auto;
    }
  `}
`;

export const StyledTextarea = styled.textarea`
  ${({ theme }) => css`
    font-family: "Roboto";
    line-height: 18px;
    font-size: 12px;
    font-weight: 400;
    padding: 6px 4px 6px 9px;
    background-color: #fff;
    color: #111827;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px rgba(189, 154, 154, 0.05);
    width: 100%;
    resize: none;

    &::-webkit-search-cancel-button {
      cursor: pointer;
    }

    &.large {
      padding: 10px 12px;
      width: 100%;
    }

    &:focus-visible {
      outline: none;
    }

    ::placeholder {
      color: ${theme.palette.text.default};
    }

    @media (min-width: 768px) {
      padding: 9px 6px 9px 13px;
      line-height: 20px;
      font-size: 14px;
    }
  `}
`;
