import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const FixedCell = styled.div`
  ${({ theme }) => css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.link-cell {
      color: ${theme.palette.border.disable};
      font-weight: 700;
    }

    &.bold {
      font-weight: 500;
      color: ${theme.palette.text.primary};
    }

    &.dark {
      font-weight: 500;
      color: ${theme.palette.text.dark};
    }

    &.clickable {
      cursor: pointer;
    }
  `}
`;
