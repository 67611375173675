import styled from "@emotion/styled";

export const DetailWrapper = styled.div`
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const DetailIconWrapper = styled.div`
  margin-right: 16px;
  height: 48px;
  width: 48px;
  background-color: #6366f1;
  border-radius: 6px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  line-height: 28px;
  flex: 0 0 48px;
`;

export const DetailDescriptionWrapper = styled.div`
  max-width: 528px;
`;
