import { useState, useMemo, useEffect } from "react";
import PageHeader from "../components/page-header/page-header";
import PageContentContainer, {
  IPageContent,
} from "../components/page-content/page-content-container";
import CompaniesTable from "../components/kyps-tables/companies-table/companies-table";
import { ManagersTable } from "../components/kyps-tables/managers-table/managers-table";
import TreeTable from "../components/kyps-tables/tree-table/tree-table";
import { useAppSelector } from "../hooks/useAppSelector";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { UserRoles, UserRolesEnum } from "../interfaces/roles";
import { getUserRoles } from "../auth/get-user-roles.helper";
import { useTranslation } from "react-i18next";
import { ManagementTabsEnum } from "./management.utils";

const Management = () => {
  const [isActiveTab, setIsActiveTab] = useState(ManagementTabsEnum.COMPANIES);

  const { user } = useAppSelector((state) => state.user);
  const { selectedCompany } = useAppSelector((state) => state.company);
  const [searchParams, setSearchParams] = useSearchParams();
  const { changeRole } = useAppDispatch();

  const companyId = useMemo(() => searchParams.get("company"), [searchParams]);

  const { t } = useTranslation();

  useEffect(() => {
    if (!companyId && selectedCompany)
      setSearchParams(`company=${selectedCompany.value}`);
  }, [companyId, selectedCompany, setSearchParams]);

  useEffect(() => {
    const handleRole = (role: UserRoles) => {
      changeRole(role);
    };

    if (selectedCompany) {
      getUserRoles({
        url: selectedCompany.value,
        handleOnChangeRole: handleRole,
      });
    }
  }, [changeRole, selectedCompany]);

  const pageContent: IPageContent<ManagementTabsEnum>[] = useMemo(() => {
    const isUsersTabAllowed =
      user &&
      user.role !== UserRolesEnum.USER &&
      user.role !== UserRolesEnum.MANAGER;

    const companiesTable = {
      tabName: t("tabs.companies"),
      tabId: ManagementTabsEnum.COMPANIES,
      content: <CompaniesTable t={t} />,
    };

    const infrastructureTable = {
      tabName: t("tabs.infrastructure"),
      tabId: ManagementTabsEnum.INFRASTRUCTURE,
      content: <TreeTable companyId={companyId} t={t} />,
    };

    const usersTable = {
      tabName: t("tabs.users"),
      tabId: ManagementTabsEnum.USERS,
      content: (
        <ManagersTable
          companyId={companyId}
          userRole={user?.role ?? UserRolesEnum.USER}
          userId={user?.id as string}
          t={t}
        />
      ),
    };

    return isUsersTabAllowed
      ? [companiesTable, usersTable, infrastructureTable]
      : [companiesTable, infrastructureTable];
  }, [companyId, t, user]);

  const handleTabClick = (tabId: ManagementTabsEnum) => {
    setIsActiveTab(tabId);
  };

  useEffect(() => {
    if (
      isActiveTab === ManagementTabsEnum.USERS &&
      user?.role === UserRolesEnum.USER
    )
      setIsActiveTab(ManagementTabsEnum.COMPANIES);
  }, [isActiveTab, user?.role]);

  return (
    <>
      <PageHeader pageName={t("pages.management.name")} />
      <PageContentContainer
        pageContent={pageContent}
        activeTab={isActiveTab}
        tabHandler={handleTabClick}
      />
    </>
  );
};

export default Management;
