import {
  SensorStateVariant,
  SensorStateVariantEnum,
} from "../../../interfaces/sensors";
import { UpdateSensorStateFunction } from "../../../interfaces/update-functions";
import { ISelectOption } from "../kyps-select";
import { createNotification } from "../../toaster/toaster.helper";
import { t, TFunction } from "i18next";

interface IGetSensorState {
  sensorState: SensorStateVariant;
  options: ISelectOption[];
  t: TFunction;
}

interface IOnUpdateSensorState {
  sensorId: string;
  state: SensorStateVariant;
  updateSensorState: UpdateSensorStateFunction;
}

export const createOptions = (t: TFunction) => [
  {
    value: SensorStateVariantEnum.CHARGING,
    label: `⚡ ${t("statsCards.sensorState.charging")}`,
  },
  {
    value: SensorStateVariantEnum.MAINTENANCE,
    label: `🛠 ${t("statsCards.sensorState.maintenance")}`,
  },
  {
    value: SensorStateVariantEnum.UNKNOWN,
    label: `✅ ${t("statsCards.sensorState.running")}`,
  },
];

export const getSensorState = ({
  options,
  sensorState,
  t,
}: IGetSensorState): ISelectOption =>
  options.find(
    ({ value }) => sensorState === (value as SensorStateVariantEnum)
  ) ?? {
    value: SensorStateVariantEnum.UNKNOWN,
    label: `✅ ${t("statsCards.sensorState.running")}`,
  };

export const handleOnUpdateSensorState = ({
  state,
  sensorId,
  updateSensorState,
}: IOnUpdateSensorState) =>
  updateSensorState({
    sensorId,
    state,
  })
    .unwrap()
    .then(() =>
      createNotification({
        variant: "success",
        message: t("notification.success", {
          action: t("notification.actions.updated"),
          entity: t("statsCards.sensorState.heading"),
        }),
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
