import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const LocationDashboardWrapper = styled.div`
  ${({ theme }) => css`
    padding: 10px 8px;
    background-color: ${theme.palette.background.default};
    border: 1px solid ${theme.palette.background.light};
    border-radius: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      padding: 20px 16px;
    }

    @media (min-width: 992px) {
      flex: unset;
    }
  `}
`;

export const LocationDashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 16px;
  gap: 12px;
`;

export const LocationHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 6px 12px;
  width: 100%;
`;

export const TableSearchWrapper = styled.div`
  max-width: 140px;
  margin-left: auto;
  min-width: 120px;

  @media (min-width: 768px) {
    max-width: 168px;
  }
`;

export const CheckboxInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  min-width: max-content;
  flex: 1 0 0;
`;
