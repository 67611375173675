import { ILocationSensor, ITemperatureRange } from "./dashboard";
import { IInstallationPoint } from "./installation-points";
import { ILocation } from "./locations";
import { IZone } from "./zones";

export interface ISensor {
  id: string;
  sensorNumber: string;
  temperatureRange: ITemperatureRange;
  showHumidity: boolean;
  customConfig: boolean;
  sensorName: string;
  state: SensorStateVariant;
}

export interface IAddSensorData extends Omit<ISensor, "id" | "state"> {}

export interface IUpdateSensor {
  sensorId: string;
  sensorData: IAddSensorData;
}

export interface IAddSensor {
  installationPointId: string;
  sensorData: IAddSensorData;
}

export interface IAddSensorResponse {
  resourceUri: string;
}

export interface IFormTemperatureRange {
  lowNormal: string;
  lowAttention: string;
  highNormal: string;
  highAttention: string;
}

export interface ISensorHistoryQuery {
  sensorNumber: string;
  to: string;
  page: number;
  size: number;
  from?: Date | string;
}

export interface ISensorHistoryPayload {
  sensorNumber: string;
  gatewayNumber: string;
  currentDbm: number;
  voltage: number;
  sensorTime: string;
  sensorTemp: number;
  sensorHum: number;
}

export interface ISensorFullPayload {
  totalPages: number;
  totalElements: number;
  sort: {
    empty: true;
    sorted: true;
    unsorted: true;
  };
  size: number;
  content: ISensorHistoryPayload[];
  number: number;
  numberOfElements: number;
  first: true;
  last: true;
  pageable: {
    sort: {
      empty: true;
      sorted: true;
      unsorted: true;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: true;
    unpaged: true;
  };
  empty: true;
}

export enum SensorStateVariantEnum {
  UNKNOWN = "UNKNOWN",
  OFF = "OFF",
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  ORANGE = "ORANGE",
  RED = "RED",
  ONLINE = "ONLINE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  CHARGING = "CHARGING",
  MAINTENANCE = "MAINTENANCE",
}

export type SensorStateVariant =
  | SensorStateVariantEnum.OFF
  | SensorStateVariantEnum.GREEN
  | SensorStateVariantEnum.ORANGE
  | SensorStateVariantEnum.RED
  | SensorStateVariantEnum.NOT_APPLICABLE
  | SensorStateVariantEnum.ONLINE
  | SensorStateVariantEnum.YELLOW
  | SensorStateVariantEnum.UNKNOWN
  | SensorStateVariantEnum.CHARGING
  | SensorStateVariantEnum.MAINTENANCE;

export interface IGetSensorsByState {
  companyId: string;
  stateVariant: SensorStateVariant;
}

export interface ISensorByState extends ILocationSensor {
  installationPoint: IInstallationPoint;
  zone: IZone;
  location: ILocation;
}

export interface IUpdateSensorState {
  sensorId: string;
  state: SensorStateVariant;
}
