import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { showMobileNavWidth } from "../components/kyps-nav/kyps-nav.styles";
import { bottomNavHeight } from "../components/kyps-bottom-nav/kyps-bottom-nav.styles";
import {
  headerDesktopHeight,
  headerMobileHeight,
} from "../components/kyps-header/kyps-header.styles";

export const AppWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.main`
  ${({ theme }) => css`
    flex: 1;
    background-color: ${theme.palette.background.secondary};
    display: flex;
    flex-direction: column;
    height: 100%;

    min-height: calc(100vh - ${bottomNavHeight} - ${headerMobileHeight});

    @media (min-width: ${showMobileNavWidth}) {
      min-height: calc(100vh - ${headerDesktopHeight});
    }
  `}
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 16px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 32px;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.clickable {
    cursor: pointer;
  }
`;

export const Subheading = styled.h3`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.text.primary};
  `}
`;

export const ColoredDot = styled.div`
  ${({
    theme: {
      palette: { error, info, success },
    },
  }) => css`
    height: 10px;
    width: 10px;
    margin-right: 2px;
    border-radius: 50%;
    flex-shrink: 0;

    &.error {
      background-color: ${error.default};
    }

    &.attention {
      background-color: ${info.default};
    }

    &.normal {
      background-color: ${success.default};
    }
  `}
`;

export const LoaderDots = styled.div`
  ${({
    theme: {
      palette: { text },
    },
  }) => css`
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${text.dark};
    color: ${text.dark};
    animation: dot-elastic 1s infinite linear;

    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
    }

    &::before {
      left: -6px;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: ${text.dark};
      color: ${text.dark};
      animation: dot-elastic-before 1s infinite linear;
    }

    &::after {
      left: 6px;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: ${text.dark};
      color: ${text.dark};
      animation: dot-elastic-after 1s infinite linear;
    }

    @keyframes dot-elastic-before {
      0% {
        transform: scale(1, 1);
      }
      25% {
        transform: scale(1, 1.5);
      }
      50% {
        transform: scale(1, 0.67);
      }
      75% {
        transform: scale(1, 1);
      }
      100% {
        transform: scale(1, 1);
      }
    }

    @keyframes dot-elastic {
      0% {
        transform: scale(1, 1);
      }
      25% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1, 1.5);
      }
      75% {
        transform: scale(1, 1);
      }
      100% {
        transform: scale(1, 1);
      }
    }

    @keyframes dot-elastic-after {
      0% {
        transform: scale(1, 1);
      }
      25% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1, 0.67);
      }
      75% {
        transform: scale(1, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
    }

    &.left {
      right: unset;
      left: unset;
    }
  `}
`;
