import { TFunction } from "i18next";

export interface ILinkData {
  name: string;
  href: string;
}

export const createTranslatedLinks = (
  t: TFunction<"translations", undefined>
): ILinkData[] => [
  { href: "/#benefits", name: t("landing.header.benefits") },
  {
    href: "/#about-us",
    name: t("landing.header.aboutUs"),
  },
  {
    href: "/#how-it-works",
    name: t("landing.header.howItWorks"),
  },
];
