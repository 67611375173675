import {
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  Dispatch,
  SetStateAction,
} from "react";
import {
  LabelValue,
  StyledInput,
  StyledLabel,
  StyledTextarea,
} from "./kyps-input.styles";
import clsx from "clsx";
import { ErrorMessage } from "../kyps-form/kyps-form.styles";
import { LoaderDots } from "../../styles/helper.styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type: HTMLInputTypeAttribute;
  placeholder: string;
  inputValue: string | number;
  onValueChange?: Dispatch<SetStateAction<string>>;
  onEnterPress?: (keyCode: string) => void;
  label?: string;
  hasIcon?: boolean;
  isLarge?: boolean;
  errorMessage?: string;
  isErrorBottom?: boolean;
  onBlur?: any;
  asTextarea?: boolean;
  hasLoader?: boolean;
  loading?: boolean;
}

const KypsInput = ({
  name,
  label,
  placeholder,
  inputValue,
  onValueChange,
  onEnterPress,
  hasIcon,
  isLarge,
  type,
  errorMessage,
  isErrorBottom,
  onBlur,
  asTextarea,
  hasLoader,
  loading,
}: InputProps) => {
  const handleOnChange = (value: string) => {
    onValueChange?.(value);
  };

  return (
    <StyledLabel
      className={clsx(
        isLarge && "large",
        (label || errorMessage) && "visible",
        hasLoader && "has-loader"
      )}
    >
      {(label || errorMessage) && (
        <LabelValue className={clsx(isErrorBottom && "bottom")}>
          {label}
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </LabelValue>
      )}
      {!asTextarea ? (
        <StyledInput
          className={clsx(hasIcon && "with-icon", isLarge && "large")}
          name={name}
          placeholder={placeholder}
          type={type}
          value={inputValue}
          onChange={({ target: { value } }) => handleOnChange(value)}
          onKeyDown={({ code }) => onEnterPress?.(code)}
          onBlur={onBlur}
          autoComplete="true"
        />
      ) : (
        <StyledTextarea
          className={clsx(hasIcon && "with-icon", isLarge && "large")}
          name={name}
          placeholder={placeholder}
          value={inputValue}
          onChange={({ target: { value } }) => handleOnChange(value)}
          onKeyDown={({ code }) => onEnterPress?.(code)}
          onBlur={onBlur}
          autoComplete="true"
          id="textarea"
        />
      )}
      {loading && <LoaderDots />}
    </StyledLabel>
  );
};

export default KypsInput;
