export enum TreeTableNamesEnum {
  COMPANY = "company",
  LOCATION = "location",
  ZONE = "zone",
  POINT = "iPoint",
  SENSOR = "sensor",
}

export type TreeTableNames =
  | TreeTableNamesEnum.COMPANY
  | TreeTableNamesEnum.LOCATION
  | TreeTableNamesEnum.ZONE
  | TreeTableNamesEnum.POINT
  | TreeTableNamesEnum.SENSOR;

export type IconNames =
  | TreeTableNamesEnum.COMPANY
  | TreeTableNamesEnum.LOCATION
  | TreeTableNamesEnum.ZONE
  | TreeTableNamesEnum.POINT
  | TreeTableNamesEnum.SENSOR
  | "cautionBattery"
  | "lowBattery"
  | "goodBattery"
  | "fullBattery"
  | "email"
  | "telegram"
  | "uaFlag"
  | "gbFlag"
  | "phone"
  | "dashboard"
  | "management"
  | "reports";

export type BatteryNames =
  | "cautionBattery"
  | "lowBattery"
  | "goodBattery"
  | "fullBattery";
