import { useEffect, useMemo, useState } from "react";
import PageHeader from "../../page-header/page-header";
import {
  useGetAllLocationsStatsByCompanyIdQuery,
  useGetLocationsStatsByCompanyIdQuery,
} from "../../../api/dashboard.api";
import { StatsSubheading } from "../../stats-item/stats-item.styles";
import LocationsTable from "../../kyps-tables/locations-table/locations-table";
import { KypsButton } from "../../kyps-button/kyps-button";
import EmptyPage from "../../kyps-empty/kyps-empty-page";
import { Outlet, useSearchParams } from "react-router-dom";
import KypsModal from "../../kyps-modal/kyps-modal";
import AddLocationSidebar from "../../kyps-sidebar/add-location-sidebar/add-location-sidebar";
import useModal from "../../../hooks/useModal";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isEmpty } from "lodash";
import AuthWrapper from "../../../auth/auth-wrapper";
import { adminRights } from "../../../interfaces/auth-wrapper";
import { PaginationState } from "@tanstack/react-table";
import Loaders from "../../loaders/loaders";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { UserRoles } from "../../../interfaces/roles";
import { getUserRoles } from "../../../auth/get-user-roles.helper";
import {
  useGetAllCompaniesQuery,
  useGetCompanyByIdQuery,
} from "../../../api/companies.api";
import { useTranslation } from "react-i18next";
import CompanyStats from "./company-stats/company-stats";

const CompanyDashboard = () => {
  const { selectedCompany, loading: globalCompanyLoading } = useAppSelector(
    (state) => state.company
  );
  const { changeRole } = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const { data: apiCompanies } = useGetAllCompaniesQuery();

  const companyId = useMemo(() => {
    const urlCompanyId = searchParams.get("company");

    if (apiCompanies) {
      if (apiCompanies.find(({ id }) => urlCompanyId === id))
        return urlCompanyId;
    }

    return null;
  }, [apiCompanies, searchParams]);

  useEffect(() => {
    const handleRole = (role: UserRoles) => {
      changeRole(role);
    };

    if (companyId) {
      getUserRoles({
        url: companyId,
        handleOnChangeRole: handleRole,
      });
    }
  }, [companyId, changeRole]);

  useEffect(() => {
    if (!companyId && selectedCompany)
      setSearchParams(`company=${selectedCompany.value}`);
  }, [companyId, selectedCompany, setSearchParams]);

  const { t } = useTranslation();

  const { closeAndResetModal, currentModal, isModalOpen, openModal } =
    useModal();

  const { data: companyFullStats, isLoading: isFullStatsLoading } =
    useGetAllLocationsStatsByCompanyIdQuery(
      companyId
        ? {
            companyId,
            pageable: { page: pageIndex, size: pageSize, sort: [] },
          }
        : skipToken
    );

  const { data: apiCompanyStats } = useGetLocationsStatsByCompanyIdQuery(
    companyId ?? skipToken
  );

  const { data: apiCompanyData } = useGetCompanyByIdQuery(
    selectedCompany?.value ?? skipToken
  );

  const companyCreatedDate = useMemo(() => {
    if (apiCompanyData) return new Date(apiCompanyData.createdAt);

    return null;
  }, [apiCompanyData]);

  const addLocationButton = (
    <KypsButton
      onClick={() => {
        if (selectedCompany?.value)
          openModal(
            <AddLocationSidebar
              closeModal={closeAndResetModal}
              companyId={selectedCompany?.value}
            />
          );
      }}
      placeholder={t("buttons.add.actionWithKey", {
        key: t("entities.location"),
      })}
      hasIcon
      iconType="plus"
      key="Add location"
    />
  );

  const renderContent = () => {
    if (isFullStatsLoading) return <Loaders variant="dashboard" />;

    if (!selectedCompany && !globalCompanyLoading) {
      return (
        <EmptyPage
          heading={t("emptyMessage.selectEntity.actionWithEntity", {
            entity: t("entities.company"),
          })}
          iconName="clipboard"
        />
      );
    } else if (
      companyFullStats?.content &&
      !isEmpty(companyFullStats?.content)
    ) {
      return (
        <>
          <StatsSubheading>{t("pages.dashboard.quickStats")}</StatsSubheading>
          <CompanyStats
            apiCompanyStats={apiCompanyStats}
            closeAndResetModal={closeAndResetModal}
            companyCreatedDate={companyCreatedDate}
            companyFullStats={companyFullStats}
            companyId={companyId}
            openModal={openModal}
            t={t}
          />
          <LocationsTable
            data={companyFullStats.content}
            totalPages={companyFullStats.totalPages}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPagination={setPagination}
          />
        </>
      );
    }

    if (companyFullStats?.content && isEmpty(companyFullStats?.content)) {
      return (
        <EmptyPage
          heading={t("emptyMessage.text", {
            entity: t("emptyMessage.entities.location"),
          })}
          iconName="clipboard"
          actionButton={
            <AuthWrapper
              allowedRoles={adminRights}
              children={addLocationButton}
            />
          }
        />
      );
    }
  };

  return (
    <>
      <PageHeader
        pageName={t("pages.dashboard.name")}
        pageActions={[
          <AuthWrapper
            allowedRoles={adminRights}
            children={addLocationButton}
            key="AuthWrapper"
          />,
        ]}
      />
      {renderContent()}
      <Outlet />
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
    </>
  );
};

export default CompanyDashboard;
