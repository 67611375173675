import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const SensorConfigsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;

  @media (min-width: 992px) {
    width: auto;
  }
`;

export const CheckboxComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.disabled {
    pointer-events: none;
  }
`;

export const CheckboxName = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.text.primary};
    font-size: 14px;
    font-weight: 500;
    margin-right: 12px;
    min-width: max-content;
  `}
`;

export const CheckboxWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
`;

export const CheckboxBackground = styled.span`
  ${({ theme }) => css`
    display: none;
    width: 40px;
    height: 24px;
    padding: 2px;
    background-color: ${theme.palette.background.light};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: background-color 0.2s ease-in-out;

    &.checked {
      justify-content: flex-end;
      background-color: ${theme.palette.border.secondary};

      &.disabled {
        background-color: ${theme.palette.background.dark};
      }
    }
  `}
`;

export const CheckboxCheckmark = styled.span`
  ${({ theme }) => css`
    width: 20px;
    height: 20px;
    background-color: ${theme.palette.background.secondary};
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))
      drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  `}
`;
