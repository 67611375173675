import { createColumnHelper } from "@tanstack/react-table";
import {
  DeleteCellContent,
  DeleteCellName,
  DeleteLocationCell,
  DetailsNameCell,
} from "../location-details-table/location-details-table.styles";
import EditableDataCell from "../../editable-data-cell/editable-data-cell";
import AuthWrapper from "../../../auth/auth-wrapper";
import { managersRights } from "../../../interfaces/auth-wrapper";
import IconButton from "../../icon-button/icon-button";
import {
  onDeleteSensorHandler,
  translateCellName,
} from "./editable-sensor-table.helper";
import EditableActionsCell from "../../editable-actions-cell/editable-actions-cell";
import { ISensor } from "../../../interfaces/sensors";
import { Dispatch, SetStateAction } from "react";
import { DeleteFunction } from "../../../interfaces/delete-function";
import { TFunction } from "i18next";
import {
  EditableSensorFieldsEnum,
  IEditableSensorTableData,
} from "../../../interfaces/editable-cells";
import { IOpenDeleteConfirm } from "../../../hooks/useDeleteConfirm";
import clsx from "clsx";
import { getColumnWidth } from "../kyps-table.utils";

interface ICreateEditableSensorTableColumns {
  sensorData: ISensor;
  selectedId: string;
  isDisabled: boolean;
  isConfirmOpen: boolean;
  t: TFunction<"translations", undefined>;
  setSelectedId: Dispatch<SetStateAction<string>>;
  deleteSensor: DeleteFunction;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  closeModal: () => void;
}

const { accessor } = createColumnHelper<IEditableSensorTableData>();
export const createEditableSensorTableColumns = ({
  sensorData,
  selectedId,
  isDisabled,
  isConfirmOpen,
  setSelectedId,
  deleteSensor,
  closeModal,
  openDeleteConfirm,
  t,
}: ICreateEditableSensorTableColumns) => [
  accessor("name", {
    header: "",
    size: getColumnWidth("sensorDetails", "name"),
    cell: ({ getValue }) => {
      if (getValue() === EditableSensorFieldsEnum.DELETE) return "";

      return (
        <DetailsNameCell>
          {translateCellName({ t, cellName: getValue() })}:
        </DetailsNameCell>
      );
    },
  }),
  accessor("value", {
    header: "",
    size: getColumnWidth("sensorDetails", "value"),
    cell: ({
      getValue,
      row: {
        id: rowId,
        original: { value, id, name },
      },
      table: {
        options: { meta },
      },
    }) => {
      if (getValue() === EditableSensorFieldsEnum.DELETE) return "";

      return (
        <EditableDataCell
          meta={meta}
          name={value}
          rowId={rowId}
          zoneId={id}
          fieldName={name}
          sensorData={sensorData}
          isSensorCell
        />
      );
    },
  }),
  accessor("id", {
    header: "",
    id: "id",
    size: getColumnWidth("sensorDetails", "id"),
    cell: ({
      row: {
        id: rowId,
        original: { value, id, name },
      },
      table,
    }) => {
      if (isDisabled) return null;

      if (name === EditableSensorFieldsEnum.DELETE && sensorData)
        return (
          <AuthWrapper
            allowedRoles={managersRights}
            children={
              <DeleteLocationCell>
                <DeleteCellContent
                  className={clsx(isConfirmOpen && "active")}
                  onClick={(e) => {
                    openDeleteConfirm({
                      deleteConfirmName: sensorData.sensorName?.length
                        ? sensorData.sensorName
                        : sensorData.sensorNumber,
                      deleteFunction: () =>
                        onDeleteSensorHandler({
                          sensorData,
                          closeModal,
                          setSelectedId,
                          deleteSensor,
                        }),
                      openerParamsProp: e.currentTarget.getBoundingClientRect(),
                    });
                    setSelectedId(id);
                  }}
                >
                  <IconButton name="bin" color="error" />
                  <DeleteCellName>
                    {t("buttons.remove.actionWithKey", {
                      key: t("entities.sensor"),
                    })}
                  </DeleteCellName>
                </DeleteCellContent>
              </DeleteLocationCell>
            }
          />
        );
      if (
        name === EditableSensorFieldsEnum.SENSOR_ID ||
        name === EditableSensorFieldsEnum.SENSOR_NAME
      )
        return (
          <EditableActionsCell
            rowId={rowId}
            id={id}
            name={value}
            table={table}
            selectIdToDelete={setSelectedId}
            selectedId={selectedId}
            t={t}
            hasNoIcons
          />
        );

      return null;
    },
  }),
];
export { EditableSensorFieldsEnum };
