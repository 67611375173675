import styled from "@emotion/styled";

export const FeedbackSectionWrapper = styled.div``;

export const FullWidthColor = styled.div`
  background-color: #4338ca;
`;

export const QuoteComponent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const QuoteContent = styled.h2`
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  margin: 24px 0;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 22px;
  }

  @media (min-width: 992px) {
    font-size: 24px;
  }
`;

export const QuoteAuthor = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  color: #e0e7ff;

  span {
    display: block;
    color: #fff;
  }
`;

export const QuoteContentContainer = styled.div`
  padding: 40px 0 20px;

  @media (min-width: 992px) {
    padding: 80px 32px 48px 0;
    max-width: 768px;
  }
`;

export const QuoteImageWrapper = styled.div`
  width: 100%;
  min-width: 260px;
  max-width: 368px;
  position: relative;
  margin: 0 auto;
  padding: 12px 0;

  @media (min-width: 992px) {
    min-width: 368px;
  }

  img {
    position: static;
    margin: auto 0;
    border-radius: 12px;
    height: 100%;

    @media (min-width: 992px) {
      position: absolute;
      top: 0;
      bottom: 0;
      height: auto;
    }
  }
`;
