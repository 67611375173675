import { t } from "i18next";
import { useLazySendManualAllLocationsReportQuery } from "../../api/reports.api";
import { createNotification } from "../../components/toaster/toaster.helper";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

type UseLazySendManualAllLocationsReportQueryType = ReturnType<
  typeof useLazySendManualAllLocationsReportQuery
>;

export type SendAllLocationsReportTriggerType =
  UseLazySendManualAllLocationsReportQueryType[0];

interface IHandleSendAllReports {
  date: string;
  sendReports: SendAllLocationsReportTriggerType;
}

export const formatDate = (date: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) return "today";
  else if (date.toDateString() === yesterday.toDateString()) return "yesterday";
  else {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `date=${year}-${month}-${day}`;
  }
};

export const handleSendAllReports = ({
  date,
  sendReports,
}: IHandleSendAllReports) =>
  sendReports({ date })
    .then(({ isError, error }) => {
      const { status } = error as FetchBaseQueryError;
      if (isError)
        throw new Error(
          t("notification.manualReportSending.error", {
            status: status,
          })
        );

      createNotification({
        message: t("notification.manualReportSending.message", {
          date,
          entityName: t("notification.manualReportSending.entities.company"),
        }),
        variant: "success",
      });
    })
    .catch((error) =>
      createNotification({
        message: error as string,
        variant: "error",
      })
    );
