import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IRangeInputField } from "./range-input";
import IconButton from "../icon-button/icon-button";
import {
  RangeHeading,
  RangeItemBlock,
  RangeItemHeading,
  RangeSwitchWrapper,
  RangeFormWrapper,
  RangeHeadingWrapper,
  RangeComponentWrapper,
  RangeHeadingContainer,
  HalfRangeHolder,
  StyledFieldset,
} from "./range-form.styles";
import clsx from "clsx";
import {
  ImperativeHandleRef,
  highTemperatureFields,
  lowTemperatureFields,
} from "./range-form.utils";
import { KypsButton } from "../kyps-button/kyps-button";
import { useTranslation } from "react-i18next";
import { fillRangeFormDefaultValues } from "./range-input.helper";

export interface ICustomErrorData {
  fieldName: string;
}

interface IRangeForm {
  fields: IRangeInputField<any>[];
  onSubmit: SubmitHandler<any>;
  customErrorsData?: ICustomErrorData[];
  checkboxes?: JSX.Element;
  showEmptyMessage?: boolean;
  isDisabled?: boolean;
  hasPadding?: boolean;
  hasBorder?: boolean;
  preventReset?: boolean;
  hasSaveButton?: boolean;
  onSaveButton?: () => void;
  onInputBlur?: () => void;
}

const RangeForm = forwardRef<ImperativeHandleRef, IRangeForm>(
  (
    {
      fields,
      onSubmit,
      customErrorsData,
      checkboxes,
      showEmptyMessage,
      isDisabled,
      hasPadding,
      hasBorder,
      preventReset,
      hasSaveButton,
      onSaveButton,
      onInputBlur,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const defaultValues = useMemo(
      () => fillRangeFormDefaultValues(fields),
      [fields]
    );

    const {
      handleSubmit,
      control,
      formState: { errors, isDirty },
      setError,
      reset: resetUseForm,
    } = useForm({ defaultValues });

    const formRef = useRef<HTMLFormElement>(null);

    useImperativeHandle(ref, () => ({
      reset: () => {
        if (isDirty) resetUseForm();
      },
      submit: () => {
        formRef.current?.dispatchEvent?.(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      },
      defaultData: defaultValues,
    }));

    useEffect(() => {
      if (customErrorsData) {
        customErrorsData.map(({ fieldName }) =>
          setError(`${fieldName}`, {
            type: "manual",
            message: `*${t("errors.invalidValue")}`,
          })
        );
      }
    }, [customErrorsData, setError, t]);

    useEffect(() => {
      if (preventReset) return;

      resetUseForm(defaultValues);
    }, [defaultValues, preventReset, resetUseForm]);

    return (
      <RangeComponentWrapper
        className={clsx(hasPadding && "has-padding", hasBorder && "has-border")}
      >
        <RangeHeadingWrapper>
          <RangeHeadingContainer>
            <RangeHeading>{t("temperatureSettings.name")}</RangeHeading>
            {hasSaveButton && (
              <KypsButton
                onClick={() => onSaveButton?.()}
                placeholder={t("buttons.save.actionWithFixedKey")}
              />
            )}
          </RangeHeadingContainer>
          {!showEmptyMessage && checkboxes}
        </RangeHeadingWrapper>
        <RangeFormWrapper className={clsx(isDisabled && "disable")}>
          {showEmptyMessage ? (
            t("emptyMessage.selectEntity.actionWithEntity", {
              entity: t("entities.iPoint"),
            })
          ) : (
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <StyledFieldset disabled={isDisabled}>
                <HalfRangeHolder>
                  <RangeItemHeading>
                    {t("temperatureSettings.lowTemperature")}
                  </RangeItemHeading>
                  <RangeItemBlock>
                    {lowTemperatureFields({
                      control,
                      errors,
                      fields,
                      onInputBlur,
                    })}
                  </RangeItemBlock>
                </HalfRangeHolder>
                <RangeSwitchWrapper>
                  <IconButton
                    name="switchHorizontal"
                    hoverColor="none"
                    hasZeroMargin
                    asIcon
                  />
                </RangeSwitchWrapper>
                <HalfRangeHolder>
                  <RangeItemHeading>
                    {t("temperatureSettings.highTemperature")}
                  </RangeItemHeading>
                  <RangeItemBlock>
                    {highTemperatureFields({
                      control,
                      errors,
                      fields,
                      onInputBlur,
                    })}
                  </RangeItemBlock>
                </HalfRangeHolder>
              </StyledFieldset>
            </form>
          )}
        </RangeFormWrapper>
      </RangeComponentWrapper>
    );
  }
);

export default RangeForm;
