import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const SelectMenuWrapper = styled.div`
  ${({ theme }) => css`
    top: 100%;
    position: absolute;
    width: 100%;
    background-color: ${theme.palette.background.default};
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
  `}
`;

export const SelectActionsWrapper = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.text.disable};
    display: flex;
    justify-content: space-between;
    padding: 8px 16px !important;
  `}
`;

export const SelectActionButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:last-of-type {
      svg {
        height: 12px;
        width: 12px;
      }
    }

    &:hover {
      color: ${theme.palette.text.dark};
    }
  `}
`;

export const SelectAction = styled.span`
  display: inline-block;
  padding-left: 4px !important;
`;
