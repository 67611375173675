import {
  Dispatch,
  RefObject,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import { DetailsTableHeading } from "./location-details-table.styles";
import { useGetLocationByIdQuery } from "../../../api/location.api";
import useDeleteFunctions from "../../../hooks/useDeleteFunctions";
import { useNavigate } from "react-router-dom";
import { Subheading } from "../../../styles/helper.styles";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { createLocationDetailsColumns } from "./location-details-table.utils";
import { createLocationTableData } from "./location-details-table.helper";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Loaders from "../../loaders/loaders";
import { TFunction } from "i18next";
import { ILocationDetailsTableData } from "../../../interfaces/editable-cells";
import DeleteConfirm from "../../delete-confirm/delete-confirm";
import useDeleteConfirm from "../../../hooks/useDeleteConfirm";
import useWindowSize from "../../../hooks/useWindowSize";
import useScrollTop from "../../../hooks/useScrollTop";

interface ILocationDetailsTable {
  locationId: string;
  sidebarRef: RefObject<HTMLDivElement>;
  closeSidebar: () => void;
  selectZone: Dispatch<SetStateAction<ISelectOption>>;
  setParentManualSkipToken: Dispatch<SetStateAction<boolean>>;
  t: TFunction<"translations", undefined>;
}

const LocationDetailsTable = ({
  locationId,
  sidebarRef,
  closeSidebar,
  selectZone,
  setParentManualSkipToken,
  t,
}: ILocationDetailsTable) => {
  const userRole = useAppSelector(({ user }) => user.user?.role);

  const [manualSkipToken, setManualSkipToken] = useState(false);
  const [editedRows, setEditedRows] = useState({});
  const [selectedId, setSelectedId] = useState<string>("");
  const tableRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const {
    deleteFunctions: { location: deleteLocation },
  } = useDeleteFunctions();
  const { sidebarExtraSpace } = useWindowSize({ sidebarRef });
  const confirmScrollTop = useScrollTop(sidebarRef);
  const {
    closeDeleteConfirm,
    deleteConfirmName,
    deleteFunction,
    isDeleteConfirmOpen,
    openDeleteConfirm,
    openerParams,
  } = useDeleteConfirm(tableRef, sidebarExtraSpace, confirmScrollTop);

  const { data: apiLocationData, isLoading } = useGetLocationByIdQuery(
    manualSkipToken ? skipToken : locationId
  );

  const data: ILocationDetailsTableData[] = useMemo(() => {
    if (apiLocationData && userRole) {
      const { address, comments, id, name, gatewayNumbers } = apiLocationData;
      return createLocationTableData({
        address,
        comments,
        id,
        name,
        userRole,
        gatewayNumbers,
      });
    }

    return [];
  }, [apiLocationData, userRole]);

  const closeAndResetSidebar = () => {
    closeSidebar();
    navigate("/dashboard");
  };

  const table = useReactTable({
    data,
    columns: createLocationDetailsColumns({
      apiLocationData,
      locationId,
      selectedId,
      isConfirmOpen: isDeleteConfirmOpen,
      selectZone,
      setSelectedId,
      deleteLocation,
      closeAndResetSidebar,
      setManualSkipToken,
      setParentManualSkipToken,
      openDeleteConfirm,
      t,
    }),
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: { left: ["name"] },
    },
    meta: {
      editedRows,
      setEditedRows,
    },
    autoResetPageIndex: false,
  });

  if (isLoading) return <Loaders variant="editable" />;

  return (
    <>
      <KypsTable
        table={table}
        isTheadHidden
        className="details-table"
        hasNoActions
        hidePagination
        hideSorting
        tableHeaderElements={[
          <DetailsTableHeading key="General Information">
            <Subheading>{t("tabs.generalInformation")}</Subheading>
            <p>Small description here</p>
          </DetailsTableHeading>,
        ]}
        tableRef={tableRef}
      />
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
          confirmContainer={tableRef}
        />
      )}
    </>
  );
};

export default LocationDetailsTable;
