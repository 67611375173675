import { TFunction } from "i18next";
import { IAddCompanyFields } from "../../../pages/management.utils";
import { InputField } from "../../kyps-form/kyps-form-input";

export const createAddCompanyFields = (
  t: TFunction<"translations", undefined>
): InputField<IAddCompanyFields>[] => [
  {
    label: t("tables.shortName"),
    name: "shortName",
    type: "text",
    rules: {
      required: t("forms.messages.isRequiredWithKey", {
        labelName: t("tables.shortName"),
      }),
      minLength: {
        value: 2,
        message: t("forms.messages.length", {
          fieldName: t("tables.shortName"),
          length: 2,
        }),
      },
    },
  },
  {
    label: t("tables.fullName"),
    name: "fullName",
    type: "text",
    rules: {
      required: t("forms.messages.isRequiredWithKey", {
        labelName: t("tables.fullName"),
      }),
      minLength: {
        value: 2,
        message: t("forms.messages.length", {
          fieldName: t("tables.fullName"),
          length: 2,
        }),
      },
    },
  },
  {
    label: t("tables.comment"),
    name: "comment",
    type: "text",
    rules: {},
  },
];
