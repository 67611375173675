import { Row } from "@tanstack/react-table";
import { IFullStatsContent } from "../../../interfaces/dashboard";
import { ISensorByState } from "../../../interfaces/sensors";
import { ISensorsStatsData } from "../../../interfaces/tables";
import { toRoundValue } from "../../dashboard-components/location-dashboard/location-dashboard.helper";
import { UserRoles, UserRolesEnum } from "../../../interfaces/roles";
import { getUserRoles } from "../../../auth/get-user-roles.helper";

interface ICreateSensorsStatsTableData {
  data: ISensorByState[];
  companyLocations: IFullStatsContent[];
}

interface IFilterSensorsData {
  data: ISensorByState[];
  companyLocations: IFullStatsContent[];
}

export const createSensorsStatsTableData = ({
  data,
  companyLocations,
}: ICreateSensorsStatsTableData): ISensorsStatsData[] =>
  filterSensorsData({ data, companyLocations }).map((arr) => {
    const sensors = arr.map(
      ({
        customConfig,
        id,
        installationPoint,
        lastDbm,
        lastHumidity,
        lastTemperature,
        lastUpdateDate,
        lastVoltage,
        sensorNumber,
        showHumidity,
        location,
        state,
        temperatureRange: { highGreen, lowGreen },
        zone,
        sensorName,
      }) => ({
        name: sensorNumber,
        id: id,
        range: `${toRoundValue(lowGreen)}; ${toRoundValue(highGreen)}`,
        iPointName: installationPoint.name,
        iPointId: installationPoint.id,
        battery: lastVoltage,
        location,
        isCustom: customConfig,
        lastUpdateDate,
        signal: lastDbm,
        lastTemperature: toRoundValue(lastTemperature),
        state,
        showHumidity,
        lastHumidity: showHumidity ? toRoundValue(lastHumidity) : undefined,
        zone,
        sensorName,
      })
    );

    return {
      name: arr[0].location.name,
      id: arr[0].location.id,
      subRow: sensors,
    };
  });

const filterSensorsData = ({ data, companyLocations }: IFilterSensorsData) =>
  companyLocations
    .map(({ locationId }) => {
      return data.filter(({ location: { id } }) => id === locationId);
    })
    .filter((locationData) => locationData.length > 0);

export const checkHumidity = (value: number | null | undefined) => {
  if (value !== null) return `${value} %`;

  return "-";
};

interface IGetIsAllowedLocation {
  id: string;
  depth: number;
  userRole: UserRoles;
  getParentRow: () => Row<ISensorsStatsData> | undefined;
}

export const getIsDisabledByLocationRole = ({
  id,
  depth,
  userRole,
  getParentRow,
}: IGetIsAllowedLocation) => {
  if (
    userRole === UserRolesEnum.ADMIN ||
    userRole === UserRolesEnum.SUPER_ADMIN
  )
    return true;

  if (depth === 0) return getUserRoles({ url: id }) === UserRolesEnum.MANAGER;

  if (depth === 1)
    return (
      getUserRoles({ url: getParentRow?.()?.original.id as string }) ===
      UserRolesEnum.MANAGER
    );
};
