interface IShouldBeVisible {
  btn: number;
  currentPage: number;
  pageCount: number;
}

export const shouldBeVisible = ({
  btn,
  currentPage,
  pageCount,
}: IShouldBeVisible): boolean => {
  const isNotByStart =
    currentPage - 2 === btn && pageCount - 1 !== currentPage && btn >= 3;
  const isLast = currentPage - 4 === btn && pageCount - 1 === currentPage;
  const isNotByEnd = currentPage + 2 === btn && pageCount - btn > 3;
  return isNotByStart || isLast || isNotByEnd;
};
