import styled from "@emotion/styled";

export const ContactSectionWrapper = styled.div`
  @media (min-width: 768px) {
    padding: 0 32px;
  }
`;

export const ContactSectionContent = styled.div`
  border-radius: 8px;
  background-color: #4338ca;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const ContactFormContent = styled.div`
  padding: 20px;
  max-width: 600px;

  @media (min-width: 768px) {
    padding: 32px;
  }

  @media (min-width: 992px) {
    padding: 40px 20px 40px 40px;
  }

  @media (min-width: 1240px) {
    padding: 80px;
  }
`;

export const ContactFormTextContent = styled.div`
  margin-bottom: 32px;
`;

export const ContactSectionHeading = styled.h2`
  color: #fff;
  font-size: 24px;
  font-weight: 800;
  line-height: 120%;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    font-size: 28px;
  }
  @media (min-width: 992px) {
    font-size: 32px;
  }
  @media (min-width: 1240px) {
    font-size: 36px;
  }
`;

export const ContactSectionDescription = styled.p`
  font-size: 16px;
  line-height: 133%;
  color: #c7d2fe;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const ContactSectionImageContainer = styled.div`
  align-self: flex-end;
  display: flex;
  padding: 20px 0 20px 20px;

  @media (min-width: 480px) {
    padding: 32px 0 32px 32px;
  }

  @media (min-width: 768px) {
    padding: 40px 0 40px 20px;
  }

  @media (min-width: 992px) {
    flex: 2 0 500px;
  }
`;
