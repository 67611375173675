import { ReactNode, RefObject, useRef, useState } from "react";
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import KypsModal from "../../kyps-modal/kyps-modal";
import useModal from "../../../hooks/useModal";
import { KypsButton } from "../../kyps-button/kyps-button";
import { createSensorsTableColumns } from "./sensors-table.utils";
import { ILocationSensor } from "../../../interfaces/dashboard";
import { hasEmptyMessage } from "../location-table/location-table.helper";
import { Subheading } from "../../../styles/helper.styles";
import { useDeleteSensorMutation } from "../../../api/sensor.api";
import { handleOnDeleteSensor } from "./sensors-table.helper";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { TFunction } from "i18next";
import useDeleteConfirm from "../../../hooks/useDeleteConfirm";
import useScrollTop from "../../../hooks/useScrollTop";
import useWindowSize from "../../../hooks/useWindowSize";
import DeleteConfirm from "../../delete-confirm/delete-confirm";

export interface ISensorsTable {
  sensorsData: ILocationSensor[];
  currentZone: ISelectOption;
  currentIPoint: ISelectOption;
  sidebarRef: RefObject<HTMLDivElement>;
  onAddSensor: () => void;
  t: TFunction<"translations", undefined>;
}

const SensorsTable = ({
  sensorsData,
  currentZone,
  currentIPoint,
  sidebarRef,
  onAddSensor,
  t,
}: ISensorsTable) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "sensorNumber", desc: false },
  ]);
  const [selectedSensorId, setSelectedSensorId] = useState("");
  const tableRef = useRef<HTMLDivElement>(null);

  const [deleteSensor] = useDeleteSensorMutation();

  const { sidebarExtraSpace } = useWindowSize({ sidebarRef });
  const extraScrollHeight = useScrollTop(sidebarRef);
  const {
    closeDeleteConfirm,
    deleteConfirmName,
    deleteFunction,
    isDeleteConfirmOpen,
    openDeleteConfirm,
    openerParams,
  } = useDeleteConfirm(tableRef, sidebarExtraSpace, extraScrollHeight);
  const { closeAndResetModal, currentModal, isModalOpen, openModal } =
    useModal();

  const sensorsTableHeader: ReactNode[] = [
    <Subheading key="Sensors">{t("tables.sensors")}</Subheading>,
    <KypsButton
      onClick={() => onAddSensor()}
      placeholder={t("buttons.add.actionWithKey", {
        key: t("entities.sensor"),
      })}
      key="Add Sensor"
    />,
  ];

  const onDeleteSensor = (id: string, name: string) =>
    handleOnDeleteSensor({ id, name, deleteSensor });

  const table = useReactTable({
    data: sensorsData,
    columns: createSensorsTableColumns({
      openModal,
      closeAndResetModal,
      onDeleteSensor,
      setSelectedSensorId,
      openDeleteConfirm,
      selectedSensorId,
      defaultZone: currentZone,
      defaultIPoint: currentIPoint,
      t,
    }),
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    sortDescFirst: false,
  });

  return (
    <>
      <KypsTable
        table={table}
        tableHeaderElements={sensorsTableHeader}
        emptyMessage={hasEmptyMessage({
          data: sensorsData,
          message: t("emptyMessage.text", {
            entity: t("emptyMessage.entities.sensor"),
          }),
          iconName: "clipboard",
          isSmall: true,
        })}
        tableRef={tableRef}
        isTransparentBg
      />
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
          confirmContainer={tableRef}
        />
      )}
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
    </>
  );
};

export default SensorsTable;
