import { useState, useEffect, MouseEvent } from "react";
import KypsInput from "../kyps-input/kyps-input";
import { KypsButton } from "../kyps-button/kyps-button";
import { onSetEditedRowsHandler } from "../editable-actions-cell/editable-actions-cell.helper";
import {
  ButtonWrapper,
  EditableDataCellValue,
  InputWrapper,
} from "./editable-data-cell.styles";
import useUpdateFunctions from "../../hooks/useUpdateFunctions";
import {
  handleOnUpdateLocation,
  handleOnUpdateSensor,
  handleOnUpdateZone,
} from "./editable-data-cell.helper";
import { ILocation } from "../../interfaces/locations";
import { ISensor } from "../../interfaces/sensors";
import { useTranslation } from "react-i18next";
import { EditableSensorFieldsEnum } from "../../interfaces/editable-cells";
import clsx from "clsx";

interface ITableCell {
  rowId: string;
  zoneId: string;
  name: string;
  meta: any;
  fieldName: string;
  asTextarea?: boolean;
  locationData?: ILocation;
  isLocationCell?: boolean;
  sensorData?: ISensor;
  isSensorCell?: boolean;
}

const EditableDataCell = ({
  rowId,
  zoneId,
  name,
  meta,
  asTextarea,
  fieldName,
  locationData,
  isLocationCell,
  sensorData,
  isSensorCell,
}: ITableCell) => {
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [value, setValue] = useState(name ?? "");

  const { t } = useTranslation();

  const {
    updateFunctions: { updateZone, updateLocation, updateSensor },
  } = useUpdateFunctions();

  const isInputValid =
    (!!value && value.replace(/\s/g, "").length > 2) ||
    asTextarea ||
    fieldName === EditableSensorFieldsEnum.SENSOR_NAME;

  useEffect(() => {
    if (isInputValid && inputErrorMessage) {
      setInputErrorMessage("");
    }
  }, [inputErrorMessage, isInputValid]);

  const handleOnButton = ({
    currentTarget: { name: buttonAction },
  }: MouseEvent<HTMLButtonElement>) => {
    if (!isInputValid && buttonAction === "save") {
      setInputErrorMessage(
        t("forms.messages.length", { fieldName, length: 2 })
      );
      return;
    }

    if (buttonAction === "save") {
      if (isLocationCell && locationData) {
        handleOnUpdateLocation({
          locationData: { ...locationData, [fieldName]: value },
          updateLocation,
        });

        onSetEditedRowsHandler({
          meta,
          rowId,
        });

        return;
      }

      if (sensorData && isSensorCell) {
        const updatedSensorData = {
          ...sensorData,
          [fieldName === "sensor name" ? "sensorName" : "sensorNumber"]: value,
        };

        handleOnUpdateSensor({
          sensorData: updatedSensorData,
          updateSensor,
        });

        onSetEditedRowsHandler({
          meta,
          rowId,
        });
        return;
      }

      handleOnUpdateZone({
        id: zoneId,
        name: value,
        updateZone,
      });
    }

    onSetEditedRowsHandler({
      meta,
      rowId,
    });
  };

  if (meta?.editedRows[rowId]) {
    return (
      <>
        <InputWrapper className={clsx(asTextarea && "textarea")}>
          <KypsInput
            inputValue={value}
            name={name}
            placeholder=""
            onValueChange={setValue}
            type="text"
            errorMessage={inputErrorMessage}
            isErrorBottom
            asTextarea={asTextarea}
          />
        </InputWrapper>
        <ButtonWrapper>
          <KypsButton
            onClick={handleOnButton}
            placeholder={t("buttons.cancel.action")}
            name="cancel"
            variant="light"
          />
          <KypsButton
            onClick={handleOnButton}
            placeholder={t("buttons.save.action")}
            name="save"
          />
        </ButtonWrapper>
      </>
    );
  }

  return <EditableDataCellValue>{value}</EditableDataCellValue>;
};

export default EditableDataCell;
