import { BottomNavWrapper } from "./kyps-bottom-nav.styles";
import { BottomNavLinksList } from "./kyps-bottom-nav.styles";
import { NavLink } from "react-router-dom";
import { TFunction } from "i18next";
import { NavLinkItem } from "../kyps-nav/kyps-nav.styles";
import { PagesPathsEnum } from "../../utils/pages-paths";

interface IKypsBottomNav {
  t: TFunction<"translations", undefined>;
}

const KypsBottomNav = ({ t }: IKypsBottomNav) => (
  <BottomNavWrapper>
    <BottomNavLinksList>
      <NavLinkItem>
        <NavLink to={PagesPathsEnum.DASHBOARD}>
          {t("pages.dashboard.name")}
        </NavLink>
      </NavLinkItem>
      <NavLinkItem>
        <NavLink to={PagesPathsEnum.MANAGEMENT}>
          {t("pages.management.name")}
        </NavLink>
      </NavLinkItem>
      <NavLinkItem>
        <NavLink to={PagesPathsEnum.REPORTS}>{t("pages.reports.name")}</NavLink>
      </NavLinkItem>
    </BottomNavLinksList>
  </BottomNavWrapper>
);

export default KypsBottomNav;
