import styled from "@emotion/styled";

export const ReportPageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 12px;
`;
