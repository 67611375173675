import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const SearchLocationWrapper = styled.div`
  max-width: 160px;

  @media (min-width: 768px) {
    max-width: 253px;
  }
`;

export const ColoredCell = styled.div`
  ${({ theme }) => css`
    font-weight: 500;

    &.darken {
      color: ${theme.palette.text.secondary};
    }

    &.error {
      color: ${theme.palette.error.primary};
    }

    &.attention {
      color: ${theme.palette.info.default};
    }
  `}
`;
