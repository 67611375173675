import { useMemo, useState, Dispatch, SetStateAction, useRef } from "react";
import { IZone } from "../../../interfaces/zones";
import KypsSidebar from "../kyps-sidebar";
import IconButton from "../../icon-button/icon-button";
import PageContentContainer, {
  IPageContent,
} from "../../page-content/page-content-container";
import AttachUserTable from "../../kyps-tables/attach-user-table/attach-user-table";
import { IAttachTableData } from "../../../interfaces/tables";
import { useGetManagersByLocationIdQuery } from "../../../api/location-manager.api";
import { locationManagersTransformer } from "../../kyps-tables/attach-user-table/attach-user-table.helper";
import LocationDetailsTable from "../../kyps-tables/location-details-table/location-details-table";
import { ISelectOption } from "../../kyps-select/kyps-select";
import ZonesEditableTable from "../../kyps-tables/editable-zones-table/editable-zones-table";
import { TFunction } from "i18next";

interface ILocationDetailsSidebar {
  locationId: string;
  locationName: string;
  zones: IZone[];
  closeModal: () => void;
  selectZone: Dispatch<SetStateAction<ISelectOption>>;
  setManualSkipToken: Dispatch<SetStateAction<boolean>>;
  t: TFunction<"translations", undefined>;
}

enum LocationDetailsTabsEnum {
  INFORMATION = "General Information",
  ZONES = "Zones",
}

const LocationDetailsSidebar = ({
  locationId,
  locationName,
  closeModal,
  selectZone,
  setManualSkipToken,
  t,
}: ILocationDetailsSidebar) => {
  const [isActiveTab, setIsActiveTab] = useState(
    LocationDetailsTabsEnum.INFORMATION
  );
  const sidebarRef = useRef<HTMLDivElement>(null);

  const { data: locationManagersApi, isLoading } =
    useGetManagersByLocationIdQuery(locationId);

  const attachedUsers: IAttachTableData[] = useMemo(() => {
    if (locationManagersApi)
      return locationManagersTransformer(locationManagersApi);

    return [];
  }, [locationManagersApi]);

  const pageContent: IPageContent<LocationDetailsTabsEnum>[] = useMemo(
    () => [
      {
        tabName: t("tabs.generalInformation"),
        tabId: LocationDetailsTabsEnum.INFORMATION,
        content: (
          <>
            <LocationDetailsTable
              locationId={locationId}
              closeSidebar={closeModal}
              selectZone={selectZone}
              setParentManualSkipToken={setManualSkipToken}
              sidebarRef={sidebarRef}
              t={t}
            />
            <AttachUserTable
              key="AttachUserTable"
              tableData={attachedUsers}
              locationId={locationId}
              sidebarRef={sidebarRef}
              t={t}
              isLoading={isLoading}
              haveBackground
            />
          </>
        ),
      },
      {
        tabName: t("tabs.zones"),
        tabId: LocationDetailsTabsEnum.ZONES,
        content: (
          <ZonesEditableTable
            locationId={locationId}
            selectZone={selectZone}
            sidebarRef={sidebarRef}
            t={t}
            key="EditableTable"
          />
        ),
      },
    ],
    [
      attachedUsers,
      closeModal,
      isLoading,
      locationId,
      selectZone,
      setManualSkipToken,
      t,
    ]
  );

  const handleTabClick = (tabName: LocationDetailsTabsEnum) =>
    setIsActiveTab(tabName);

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: t("sidebarHeadings.locationDetailsWithKey", {
          key: locationName,
        }),
        buttons: [
          <IconButton
            name="close"
            onClick={closeModal}
            hasZeroMargin
            key="close"
          />,
        ],
      }}
      content={[
        <PageContentContainer
          pageContent={pageContent}
          activeTab={isActiveTab}
          tabHandler={handleTabClick}
          key="Page content container"
        />,
      ]}
      sidebarRef={sidebarRef}
      isHeaderReverse
    />
  );
};

export default LocationDetailsSidebar;
