import { useRoutes } from "react-router-dom";
import routes from "../../routes";
import { Container, Wrapper } from "../../styles/helper.styles";

const Holder = () => {
  const content = useRoutes(routes);

  return (
    <Wrapper>
      <Container>{content}</Container>
    </Wrapper>
  );
};

export default Holder;
