import { TFunction } from "i18next";
import { IRangeInputField, RangeInputVariantsEnum } from "./range-input";

export interface FormData {
  [key: string]: any;
}

interface ITranslateRangeVariant {
  t: TFunction<"translations", undefined>;
  variant?: RangeInputVariantsEnum;
}

const validChars = /[0-9.-]/;
const validSpecialKeys = /ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Tab/;

export const checkIsValidValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const key = e.key;
  const isValid = validChars.test(key) || validSpecialKeys.test(key);

  if (key === "Backspace" || key === "Delete") {
    return;
  }

  if (e.currentTarget.value.length > 3 && !validSpecialKeys.test(key)) {
    e.preventDefault();
    return;
  }

  if (!isValid) {
    e.preventDefault();
  }
};

export const translateRangeVariant = ({
  t,
  variant,
}: ITranslateRangeVariant) => {
  if (!variant) return null;

  switch (variant) {
    case RangeInputVariantsEnum.ATTENTION:
      return t("temperatureSettings.attention");

    case RangeInputVariantsEnum.NORMAL:
      return t("temperatureSettings.normal");

    default:
      return variant;
  }
};

export const fillRangeFormDefaultValues = (
  fields: IRangeInputField<string>[]
): FormData => {
  const defaultValues: FormData = {};

  fields.forEach((field) => {
    if (typeof field.name === "string")
      defaultValues[field.name] =
        field.value !== undefined ? field.value : '';
  });

  return defaultValues;
};
