import { useCallback } from "react";
import KypsSidebar from "../kyps-sidebar";
import { KypsButton } from "../../kyps-button/kyps-button";
import KypsForm from "../../kyps-form/kyps-form";
import { useMemo, useRef, useState, useEffect } from "react";
import { IAddIPointFormFields } from "../add-installation-point-sidebar/add-installation-point-sidebar";
import { IAddRangeTemperatureFields } from "../add-installation-point-sidebar/add-installation-point-sidebar.utils";
import { rangeTemperatureCheck } from "../add-installation-point-sidebar/add-installation-point-sidebar.helper";
import RangeForm, { ICustomErrorData } from "../../kyps-range-form/range-form";
import {
  createSelectOptionsFromZone,
  fillEditFields,
  fillEditIPointFields,
  handleOnUpdateIPoint,
} from "./edit-installation-point-sidebar.helper";
import { useUpdateIPointMutation } from "../../../api/installation-point.api";
import { isEmpty } from "lodash";
import SensorsTable from "../../kyps-tables/sensors-table/sensors-table";
import useModal from "../../../hooks/useModal";
import KypsModal from "../../kyps-modal/kyps-modal";
import AddSensorSidebar from "../add-sensor-sidebar/add-sensor-sidebar";
import { useGetIPointStatsByIdQuery } from "../../../api/dashboard.api";
import { useGetZoneByIdQuery } from "../../../api/zone.api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import Loaders from "../../loaders/loaders";
import { TFunction } from "i18next";
import { ImperativeHandleRef } from "../../kyps-range-form/range-form.utils";

interface IEditIPointSidebar {
  iPointId: string;
  zoneId: string;
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
}

const EditIPointSidebar = ({
  iPointId,
  zoneId,
  closeModal,
  t,
}: IEditIPointSidebar) => {
  const [formData, setFormData] = useState({
    nameForm: {},
    rangeForm: {},
  });
  const [customErrors, setCustomErrors] = useState<ICustomErrorData[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const {
    closeAndResetModal: closeAndResetAddModal,
    currentModal: currentAddModal,
    isModalOpen: isAddModalOpen,
    openModal: openAddModal,
  } = useModal();

  const [updateIPoint] = useUpdateIPointMutation();

  const { data: apiCurrentZoneData, isLoading: isCurrentZoneDataLoading } =
    useGetZoneByIdQuery(isEmpty(zoneId) ? skipToken : zoneId);
  const zoneData = useMemo(() => {
    if (apiCurrentZoneData)
      return createSelectOptionsFromZone(apiCurrentZoneData);

    return [];
  }, [apiCurrentZoneData]);

  const { data: apiSensorsData, isLoading: isSensorsDataLoading } =
    useGetIPointStatsByIdQuery(iPointId);
  const sensorsData = useMemo(() => {
    if (apiSensorsData) return apiSensorsData.sensors;

    return [];
  }, [apiSensorsData]);

  const currentIPoint = useMemo(() => {
    if (apiSensorsData)
      return { label: apiSensorsData.name, value: apiSensorsData.id };
    return { label: "", value: "" };
  }, [apiSensorsData]);

  const formRef = useRef<HTMLFormElement>(null);
  const rangeFormRef = useRef<ImperativeHandleRef>(null);

  const handleAddIPointFormSubmit = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
    rangeFormRef.current?.submit();
  };

  const onEditIPoint = useCallback(() => {
    if (isSubmitting) return;
    if (apiSensorsData?.id) {
      handleOnUpdateIPoint({
        iPointId: apiSensorsData.id,
        formData,
        setIsSubmitting,
        updateIPoint,
      });
    }
  }, [apiSensorsData?.id, formData, isSubmitting, updateIPoint]);

  useEffect(() => {
    if (!isEmpty(formData.nameForm) && !isEmpty(formData.rangeForm)) {
      closeModal();
      onEditIPoint();
      setFormData({ nameForm: {}, rangeForm: {} });
    }
  }, [closeModal, formData, onEditIPoint]);

  const editIPointFields = useMemo(() => {
    if (apiSensorsData) return fillEditIPointFields(apiSensorsData, t);

    return [];
  }, [apiSensorsData, t]);

  const editRangeFields = useMemo(() => {
    if (apiSensorsData)
      return fillEditFields({
        IPointRange: apiSensorsData.temperatureRange,
        t,
      });

    return [];
  }, [apiSensorsData, t]);

  const onSubmitAddCompany = (data: IAddIPointFormFields) => {
    if (data) {
      setFormData((prev) => ({ ...prev, nameForm: data }));
    }
  };

  const onSubmitRangeTemperature = useCallback(
    (data: IAddRangeTemperatureFields) => {
      if (data) {
        rangeTemperatureCheck({
          data,
          setCustomErrors,
          setFormData,
          t,
        });
      }
    },
    [setCustomErrors, setFormData, t]
  );

  const sidebarContent = useMemo(() => {
    if (isCurrentZoneDataLoading || isSensorsDataLoading)
      return [<Loaders key="Loader" variant="sidebar" />];

    return [
      <KypsForm
        fields={editIPointFields}
        onSubmit={onSubmitAddCompany}
        ref={formRef}
        key="EditCompanyForm"
      />,
      <RangeForm
        fields={editRangeFields}
        onSubmit={onSubmitRangeTemperature}
        ref={rangeFormRef}
        customErrorsData={customErrors}
        key="AddRangeTemperature"
      />,
      <SensorsTable
        sensorsData={sensorsData}
        currentZone={zoneData[0]}
        currentIPoint={currentIPoint}
        onAddSensor={() =>
          openAddModal(
            <AddSensorSidebar
              closeModal={closeAndResetAddModal}
              defaultZone={zoneData[0]}
              zonesOptions={zoneData}
              defaultIPoint={currentIPoint}
              isFixedValue
              t={t}
            />
          )
        }
        sidebarRef={sidebarRef}
        t={t}
        key="SensorsTable"
      />,
    ];
  }, [
    closeAndResetAddModal,
    currentIPoint,
    customErrors,
    editIPointFields,
    editRangeFields,
    isCurrentZoneDataLoading,
    isSensorsDataLoading,
    onSubmitRangeTemperature,
    openAddModal,
    sensorsData,
    t,
    zoneData,
  ]);

  return (
    <>
      <KypsSidebar
        sidebarHeader={{
          heading: t("buttons.edit.actionWithKey", {
            key: apiSensorsData?.name ?? t("entities.iPoint"),
          }),
          buttons: [
            <KypsButton
              key="Cancel"
              placeholder={t("buttons.cancel.action")}
              onClick={closeModal}
              variant="light"
            />,
            <KypsButton
              key="Edit Installation Point"
              placeholder={t("buttons.edit.actionWithKey", {
                key: t("entities.iPoint"),
              })}
              onClick={handleAddIPointFormSubmit}
            />,
          ],
        }}
        content={sidebarContent}
        sidebarRef={sidebarRef}
      />
      {isAddModalOpen && (
        <KypsModal
          isModalOpen={isAddModalOpen}
          modalContent={currentAddModal}
        />
      )}
    </>
  );
};

export default EditIPointSidebar;
