import { useState, useEffect, useRef, RefObject } from "react";
import { debounce } from "lodash";

const useScrollTop = (
  ref: RefObject<HTMLElement>,
  delay: number = 100
): number => {
  const [scrollTop, setScrollTop] = useState(0);

  const handleScrollRef = useRef(
    debounce(() => {
      if (ref.current) setScrollTop(ref.current.scrollTop);
    }, delay)
  );

  useEffect(() => {
    const element = ref.current;
    const handleScroll = handleScrollRef.current;

    if (element) element.addEventListener("scroll", handleScroll);

    return () => {
      if (element) element.removeEventListener("scroll", handleScroll);

      handleScroll.cancel();
    };
  }, [ref, delay]);

  return scrollTop;
};

export default useScrollTop;
