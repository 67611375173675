import { Dispatch, ReactNode, SetStateAction } from "react";
import { ISensorCardData } from "../../interfaces/dashboard";
import IconButton from "../icon-button/icon-button";
import {
  checkBatteryLevel,
  checkSignalLevel,
} from "../kyps-tables/kyps-table.helper";
import { getHumidityValue } from "./sensor-card.helper";
import {
  BatteryValue,
  CardActions,
  CardCurrentTemperature,
  CardHeader,
  CardIPointName,
  CardRange,
  CardRangeWrapper,
  SensorAddStats,
  SensorCardItem,
  SignalValue,
  TimeWrapper,
} from "./sensor-card.styles";
import { useDeleteSensorMutation } from "../../api/sensor.api";
import { handleOnDeleteSensor } from "../kyps-tables/sensors-table/sensors-table.helper";
import AuthWrapper from "../../auth/auth-wrapper";
import { managersRights } from "../../interfaces/auth-wrapper";
import { KypsChart } from "../kyps-chart/kyps-chart";
import { TFunction } from "i18next";
import SensorNameComponent from "../sensor-name-component/sensor-name-component";
import { IOpenDropdownMenu } from "../../hooks/useDropdownMenu";
import { checkElementVisibility } from "../../helpers/check-element-visibility";
import clsx from "clsx";
import { createSensorMenuItems } from "../sensors-grid/sensor-grid.helper";
import { IOpenDeleteConfirm } from "../../hooks/useDeleteConfirm";
import SensorDetailsSidebar from "../kyps-sidebar/sensor-details-sidebar/sensor-details-sidebar";
import { UserRoles, UserRolesEnum } from "../../interfaces/roles";
import { DropdownOpenerWrapper } from "../dropdown-menu/dropdown-menu.styles";

interface ISensorCard {
  sensorData: ISensorCardData;
  selectedId: string;
  minDate: Date | null;
  isMenuOpen: boolean;
  userRole: UserRoles;
  openModal: (modalContent: ReactNode) => void;
  setSelectedId: Dispatch<SetStateAction<string>>;
  closeAndResetModal: () => void;
  openDropdownMenu: (openDropdownMenuProps: IOpenDropdownMenu) => void;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  t: TFunction<"translations", undefined>;
}

const SensorCard = ({
  sensorData,
  selectedId,
  isMenuOpen,
  minDate,
  userRole,
  openModal,
  setSelectedId,
  closeAndResetModal,
  openDropdownMenu,
  openDeleteConfirm,
  t,
}: ISensorCard) => {
  const [deleteSensor] = useDeleteSensorMutation();

  const {
    battery,
    currentState,
    currentTemperature,
    highAttention,
    highNormal,
    iPoint,
    lowAttention,
    lowNormal,
    sensorId,
    sensorName,
    signal,
    time,
    currentHumidity,
    custom,
    showHumidity,
    sensorNumber,
    zone,
  } = sensorData;

  const onDeleteSensor = () =>
    handleOnDeleteSensor({
      deleteSensor,
      id: sensorId,
      name: sensorNumber,
    });

  const onSensorName = () =>
    openModal(
      <SensorDetailsSidebar
        closeModal={closeAndResetModal}
        defaultZone={zone ?? { label: "", value: "" }}
        sensorId={sensorId}
        sensorNumber={sensorNumber}
        defaultIPoint={iPoint}
        showHumidity={!!showHumidity}
        minDate={minDate}
        isDisabled={userRole === UserRolesEnum.USER}
      />
    );

  return (
    <SensorCardItem className={currentState.toLowerCase()}>
      <CardHeader>
        <div>
          <CardIPointName>{iPoint.label}</CardIPointName>
          <SensorNameComponent
            name={sensorNumber}
            isCustom={custom}
            onClick={onSensorName}
            subName={sensorName}
            isActive
          />
        </div>
        <CardActions>
          <IconButton
            name="chart"
            onClick={() =>
              openModal(
                <KypsChart
                  closeModal={closeAndResetModal}
                  range={[
                    +lowAttention,
                    +lowNormal,
                    +highNormal,
                    +highAttention,
                  ]}
                  sensorNumber={sensorNumber}
                  minDate={minDate}
                  iPointName={iPoint.label}
                  t={t}
                />
              )
            }
          />
          <AuthWrapper
            allowedRoles={managersRights}
            children={
              <DropdownOpenerWrapper
                className={clsx(
                  isMenuOpen && selectedId === sensorId && "open",
                  "light"
                )}
              >
                <IconButton
                  name="vDots"
                  hoverColor="none"
                  hasZeroMargin
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedId(sensorId);

                    const openerParams =
                      e.currentTarget.getBoundingClientRect();
                    const menuItems = createSensorMenuItems({
                      closeAndResetModal,
                      defaultIPoint: iPoint,
                      isDisabled: false,
                      minDate,
                      openModal,
                      sensorId,
                      sensorNumber,
                      showHumidity: !!showHumidity,
                      t,
                      zone: zone,
                      onDeleteButton: () =>
                        openDeleteConfirm({
                          deleteConfirmName: sensorName ?? sensorNumber,
                          deleteFunction: onDeleteSensor,
                          openerParamsProp: openerParams,
                        }),
                    });

                    openDropdownMenu({
                      isDropdownMenuOpen: true,
                      position: checkElementVisibility(
                        openerParams,
                        menuItems.length
                      ),
                      menuItems,
                    });
                  }}
                />
              </DropdownOpenerWrapper>
            }
          />
        </CardActions>
      </CardHeader>
      <div>
        <CardRangeWrapper>
          <CardRange className="out">{lowAttention}</CardRange>
          <CardRange className="in">{lowNormal}</CardRange>
          <CardCurrentTemperature className={currentState.toLowerCase()}>
            {currentTemperature ? `${currentTemperature} °C` : "-"}
          </CardCurrentTemperature>
          <CardRange className="in">{highNormal}</CardRange>
          <CardRange className="out">{highAttention}</CardRange>
        </CardRangeWrapper>
        <SensorAddStats>
          <BatteryValue>{checkBatteryLevel(battery)}</BatteryValue>
          {getHumidityValue(currentHumidity, !!showHumidity)}
          <SignalValue>{checkSignalLevel(signal)}</SignalValue>
        </SensorAddStats>
        <TimeWrapper>
          <IconButton name="time" asIcon />
          {time}
        </TimeWrapper>
      </div>
    </SensorCardItem>
  );
};

export default SensorCard;
