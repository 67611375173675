import { TFunction } from "i18next";
import { useLazySendManualLocationReportQuery } from "../../../api/reports.api";
import { IHourlyPayload, IReportByDate } from "../../../interfaces/reports";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { sortingFunction } from "../kyps-table.helper";
import { toast } from "react-toastify";

type UseLazySendManualLocationReportQueryType = ReturnType<
  typeof useLazySendManualLocationReportQuery
>;
export type SendLocationReportTriggerType =
  UseLazySendManualLocationReportQueryType[0];

interface IHandleSendLocationReport {
  date: string;
  locationId: string;
  locationName: string;
  trigger: SendLocationReportTriggerType;
  t: TFunction<"translations", undefined>;
}

export interface IReportSensorData {
  battery: string;
  id: string;
  range: string;
  sensorId: string;
  sensorTemperatureData: IHourlyPayload[];
  iPoint: ISelectOption;
  zone: ISelectOption;
  installationPointDeleted: boolean;
  zoneDeleted: boolean;
  showHumidity: boolean;
  sensorName: string | null;
  subRows: IReportSensorData[];
}
export const transformReportData = (data: IReportByDate) => {
  const dataCopy: IReportByDate = JSON.parse(JSON.stringify(data));

  const sortedArray = dataCopy.sensorsData.sort(
    ({ installationPointName: a }, { installationPointName: b }) =>
      sortingFunction(a, b)
  );

  return sortedArray.map((sensorItem) => {
    const {
      temperatureRange: { lowGreen, highGreen },
      installationPointName,
      temperatureHourlyPayload,
      voltage,
      sensorNumber,
      humidityHourlyPayload,
      showHumidity,
      installationPointDeleted,
      installationPointId,
      zoneDeleted,
      zoneId,
      zoneName,
      sensorName,
    } = sensorItem;

    const formatHour = (index: number) =>
      index < 10 ? `0${index}:00` : `${index}:00`;

    const mapHourlyPayload = (sensorItem: IHourlyPayload, index: number) => ({
      [formatHour(index)]: sensorItem,
    });

    const newTemperatureArray = temperatureHourlyPayload.map(mapHourlyPayload);
    const mergedTemperatureData = Object.assign({}, ...newTemperatureArray);

    const newHumidityArray = humidityHourlyPayload?.map(mapHourlyPayload);

    const mergeHumidityData = () =>
      newHumidityArray ? Object.assign({}, ...newHumidityArray) : {};

    const baseObject = {
      battery: voltage,
      id: sensorNumber ?? "null",
      range: `${lowGreen.toFixed(1)}; ${highGreen.toFixed(1)}`,
      sensorName,
      sensorId: installationPointName,
      iPoint: { label: installationPointName, value: installationPointId },
      zone: { label: zoneName, value: zoneId },
      installationPointDeleted: installationPointDeleted,
      zoneDeleted: zoneDeleted,
      showHumidity: showHumidity,
    };

    if (showHumidity)
      return {
        ...baseObject,
        ...mergedTemperatureData,
        subRows: [
          {
            ...baseObject,
            range: "💧",
            ...mergeHumidityData(),
          },
        ],
      };

    return {
      ...baseObject,
      ...mergedTemperatureData,
    };
  });
};

export const handleSendLocationReport = ({
  date,
  locationId,
  locationName,
  trigger,
  t,
}: IHandleSendLocationReport) =>
  toast.promise(trigger({ locationId, date }), {
    pending: t("notification.loading"),
    success: {
      render() {
        return t("notification.manualReportSending.message", {
          date,
          entityName: `${t(
            "notification.manualReportSending.entities.location"
          )} "${locationName}"`,
        });
      },
    },
    error: {
      render() {
        return "Error by sending report";
      },
    },
  });
