import { IconDiv } from "../kyps-tables/tree-table/tree-table.styles";
import { capitalize } from "lodash";
import clsx from "clsx";
import { IconNames } from "../../utils/icon-names";
import CompanySvg from "../../assets/company.svg";
import RestaurantSvg from "../../assets/restaurant.svg";
import FlagSvg from "../../assets/flag.svg";
import FridgeSvg from "../../assets/fridge.svg";
import TemperatureSvg from "../../assets/temperature.svg";
import CautionBatterySvg from "../../assets/battery-caution.svg";
import LowBatterySvg from "../../assets/battery-low.svg";
import GoodBatterySvg from "../../assets/battery-good.svg";
import FullBatterySvg from "../../assets/battery-full.svg";
import TelegramSvg from "../../assets/telegram.svg";
import EmailSvg from "../../assets/email.svg";
import UkraineFlagSvg from "../../assets/ukraine-flag.svg";
import GBFlagSvg from "../../assets/gb-flag.svg";
import PhoneSvg from "../../assets/phone.svg";
import DashboardSvg from "../../assets/dashboard.svg";
import ManagementSvg from "../../assets/management.svg";
import ReportsSvg from "../../assets/report.svg";

export interface IIcon {
  iconName: IconNames;
  isSmall?: boolean;
  hasTitle?: boolean;
  hasZeroMargin?: boolean;
  isLight?: boolean;
}

const icons: Record<IIcon["iconName"], string> = {
  company: CompanySvg,
  location: RestaurantSvg,
  zone: FlagSvg,
  iPoint: FridgeSvg,
  sensor: TemperatureSvg,
  cautionBattery: CautionBatterySvg,
  lowBattery: LowBatterySvg,
  goodBattery: GoodBatterySvg,
  fullBattery: FullBatterySvg,
  telegram: TelegramSvg,
  email: EmailSvg,
  gbFlag: GBFlagSvg,
  uaFlag: UkraineFlagSvg,
  phone: PhoneSvg,
  dashboard: DashboardSvg,
  management: ManagementSvg,
  reports: ReportsSvg,
};

const Icon = ({
  iconName,
  isSmall,
  hasTitle,
  hasZeroMargin,
  isLight,
}: IIcon) => (
  <IconDiv
    className={clsx(
      isSmall && "small",
      hasZeroMargin && "zero-margin",
      isLight && "light"
    )}
    style={{ backgroundImage: `url(${icons[iconName]})` }}
    title={hasTitle ? capitalize(iconName) : ""}
  />
);

export default Icon;
