import { useRef, useMemo, useState, useEffect } from "react";
import KypsSidebar from "../kyps-sidebar";
import KypsForm from "../../kyps-form/kyps-form";
import {
  ILocationFormData,
  createAddLocationFields,
} from "../add-location-sidebar/add-location-sidebar.utils";
import {
  useGetLocationByIdQuery,
  useUpdateLocationByIdMutation,
} from "../../../api/location.api";
import AttachUserTable from "../../kyps-tables/attach-user-table/attach-user-table";
import GatewayCreator from "../../gateway-creator/gateway-creator";
import GatewayTable from "../../gateway-creator/gateway-table";
import useGateway from "../../../hooks/useGateway";
import { useGetManagersByLocationIdQuery } from "../../../api/location-manager.api";
import { locationManagersTransformer } from "../../kyps-tables/attach-user-table/attach-user-table.helper";
import { IAttachTableData } from "../../../interfaces/tables";
import { sidebarHeaderButtons } from "./edit-location-sidebar.utils";
import { handleOnEditLocation } from "./edit-location.sidebar.helper";
import Loaders from "../../loaders/loaders";
import { useTranslation } from "react-i18next";

interface IEditLocationSidebar {
  locationId: string;
  closeModal: () => void;
}

const EditLocationSidebar = ({
  locationId,
  closeModal,
}: IEditLocationSidebar): JSX.Element => {
  const [attachedUsers, setAttachedUsers] = useState<IAttachTableData[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const [updateLocation] = useUpdateLocationByIdMutation();
  const { data: locationDataApi, isLoading } =
    useGetLocationByIdQuery(locationId);
  const { data: locationManagersApi, isLoading: isAttachTableLoading } =
    useGetManagersByLocationIdQuery(locationId);

  useEffect(() => {
    if (locationManagersApi) {
      const managersArray = locationManagersTransformer(locationManagersApi);
      setAttachedUsers(managersArray);
    }
  }, [locationManagersApi]);

  const {
    addGatewayNumber,
    gatewayNumbers,
    removeGatewayNumber,
    setGatewayNumbers,
  } = useGateway();

  const editFields = useMemo(() => {
    if (locationDataApi) {
      setGatewayNumbers(locationDataApi.gatewayNumbers);

      return createAddLocationFields(t).map((field) => {
        const value =
          locationDataApi[field.name as keyof ILocationFormData] ?? "";

        return { ...field, value };
      });
    }

    return [];
  }, [locationDataApi, setGatewayNumbers, t]);

  const handleEditLocationFormSubmit = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmitEditLocation = ({
    address,
    comments,
    name,
  }: ILocationFormData) => {
    closeModal();

    handleOnEditLocation({
      id: locationId,
      data: { name, address, comments, gatewayNumbers },
      editLocation: updateLocation,
    });
  };

  const sidebarContent = () => {
    if (isLoading) return [<Loaders variant="table" key="Loader" />];

    return [
      <KypsForm
        fields={editFields}
        onSubmit={onSubmitEditLocation}
        ref={formRef}
        key="EditLocationForm"
      />,
      <GatewayCreator
        creatorName={t("buttons.add.actionWithKey", {
          key: t("entities.gatewayNumber"),
        })}
        buttonPlaceholder={t("buttons.add.action")}
        onAddClick={addGatewayNumber}
        gatewayNumbers={gatewayNumbers}
        inputPlaceholder={`${t("forms.fieldNames.gatewayNumber")}...`}
        key="GatewayNumbers"
      />,
      <GatewayTable
        gatewayNumbers={gatewayNumbers}
        onDelete={removeGatewayNumber}
        key="GatewayTable"
      />,
      <AttachUserTable
        key="AttachUserTable"
        tableData={attachedUsers}
        locationId={locationId}
        isLoading={isAttachTableLoading}
        sidebarRef={sidebarRef}
        t={t}
      />,
    ];
  };

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: t("buttons.edit.actionWithKey", {
          key: locationDataApi?.name ?? t("entities.location"),
        }),
        buttons: sidebarHeaderButtons({
          closeModal,
          handleEditLocationFormSubmit,
          t,
        }),
      }}
      content={sidebarContent()}
      sidebarRef={sidebarRef}
    />
  );
};

export default EditLocationSidebar;
