import { ISelectOption } from "../components/kyps-select/kyps-select";

export enum UserRolesEnum {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  USER = "USER",
}

export type UserRoles =
  | UserRolesEnum.SUPER_ADMIN
  | UserRolesEnum.ADMIN
  | UserRolesEnum.MANAGER
  | UserRolesEnum.USER;

export type LocationUserRoles = UserRolesEnum.MANAGER | UserRolesEnum.USER;

export const locationRoles: ISelectOption[] = [
  {
    label: UserRolesEnum.MANAGER,
    value: UserRolesEnum.MANAGER,
  },
  {
    label: UserRolesEnum.USER,
    value: UserRolesEnum.USER,
  },
];
