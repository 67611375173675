import { AddCompanyFunction } from "../../../interfaces/add-functions";
import { IAddCompanyFormFields } from "../../../interfaces/forms";
import { createNotification } from "../../toaster/toaster.helper";

interface IHandleOnSubmitAddCompany {
  data: IAddCompanyFormFields;
  closeModal: () => void;
  addCompany: AddCompanyFunction;
}

export const handleOnSubmitAddCompany = ({
  data,
  addCompany,
  closeModal,
}: IHandleOnSubmitAddCompany) => {
  closeModal();

  addCompany(data)
    .unwrap()
    .then(({ fullName }) =>
      createNotification({
        message: `Company ${fullName} was successfully created!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};
