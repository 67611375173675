import { useState } from "react";
import { ISelectOption } from "../components/kyps-select/kyps-select";

const useLocalStorage = (
  key: string,
  initialValue: ISelectOption | null
): [ISelectOption, (data: ISelectOption) => void] => {
  const [data, setData] = useState<ISelectOption>(() => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : initialValue;
  });

  const setLocalStorageData = (value: ISelectOption) => {
    localStorage.setItem(key, JSON.stringify(value));
    setData(value);
  };

  return [data, setLocalStorageData];
};

export default useLocalStorage;
