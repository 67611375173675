import {
  ExpandedState,
  PaginationState,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { KypsTable } from "../kyps-table";
import { createSensorsStatsTableColumns } from "./sensors-stats-table.utils";
import Loaders from "../../loaders/loaders";
import EmptyPage from "../../kyps-empty/kyps-empty-page";
import { isEmpty } from "lodash";
import useModal from "../../../hooks/useModal";
import KypsModal from "../../kyps-modal/kyps-modal";
import { ISensorsStatsData } from "../../../interfaces/tables";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { UserRolesEnum } from "../../../interfaces/roles";

interface ISensorsStatsTable {
  data: ISensorsStatsData[];
  isLoading: boolean;
  minDate?: Date | null;
}

const SensorsStatsTable = ({
  data,
  isLoading,
  minDate,
}: ISensorsStatsTable) => {
  const userRole = useAppSelector(({ user: { user } }) => user?.role);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });
  const [sorting] = useState<SortingState>([{ id: "name", desc: false }]);
  const [expanded, setExpanded] = useState<ExpandedState>(true);

  const { t } = useTranslation();

  const { openModal, currentModal, isModalOpen, closeAndResetModal } =
    useModal();

  const table = useReactTable({
    data,
    columns: createSensorsStatsTableColumns({
      openModal,
      closeModal: closeAndResetModal,
      minDate,
      t,
      userRole: userRole ?? UserRolesEnum.USER,
    }),
    state: {
      expanded,
      sorting,
      pagination: {
        pageIndex,
        pageSize,
      },
      columnPinning: { left: ["name"] },
    },
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRow,
    autoResetPageIndex: false,
    manualSorting: true,
  });

  return (
    <>
      <KypsTable
        table={table}
        loader={<Loaders variant="sidebar" />}
        isLoading={isLoading}
        isTransparentBg
        hasSmallRows
        hideSorting
        hasNoActions
        className="stats"
        emptyMessage={
          isEmpty(data) && !isLoading ? (
            <EmptyPage
              iconName="clipboard"
              heading={t("emptyMessage.text", {
                entity: t("emptyMessage.entities.sensor"),
              })}
              isSmall
            />
          ) : null
        }
      />
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
    </>
  );
};

export default SensorsStatsTable;
