import {
  IPaginatedResponse,
  IPaginationQuery,
  IPaginationQueryWithValue,
} from "../interfaces/companies";
import {
  IAddManager,
  IAddManagerResponse,
  IEditManager,
  ISortByKeysManager,
  IManagerDTO,
} from "../interfaces/managers";
import { getSortParams } from "./api-helpers";
import { kypsApi } from "./kyps.api";

export const managerControllerEndpoints = kypsApi.injectEndpoints({
  endpoints: (builder) => ({
    getManagers: builder.query<
      IPaginatedResponse<IManagerDTO>,
      IPaginationQuery<ISortByKeysManager>
    >({
      query: ({
        page,
        size,
        sort = [{ direction: "ASC", property: "name" }],
      }) => ({
        url: `managers?page=${page}&size=${size}&sort=${getSortParams(sort)}`,
        method: "GET",
      }),
      providesTags: ["Managers"],
    }),

    addManager: builder.mutation<IAddManagerResponse, IAddManager>({
      query: (data) => ({
        url: `/managers`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Managers"],
    }),

    getManagerById: builder.query<IAddManagerResponse, string>({
      query: (managerId) => ({
        url: `/managers/${managerId}`,
        method: "GET",
      }),
      providesTags: ["Managers"],
    }),

    updateManagerById: builder.mutation<IAddManagerResponse, IEditManager>({
      query: ({ data, id }) => ({
        url: `/managers/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Managers"],
    }),

    deleteManagerById: builder.mutation<void, string>({
      query: (id) => ({
        url: `/managers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Managers"],
    }),

    searchManagerByExactPhone: builder.query<
      IPaginatedResponse<IManagerDTO>,
      string
    >({
      query: (phone) => ({
        url: `managers/search/phone?q=${phone}`,
        method: "GET",
      }),
    }),

    searchManagerByExactEmail: builder.query<
      IPaginatedResponse<IManagerDTO>,
      string
    >({
      query: (email) => ({
        url: `managers/search/email?${email}`,
        method: "GET",
      }),
    }),

    searchManagerAutocomplete: builder.query<
      IPaginatedResponse<IManagerDTO>,
      IPaginationQueryWithValue
    >({
      query: ({
        value,
        size,
        page = 0,
        sort = [{ direction: "DESC", property: "updatedAt" }],
      }) => ({
        url: `managers/search/autocomplete?q=${value}&page=${page}&size=${size}&sort=${getSortParams(
          sort
        )}`,
        method: "GET",
      }),
    }),

    getAuthorizedUserData: builder.query<IManagerDTO, void | null>({
      query: () => ({
        url: "/managers/self",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetManagersQuery,
  useDeleteManagerByIdMutation,
  useGetManagerByIdQuery,
  useAddManagerMutation,
  useUpdateManagerByIdMutation,
  useLazySearchManagerByExactPhoneQuery,
  useLazySearchManagerByExactEmailQuery,
  useSearchManagerAutocompleteQuery,
  useGetAuthorizedUserDataQuery,
} = managerControllerEndpoints;
