import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const SensorCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 12px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  }
`;

export const SensorCardItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${theme.palette.background.default};
    border: 1px solid ${theme.palette.background.light};
    border-radius: 8px;
    padding: 6px;

    &.off {
      background-color: ${theme.palette.background.light}60;
    }

    &.green {
      background-color: ${theme.palette.success.light}60;
    }

    &.red {
      background-color: ${theme.palette.error.light}60;
    }

    &.orange {
      background-color: ${theme.palette.info.light}60;
    }

    @media (min-width: 768px) {
      padding: 12px;
    }
  `}
`;

export const CardRangeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  gap: 6px;

  @media (min-width: 768px) {
    gap: 16px;
  }
`;

export const CardCurrentTemperature = styled.div`
  ${({ theme }) => css`
    font-size: 28px;
    color: ${theme.palette.text.default};
    line-height: 140%;
    text-align: center;
    white-space: nowrap;
    letter-spacing: -2px;

    &.green {
      color: ${theme.palette.success.dark};
    }

    &.orange {
      color: ${theme.palette.info.secondary};
    }

    &.red {
      color: ${theme.palette.error.default};
    }

    @media (min-width: 768px) {
      font-size: 36px;
    }
  `}
`;

export const CardRange = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    max-width: 32px;
    width: 100%;
    border-radius: 4px;
    border: none;

    &.in {
      background-color: ${theme.palette.success.light};
      color: ${theme.palette.success.dark};
    }

    &.out {
      background-color: ${theme.palette.info.light};
      color: ${theme.palette.info.dark};
    }

    @media (min-width: 768px) {
      max-width: 36px;
    }
  `}
`;

export const CardIPointName = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.text.secondary};
    margin-bottom: 4px;
  `}
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
`;

export const CardActions = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin: 0;
  }
`;

export const TimeWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

export const SensorAddStats = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SignalValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BatteryValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
