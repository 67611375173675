import { useState, useMemo, useRef } from "react";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetSensorByNumberQuery,
  useGetSensorByIdQuery,
} from "../../../api/sensor.api";
import { isEmpty } from "lodash";
import KypsSidebar from "../kyps-sidebar";
import IconButton from "../../icon-button/icon-button";
import PageContentContainer, {
  IPageContent,
} from "../../page-content/page-content-container";
import EditableSensorTable from "../../kyps-tables/editable-sensor-table/editable-sensor-table";
import SensorHistoryTable from "../../kyps-tables/sensor-history-table/sensor-history-table";
import SensorRangeDetails from "../../sensor-range-details/sensor-range-details";
import { getFormattedDate } from "../../../helpers/check-date.helper";
import Loaders from "../../loaders/loaders";
import EmptyPage from "../../kyps-empty/kyps-empty-page";
import { KypsButton } from "../../kyps-button/kyps-button";
import KypsModal from "../../kyps-modal/kyps-modal";
import useModal from "../../../hooks/useModal";
import { useTranslation } from "react-i18next";

interface ISensorDetailsSidebar {
  sensorNumber: string;
  showHumidity: boolean;
  defaultZone: ISelectOption;
  defaultIPoint: ISelectOption;
  isDisabled: boolean;
  closeModal: () => void;
  minDate?: Date | null;
  sensorId?: string;
}

enum SensorDetailsTabsEnum {
  INFORMATION = "General Information",
  HISTORY = "History",
}

const SensorDetailsSidebar = ({
  sensorId,
  sensorNumber,
  showHumidity,
  defaultZone,
  defaultIPoint,
  minDate,
  isDisabled,
  closeModal,
}: ISensorDetailsSidebar) => {
  const sidebarRef = useRef<HTMLDivElement>(null);

  const {
    data: apiSensorDataByNumber,
    isLoading: isLoadingByNumber,
    error,
  } = useGetSensorByNumberQuery(
    isEmpty(sensorNumber) || sensorId ? skipToken : sensorNumber
  );

  const { t } = useTranslation();

  const verifiedSensorId = useMemo(() => {
    if (sensorId) return sensorId;

    if (apiSensorDataByNumber) return apiSensorDataByNumber.id;

    return false;
  }, [apiSensorDataByNumber, sensorId]);

  const { data: apiSensorData, isLoading: isLoadingById } =
    useGetSensorByIdQuery(verifiedSensorId || skipToken);

  const { closeAndResetModal, currentModal, isModalOpen, openModal } =
    useModal();

  const generalInformationContent = useMemo(() => {
    if (isLoadingById || isLoadingByNumber)
      return <Loaders variant="editable" />;

    if (error)
      return (
        <EmptyPage
          heading={t("emptyMessage.deletedMessage", {
            entity: t("entities.sensor"),
            sensorNumber,
          })}
          iconName="error"
          actionButton={
            <KypsButton
              onClick={() => closeModal()}
              placeholder={t("buttons.back.action")}
            />
          }
        />
      );

    if (apiSensorData)
      return (
        <>
          <EditableSensorTable
            sensorData={apiSensorData}
            defaultIPoint={defaultIPoint}
            defaultZone={defaultZone}
            closeModal={closeModal}
            isDisabled={isDisabled}
            sidebarRef={sidebarRef}
          />
          <SensorRangeDetails
            iPointId={defaultIPoint.value}
            sensorId={apiSensorData?.id}
            sensorNumber={apiSensorData?.sensorNumber ?? ""}
            sensorName={apiSensorData.sensorName}
            customRange={apiSensorData?.temperatureRange}
            isDisabled={isDisabled}
            key="SensorRangeDetails"
          />
        </>
      );

    return <></>;
  }, [
    apiSensorData,
    closeModal,
    defaultIPoint,
    defaultZone,
    error,
    isDisabled,
    isLoadingById,
    isLoadingByNumber,
    sensorNumber,
    t,
  ]);

  const range = useMemo(() => {
    if (apiSensorData) {
      const { lowYellow, lowGreen, highGreen, highYellow } =
        apiSensorData.temperatureRange;
      return [lowYellow, lowGreen, highGreen, highYellow];
    }
    return [];
  }, [apiSensorData]);

  const pageContent: IPageContent<SensorDetailsTabsEnum>[] = useMemo(
    () => [
      {
        tabName: t("tabs.generalInformation"),
        tabId: SensorDetailsTabsEnum.INFORMATION,
        content: generalInformationContent,
      },
      {
        tabName: t("tabs.history"),
        tabId: SensorDetailsTabsEnum.HISTORY,
        content: (
          <SensorHistoryTable
            date={getFormattedDate()}
            sensorNumber={sensorNumber}
            showHumidity={showHumidity}
            iPointName={defaultIPoint.label}
            closeAndResetModal={closeAndResetModal}
            minDate={minDate}
            openModal={openModal}
            range={range}
            t={t}
          />
        ),
      },
    ],
    [
      closeAndResetModal,
      defaultIPoint.label,
      generalInformationContent,
      minDate,
      openModal,
      range,
      sensorNumber,
      showHumidity,
      t,
    ]
  );

  const [isActiveTab, setIsActiveTab] = useState(
    SensorDetailsTabsEnum.INFORMATION
  );
  const handleTabClick = (tabName: SensorDetailsTabsEnum) =>
    setIsActiveTab(tabName);

  return (
    <>
      <KypsSidebar
        sidebarHeader={{
          heading: t("sidebarHeadings.sensorDetailsWithKey", {
            value: sensorNumber,
          }),
          buttons: [
            <IconButton name="close" onClick={closeModal} key="close" />,
          ],
        }}
        content={[
          <PageContentContainer
            activeTab={isActiveTab}
            pageContent={pageContent}
            tabHandler={handleTabClick}
            key="PageContentContainer"
          />,
        ]}
        isHeaderReverse
        sidebarRef={sidebarRef}
      />
      {isModalOpen && (
        <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
      )}
    </>
  );
};

export default SensorDetailsSidebar;
