import { Link } from "react-router-dom";
import {
  AdminPanel,
  Header,
  HeaderContainer,
  Logo,
} from "./kyps-header.styles";
import CompanyDropdown from "../company-dropdown/company-dropdown";
import keycloak from "../../keycloak";
import KypsModal from "../kyps-modal/kyps-modal";
import useModal from "../../hooks/useModal";
import { ReactNode, useState } from "react";
import UserIcon from "../user-icon/user-icon";
import UserDropdown from "../user-dropdown/user-dropdown";
import { useAppSelector } from "../../hooks/useAppSelector";
import LanguageSwitcher from "../language-switcher/language-switcher";
import { TFunction } from "i18next";
import MobileMenuModal from "../../containers/mobile-menu-modal/mobile-menu-modal";
import AppMobileMenu from "../app-mobile-menu/app-mobile-menu";

interface IKypsHeaderProps {
  logo: string;
  nav: ReactNode;
  t: TFunction<"translations", undefined>;
}

export const KypsHeader = ({ logo, nav, t }: IKypsHeaderProps) => {
  const user = useAppSelector(({ user }) => user.user);

  const { closeAndResetModal, currentModal, isModalOpen, openModal } =
    useModal();

  const logout = () =>
    keycloak
      .logout({ redirectUri: "https://kyps.link/dashboard" })
      .then(() => keycloak.clearToken())
      .catch((err) => console.log(err));

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOnMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <Header>
        <HeaderContainer>
          <Link to="/">
            <Logo
              src={logo}
              alt="KYPS Logo"
              aria-label="KYPS Logo"
              width={106}
              height={32}
            />
          </Link>
          {nav}
          <AdminPanel>
            <LanguageSwitcher />
            <CompanyDropdown
              openModal={openModal}
              closeModal={closeAndResetModal}
              t={t}
            />
            <UserDropdown
              dropdownOpener={<UserIcon userName={user?.name ?? ""} />}
              logout={logout}
              email={user?.email ?? "Not found in Database"}
              userName={user?.name ?? ""}
            />
          </AdminPanel>
          <MobileMenuModal
            handleCloseMenu={handleCloseMenu}
            handleOnMenuOpen={handleOnMenuOpen}
            isMenuOpen={isMenuOpen}
            mobileMenuContent={
              <AppMobileMenu
                user={user}
                logout={logout}
                t={t}
                companyDropdown={
                  <CompanyDropdown
                    openModal={openModal}
                    closeModal={closeAndResetModal}
                    onSelectHandler={handleCloseMenu}
                    t={t}
                  />
                }
              />
            }
            t={t}
          />
        </HeaderContainer>
      </Header>
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
    </>
  );
};
