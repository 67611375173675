import { IEditManager } from "../../../interfaces/managers";
import { UpdateManagerFunction } from "../../../interfaces/update-functions";
import { createNotification } from "../../toaster/toaster.helper";

interface IOnSubmitEditManager {
  managerData: IEditManager;
  closeModal: () => void;
  updateManager: UpdateManagerFunction;
}

export const onSubmitEditManager = ({
  managerData,
  closeModal,
  updateManager,
}: IOnSubmitEditManager) => {
  closeModal();

  updateManager(managerData)
    .unwrap()
    .then(({ name }) =>
      createNotification({
        message: `${name} was successfully edited!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};
