import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  button {
    &:not(:last-of-type) {
      margin-right: 6px;
    }
  }
`;

export const EditableDataCellValue = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.primary};
  `}
`;

export const InputWrapper = styled.div`
  min-width: 142px;
  flex: 1;

  label {
    display: flex;
  }

  &.textarea {
    margin: 6px 0;

    label {
      max-height: none;
    }

    textarea {
      height: 68px;
    }
  }
`;
