import { MutableRefObject, useEffect, useState } from "react";
import { SelectedDOMRect } from "./useDeleteConfirm";
import { IAttachTableData } from "../interfaces/tables";

export interface IOpenRoleListProps {
  openerParamsProp: DOMRect;
  userData: IAttachTableData;
}

interface IUseLocationRoleSelect {
  isRoleListOpen: boolean;
  openerParams: SelectedDOMRect;
  userData: IAttachTableData | null;
  closeRoleList: () => void;
  openRoleList: (openRoleListProps: IOpenRoleListProps) => void;
}

const useLocationRoleSelect = (
  scrollContainerRef: MutableRefObject<HTMLDivElement | null>,
  extraModalSpace = 0,
  extraScrollHeight = 0
): IUseLocationRoleSelect => {
  const [isRoleListOpen, setIsRoleListOpen] = useState(false);
  const [openerParams, setOpenerParams] = useState<SelectedDOMRect>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  const [userData, setUserData] = useState<IAttachTableData | null>(null);

  const openRoleList = ({
    openerParamsProp,
    userData: propsUserData,
  }: IOpenRoleListProps) => {
    const { height, width, x, y } = openerParamsProp;
    const calculatedY =
      extraScrollHeight > 0 ? extraScrollHeight + y : y + window.scrollY;

    setIsRoleListOpen(true);
    setUserData(propsUserData);
    setOpenerParams({
      height,
      width,
      x: x - extraModalSpace,
      y: calculatedY,
    });
  };

  const closeRoleList = () => {
    setIsRoleListOpen(false);
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (scrollContainer && isRoleListOpen) {
        closeRoleList();
      }
    };

    if (scrollContainer) {
      window.addEventListener("resize", handleScroll);
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      }
    };
  }, [scrollContainerRef, isRoleListOpen]);

  return {
    isRoleListOpen,
    openerParams,
    userData,
    closeRoleList,
    openRoleList,
  };
};

export default useLocationRoleSelect;
