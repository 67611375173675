import { MouseEvent, ReactNode } from "react";
import SensorDetailsSidebar from "../kyps-sidebar/sensor-details-sidebar/sensor-details-sidebar";
import { ISelectOption } from "../kyps-select/kyps-select";
import { TFunction } from "i18next";

interface ICreateSensorMenuItems {
  sensorId: string;
  sensorNumber: string;
  defaultIPoint: ISelectOption;
  showHumidity: boolean;
  isDisabled: boolean;
  minDate: Date | null;
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  onDeleteButton: (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void;
  t: TFunction<"translations", undefined>;
  zone?: ISelectOption;
}

export const createSensorMenuItems = ({
  zone,
  sensorId,
  sensorNumber,
  defaultIPoint,
  showHumidity,
  isDisabled,
  minDate,
  openModal,
  closeAndResetModal,
  onDeleteButton,
  t,
}: ICreateSensorMenuItems) => [
  {
    name: t("buttons.edit.actionWithKey", { key: t("entities.sensor") }),
    onItemClick: () =>
      openModal(
        <SensorDetailsSidebar
          closeModal={closeAndResetModal}
          defaultZone={zone ?? { label: "", value: "" }}
          sensorId={sensorId}
          sensorNumber={sensorNumber}
          defaultIPoint={defaultIPoint}
          showHumidity={!!showHumidity}
          minDate={minDate}
          isDisabled={isDisabled}
        />
      ),
  },
  {
    name: t("buttons.delete.actionWithKey", { key: t("entities.sensor") }),
    onItemClick: onDeleteButton,
  },
];
