import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const FlagContainer = styled.div``;

export const FlagWrapper = styled.div`
  ${({ theme: { zIndex } }) => css`
    position: sticky;
    top: 60%;
    margin: 0px -40px 0 auto;
    max-width: 120px;
    border-radius: 2px;
    overflow: hidden;
    width: 100%;
    transform: rotate(-90deg);
    z-index: ${zIndex.flag};
    box-shadow: 0px 1px 2px 0px #0000000d;

    @media (min-width: 768px) {
      margin: 0 -37px 0 auto;
      max-width: 132px;
    }
  `}
`;

export const FlagSide = styled.div`
  background-color: #0057b8;
  height: 20px;

  &.yellow {
    background-color: #ffd700;
  }

  @media (min-width: 768px) {
    height: 29px;
  }
`;

export const FlagContent = styled.p`
  ${({ theme }) => css`
    position: absolute;
    inset: 0;
    margin: auto;
    color: ${theme.palette.background.default};
    padding: 2px 17px;
    text-align: center;
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;

    @media (min-width: 768px) {
      padding: 9px 17px;
      line-height: 20px;
      font-size: 16px;
    }
  `}
`;
