import { PaginationState } from "@tanstack/react-table";
import { debounce } from "lodash";
import { useState, useEffect } from "react";

const defaultMinPageSize = 7;
const defaultMaxPageSize = 15;
const defaultWindowBaseWidth = 1440;

export const useWindowWidth = (
  windowBaseWidth = defaultWindowBaseWidth,
  maxPageSize = defaultMaxPageSize,
  minPageSize = defaultMinPageSize
) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: window.innerWidth > windowBaseWidth ? maxPageSize : minPageSize,
  });

  const debouncedResizeHandler = debounce(() => {
    setPagination({
      pageIndex: 0,
      pageSize: window.innerWidth > windowBaseWidth ? maxPageSize : minPageSize,
    });
  }, 300);

  useEffect(() => {
    window.addEventListener("resize", debouncedResizeHandler);

    return () => {
      window.removeEventListener("resize", debouncedResizeHandler);
    };
  }, [debouncedResizeHandler]);

  return pagination;
};
