import { ReactNode, useMemo, useState } from "react";
import { useGetSensorHistoryQuery } from "../../../api/sensor.api";
import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import { createSensorHistoryColumns } from "./sensor-history-table.utils";
import Loaders from "../../loaders/loaders";
import { Subheading } from "../../../styles/helper.styles";
import IconButton from "../../icon-button/icon-button";
import { KypsChart } from "../../kyps-chart/kyps-chart";
import {
  ChartOpener,
  HeadingChartText,
  SensorHistoryActionsWrapper,
} from "./sensor-history-table.styles";
import { TFunction } from "i18next";
import { hasEmptyMessage } from "../location-table/location-table.helper";
import { KypsButton } from "../../kyps-button/kyps-button";
import AuthWrapper from "../../../auth/auth-wrapper";
import { managersRights } from "../../../interfaces/auth-wrapper";
import { downloadCSV } from "../../../helpers/download-csv.helper";

interface ISensorHistoryTable {
  date: string;
  sensorNumber: string;
  showHumidity: boolean;
  iPointName: string;
  range: number[];
  minDate?: Date | null;
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  t: TFunction<"translations", undefined>;
}

const initialPaginationState: PaginationState = {
  pageIndex: 0,
  pageSize: 20,
};

const SensorHistoryTable = ({
  date,
  sensorNumber,
  showHumidity,
  iPointName,
  range,
  minDate,
  openModal,
  closeAndResetModal,
  t,
}: ISensorHistoryTable) => {
  const [pagination, setPagination] = useState<PaginationState>(
    initialPaginationState
  );

  const { data: sensorHistoryApi, isLoading } = useGetSensorHistoryQuery({
    page: pagination.pageIndex,
    size: pagination.pageSize,
    to: date,
    sensorNumber,
  });

  const data = useMemo(() => {
    if (sensorHistoryApi) return sensorHistoryApi.content;
    return [];
  }, [sensorHistoryApi]);

  const table = useReactTable({
    columns: createSensorHistoryColumns({ showHumidity, t }),
    data,
    state: { pagination },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    pageCount: sensorHistoryApi?.totalPages,
  });

  const tableHeaderElements = [
    <Subheading key="Locations">{t("tabs.history")}</Subheading>,
    <SensorHistoryActionsWrapper key="openChart">
      <AuthWrapper
        allowedRoles={managersRights}
        children={
          <KypsButton
            onClick={() => downloadCSV(sensorNumber)}
            placeholder={`${t("buttons.download.action")} CSV`}
            variant="active"
            hasIcon
            iconType="download"
            key="downloadCSV"
          />
        }
      />
      <ChartOpener
        onClick={() => {
          const [lowAttention, lowNormal, highNormal, highAttention] = range;
          openModal(
            <KypsChart
              closeModal={closeAndResetModal}
              range={[+lowAttention, +lowNormal, +highNormal, +highAttention]}
              sensorNumber={sensorNumber}
              minDate={minDate}
              iPointName={iPointName}
              t={t}
            />
          );
        }}
      >
        <HeadingChartText>
          {t("buttons.open.actionWithKey", { key: t("entities.chart") })}
        </HeadingChartText>
        <IconButton name="chart" />
      </ChartOpener>
    </SensorHistoryActionsWrapper>,
  ];

  if (isLoading) return <Loaders variant="table" />;

  return (
    <KypsTable
      table={table}
      hasSmallRows
      hideSorting
      tableHeaderElements={tableHeaderElements}
      hasNoActions
      emptyMessage={hasEmptyMessage({
        data,
        message: t("emptyMessage.text", {
          entity: t("emptyMessage.entities.data"),
        }),
      })}
    />
  );
};

export default SensorHistoryTable;
