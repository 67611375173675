import { useUpdateLocationByIdMutation } from "../../../api/location.api";
import { ILocationData } from "../../../interfaces/locations";
import { createNotification } from "../../toaster/toaster.helper";

type UpdateLocationResult = ReturnType<typeof useUpdateLocationByIdMutation>;
type UpdateLocationFunction = UpdateLocationResult[0];

interface IHandleOnEditLocation {
  id: string;
  data: ILocationData;
  editLocation: UpdateLocationFunction;
}

export const handleOnEditLocation = ({
  id,
  data,
  editLocation,
}: IHandleOnEditLocation) =>
  editLocation({ data, id })
    .unwrap()
    .then(() =>
      createNotification({
        message: `${data.name} was successfully updated!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
