import { DeleteFunction } from "../../../interfaces/delete-function";
import { ICompany, IPaginatedResponse } from "../../../interfaces/companies";
import { ICompanyAdminState } from "../../../interfaces/managers";
import { UserRolesEnum } from "../../../interfaces/roles";
import { isEmpty } from "lodash";
import { createNotification } from "../../toaster/toaster.helper";

interface IHandleOnDeleteCompany {
  id: string;
  name: string;
  companiesData: IPaginatedResponse<ICompany> | undefined;
  deleteCompany: DeleteFunction;
  handleResetPage: () => void;
}

interface IGetIsAllowedCompany {
  authorizedUserData: ICompanyAdminState[];
  currentCompanyId: string;
  userRole: UserRolesEnum;
}

export const handleOnDeleteCompany = ({
  id,
  name,
  companiesData,
  deleteCompany,
  handleResetPage,
}: IHandleOnDeleteCompany) =>
  deleteCompany(id)
    .unwrap()
    .then(() => {
      createNotification({
        message: `${name} was successfully deleted!`,
        variant: "success",
      });

      if (companiesData?.last && companiesData.numberOfElements <= 1)
        handleResetPage();
    })
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const getIsAllowedCompany = ({
  authorizedUserData,
  currentCompanyId,
  userRole,
}: IGetIsAllowedCompany) => {
  if (userRole === UserRolesEnum.SUPER_ADMIN) return true;

  if (isEmpty(authorizedUserData)) return false;

  return !!authorizedUserData.filter(
    ({ companyId }) => companyId === currentCompanyId
  )[0]?.admin;
};
