import { t } from "i18next";

interface IValidatePhoneNumber {
  value: string;
  type: string;
}

const phoneNumberRegex = /^[+()\-\s0-9]{12,25}$/;
const phoneNumberCodeLength = 3;

export const getValidPhoneValue = (value: string): string | null => {
  if (phoneNumberRegex.test(value)) return value;

  return "";
};

export const getValidTelegramId = (value: number | null): number =>
  !value ? 0 : value;

export const validatePhoneNumber = ({ type, value }: IValidatePhoneNumber) => {
  if (type !== "tel") return true;

  if (value.length <= phoneNumberCodeLength) return true;

  if (!phoneNumberRegex.test(value)) {
    return t("forms.messages.enterValidPhone");
  }

  return true;
};
