import clsx from "clsx";
import { useMemo } from "react";
import { StyledLabel } from "../kyps-input/kyps-input.styles";
import { StyledSelect } from "./kyps-select.styles";
import { ErrorMessage } from "../kyps-form/kyps-form.styles";

export interface ISelectOption {
  label: string;
  value: string;
}
export interface IKypsSelect {
  options: ISelectOption[] | undefined;
  placeholder: string;
  onSelect: (data: ISelectOption) => void;
  selectedOption: ISelectOption | null;
  onInputChange?: (value: string) => void;
  className?: string;
  label?: string;
  isLoading?: boolean;
  isError?: boolean;
  isDisable?: boolean;
  size?: "large" | "small";
}

const KypsSelect = ({
  options,
  placeholder,
  className,
  label,
  onSelect,
  selectedOption,
  onInputChange,
  isLoading,
  isError,
  isDisable,
  size,
}: IKypsSelect) => {
  const sortedOptions = useMemo(() => {
    if (options)
      return options.sort(({ label: labelA = "" }, { label: labelB = "" }) =>
        labelA.localeCompare(labelB)
      );

    return;
  }, [options]);

  return (
    <StyledLabel className={clsx(size, isDisable && "disabled", className)}>
      <div>
        {label}
        {isError && (
          <ErrorMessage>Please, choose Installation point!</ErrorMessage>
        )}
      </div>
      <StyledSelect
        className={clsx(size, className)}
        options={sortedOptions}
        placeholder={placeholder}
        classNamePrefix="styled-select"
        components={{ IndicatorSeparator: () => null }}
        isLoading={isLoading}
        onChange={(option) => onSelect(option as ISelectOption)}
        onInputChange={(value) => onInputChange?.(value)}
        value={selectedOption?.label ? selectedOption : ""}
        isSearchable
        tabIndex={isDisable ? -1 : 0}
      />
    </StyledLabel>
  );
};

export default KypsSelect;
