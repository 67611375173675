import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const mobileHeaderWidth = "992px";

export const LHeader = styled.header`
  ${({ theme: { palette, zIndex } }) => css`
    background-color: ${palette.background.default};
    padding: 12px 0;
    position: sticky;
    top: 0;
    z-index: ${zIndex.header};
    transition: all 0.3s ease;

    &.has-shadow {
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 768px) {
      padding: 24px 0;
    }
  `}
`;

export const LHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`;

export const LandingMenuWrapper = styled.div`
  display: none;

  @media (min-width: ${mobileHeaderWidth}) {
    display: block;
    margin-left: 40px;
  }

  a:not(:last-of-type) {
    margin-right: 16px;

    @media (min-width: 1024px) {
      margin-right: 24px;
    }

    @media (min-width: 1240px) {
      margin-right: 40px;
    }
  }
`;

export const LandingMenuLink = styled.a`
  ${({ theme }) => css`
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.palette.text.default};

    &:hover {
      color: ${theme.palette.text.primary};
    }
  `}
`;

export const LandingButtonWrapper = styled.a`
  display: inline-block;

  button {
    height: 100%;
  }
`;

export const LandingSwitcherWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 2 1 0;
  margin-right: 40px;

  @media (min-width: ${mobileHeaderWidth}) {
    margin-right: 0;
  }
`;

export const HeaderActions = styled.div`
  display: none;

  @media (min-width: ${mobileHeaderWidth}) {
    display: flex;
    margin: 0 0 0 auto;
  }

  > :not(:last-child) {
    margin-right: 8px;
  }
`;
