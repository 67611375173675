import { LContainer } from "../../landing-content.styles";
import {
  ImageSectionWrapper,
  StyledLandingImage,
} from "./image-section.styles";
import main_screen from "../../../../assets/landing-images/main_screen.svg";

const ImageSection = () => (
  <ImageSectionWrapper>
    <LContainer>
      <StyledLandingImage
        src={main_screen}
        alt="Main_screen"
        height={605}
        width={1236}
      />
    </LContainer>
  </ImageSectionWrapper>
);

export default ImageSection;
