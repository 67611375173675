import styled from "@emotion/styled";

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: #111827;
    border-radius: 10px;
    transition: all 0.15s linear;
    position: relative;
    transform-origin: 1px;
  }

  &.isOpen {
    > div {
      &:first-of-type {
        transform: rotate(47deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
        transform: translateX(-20px);
      }

      &:nth-of-type(3) {
        transform: rotate(-47deg);
      }
    }
  }
`;
