export enum EditableSensorFieldsEnum {
  ZONE = "zone",
  POINT = "installation point",
  SENSOR_ID = "sensor id",
  SENSOR_NAME = "sensor name",
  DELETE = "delete",
}

export interface IEditableSensorTableData {
  name:
    | EditableSensorFieldsEnum.ZONE
    | EditableSensorFieldsEnum.POINT
    | EditableSensorFieldsEnum.SENSOR_ID
    | EditableSensorFieldsEnum.SENSOR_NAME
    | EditableSensorFieldsEnum.DELETE;
  value: string;
  id: string;
}

export enum EditableLocationFieldsEnum {
  NAME = "name",
  ADDRESS = "address",
  LOCATION = "location",
  COMMENTS = "comments",
  DELETE = "delete",
}

export interface ILocationDetailsTableData {
  name:
    | EditableLocationFieldsEnum.NAME
    | EditableLocationFieldsEnum.ADDRESS
    | EditableLocationFieldsEnum.LOCATION
    | EditableLocationFieldsEnum.COMMENTS
    | EditableLocationFieldsEnum.DELETE;
  value: string;
  id: string;
}
