import { MutableRefObject, useEffect, useState } from "react";

export interface IOpenDeleteConfirm {
  deleteConfirmName: string;
  openerParamsProp: DOMRect;
  deleteFunction: () => void;
}

export interface SelectedDOMRect {
  height: number;
  width: number;
  x: number;
  y: number;
}

interface IUseDeleteConfirm {
  isDeleteConfirmOpen: boolean;
  deleteConfirmName: string;
  openerParams: SelectedDOMRect;
  closeDeleteConfirm: () => void;
  deleteFunction: () => void;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
}

const useDeleteConfirm = (
  scrollContainerRef: MutableRefObject<HTMLDivElement | null>,
  extraModalSpace = 0,
  extraScrollHeight = 0
): IUseDeleteConfirm => {
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [deleteConfirmName, setDeleteConfirmName] = useState<string>("");
  const [openerParams, setOpenerParams] = useState<SelectedDOMRect>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  const [deleteFunction, setDeleteFunction] = useState<() => void>(
    () => () => {}
  );

  const openDeleteConfirm = ({
    deleteConfirmName,
    openerParamsProp,
    deleteFunction,
  }: IOpenDeleteConfirm) => {
    const { height, width, x, y } = openerParamsProp;

    const calculatedY =
      extraScrollHeight > 0 ? extraScrollHeight + y : y + window.scrollY;

    setDeleteConfirmName(deleteConfirmName);
    setIsDeleteConfirmOpen(true);
    setDeleteFunction(() => deleteFunction);
    setOpenerParams({
      height,
      width,
      x: x - extraModalSpace,
      y: calculatedY,
    });
  };

  const closeDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (scrollContainer && isDeleteConfirmOpen) {
        closeDeleteConfirm();
      }
    };

    if (scrollContainer) {
      window.addEventListener("resize", handleScroll);
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      }
    };
  }, [scrollContainerRef, isDeleteConfirmOpen]);

  return {
    isDeleteConfirmOpen,
    deleteConfirmName,
    openerParams,
    openDeleteConfirm,
    closeDeleteConfirm,
    deleteFunction,
  };
};

export default useDeleteConfirm;
