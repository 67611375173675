import styled from "@emotion/styled";
import ArrowTop from "../../../../assets/landing-images/arrow-top.svg";
import ArrowBottom from "../../../../assets/landing-images/arrow-bottom.svg";
import TwoArrows from "../../../../assets/landing-images/two-arrows.svg";

export const StepWrapper = styled.div`
  background-color: #f9fafb;
  border-radius: 8px;
  position: relative;
  margin-top: 24px;
  min-height: 210px;

  &::before,
  &::after {
    display: inline-block;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 10;
    content: "";
    width: 66px;
    height: 66px;

    @media (min-width: 992px) {
      width: 72px;
      height: 72px;
    }

    @media (min-width: 1240px) {
      width: 86px;
      height: 86px;
    }
  }

  &.two-icons {
    &::before {
      background-image: url(${ArrowBottom});
      bottom: -56px;
      rotate: 60deg;
      left: 40px;

      @media (min-width: 480px) {
        left: 56px;
      }

      @media (min-width: 768px) {
        bottom: -66px;
      }

      @media (min-width: 992px) {
        top: 20px;
        right: -46px;
        left: initial;
        rotate: initial;
      }
    }

    &::after {
      background-image: url(${ArrowTop});
      bottom: -56px;
      left: -10px;
      rotate: 106deg;

      @media (min-width: 480px) {
        left: 0;
      }

      @media (min-width: 768px) {
        bottom: -66px;
        left: -18px;
      }

      @media (min-width: 992px) {
        bottom: 20px;
        right: -46px;
        left: initial;
        rotate: initial;
      }
    }
  }

  &.reverse-arrows {
    &::after {
      background-image: url(${TwoArrows});
      bottom: -60px;
      left: 20px;
      rotate: 90deg;
      
      @media (min-width: 480px) {
        left: 30px;
      }

      @media (min-width: 992px) {
        rotate: initial;
        left: initial;
        top: 0;
        bottom: 0;
        right: -56px;
        margin: auto 0;
      }
    }
  }
`;

export const StepContent = styled.div`
  padding: 10px 18px;
  height: 100%;
  display: flex;

  @media (min-width: 480px) {
    padding: 10px 36px;
  }

  @media (min-width: 768px) {
    padding: 10px 18px;
  }

  @media (min-width: 1240px) {
    padding: 10px 36px;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
  margin-right: 20px;

  &.mobile {
    svg {
      max-width: 60px;

      @media (min-width: 768px) {
        max-width: 80px;
      }

      @media (min-width: 1240px) {
        max-width: none;
      }
    }
  }
`;

export const StepTextWrapper = styled.div`
  padding: 40px 0;
`;

export const StepHeading = styled.h3`
  color: #111827;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 8px;
  min-height: 28px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const StepDescription = styled.p`
  color: #6b7280;
  text-align: left;
  font-size: 14px;
  line-height: 24px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;
