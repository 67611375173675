import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const UserDropdownWrapper = styled.div`
  position: relative;
`;

export const UserDropdownOpener = styled.div`
  margin-left: 16px;
`;

export const UserDropdownMenu = styled.div`
  ${({ theme }) => css`
    position: absolute;
    margin-top: 8px;
    right: 0;
    background-color: ${theme.palette.background.default};
    border: 1px solid ${theme.palette.background.light};
    border-radius: 4px;
    min-width: 300px;
    display: none;

    &.visible {
      display: block;
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 0px 0px 1px rgba(0, 0, 0, 0.01);
    }

    &::before,
    &::after {
      top: -5px;
      right: 10px;
      content: "";
      position: absolute;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
    }

    &::before {
      top: -6px;
      border-bottom-color: inherit;
    }
  `}
`;

export const UserDataWrapper = styled.div`
  ${({ theme }) => css`
    border-bottom: 2px solid ${theme.palette.background.secondary};
    padding: 12px 16px;
    display: flex;
  `}
`;

export const UserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const UserActionsWrapper = styled.div`
  padding: 12px 16px;
`;

export const UserActionButton = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    display: inline-block;
    color: ${theme.palette.text.dark};

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const UserName = styled.h3`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 4px;
    color: ${theme.palette.text.primary};
  `}
`;

export const UserMail = styled.p`
  ${({ theme }) => css`
    font-weight: 500;
    color: ${theme.palette.text.dark};
  `}
`;
