import styled from "@emotion/styled";
import { mobileHeaderWidth } from "../../landing-header/landing-header.styles";
import { css } from "@emotion/react";

export const BurgerContainer = styled.div`
  ${({ theme: { zIndex } }) => css`
    position: absolute;
    right: 20px;
    z-index: ${zIndex.burger};

    @media (min-width: 768px) {
      right: 40px;
    }

    @media (min-width: ${mobileHeaderWidth}) {
      display: none;
    }
  `}
`;

export const MobileMenuWrapper = styled.div`
  ${({ theme: { palette, zIndex } }) => css`
    position: fixed;
    top: 0;
    right: -150%;
    width: 80%;
    padding: 12px 20px;
    z-index: ${zIndex.mobileMenu};
    background-color: ${palette.background.default};
    min-height: 100vh;
    height: 100%;
    transition: all 0.3s ease-out;
    display: block;

    &.isOpen {
      transition: all 0.3s ease-in-out;
      right: 0;
      box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 768px) {
      width: 60%;
    }

    @media (min-width: ${mobileHeaderWidth}) {
      display: none;
    }
  `}
`;

export const MobileMenuContainer = styled.div`
  padding: 44px 0 0 0;
  height: 100%;
  position: relative;
`;

export const MobileMenuItemsWrapper = styled.div`
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;

export const MobileActionsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;
`;

export const ShadowOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;

  @media (min-width: ${mobileHeaderWidth}) {
    display: none;
  }
`;
