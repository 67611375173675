import { debounce } from "lodash";
import { ILocationManager } from "../../../interfaces/location-managers";
import { IManagerDTO } from "../../../interfaces/managers";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { Dispatch, SetStateAction } from "react";
import { IAttachTableData } from "../../../interfaces/tables";
import {
  AttachManagerFunction,
  UpdateManagerNotificationsFunction,
} from "../../../interfaces/update-functions";
import { IOnDetachUser } from "./attach-user-table.utils";
import { LocationUserRoles } from "../../../interfaces/roles";
import { DetachFunction } from "../attach-location-table/attach-location-table.helper";
import { createNotification } from "../../toaster/toaster.helper";
import { t } from "i18next";

export type ToggleNotificationField = "telegram" | "email";

interface IHandleOnSelect {
  setSelectedOption: (value: SetStateAction<ISelectOption | null>) => void;
  option: ISelectOption | null;
}

interface IHandleOnToggleNotification {
  updateManagerNotifications: UpdateManagerNotificationsFunction;
  userData: IAttachTableData;
  changedField?: ToggleNotificationField;
}

interface IHandleOnDetachUser {
  data: IOnDetachUser;
  detachUser: DetachFunction;
}

interface IHandleOnAttachUser {
  setSelectInputValue: (value: SetStateAction<string>) => void;
  setSelectedOption: (value: SetStateAction<ISelectOption | null>) => void;
  attachUserToLocation: AttachManagerFunction;
  locationId: string;
  managerId: string;
  role: LocationUserRoles;
}

interface IGetNotificationTranslation {
  name: string;
  userData: IAttachTableData;
  changedField?: ToggleNotificationField;
}

export const locationManagersTransformer = (
  data: ILocationManager[]
): IAttachTableData[] => {
  const transformedData = data.map(
    ({
      manager: { name, email, id: userId },
      notifyEmail,
      notifyTelegram,
      location: { id: locationId },
      role,
    }) => ({
      user: name,
      userId,
      email,
      emailNotification: notifyEmail,
      telegramNotification: notifyTelegram,
      locationId,
      role,
    })
  );

  const sorted = transformedData.sort(({ user: userA }, { user: userB }) =>
    userA.localeCompare(userB)
  );

  return sorted;
};

export const createSelectUsersArray = (
  apiUsers: IManagerDTO[],
  currentUsers: IAttachTableData[]
): ISelectOption[] =>
  apiUsers.reduce((acc: ISelectOption[], apiUser) => {
    const isCurrentUser = currentUsers.some(
      (currentUser) => currentUser.userId === apiUser.id
    );

    if (!isCurrentUser) acc.push({ label: apiUser.name, value: apiUser.id });

    return acc;
  }, []);

const debouncedSearchUsers = debounce(
  (value: string, setSelectInputValue: Dispatch<SetStateAction<string>>) => {
    if (value.replace(/\s/g, "").length > 0) return setSelectInputValue(value);

    setSelectInputValue("");
  },
  700
);

export const onSelectValueChange = (
  value: string,
  setSelectInputValue: Dispatch<SetStateAction<string>>
) => debouncedSearchUsers(value, setSelectInputValue);

const getNotificationTranslation = ({
  name,
  userData,
  changedField,
}: IGetNotificationTranslation) => {
  if (!changedField) return `${name} was successfully edited!`;

  if (changedField === "email")
    return userData.emailNotification
      ? t("notification.locationNotification.emailNotification.enable", {
          userName: userData.user,
        })
      : t("notification.locationNotification.emailNotification.disable", {
          userName: userData.user,
        });

  return userData.emailNotification
    ? t("notification.locationNotification.telegramNotification.enable", {
        userName: userData.user,
      })
    : t("notification.locationNotification.telegramNotification.disable", {
        userName: userData.user,
      });
};

export const handleOnToggleNotification = ({
  updateManagerNotifications,
  userData,
  changedField,
}: IHandleOnToggleNotification) => {
  const { userId, emailNotification, telegramNotification, locationId, role } =
    userData;

  updateManagerNotifications({
    locationId,
    managerId: userId,
    notifyEmail: emailNotification,
    notifyTelegram: telegramNotification,
    role,
  })
    .unwrap()
    .then(({ manager }) =>
      createNotification({
        message: getNotificationTranslation({
          name: manager.name,
          userData,
          changedField,
        }),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};

export const handleOnDetachUser = ({
  data: { locationId, managerId, managerName },
  detachUser,
}: IHandleOnDetachUser) =>
  detachUser({ managerId, locationId })
    .unwrap()
    .then(() => {
      createNotification({
        message: `${managerName} was successfully detached!`,
        variant: "success",
      });
    })
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const handleOnAttachUser = ({
  setSelectInputValue,
  setSelectedOption,
  attachUserToLocation,
  locationId,
  managerId,
  role,
}: IHandleOnAttachUser) =>
  attachUserToLocation({
    locationId,
    data: {
      managerId,
      notifyEmail: true,
      notifyTelegram: false,
      role,
    },
  })
    .unwrap()
    .then(({ manager: { name } }) => {
      createNotification({
        message: t("notification.success", {
          action: t("notification.actions.attached"),
          entity: `${t("entities.user")} "${name}"`,
        }),
        variant: "success",
      });
      setSelectInputValue("");
      setSelectedOption({ label: "", value: "" });
    })
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const handleOnSelect = ({
  option,
  setSelectedOption,
}: IHandleOnSelect) => {
  if (option) setSelectedOption(option);
};

export const handleOnChangeUserRole = ({
  updateManagerNotifications,
  userData,
}: IHandleOnToggleNotification) => {
  const { emailNotification, locationId, role, telegramNotification, userId } =
    userData;

  updateManagerNotifications({
    locationId,
    managerId: userId,
    notifyEmail: emailNotification,
    notifyTelegram: telegramNotification,
    role,
  })
    .unwrap()
    .then(({ manager }) =>
      createNotification({
        message: `${manager.name}'s role was successfully updated!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};
