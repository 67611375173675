import { TFunction } from "i18next";
import { InputField } from "../../kyps-form/kyps-form-input";
import { IAddSensorFormFields } from "./add-sensor-sidebar";

export const createAddSensorFields = (
  t: TFunction<"translations", undefined>
): InputField<IAddSensorFormFields>[] => [
  {
    label: t("tables.sensorId"),
    name: "sensorNumber",
    type: "text",
    rules: {
      required: t("forms.messages.isRequiredWithKey", {
        labelName: t("tables.sensorId"),
      }),
      minLength: {
        value: 2,
        message: t("forms.messages.length", {
          fieldName: t("tables.sensorId"),
          length: 1,
        }),
      },
    },
    placeholder: `${t("tables.sensorId")}...`,
  },
  {
    label: t("tables.sensorName"),
    name: "sensorName",
    type: "text",
    placeholder: `${t("tables.sensorName")}...`,
  },
];
