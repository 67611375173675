import { Fragment } from "react";

const height = 36;

export const createReportLoader = () => {
  const reportRowsCount = 7;
  const reportRows = [];
  for (let i = 0; i < reportRowsCount; i++) {
    reportRows.push(createReportRow(i * (height + 2) + 36));
  }

  return (
    <>
      <rect x={0} y={0} rx={4} ry={4} width={"100%"} height={32} />
      {reportRows}
    </>
  );
};

const createReportRow = (y: number) => {
  const startValue = 208;
  const step = 38;
  const count = 24;

  const hoursRects = [];
  for (let i = 0; i < count; i++) {
    const x = startValue + i * step;
    hoursRects.push(
      <rect key={i} x={x} y={y} rx={4} ry={4} width={36} height={height} />
    );
  }

  return (
    <Fragment key={y}>
      <rect x={0} y={y} rx={4} ry={4} width={144} height={height} />
      <rect x={146} y={y} rx={4} ry={4} width={60} height={height} />
      {hoursRects}
      <rect x={1120} y={y} rx={4} ry={4} width={58} height={height} />
      <rect x={1180} y={y} rx={4} ry={4} width={36} height={height} />
    </Fragment>
  );
};

export const createSidebarFormLoader = () => {
  return (
    <Fragment>
      <rect x={0} y={0} rx={4} ry={4} width={"25%"} height={40} />
      {createSidebarFormRow()}
    </Fragment>
  );
};

const createSidebarFormRow = () => {
  return Array.from({ length: 4 }, (_, i) => {
    const yStep = 48 + i * 76;

    return (
      <Fragment key={yStep}>
        <rect x={0} y={yStep} rx={4} ry={4} width={"10%"} height={20} />
        <rect x={0} y={yStep + 24} rx={4} ry={4} width={"100%"} height={40} />
      </Fragment>
    );
  });
};

export const createTableLoader = (
  baseHeight: number = 0,
  rowsNumber: number = 7,
  hasAction: boolean = false
) => {
  const rows = [];
  for (let i = 0; i < rowsNumber; i++) {
    rows.push(createBaseTableRow(i * (40 + 5), baseHeight));
  }

  return (
    <Fragment>
      <rect x={0} y={baseHeight} rx={4} ry={4} width={"17%"} height={32} />
      {hasAction && (
        <rect
          x={`${100 - 17}%`}
          y={baseHeight}
          rx={4}
          ry={4}
          width={"17%"}
          height={32}
        />
      )}
      {rows}
    </Fragment>
  );
};

const createBaseTableRow = (n: number, baseHeight: number = 0) => {
  return Array.from({ length: 5 }, (_, i) => {
    const x = `${25 * i}%`;

    return (
      <rect
        key={i}
        x={x}
        y={n + 40 + baseHeight}
        rx={4}
        ry={4}
        width={"24.6%"}
        height={40}
      />
    );
  });
};

const createDashboardCards = () => {
  const cardWidth = 292;
  const cardHeight = 110;

  return (
    <>
      <rect x={0} y={36} rx={4} ry={4} width={cardWidth} height={cardHeight} />
      <rect
        y={36}
        x={cardWidth + 16}
        rx={4}
        ry={4}
        width={cardWidth}
        height={cardHeight}
      />
      <rect
        y={36}
        x={(cardWidth + 16) * 2}
        rx={4}
        ry={4}
        width={cardWidth}
        height={cardHeight}
      />
      <rect
        y={36}
        x={(cardWidth + 16) * 3}
        rx={4}
        ry={4}
        width={cardWidth}
        height={cardHeight}
      />
    </>
  );
};

export const createDashboardLoader = () => {
  return (
    <>
      <rect x={0} y={0} rx={4} ry={4} width={"20%"} height={24} />
      {createDashboardCards()}
      {createTableLoader(166)}
    </>
  );
};

export const createEditableSidebarLoader = () => {
  return (
    <>
      <rect x={0} y={0} rx={4} ry={4} width={"25%"} height={88} />
      {createEditableRows()}
    </>
  );
};

export const createEditableRows = (
  rowsCount: number = 5,
  baseHeight: number = 96,
  rowHeight: number = 60,
  rowMargin: number = 8
) => {
  return Array.from({ length: rowsCount }, (_, i) => (
    <rect
      key={rowsCount + i}
      x={0}
      y={baseHeight + 60 * i + i * rowMargin}
      rx={4}
      ry={4}
      width={"100%"}
      height={rowHeight}
    />
  ));
};

export const createManagementLoader = () => {
  return createTableLoader(0, 7, true);
};

export const createLocationLoader = () => {
  return (
    <>
      <rect x={0} y={0} rx={4} ry={4} width={"20%"} height={46} />
      <rect x={`${100 - 6}%`} y={4} rx={4} ry={4} width={"6%"} height={38} />
      <rect x={`${100 - 17}%`} y={4} rx={4} ry={4} width={"10%"} height={38} />
      {createTableLoader(78, 6, true)}
    </>
  );
};

export const createSensorCards = () => {
  return (
    <>
      <rect x={0} y={0} rx={4} ry={4} width={386} height={212} />
      <rect x={386 + 12} y={0} rx={4} ry={4} width={386} height={212} />
      <rect x={386 * 2 + 12 * 2} y={0} rx={4} ry={4} width={386} height={212} />
    </>
  );
};
