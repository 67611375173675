import {
  ILocationInstallationPointWithZone,
  ILocationZone,
} from "../../../interfaces/dashboard";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { ILocationTableData, ISubRowsData } from "../../../interfaces/tables";
import { useDeleteSensorMutation } from "../../../api/sensor.api";
import { toRoundValue } from "../../dashboard-components/location-dashboard/location-dashboard.helper";
import { createNotification } from "../../toaster/toaster.helper";

type DeleteSensorResult = ReturnType<typeof useDeleteSensorMutation>;
export type DeleteSensorFunction = DeleteSensorResult[0];

interface IOnDeleteSensor {
  id: string;
  name: string;
  deleteSensor: DeleteSensorFunction;
}

export const getZoneData = (
  installationPoints: ILocationInstallationPointWithZone[]
): ILocationTableData[] =>
  installationPoints.map(
    ({ sensors, name, temperatureRange: iPointRange, id, zone }) => {
      const subItems: ISubRowsData[] = sensors.map(
        ({
          id,
          lastUpdateDate,
          lastTemperature,
          sensorNumber,
          lastVoltage,
          lastDbm,
          state,
          customConfig,
          temperatureRange,
          showHumidity,
          sensorName,
        }) => ({
          id,
          battery: lastVoltage,
          name: { value: sensorNumber, isCustomConfig: customConfig },
          safeZone: temperatureRange,
          signal: lastDbm,
          temperature: {
            value:
              state !== "OFF" ? `${toRoundValue(lastTemperature)}` : "OFFLINE",
            state: `${state.toLocaleLowerCase()}`,
          },
          time: lastUpdateDate,
          showHumidity,
          sensorName,
        })
      );

      return {
        name: { value: name },
        id,
        safeZone: iPointRange,
        subRows: subItems,
        zone,
      };
    }
  );

export const getZoneSelectArray = (data: ILocationZone[]): ISelectOption[] =>
  data.map(({ name, id }) => ({ label: name, value: id }));

export const handleOnDeleteSensor = ({
  id,
  name,
  deleteSensor,
}: IOnDeleteSensor) =>
  deleteSensor(id)
    .then(() =>
      createNotification({
        message: `Sensor ${name} was successfully deleted!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
