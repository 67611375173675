import clsx from "clsx";
import { TabItem, TabsWrapper } from "./page-content-container.styles";

export interface IPageContent<T = string> {
  tabName: string;
  tabId: T;
  content?: JSX.Element;
}

interface IPageContentContainer<T = string> {
  pageContent: IPageContent<T>[];
  activeTab: T;
  tabHandler: (tabId: T) => void;
}

const PageContentContainer = <T extends string>({
  pageContent,
  activeTab,
  tabHandler,
}: IPageContentContainer<T>): JSX.Element => (
  <>
    <TabsWrapper>
      {pageContent.map(({ tabName, tabId }) => (
        <TabItem
          key={tabName}
          onClick={() => tabHandler(tabId)}
          className={clsx(activeTab === tabId && "active")}
        >
          {tabName}
        </TabItem>
      ))}
    </TabsWrapper>
    {pageContent.map(
      ({ tabName, content, tabId }) =>
        activeTab === tabId && <div key={tabName}>{content}</div>
    )}
  </>
);

export default PageContentContainer;
