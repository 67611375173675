import { IPaginatedResponse } from "../interfaces/companies";
import {
  IAddCompanyManager,
  IAttachManagerToCompany,
  ICompanyManagerResponseDTO,
  IDetachManagerToCompany,
  IGetCompanyManagersAutocomplete,
  IGetCompanyManagersQuery,
  IToggleCompanyAdmin,
} from "../interfaces/company-managers";
import { IManagerDTO } from "../interfaces/managers";
import { getSortParams } from "./api-helpers";
import { kypsApi } from "./kyps.api";

export const companyManagersControllerEndpoints = kypsApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyManagersByCompanyId: builder.query<
      IPaginatedResponse<IManagerDTO>,
      IGetCompanyManagersQuery
    >({
      query: ({
        page,
        size,
        sort = [{ direction: "ASC", property: "name" }],
        companyId,
      }) => ({
        url: `companies/${companyId}/managers?page=${page}&size=${size}&sort=${getSortParams(
          sort
        )}`,
        method: "GET",
      }),
      providesTags: ["Managers"],
    }),

    getCompanyManagersAutocomplete: builder.query<
      IPaginatedResponse<IManagerDTO>,
      IGetCompanyManagersAutocomplete
    >({
      query: ({
        page,
        size,
        sort = [{ direction: "ASC", property: "name" }],
        companyId,
        searchValue,
      }) => ({
        url: `/companies/${companyId}/managers/search/autocomplete?q=${searchValue}&page=${page}&size=${size}&sort=${getSortParams(
          sort
        )}`,
        method: "GET",
      }),
      providesTags: ["Managers"],
    }),

    addCompanyManager: builder.mutation<IManagerDTO, IAddCompanyManager>({
      query: ({ companyId, data }) => ({
        url: `/companies/${companyId}/managers`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Managers"],
    }),

    toggleIsCompanyAdmin: builder.mutation<
      ICompanyManagerResponseDTO,
      IToggleCompanyAdmin
    >({
      query: ({ companyId, managerId, admin }) => ({
        url: `/companies/${companyId}/managers/${managerId}`,
        method: "PUT",
        body: { admin },
      }),
      invalidatesTags: ["Managers"],
    }),

    attachManagerToCompany: builder.mutation<
      ICompanyManagerResponseDTO,
      IAttachManagerToCompany
    >({
      query: ({ admin, companyId, managerId }) => ({
        url: `/companies/${companyId}/managers/${managerId}`,
        method: "POST",
        body: {
          managerId,
          admin,
        },
      }),
      invalidatesTags: ["Managers"],
    }),

    detachManagerFromCompany: builder.mutation<void, IDetachManagerToCompany>({
      query: ({ companyId, managerId }) => ({
        url: `/companies/${companyId}/managers/${managerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Managers"],
    }),
  }),
});

export const {
  useGetCompanyManagersByCompanyIdQuery,
  useAddCompanyManagerMutation,
  useToggleIsCompanyAdminMutation,
  useAttachManagerToCompanyMutation,
  useDetachManagerFromCompanyMutation,
  useGetCompanyManagersAutocompleteQuery,
} = companyManagersControllerEndpoints;
