import {
  BurgerContainer,
  MobileActionsWrapper,
  MobileMenuContainer,
  MobileMenuItemsWrapper,
  MobileMenuWrapper,
  ShadowOverlay,
} from "./header-mobile-menu.styles";
import Burger from "../burger/burger";
import clsx from "clsx";
import {
  LandingButtonWrapper,
  LandingMenuLink,
} from "../../landing-header/landing-header.styles";
import { KypsButton } from "../../../../components/kyps-button/kyps-button";
import { useNavigate } from "react-router-dom";
import { createTranslatedLinks } from "../../../../components/kyps-nav/landing-nav.utils";
import { TFunction } from "i18next";

interface IHeaderMobileMenu {
  isMenuOpen: boolean;
  handleOnMenuOpen: () => void;
  handleCloseMenu: () => void;
  t: TFunction<"translations", undefined>;
}

const HeaderMobileMenu = ({
  isMenuOpen,
  handleOnMenuOpen,
  handleCloseMenu,
  t,
}: IHeaderMobileMenu) => {
  const navigate = useNavigate();

  return (
    <>
      <BurgerContainer onClick={handleOnMenuOpen}>
        <Burger isMenuOpen={isMenuOpen} t={t} />
      </BurgerContainer>
      <MobileMenuWrapper className={clsx(isMenuOpen && "isOpen")}>
        <MobileMenuContainer>
          <MobileMenuItemsWrapper>
            {createTranslatedLinks(t).map(({ href, name }) => (
              <LandingMenuLink href={href} onClick={handleCloseMenu} key={name}>
                {name}
              </LandingMenuLink>
            ))}
          </MobileMenuItemsWrapper>
          <MobileActionsWrapper>
            <LandingButtonWrapper href="/#contact-us" onClick={handleCloseMenu}>
              <KypsButton
                placeholder={t("landing.header.buttons.contactUs")}
                onClick={() => {}}
                isLargerFont
              />
            </LandingButtonWrapper>
            <KypsButton
              placeholder={t("landing.header.buttons.logInToAccount")}
              onClick={() => {
                handleCloseMenu();
                navigate("/dashboard");
              }}
              variant="active"
              isLargerFont
            />
          </MobileActionsWrapper>
        </MobileMenuContainer>
      </MobileMenuWrapper>
      {isMenuOpen && <ShadowOverlay onClick={handleCloseMenu} />}
    </>
  );
};

export default HeaderMobileMenu;
