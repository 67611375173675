import { useRef } from "react";
import KypsSidebar from "../kyps-sidebar";
import { KypsButton } from "../../kyps-button/kyps-button";
import KypsForm from "../../kyps-form/kyps-form";
import { useAddCompanyMutation } from "../../../api/companies.api";
import { createAddCompanyFields } from "./add-company-sidebar.utils";
import { IAddCompanyFormFields } from "../../../interfaces/forms";
import { handleOnSubmitAddCompany } from "./add-company-sidebar.helper";
import { TFunction } from "i18next";

export interface IAddCompanySidebar {
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
}

const AddCompanySidebar = ({
  closeModal,
  t,
}: IAddCompanySidebar): JSX.Element => {
  const [addCompany] = useAddCompanyMutation();
  const formRef = useRef<HTMLFormElement>(null);

  const handleAddCompanyFormSubmit = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmitAddCompany = (companyData: IAddCompanyFormFields) =>
    handleOnSubmitAddCompany({
      addCompany,
      closeModal,
      data: companyData,
    });

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: t("buttons.add.actionWithKey", { key: t("entities.company") }),
        buttons: [
          <KypsButton
            key="Cancel"
            placeholder={t("buttons.cancel.action")}
            onClick={closeModal}
            variant="light"
          />,
          <KypsButton
            key="Add company"
            placeholder={t("buttons.add.actionWithKey", {
              key: t("entities.company"),
            })}
            onClick={handleAddCompanyFormSubmit}
          />,
        ],
      }}
      content={[
        <KypsForm
          fields={createAddCompanyFields(t)}
          onSubmit={onSubmitAddCompany}
          ref={formRef}
          key="AddCompanyForm"
        />,
      ]}
    />
  );
};

export default AddCompanySidebar;
