import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const RangeFormWrapper = styled.div`
  ${({ theme }) => css`
    &.disable {
      input {
        background-color: ${theme.palette.background.secondary};
        pointer-events: none;
      }
    }
  `}
`;

export const RangeComponentWrapper = styled.div`
  ${({ theme }) => css`
    margin: 16px 0 32px;

    &.has-padding {
      border-radius: 4px;
      padding: 10px 8px;
      background-color: ${theme.palette.background.default};

      @media (min-width: 768px) {
        padding: 20px 24px;
      }
    }

    &.has-border {
      border: 1px solid ${theme.palette.background.light};
    }
  `}
`;

export const RangeHeadingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;

  h2 {
    margin: 0;
    min-height: 32px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;

    h2 {
      min-height: 40px;
    }
  }
`;

export const RangeHeadingContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const RangeHeading = styled.h2`
  ${({ theme }) => css`
    color: ${theme.palette.text.primary};
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 16px;
  `}
`;

export const RangeItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .message {
    position: absolute;
    top: 100%;
    margin: 0;
  }
`;

export const RangeItemBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:first-of-type {
    margin-right: 16px;
  }

  input {
    width: 100%;
  }
`;

export const RangeSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px 0 0;

  svg {
    max-height: 20px;
    rotate: 90deg;
  }

  @media (min-width: 768px) {
    margin: auto 12px 10px;

    svg {
      rotate: unset;
    }
  }
`;

export const RangeItemHeading = styled.h3`
  ${({ theme }) => css`
    color: ${theme.palette.text.dark};
    font-weight: 500;
    margin-bottom: 4px;
    font-size: 14px;
  `}
`;

export const RangeType = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 6px;
    margin: auto 0;
    height: fit-content;
    pointer-events: none;
    color: ${theme.palette.success.primary};
    text-transform: capitalize;

    &.attention {
      color: ${theme.palette.info.secondary};
    }

    @media (min-width: 768px) {
      right: 12px;
    }
  `}
`;

export const TemperatureSign = styled.div`
  position: absolute;
  pointer-events: none;
  left: 36px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: fit-content;

  @media (min-width: 768px) {
    left: 56px;
  }
`;

export const HalfRangeHolder = styled.div``;

export const StyledFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
