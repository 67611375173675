import { IPaginatedResponse } from "../../../interfaces/companies";
import {
  DeleteFunction,
  DetachManagerFromCompanyFunction,
} from "../../../interfaces/delete-function";
import { IManagerDTO } from "../../../interfaces/managers";
import { createNotification } from "../../toaster/toaster.helper";

interface IHandleOnDeleteManagerFromCompany {
  managerId: string;
  companyId: string;
  name: string;
  managersData: IPaginatedResponse<IManagerDTO> | null;
  deleteManagerFromCompany: DetachManagerFromCompanyFunction;
  handleResetPage: () => void;
}

interface IHandleOnDeleteManager {
  id: string;
  name: string;
  managersData: IPaginatedResponse<IManagerDTO> | null;
  deleteManager: DeleteFunction;
  handleResetPage: () => void;
}

export const handleOnDeleteManager = ({
  id,
  name,
  managersData,
  deleteManager,
  handleResetPage,
}: IHandleOnDeleteManager) =>
  deleteManager(id)
    .unwrap()
    .then(() => {
      createNotification({
        message: `${name} was successfully deleted!`,
        variant: "success",
      });

      if (managersData?.last && managersData.numberOfElements <= 1)
        handleResetPage();
    })
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const handleOnDeleteManagerFromCompany = ({
  managerId,
  companyId,
  name,
  managersData,
  deleteManagerFromCompany,
  handleResetPage,
}: IHandleOnDeleteManagerFromCompany) =>
  deleteManagerFromCompany({ companyId, managerId })
    .unwrap()
    .then(() => {
      createNotification({
        message: `${name} was successfully deleted!`,
        variant: "success",
      });

      if (managersData?.last && managersData.numberOfElements <= 1)
        handleResetPage();
    })
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
