import {
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  RefObject,
  useRef,
} from "react";
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import { createEditableColumns } from "./editable-zones-table.utils";
import { KypsButton } from "../../kyps-button/kyps-button";
import { Subheading } from "../../../styles/helper.styles";
import useModal from "../../../hooks/useModal";
import KypsModal from "../../kyps-modal/kyps-modal";
import AddZoneSidebar from "../../kyps-sidebar/add-zone-sidebar/add-zone-sidebar";
import { useGetLocationSensorsDataByLocationIdQuery } from "../../../api/dashboard.api";
import { getEditData } from "./editable-zones-table.helper";
import { isEmpty } from "lodash";
import EmptyPage from "../../kyps-empty/kyps-empty-page";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import AuthWrapper from "../../../auth/auth-wrapper";
import { managersRights } from "../../../interfaces/auth-wrapper";
import Loaders from "../../loaders/loaders";
import { TFunction } from "i18next";
import useDeleteConfirm from "../../../hooks/useDeleteConfirm";
import useWindowSize from "../../../hooks/useWindowSize";
import useScrollTop from "../../../hooks/useScrollTop";
import DeleteConfirm from "../../delete-confirm/delete-confirm";

interface IZonesEditableTable {
  locationId: string;
  selectZone: Dispatch<SetStateAction<ISelectOption>>;
  sidebarRef: RefObject<HTMLDivElement>;
  t: TFunction<"translations", undefined>;
}

const ZonesEditableTable = ({
  locationId,
  sidebarRef,
  selectZone,
  t,
}: IZonesEditableTable) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "name", desc: false },
  ]);
  const [editedRows, setEditedRows] = useState({});
  const [selectedId, setSelectedId] = useState<string>("");
  const tableRef = useRef<HTMLDivElement>(null);

  const { sidebarExtraSpace } = useWindowSize({ sidebarRef });
  const extraScrollHeight = useScrollTop(sidebarRef);
  const {
    closeDeleteConfirm,
    deleteConfirmName,
    deleteFunction,
    isDeleteConfirmOpen,
    openDeleteConfirm,
    openerParams,
  } = useDeleteConfirm(sidebarRef, sidebarExtraSpace, extraScrollHeight);

  const { data: apiLocationData, isLoading } =
    useGetLocationSensorsDataByLocationIdQuery(
      isEmpty(locationId) ? skipToken : locationId
    );

  const data = useMemo(() => {
    if (apiLocationData) return getEditData(apiLocationData);

    return [];
  }, [apiLocationData]);

  const table = useReactTable({
    data,
    columns: createEditableColumns({
      selectedId,
      openDeleteConfirm,
      setSelectedId,
      selectZone,
      t,
    }),
    getCoreRowModel: getCoreRowModel(),
    meta: {
      editedRows,
      setEditedRows,
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    sortDescFirst: false,
  });

  const { closeAndResetModal, currentModal, isModalOpen, openModal } =
    useModal();

  const addZoneButton = (
    <KypsButton
      key="Add Zone"
      placeholder={t("buttons.add.actionWithKey", { key: t("entities.zone") })}
      onClick={() =>
        openModal(
          <AddZoneSidebar
            closeModal={closeAndResetModal}
            currentLocationId={locationId}
            t={t}
          />
        )
      }
    />
  );

  const renderContent = () => (
    <KypsTable
      table={table}
      tableHeaderElements={[
        <Subheading key="Zones Table">{t("tabs.zones")}</Subheading>,
        <AuthWrapper
          allowedRoles={managersRights}
          children={addZoneButton}
          key="Add Zone"
        />,
      ]}
      tableRef={tableRef}
    />
  );

  const renderEmptyPage = () => (
    <EmptyPage
      heading={t("emptyMessage.text", {
        entity: t("emptyMessage.entities.zone"),
      })}
      iconName="clipboard"
      actionButton={addZoneButton}
    />
  );

  if (isLoading) return <Loaders variant="table" />;

  return (
    <>
      {isEmpty(data) ? renderEmptyPage() : renderContent()}
      {isModalOpen && (
        <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
      )}
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
          confirmContainer={tableRef}
        />
      )}
    </>
  );
};

export default ZonesEditableTable;
