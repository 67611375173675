import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { IPositionCoords } from "../interfaces/delete-confirm";
import { IMenuItems } from "../interfaces/dropdown-menu";

export interface IOpenDropdownMenu {
  isDropdownMenuOpen: boolean;
  position: IPositionCoords;
  menuItems: IMenuItems[];
}

interface IUseDropdownMenu {
  dropdownMenuPosition: IPositionCoords;
  isDropdownMenuOpen: boolean;
  menuItems: IMenuItems[];
  setIsDropdownMenuOpen: Dispatch<SetStateAction<boolean>>;
  openDropdownMenu: (openDropdownMenuProps: IOpenDropdownMenu) => void;
}

const useDropdownMenu = (
  scrollContainerRef: MutableRefObject<HTMLDivElement | null>
): IUseDropdownMenu => {
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
  const [dropdownMenuPosition, setDropdownMenuPosition] =
    useState<IPositionCoords>({
      x: 0,
      y: 0,
    });
  const [menuItems, setMenuItems] = useState<IMenuItems[]>([]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (scrollContainer && isDropdownMenuOpen) setIsDropdownMenuOpen(false);
    };

    if (scrollContainer) {
      window.addEventListener("resize", handleScroll);
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      }
    };
  }, [setIsDropdownMenuOpen, isDropdownMenuOpen, scrollContainerRef]);

  const openDropdownMenu = ({
    isDropdownMenuOpen,
    menuItems,
    position,
  }: IOpenDropdownMenu) => {
    setDropdownMenuPosition(position);
    setIsDropdownMenuOpen(isDropdownMenuOpen);
    setMenuItems(menuItems);
  };

  return {
    isDropdownMenuOpen,
    dropdownMenuPosition,
    menuItems,
    openDropdownMenu,
    setIsDropdownMenuOpen,
  };
};

export default useDropdownMenu;
