import { TFunction } from "i18next";
import {
  LandingMenuLink,
  LandingMenuWrapper,
} from "../../containers/landing-content/landing-header/landing-header.styles";
import { createTranslatedLinks } from "./landing-nav.utils";

const LandingNav = ({ t }: { t: TFunction }) => (
  <LandingMenuWrapper>
    {createTranslatedLinks(t).map(({ href, name }) => (
      <LandingMenuLink href={href} key={name}>
        {name}
      </LandingMenuLink>
    ))}
  </LandingMenuWrapper>
);

export default LandingNav;
