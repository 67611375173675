import {
  ReactNode,
  useMemo,
  useState,
  useCallback,
  useRef,
  RefObject,
} from "react";
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "./../kyps-table";
import KypsSelect, { ISelectOption } from "../../kyps-select/kyps-select";
import { KypsButton } from "../../kyps-button/kyps-button";
import { hasEmptyMessage } from "../location-table/location-table.helper";
import { useSearchManagerAutocompleteQuery } from "../../../api/managers.api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  createSelectUsersArray,
  handleOnAttachUser,
  handleOnDetachUser,
  handleOnSelect,
  handleOnToggleNotification,
  onSelectValueChange,
  ToggleNotificationField,
} from "./attach-user-table.helper";
import {
  useAttachManagerByLocationIdMutation,
  useDetachManagerByLocationIdMutation,
  useUpdateManagerByLocationIdMutation,
} from "../../../api/location-manager.api";
import { IOnDetachUser, attachUserColumns } from "./attach-user-table.utils";
import { IAttachTableData } from "../../../interfaces/tables";
import { AttachTableWrapper } from "./attach-user-table.styles";
import { isEmpty } from "lodash";
import AuthWrapper from "../../../auth/auth-wrapper";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { managersRights } from "../../../interfaces/auth-wrapper";
import Loaders from "../../loaders/loaders";
import {
  LocationUserRoles,
  UserRolesEnum,
  locationRoles,
} from "../../../interfaces/roles";
import { useGetCompanyManagersAutocompleteQuery } from "../../../api/company-manager.api";
import { TFunction } from "i18next";
import useLocationRoleSelect from "../../../hooks/useLocationRoleSelect";
import LocationRoleSelectList from "../../kyps-select/location-role-select/location-role-select-list";
import useWindowSize from "../../../hooks/useWindowSize";
import useScrollTop from "../../../hooks/useScrollTop";

interface IAttachUserTable {
  tableData: IAttachTableData[];
  locationId: string;
  isLoading: boolean;
  sidebarRef: RefObject<HTMLDivElement>;
  haveBackground?: boolean;
  t: TFunction<"translations", undefined>;
}

const AttachUserTable = ({
  tableData,
  locationId,
  sidebarRef,
  haveBackground,
  isLoading,
  t,
}: IAttachUserTable) => {
  const companyId = useAppSelector(
    ({ company }) => company.selectedCompany?.value
  );
  const userRole = useAppSelector(({ user }) => user.user?.role);
  const userId = useAppSelector(({ user }) => user.user?.id ?? "");

  const [sorting, setSorting] = useState<SortingState>([
    { id: "user", desc: false },
  ]);
  const [selectedOption, setSelectedOption] = useState<ISelectOption | null>(
    null
  );
  const [selectInputValue, setSelectInputValue] = useState("");
  const [searchInCompany] = useState<boolean>(
    userRole !== UserRolesEnum.SUPER_ADMIN
  );

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [updateManagerNotifications] = useUpdateManagerByLocationIdMutation();
  const [attachUserToLocation] = useAttachManagerByLocationIdMutation();
  const [detachUser] = useDetachManagerByLocationIdMutation();
  const { data: foundData, isLoading: isSearchingUser } =
    useSearchManagerAutocompleteQuery(
      selectInputValue.length > 1 && !searchInCompany
        ? { value: selectInputValue, page: 0, size: 10 }
        : skipToken
    );

  const { data: foundInCompanyData, isLoading: isSearchingCompanyUser } =
    useGetCompanyManagersAutocompleteQuery(
      selectInputValue.length > 1 && searchInCompany && companyId
        ? {
            companyId: companyId,
            searchValue: selectInputValue,
            page: 0,
            size: 10,
          }
        : skipToken
    );

  const selectData = useMemo(() => {
    if (!searchInCompany && foundData)
      return createSelectUsersArray(foundData.content, tableData);

    if (searchInCompany && foundInCompanyData)
      return createSelectUsersArray(foundInCompanyData.content, tableData);

    return [];
  }, [foundData, foundInCompanyData, searchInCompany, tableData]);
  const { sidebarExtraSpace } = useWindowSize({ sidebarRef });
  const confirmScrollTop = useScrollTop(sidebarRef);
  const {
    closeRoleList,
    isRoleListOpen,
    openRoleList,
    userData,
    openerParams,
  } = useLocationRoleSelect(
    scrollContainerRef,
    sidebarExtraSpace,
    confirmScrollTop
  );

  const [selectedRoleOption, setSelectedRoleOption] =
    useState<ISelectOption | null>(locationRoles[1]);

  const onDetachUser = useCallback(
    ({ locationId, managerId, managerName }: IOnDetachUser) =>
      handleOnDetachUser({
        data: {
          locationId,
          managerId,
          managerName,
        },
        detachUser,
      }),
    [detachUser]
  );

  const onAttachUser = () => {
    if (!isEmpty(selectedOption?.value) && selectedOption)
      handleOnAttachUser({
        locationId,
        managerId: selectedOption?.value,
        attachUserToLocation,
        setSelectedOption,
        setSelectInputValue,
        role: selectedRoleOption?.value as LocationUserRoles,
      });
  };

  const onNotificationToggle = useCallback(
    (
      currentUserData: IAttachTableData,
      changedField?: ToggleNotificationField
    ) =>
      handleOnToggleNotification({
        updateManagerNotifications,
        userData: currentUserData,
        changedField,
      }),
    [updateManagerNotifications]
  );

  const table = useReactTable({
    data: tableData,
    columns: attachUserColumns({
      onDetachUser,
      onNotificationToggle,
      openRoleList,
      userRole,
      userId,
      t,
    }),
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnPinning: { left: ["user"] },
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    sortDescFirst: false,
  });

  const AttachUsersTableHeader: ReactNode[] = [
    <KypsSelect
      key="selectUser"
      placeholder={`${t("buttons.search.action")}...`}
      options={selectData}
      onSelect={(option) => handleOnSelect({ option, setSelectedOption })}
      selectedOption={selectedOption}
      onInputChange={(value) => onSelectValueChange(value, setSelectInputValue)}
      isLoading={isSearchingUser || isSearchingCompanyUser}
    />,
    <KypsSelect
      key="selectRole"
      placeholder="Choose..."
      options={locationRoles}
      onSelect={(option) =>
        handleOnSelect({ option, setSelectedOption: setSelectedRoleOption })
      }
      selectedOption={selectedRoleOption}
      size="small"
    />,
    <KypsButton
      key="Attach user"
      placeholder={t("buttons.attach.actionWithKey", {
        key: t("entities.user"),
      })}
      onClick={onAttachUser}
      size="large"
    />,
  ];

  if (isLoading) return <Loaders variant="table" />;

  return (
    <AttachTableWrapper>
      <KypsTable
        table={table}
        tableName={t("attachTables.attachUsers.tableName")}
        tableHeaderElements={[
          <AuthWrapper
            allowedRoles={managersRights}
            key="AttachUsersTableHeader"
          >
            {AttachUsersTableHeader}
          </AuthWrapper>,
        ]}
        isTransparentBg={!haveBackground}
        emptyMessage={hasEmptyMessage({
          data: tableData,
          message: t("emptyMessage.text", {
            entity: t("emptyMessage.entities.user"),
          }),
          iconName: "clipboard",
          isSmall: true,
        })}
        tableRef={scrollContainerRef}
        hidePagination
      />
      {isRoleListOpen && (
        <LocationRoleSelectList
          container={sidebarRef}
          handleOnSelect={(newRole: LocationUserRoles) =>
            handleOnToggleNotification({
              userData: { ...(userData as IAttachTableData), role: newRole },
              updateManagerNotifications,
            })
          }
          isOpen={isRoleListOpen}
          openerParams={openerParams}
          selectedValue={userData?.role ?? UserRolesEnum.USER}
          closeRoleSelectList={closeRoleList}
        />
      )}
    </AttachTableWrapper>
  );
};

export default AttachUserTable;
