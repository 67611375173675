import { ILocation } from "../../interfaces/locations";
import { ISensor } from "../../interfaces/sensors";
import {
  UpdateLocationFunction,
  UpdateSensorFunction,
  UpdateZoneFunction,
} from "../../interfaces/update-functions";
import { createNotification } from "../toaster/toaster.helper";

interface IHandleOnUpdateLocation {
  locationData: ILocation;
  updateLocation: UpdateLocationFunction;
}

interface IHandleOnUpdateSensor {
  sensorData: ISensor;
  updateSensor: UpdateSensorFunction;
}

interface IHandleOnUpdateZone {
  id: string;
  name: string;
  updateZone: UpdateZoneFunction;
}

export const handleOnUpdateZone = ({
  id,
  name,
  updateZone,
}: IHandleOnUpdateZone) =>
  updateZone({ id, zoneData: { name } })
    .unwrap()
    .then(({ name: apiName }) =>
      createNotification({
        message: `${apiName} was successfully updated!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const handleOnUpdateLocation = ({
  locationData,
  updateLocation,
}: IHandleOnUpdateLocation) => {
  const { id, address, comments, gatewayNumbers, name } = locationData;

  updateLocation({
    id,
    data: {
      name,
      address,
      comments,
      gatewayNumbers,
    },
  })
    .unwrap()
    .then(({ name: apiName }) =>
      createNotification({
        message: `${apiName} was successfully updated!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};

export const handleOnUpdateSensor = ({
  updateSensor,
  sensorData,
}: IHandleOnUpdateSensor) => {
  const {
    id,
    customConfig,
    sensorNumber,
    showHumidity,
    temperatureRange,
    sensorName,
  } = sensorData;
  updateSensor({
    sensorId: id,
    sensorData: {
      customConfig,
      sensorNumber,
      showHumidity,
      temperatureRange,
      sensorName,
    },
  })
    .unwrap()
    .then(({ sensorNumber: sensorNumberApi }) =>
      createNotification({
        message: `Sensor ${sensorNumberApi} was successfully updated!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};
