import { LandingIcons } from "../../../../interfaces/landing-icons";
import LandingIcon from "../landing-icon/landing-icon";
import {
  BenefitContent,
  BenefitDescription,
  BenefitHeading,
  BenefitIconWrapper,
  BenefitWrapper,
} from "./benefit-item.styles";

export interface IBenefitItem {
  title: string;
  description: string;
  iconName: LandingIcons;
}

const BenefitItem = ({ title, description, iconName }: IBenefitItem) => {
  return (
    <BenefitWrapper>
      <BenefitIconWrapper>
        <LandingIcon name={iconName} />
      </BenefitIconWrapper>
      <BenefitContent>
        <BenefitHeading>{title}</BenefitHeading>
        <BenefitDescription>{description}</BenefitDescription>
      </BenefitContent>
    </BenefitWrapper>
  );
};

export default BenefitItem;
