import { MutableRefObject, useEffect, useState } from "react";
import { Table, flexRender } from "@tanstack/react-table";
import {
  StyledTBody,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
  TableHeader,
  TableName,
  TableComponentWrapper,
  TdContent,
  ThContent,
  TableWrapper,
  TableWrapperWithHeader,
} from "./kyps-table.styles";
import clsx from "clsx";
import TablePagination from "../kyps-table-pagination/kyps-table-pagination";
import { isEmpty } from "lodash";
import {
  ReportTableHeading,
  ReportTableHeadingWrapper,
} from "./report-table/report-table.styles";
import KypsTooltip from "../kyps-tooltip/kyps-tooltip";
import { useTranslation } from "react-i18next";

export interface IKypsTable {
  table: Table<any>;
  tableName?: string;
  tableHeaderElements?: React.ReactNode[];
  hasNoActions?: boolean;
  className?: string;
  isAutoLayout?: boolean;
  isTransparentBg?: boolean;
  emptyMessage?: JSX.Element | null;
  hidePagination?: boolean;
  isTheadHidden?: boolean;
  rowSpannedCells?: string[];
  hasSmallRows?: boolean;
  isLoading?: boolean;
  resetCellPaddings?: boolean;
  reportTableName?: string;
  hideSorting?: boolean;
  loader?: JSX.Element;
  serverSideSorting?: boolean;
  reportTableButton?: JSX.Element;
  tableRef?: MutableRefObject<HTMLDivElement | null>;
}

const defaultCellSize = 150;

export const KypsTable = ({
  table,
  tableName,
  tableHeaderElements,
  hasNoActions,
  className,
  isTransparentBg,
  isAutoLayout,
  emptyMessage,
  hidePagination,
  isTheadHidden,
  rowSpannedCells,
  hasSmallRows,
  isLoading,
  resetCellPaddings,
  reportTableName,
  reportTableButton,
  loader,
  hideSorting,
  serverSideSorting,
  tableRef,
}: IKypsTable) => {
  const { t } = useTranslation();

  const pageIndex = table.getState().pagination.pageIndex;
  const pageCount = table.getPageCount();

  const [currentPage, setCurrentPage] = useState(pageIndex);

  const isPaginationVisible = pageCount > 1 && !hidePagination;

  const handlePageSetter = (pageNumber: number) => {
    table.setPageIndex(pageNumber);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (pageCount > 0) setCurrentPage(pageIndex);
  }, [pageCount, pageIndex, table]);

  return (
    <TableComponentWrapper
      className={clsx(isTransparentBg && "without-bg", className)}
    >
      {tableName && <TableName>{tableName}</TableName>}
      {tableHeaderElements?.length && (
        <TableHeader>{tableHeaderElements}</TableHeader>
      )}
      {isLoading ? (
        loader
      ) : (
        <TableWrapperWithHeader>
          {reportTableName && (
            <ReportTableHeadingWrapper>
              <ReportTableHeading>{reportTableName}</ReportTableHeading>
              {reportTableButton}
            </ReportTableHeadingWrapper>
          )}
          <TableWrapper ref={tableRef}>
            <StyledTable
              className={clsx(
                !hasNoActions && "has-actions",
                isAutoLayout && "auto-layout",
                className
              )}
            >
              <StyledThead className={clsx(isTheadHidden && "hidden")}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <StyledTr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const { column } = header;
                      const canColumnSort =
                        header.column.getCanSort() &&
                        !!header.column.columnDef.header;
                      const nextSortOrder =
                        header.column.getNextSortingOrder() as string;
                      const isColumnSorted = header.column.getIsSorted();

                      const sortClassnames = [
                        nextSortOrder === "asc" || serverSideSorting
                          ? ""
                          : nextSortOrder === "desc"
                          ? "up"
                          : "down",
                        nextSortOrder === "asc" && serverSideSorting
                          ? "down"
                          : "up",
                      ];

                      return (
                        <StyledTh
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            width:
                              header.getSize() !== defaultCellSize
                                ? header.getSize()
                                : undefined,
                          }}
                          className={clsx(
                            column.getIsPinned() && "pinned",
                            column.getIsPinned()
                          )}
                        >
                          <ThContent
                            className={clsx(
                              canColumnSort &&
                                !isColumnSorted &&
                                !hideSorting &&
                                "can-sort",
                              isColumnSorted && !hideSorting && "sortable",
                              resetCellPaddings && "zero-padding",
                              hasSmallRows && "small",
                              sortClassnames
                            )}
                            onClick={
                              hideSorting
                                ? undefined
                                : header.column.getToggleSortingHandler()
                            }
                          >
                            {!header.isPlaceholder &&
                              (!!header.column.columnDef.header &&
                              !hideSorting &&
                              canColumnSort ? (
                                <KypsTooltip
                                  tooltipValue={
                                    canColumnSort
                                      ? nextSortOrder === "asc"
                                        ? t("buttons.sort.asc")
                                        : nextSortOrder === "desc"
                                        ? t("buttons.sort.desc")
                                        : t("buttons.sort.reset")
                                      : ""
                                  }
                                  children={flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                />
                              ) : (
                                flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )
                              ))}
                          </ThContent>
                        </StyledTh>
                      );
                    })}
                  </StyledTr>
                ))}
              </StyledThead>

              <StyledTBody>
                {table.getRowModel().rows.map((row) => (
                  <StyledTr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      const isHumidityVisible =
                        table.getIsSomeRowsExpanded() && !!rowSpannedCells;
                      const { column } = cell;

                      if (isHumidityVisible) {
                        const parentRow = cell.row.getParentRow();
                        const hasRowSubRows =
                          !isEmpty(row.subRows) &&
                          rowSpannedCells?.includes(cell.column.id);

                        const isHidden =
                          rowSpannedCells?.includes(cell.column.id) &&
                          !!parentRow;

                        return (
                          <StyledTd
                            key={cell.id}
                            rowSpan={
                              hasRowSubRows && isHumidityVisible ? 2 : undefined
                            }
                            className={clsx(
                              isHidden && "hidden",
                              column.getIsPinned() && "pinned",
                              column.getIsPinned()
                            )}
                          >
                            <TdContent
                              className={clsx(
                                hasRowSubRows && "row-span",
                                className === "details-table" && "large",
                                hasSmallRows && "small",
                                resetCellPaddings && "zero-padding"
                              )}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TdContent>
                          </StyledTd>
                        );
                      }

                      return (
                        <StyledTd
                          key={cell.id}
                          className={clsx(
                            column.getIsPinned() && "pinned",
                            row.depth > 0 && "is-sub-row",
                            column.getIsPinned()
                          )}
                        >
                          <TdContent
                            className={clsx(
                              className === "details-table" && "large",
                              hasSmallRows && "small",
                              resetCellPaddings && "zero-padding"
                            )}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TdContent>
                        </StyledTd>
                      );
                    })}
                  </StyledTr>
                ))}
              </StyledTBody>
            </StyledTable>
          </TableWrapper>
        </TableWrapperWithHeader>
      )}
      {isPaginationVisible && (
        <TablePagination
          currentPage={currentPage}
          pageCount={pageCount}
          setCurrentPage={handlePageSetter}
        />
      )}
      {emptyMessage}
    </TableComponentWrapper>
  );
};
