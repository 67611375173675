import styled from "@emotion/styled";

export const StyledH2 = styled.h2`
  font-size: 28px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
  color: #111827;

  @media (min-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 992px) {
    font-size: 36px;
  }

  &.light {
    color: #fff;
  }
`;

export const StyledP = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #6b7280;

  &.light {
    color: #c7d2fe;
  }

  &:not(:last-of-type) {
    margin-bottom: 40px;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const SectionLabel = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #4f46e5;
  margin-bottom: 8px;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  &.light {
    color: #c7d2fe;
  }

  &.anchor {
    scroll-margin-top: 112px;

    @media (min-width: 992px) {
      scroll-margin-top: 152px;
    }

    @media (min-width: 1240px) {
      scroll-margin-top: 196px;
    }
  }
`;
