import clsx from "clsx";
import { StyledSelect } from "../kyps-select.styles";
import CustomMenu from "../select-components/custom-menu";
import { ISelectOption } from "../kyps-select";
import { Dispatch, SetStateAction, useRef, useEffect } from "react";
import { GroupBase } from "react-select";
import Select from "react-select/dist/declarations/src/Select";

interface ICompanySelect {
  options: ISelectOption[] | undefined;
  placeholder: string;
  isSelectOpen: boolean;
  selectedOption: ISelectOption | null;
  onSelect: (data: ISelectOption) => void;
  onAddClick: () => void;
  onEditClick: () => void;
  setIsSelectOpen: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
  className?: string;
}

const CompanySelect = ({
  options,
  placeholder,
  isSelectOpen,
  selectedOption,
  onSelect,
  onAddClick,
  onEditClick,
  setIsSelectOpen,
  isLoading,
  className,
}: ICompanySelect) => {
  const selectRef = useRef<Select<unknown, boolean, GroupBase<unknown>>>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleOnAction = (action: () => void) => {
    action();
  };

  const handleMenuClose = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setIsSelectOpen(false);
      selectRef.current?.blur();
    }, 10);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <StyledSelect
      ref={selectRef}
      className={clsx("large", className)}
      options={options}
      placeholder={placeholder}
      classNamePrefix="styled-select"
      components={{
        IndicatorSeparator: () => null,
        Menu: (menuProps) => (
          <CustomMenu
            {...menuProps}
            onAddClick={() => handleOnAction(onAddClick)}
            onEditClick={() => handleOnAction(onEditClick)}
          />
        ),
      }}
      value={selectedOption?.value && selectedOption}
      isLoading={isLoading}
      onChange={(option) => onSelect(option as ISelectOption)}
      isSearchable
      onInputChange={() => handleMenuClose()}
      onBlur={() => handleMenuClose()}
      onFocus={() => setIsSelectOpen(true)}
      onMenuClose={handleMenuClose}
      menuIsOpen={isSelectOpen}
      blurInputOnSelect
    />
  );
};

export default CompanySelect;
