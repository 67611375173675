import { useDeleteCompanyByIdMutation } from "../api/companies.api";
import { useDeleteLocationByIdMutation } from "../api/location.api";
import { useDeleteZoneMutation } from "../api/zone.api";
import { useDeleteIPointMutation } from "../api/installation-point.api";
import { useDeleteSensorMutation } from "../api/sensor.api";
import { useDetachManagerByLocationIdMutation } from "../api/location-manager.api";
import { IDeleteFunctions } from "../interfaces/delete-function";

const useDeleteFunctions = () => {
  const [deleteCompany] = useDeleteCompanyByIdMutation();
  const [deleteLocation] = useDeleteLocationByIdMutation();
  const [deleteZone] = useDeleteZoneMutation();
  const [deleteIPoint] = useDeleteIPointMutation();
  const [deleteSensor] = useDeleteSensorMutation();
  const [detachManager] = useDetachManagerByLocationIdMutation();

  const deleteFunctions: IDeleteFunctions = {
    company: deleteCompany,
    location: deleteLocation,
    zone: deleteZone,
    iPoint: deleteIPoint,
    sensor: deleteSensor,
    manager: detachManager,
  };

  return {
    deleteFunctions,
  };
};

export default useDeleteFunctions;
