import {
  ExpandedState,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import {
  IReportSensorData,
  handleSendLocationReport,
} from "./report-table.helper";
import { createReportTableColumns } from "./report-table.utils";
import { useCallback, useMemo, useState } from "react";
import useModal from "../../../hooks/useModal";
import KypsModal from "../../kyps-modal/kyps-modal";
import { UserRoles, UserRolesEnum } from "../../../interfaces/roles";
import { getUserRoles } from "../../../auth/get-user-roles.helper";
import { TFunction } from "i18next";
import { useLazySendManualLocationReportQuery } from "../../../api/reports.api";
import { KypsButton } from "../../kyps-button/kyps-button";
import AuthWrapper from "../../../auth/auth-wrapper";
import { managersRights } from "../../../interfaces/auth-wrapper";

export interface IReportTable {
  data: IReportSensorData[];
  locationName: string;
  userRole: UserRoles;
  id: string;
  date: string;
  t: TFunction<"translations", undefined>;
}

const sorting: SortingState = [{ id: "sensorId", desc: false }];

const ReportTable = ({
  date,
  data: locationReportData,
  locationName,
  userRole,
  id,
  t,
}: IReportTable) => {
  const getIsDisabledByRole = useCallback(() => {
    if (
      userRole === UserRolesEnum.SUPER_ADMIN ||
      userRole === UserRolesEnum.ADMIN
    )
      return false;

    if (getUserRoles({ url: id }) === UserRolesEnum.MANAGER) return false;

    return true;
  }, [id, userRole]);

  const [expanded, setExpanded] = useState<ExpandedState>(true);

  const [trigger] = useLazySendManualLocationReportQuery();

  const { openModal, isModalOpen, closeAndResetModal, currentModal } =
    useModal();

  const columns = useMemo(
    () =>
      createReportTableColumns({
        locationReportData,
        closeModal: closeAndResetModal,
        openModal,
        isDisabled: getIsDisabledByRole(),
        t,
      }),
    [closeAndResetModal, getIsDisabledByRole, locationReportData, openModal, t]
  );

  const table = useReactTable({
    data: locationReportData,
    columns,
    state: { expanded, sorting, columnPinning: { left: ["sensorId"] } },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    autoResetPageIndex: false,
  });

  return (
    <>
      <KypsTable
        table={table}
        hasNoActions
        hidePagination
        className="report"
        rowSpannedCells={["sensorId", "id", "battery"]}
        resetCellPaddings
        reportTableName={locationName}
        hideSorting
        reportTableButton={
          <AuthWrapper
            allowedRoles={managersRights}
            additionalCheck={!getIsDisabledByRole()}
          >
            <KypsButton
              onClick={() =>
                handleSendLocationReport({
                  date,
                  locationId: id,
                  locationName,
                  t,
                  trigger,
                })
              }
              placeholder={t("buttons.send.actionWithKey", {
                key: t("tables.dailyReport"),
              })}
              size="small"
              variant="active"
              iconType="email"
              hasIcon
            />
          </AuthWrapper>
        }
      />
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
    </>
  );
};

export default ReportTable;
