import React from "react";
import {
  DetailsContainer,
  ImageContainer,
  ImageDetailsWrapper,
} from "../../landing-body/notifications-section/notifications-section.styles";
import { StyledLandingImage } from "../../landing-body/image-section/image-section.styles";
import ImportantDescription from "../important-description/important-description";
import DetailDescription, {
  IDetailDescription,
} from "../detail-description/detail-description";
import clsx from "clsx";

export interface IImageDetails {
  imageSrc: string;
  imageAlt: string;
  title: string;
  description: string;
  detailsItems: IDetailDescription[];
  isReverse?: boolean;
  hasPadding?: boolean;
}

const ImageDetails = ({
  imageSrc,
  imageAlt,
  title,
  description,
  detailsItems,
  isReverse,
  hasPadding,
}: IImageDetails) => (
  <ImageDetailsWrapper className={clsx(isReverse && "reverse")}>
    <ImageContainer className={clsx(hasPadding && "has-padding")}>
      <StyledLandingImage
        src={imageSrc}
        alt={imageAlt}
        width={600}
        height={164}
      />
    </ImageContainer>
    <DetailsContainer>
      <ImportantDescription title={title} description={description} />
      {detailsItems.map(({ iconName, title, description }) => (
        <DetailDescription
          iconName={iconName}
          title={title}
          description={description}
          key={iconName}
        />
      ))}
    </DetailsContainer>
  </ImageDetailsWrapper>
);

export default ImageDetails;
