import { TFunction } from "i18next";
import { InputField } from "../../kyps-form/kyps-form-input";
import { IAddZoneFormFields } from "./add-zone-sidebar";

export const createAddZoneFields = (
  t: TFunction<"translations", undefined>
): InputField<IAddZoneFormFields>[] => [
  {
    label: t("forms.fieldNames.zoneName"),
    name: "name",
    type: "text",
    rules: {
      required: `${t("forms.messages.isRequiredWithKey", {
        labelName: t("forms.fieldNames.zoneName"),
      })}`,
      minLength: {
        value: 2,
        message: t("forms.messages.length", {
          fieldName: t("forms.fieldNames.zoneName"),
          length: 2,
        }),
      },
    },
    placeholder: `${t("forms.fieldNames.zoneName")}...`,
  },
];
