import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateForDisplay, updateDate } from "./kyps-date-picker.helper";
import { DatePickerWrapper, PickerArrow } from "./kyps-date-picker.styles";
import useDays, {
  RangesDaysOptions,
  RangesDaysOptionsEnum,
} from "../../hooks/useDays";
import DatepickerRangeButtons from "./range-buttons/range-buttons";
import clsx from "clsx";
import { isArray } from "lodash";
import { TFunction } from "i18next";
import { isToday } from "date-fns";

interface IKypsDatePicker {
  startDate: Date;
  setStartDate: Dispatch<SetStateAction<Date>>;
  t: TFunction<"translations", undefined>;
  hasRange?: boolean;
  endDate?: Date | null;
  minDate?: Date | null;
  setEndDate?: Dispatch<SetStateAction<Date | null>>;
}

const KypsDatePicker = ({
  startDate,
  setStartDate,
  t,
  hasRange,
  endDate,
  minDate,
  setEndDate,
}: IKypsDatePicker) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRange, setIsRange] = useState(false);
  const [startRangeDates, setStartRangeDates] = useState<Date | null>(null);
  const [endRangeDates, setEndRangeDates] = useState<Date | null>(null);
  const [rangeDays, setRangeDays] = useState<RangesDaysOptions>(
    RangesDaysOptionsEnum.TODAY
  );

  const { endDate: hookEndDate, startDate: hookStartDate } = useDays(rangeDays);

  const datePickerValue = formatDateForDisplay(startDate, t);
  const isNextDayDisabled = isToday(startDate) || isRange;

  const formattedDateRange = useMemo(() => {
    if (!isRange) return datePickerValue;

    return `${formatDateForDisplay(startDate, t)} - ${
      endDate ? formatDateForDisplay(endDate, t) : ""
    }`;
  }, [isRange, startDate, t, endDate, datePickerValue]);

  const getPreviousDay = () => {
    if (isRange) return;
    setStartDate(updateDate(startDate, -1));
  };

  const getNextDay = () => {
    if (isNextDayDisabled) return;
    setStartDate(updateDate(startDate, 1));
  };

  const onChange = (date: Date | [Date | null, Date | null] | null) => {
    if (isArray(date)) {
      const [start, end] = date;
      setStartRangeDates(start);

      if (isRange && end && start) {
        setEndDate?.(end);
        setStartDate(start);
        setIsOpen(false);
      }
      return;
    }

    if (date) {
      if (endDate) setEndDate?.(null);

      setStartDate(date);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isRange && hookEndDate && hookStartDate) {
      setStartDate(hookStartDate);
      setEndDate?.(hookEndDate);
      return;
    }

    if (hookStartDate) {
      setStartDate(hookStartDate);
      return;
    }
  }, [hookEndDate, hookStartDate, isRange, setEndDate, setStartDate]);

  const onInputHandler = () => {
    if (!isRange) {
      setIsOpen(true);
      return;
    }

    setEndRangeDates(null);
    setStartRangeDates(null);
    setIsOpen(true);
  };

  const onSingleDayHandler = (day: RangesDaysOptions) => {
    setRangeDays(day);
    setIsOpen(false);
    if (endDate) setEndDate?.(null);
    if (isRange) setIsRange(false);
  };

  const onPreselectedRangeHandler = (days: RangesDaysOptions) => {
    setRangeDays(days);
    if (!isRange) setIsRange(true);
    setIsOpen(false);
  };

  const onCustomRange = () => {
    if (isRange) return;
    setEndDate?.(null);
    setIsRange(true);
  };

  return (
    <DatePickerWrapper className={clsx(hasRange && "with-range")}>
      <DatePicker
        dateFormat="yyyy/MM/dd"
        selected={isRange ? undefined : startDate}
        onChange={onChange}
        onInputClick={onInputHandler}
        onClickOutside={() => setIsOpen(false)}
        maxDate={new Date()}
        minDate={minDate}
        value={formattedDateRange}
        open={isOpen}
        startDate={isRange ? startRangeDates : startDate}
        endDate={endRangeDates}
        selectsRange={isRange}
        id="datepicker"
      >
        {hasRange && (
          <DatepickerRangeButtons
            isRange={isRange}
            onCustomRange={onCustomRange}
            onPreselectedRangeHandler={onPreselectedRangeHandler}
            onSingleDayHandler={onSingleDayHandler}
            t={t}
          />
        )}
      </DatePicker>
      <PickerArrow
        className={clsx("back", isRange && "disabled")}
        onClick={getPreviousDay}
      />
      <PickerArrow
        className={clsx(isNextDayDisabled && "disabled")}
        onClick={getNextDay}
      />
    </DatePickerWrapper>
  );
};

export default KypsDatePicker;
