import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Select from "react-select";

export const StyledSelect = styled(Select)`
  ${({ theme: { palette, zIndex } }) => css`
    display: inline-block;
    min-width: 126px;

    @media (min-width: 768px) {
      min-width: 206px;
    }

    &:hover {
      .styled-select__indicator {
        color: ${palette.text.dark};
      }
    }

    &.large {
      width: 100%;
    }

    &.small {
      min-width: 126px;
    }

    * {
      font-weight: 400;
      font-size: 12px;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }

    .styled-select__value-container {
      padding: 0;
      margin: 0;
    }

    .styled-select__control {
      cursor: pointer;
      padding: 6px 9px;
      border: 1px solid ${palette.background.light};
      box-shadow: none;
      min-height: auto;

      :hover {
        border-color: ${palette.background.dark};
      }

      @media (min-width: 768px) {
        padding: 9px 13px;
      }
    }

    .styled-select__control--menu-is-open {
      border-color: ${palette.background.light};
      box-shadow: 0 0 0 1px ${palette.background.light};
    }

    .styled-select__indicator {
      margin-left: 8px;
      color: ${palette.text.default};
      padding: 0;
    }

    .styled-select__option {
      cursor: pointer;
      padding: 6px 9px;

      :active {
        background-color: ${palette.background.light};
      }

      @media (min-width: 768px) {
        padding: 9px 13px;
      }
    }

    .styled-select__option--is-focused {
      background-color: ${palette.background.light};
    }

    .styled-select__option--is-selected {
      background-color: ${palette.background.disable};
      color: inherit;
    }

    .styled-select__menu-list {
      padding: 0;

      &::-webkit-scrollbar-track {
        background-color: ${palette.background.default};
      }
    }

    .styled-select__single-value {
      padding-right: 4px;
      margin: 0;
    }

    .styled-select__menu {
      z-index: ${zIndex.select};
    }

    .styled-select__input-container {
      padding: 0;
      margin: 0;
    }

    [id*="react-select-"][id*="-listbox"] {
      margin: 0;
    }

    .styled-select__loading-indicator {
      span {
        height: 8px;
        width: 8px;
        margin: 0 1px;
      }
    }
  `}
`;
