import { LandingIcons } from "../../../../interfaces/landing-icons";
import { BenefitIconWrapper } from "../benefit-item/benefit-item.styles";
import LandingIcon from "../landing-icon/landing-icon";
import {
  IconsWrapper,
  StepContent,
  StepDescription,
  StepHeading,
  StepTextWrapper,
  StepWrapper,
} from "./step-item.styles";

export interface IStepItem {
  stepTitle: string;
  stepDescription: string;
  iconNames: LandingIcons[];
  number: number;
  variant?: "two-icons" | "reverse-arrows";
}

const StepItem = ({
  stepTitle,
  stepDescription,
  iconNames,
  number,
  variant,
}: IStepItem) => {
  return (
    <StepWrapper className={variant}>
      <BenefitIconWrapper>{number}</BenefitIconWrapper>
      <StepContent>
        <IconsWrapper className="mobile">
          {iconNames.map((i) => (
            <LandingIcon name={i} key={i} />
          ))}
        </IconsWrapper>
        <StepTextWrapper>
          <StepHeading>{stepTitle}</StepHeading>
          <StepDescription>{stepDescription}</StepDescription>
        </StepTextWrapper>
      </StepContent>
    </StepWrapper>
  );
};

export default StepItem;
