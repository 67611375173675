import { useRef, useState, useEffect, useMemo, useCallback } from "react";
import KypsSidebar from "../kyps-sidebar";
import { KypsButton } from "../../kyps-button/kyps-button";
import KypsForm from "../../kyps-form/kyps-form";
import {
  useAddSensorMutation,
  useDeleteSensorMutation,
  useGetSensorByIdQuery,
  useUpdateSensorMutation,
} from "../../../api/sensor.api";
import KypsSelect, { ISelectOption } from "../../kyps-select/kyps-select";
import {
  useGetIPointByIdQuery,
  useGetIPointsByZoneIdQuery,
} from "../../../api/installation-point.api";
import { isEmpty } from "lodash";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  fillEditSensorFields,
  handleOnEditSensor,
  handleOnRelocateSensor,
} from "./edit-sensor-sidebar.helper";
import RangeForm, { ICustomErrorData } from "../../kyps-range-form/range-form";
import CustomCheckbox from "../../kyps-custom-checkbox/custom-checkbox";
import { IAddRangeTemperatureFields } from "../add-installation-point-sidebar/add-installation-point-sidebar.utils";
import { rangeTemperatureCheck } from "../add-installation-point-sidebar/add-installation-point-sidebar.helper";
import { SensorConfigsWrapper } from "../../kyps-custom-checkbox/custom-checkbox.styles";
import { IAddSensorFormFields } from "../add-sensor-sidebar/add-sensor-sidebar";
import {
  createIPointSelectOptions,
  fillRangeFields,
} from "../add-sensor-sidebar/add-sensor-sidebar.helper";
import { UncontrolledFieldsHolder } from "../kyps-sidebar.styles";
import Loaders from "../../loaders/loaders";
import { TFunction } from "i18next";
import { ImperativeHandleRef } from "../../kyps-range-form/range-form.utils";
import AuthWrapper from "../../../auth/auth-wrapper";
import { superAdminRights } from "../../../interfaces/auth-wrapper";
import SensorStateSelect from "../../kyps-select/sensor-state-select/sensor-state-select";
import { SensorStateVariantEnum } from "../../../interfaces/sensors";

export interface IEditSensorSidebar {
  sensorId: string;
  zonesOptions: ISelectOption[];
  defaultZone: ISelectOption;
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
  defaultIPoint?: ISelectOption;
  isSecondSidebar?: boolean;
}

const EditSensorSidebar = ({
  sensorId,
  zonesOptions,
  defaultZone,
  closeModal,
  t,
  defaultIPoint,
  isSecondSidebar,
}: IEditSensorSidebar): JSX.Element => {
  const [formData, setFormData] = useState({
    nameForm: {},
    rangeForm: {},
  });
  const [customRangeErrors, setCustomRangeErrors] = useState<
    ICustomErrorData[]
  >([]);
  const [customIPointError, setCustomIPointError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedZoneOption, setSelectedZoneOption] =
    useState<ISelectOption | null>(null);
  const [selectedIPointOption, setSelectedIPointOption] =
    useState<ISelectOption | null>(null);
  const [isCustomConfig, setIsCustomConfig] = useState(false);
  const [showHumidity, setShowHumidity] = useState(false);
  const [shouldRelocate, setShouldRelocate] = useState<boolean>(false);

  const [addSensor] = useAddSensorMutation();
  const [deleteSensor] = useDeleteSensorMutation();
  const [updateSensor] = useUpdateSensorMutation();
  const { data: apiIPointsByZoneId } = useGetIPointsByZoneIdQuery(
    selectedZoneOption?.value ? selectedZoneOption.value : skipToken
  );

  const { data: apiSensorData, isLoading } = useGetSensorByIdQuery(
    isEmpty(sensorId) ? skipToken : sensorId
  );
  const { data: apiIPointInAllZoneData } = useGetIPointByIdQuery(
    selectedIPointOption?.value.length ? selectedIPointOption.value : skipToken
  );
  const { data: apiZoneIPoints } = useGetIPointsByZoneIdQuery(
    selectedZoneOption?.value ?? skipToken
  );

  const zoneOptions: ISelectOption[] = useMemo(
    () =>
      apiZoneIPoints?.map(({ id, name }) => ({ label: name, value: id })) ?? [],
    [apiZoneIPoints]
  );

  const onEditSensor = useCallback(() => {
    if (isSubmitting) return;

    if (shouldRelocate && selectedIPointOption)
      return handleOnRelocateSensor({
        addRelocatedSensor: addSensor,
        formData,
        installationPointId: selectedIPointOption.value,
        sensorId,
        isCustomConfig,
        showHumidity,
        setIsSubmitting,
        deleteSensor,
      });

    if (selectedIPointOption?.value)
      handleOnEditSensor({
        updateSensor,
        sensorId,
        formData,
        isCustomConfig,
        setIsSubmitting,
        showHumidity,
      });
  }, [
    isSubmitting,
    shouldRelocate,
    selectedIPointOption,
    addSensor,
    formData,
    sensorId,
    isCustomConfig,
    showHumidity,
    deleteSensor,
    updateSensor,
  ]);

  useEffect(() => {
    if (!isEmpty(formData.nameForm) && !isEmpty(formData.rangeForm)) {
      closeModal();
      onEditSensor();
      setFormData({ nameForm: {}, rangeForm: {} });
    }
  }, [closeModal, formData, onEditSensor]);

  const sensorFormRef = useRef<HTMLFormElement>(null);
  const rangeFormRef = useRef<ImperativeHandleRef>(null);

  const handleEditSensorFormSubmit = () => {
    sensorFormRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );

    rangeFormRef.current?.submit();
    if (!rangeFormRef.current) return setCustomIPointError(true);
  };

  const iPoints = useMemo(() => {
    if (defaultIPoint)
      return !isEmpty(zoneOptions) ? zoneOptions : [defaultIPoint];
    setSelectedIPointOption({ label: "", value: "" });

    if (apiIPointsByZoneId)
      return createIPointSelectOptions(apiIPointsByZoneId);

    return [];
  }, [apiIPointsByZoneId, defaultIPoint, zoneOptions]);

  useEffect(() => {
    setSelectedZoneOption(defaultZone);
    if (defaultIPoint) setSelectedIPointOption(defaultIPoint);

    if (apiSensorData?.customConfig)
      setIsCustomConfig(apiSensorData.customConfig);

    if (apiSensorData?.showHumidity)
      setShowHumidity(apiSensorData.showHumidity);
  }, [
    apiSensorData?.customConfig,
    apiSensorData?.showHumidity,
    defaultIPoint,
    defaultZone,
  ]);

  const handleOnZoneSelect = (option: ISelectOption | null) => {
    if (option) setSelectedZoneOption(option);
  };

  const handleOnIPointSelect = (option: ISelectOption | null) => {
    if (option) {
      setSelectedIPointOption(option);
      setCustomIPointError(false);
      setIsCustomConfig(false);
    }
  };

  const iPointInAllZoneData = useMemo(() => {
    if (apiIPointInAllZoneData) return apiIPointInAllZoneData;

    return;
  }, [apiIPointInAllZoneData]);

  const editRangeFields = useMemo(() => {
    if (apiSensorData?.customConfig && !shouldRelocate)
      return fillRangeFields({
        range: apiSensorData.temperatureRange,
        t,
      });

    if (iPointInAllZoneData)
      return fillRangeFields({
        range: iPointInAllZoneData.temperatureRange,
        t,
      });

    return [];
  }, [
    apiSensorData?.customConfig,
    apiSensorData?.temperatureRange,
    iPointInAllZoneData,
    shouldRelocate,
    t,
  ]);

  const editSensorFields = useMemo(() => {
    if (apiSensorData) return fillEditSensorFields({ apiSensorData, t });

    return [];
  }, [apiSensorData, t]);

  const onSubmitEditSensorForm = (data: IAddSensorFormFields) => {
    if (data) setFormData((prev) => ({ ...prev, nameForm: data }));
  };

  const onSubmitRangeTemperature = (data: IAddRangeTemperatureFields) => {
    if (data)
      rangeTemperatureCheck({
        data,
        setCustomErrors: setCustomRangeErrors,
        setFormData,
        t,
      });
  };

  const sidebarContent = [
    <KypsForm
      fields={editSensorFields}
      onSubmit={onSubmitEditSensorForm}
      ref={sensorFormRef}
      uncontrolledFields={
        <UncontrolledFieldsHolder>
          <KypsSelect
            key="selectZone"
            placeholder={`${t("buttons.select.action")}...`}
            options={zonesOptions}
            size="large"
            onSelect={handleOnZoneSelect}
            selectedOption={selectedZoneOption}
            label={t("tables.zone")}
            isDisable
          />
          <KypsSelect
            key="selectIPoint"
            placeholder={`${t("buttons.select.action")}...`}
            options={iPoints}
            size="large"
            onSelect={handleOnIPointSelect}
            selectedOption={selectedIPointOption}
            label={t("tables.iPoint")}
            isError={customIPointError}
            isDisable={!shouldRelocate}
          />
        </UncontrolledFieldsHolder>
      }
      description={
        <SensorStateSelect
          sensorId={sensorId}
          sensorState={apiSensorData?.state ?? SensorStateVariantEnum.UNKNOWN}
        />
      }
      key="EditSensorForm"
    />,
    <RangeForm
      key="RangeForm"
      fields={editRangeFields}
      onSubmit={onSubmitRangeTemperature}
      ref={rangeFormRef}
      checkboxes={
        <SensorConfigsWrapper>
          <CustomCheckbox
            isChecked={showHumidity}
            onCheckboxClick={() => setShowHumidity(!showHumidity)}
            checkboxHeading={t("checkboxes.showHumidity")}
            key="Show humidity"
          />
          <CustomCheckbox
            isChecked={isCustomConfig}
            onCheckboxClick={() => {
              setIsCustomConfig(!isCustomConfig);
              rangeFormRef.current?.reset();
            }}
            checkboxHeading={t("checkboxes.setupCustomSettings")}
            key="Setup Custom Settings"
          />
        </SensorConfigsWrapper>
      }
      customErrorsData={customRangeErrors}
      showEmptyMessage={isEmpty(editRangeFields)}
      isDisabled={!isCustomConfig}
    />,
  ];

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: t("buttons.edit.actionWithKey", {
          key: apiSensorData?.sensorNumber ?? t("entities.sensor"),
        }),
        buttons: [
          <AuthWrapper
            key="CustomCheckbox_Relocate"
            allowedRoles={superAdminRights}
            children={
              <CustomCheckbox
                checkboxHeading={t("buttons.relocate.actionWithKey", {
                  key: t("tables.sensor"),
                })}
                isChecked={shouldRelocate}
                onCheckboxClick={() => {
                  setShouldRelocate(!shouldRelocate);
                  setIsCustomConfig(!!apiSensorData?.customConfig);

                  if (defaultIPoint?.value !== selectedIPointOption?.value)
                    setSelectedIPointOption(defaultIPoint || null);
                }}
              />
            }
          />,
          <KypsButton
            key="Cancel"
            placeholder={t(
              isSecondSidebar ? "buttons.back.action" : "buttons.cancel.action"
            )}
            onClick={closeModal}
            variant="light"
          />,
          <KypsButton
            key="Edit sensor"
            placeholder={t("buttons.edit.actionWithKey", {
              key: t("entities.sensor"),
            })}
            onClick={handleEditSensorFormSubmit}
          />,
        ],
      }}
      content={
        isLoading
          ? [<Loaders key="Loader" variant="sidebar" />]
          : sidebarContent
      }
      subName={isSecondSidebar ? defaultIPoint?.label : undefined}
    />
  );
};

export default EditSensorSidebar;
