import { PropsWithChildren, useMemo } from "react";
import { UserRoles } from "../interfaces/roles";
import { useAppSelector } from "../hooks/useAppSelector";
import keycloak from "../keycloak";

interface IAuthWrapper {
  allowedRoles: UserRoles[];
  additionalCheck?: boolean;
}

const AuthWrapper = ({
  children,
  allowedRoles,
  additionalCheck = true,
}: PropsWithChildren<IAuthWrapper>) => {
  const currentRole = useAppSelector(({ user }) => user.user?.role);
  const authStatus = !!keycloak.authenticated;

  const isContentAllowedByRole = useMemo(() => {
    if (currentRole) {
      return allowedRoles.includes(currentRole);
    }

    return false;
  }, [allowedRoles, currentRole]);

  if (authStatus && isContentAllowedByRole && additionalCheck)
    return <>{children}</>;

  return null;
};

export default AuthWrapper;
