import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const MenuUserBlockWrapper = styled.div`
  ${({ theme }) => css`
    margin: 24px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    padding-bottom: 24px;
    border-bottom: 2px solid ${theme.palette.background.secondary};
  `}
`;

export const MenuUserDataContainer = styled.div`
  max-width: 100%;
`;

export const MenuLanguageSwitcher = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const UserActionButtonWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 24px;
    border-bottom: 2px solid ${theme.palette.background.secondary};
    margin: 12px 0;
    width: 100%;
    padding: 12px 0;
  `}
`;
