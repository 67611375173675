import { LContainer, SectionContainer } from "../../landing-content.styles";
import SectionDescription from "../../components/section-description/section-description";
import {
  BenefitsDescriptionContainer,
  BenefitsItemsContainer,
} from "./benefits-section.styles";
import BenefitItem from "../../components/benefit-item/benefit-item";
import { SectionSummary } from "../../components/benefit-item/benefit-item.styles";
import { TFunction } from "i18next";
import { createTranslatedBenefitsData } from "./benefits-section.utils";
import { useMemo } from "react";

const BenefitsSection = ({ t }: { t: TFunction }) => {
  const renderBenefitItems = useMemo(
    () =>
      createTranslatedBenefitsData(t).map(
        ({ title, description, iconName }) => (
          <BenefitItem
            title={title}
            description={description}
            iconName={iconName}
            key={title}
          />
        )
      ),
    [t]
  );

  return (
    <LContainer>
      <SectionContainer>
        <BenefitsDescriptionContainer>
          <SectionDescription
            label={t("landing.benefits.label")}
            heading={t("landing.benefits.heading")}
            description={[t("landing.benefits.text")]}
            anchor="benefits"
          />
        </BenefitsDescriptionContainer>
        <BenefitsItemsContainer>{renderBenefitItems}</BenefitsItemsContainer>
        <SectionSummary>
          {t("landing.benefits.summary.regular1")}
          <span>{t("landing.benefits.summary.important1")}</span>
          {t("landing.benefits.summary.regular2")}
          <span>{t("landing.benefits.summary.important2")}</span>
          {t("landing.benefits.summary.regular3")}
        </SectionSummary>
      </SectionContainer>
    </LContainer>
  );
};

export default BenefitsSection;
