import { ITemperatureRange } from "../../../interfaces/dashboard";
import { IAddIPointFormFields } from "../add-installation-point-sidebar/add-installation-point-sidebar";
import {
  IAddRangeTemperatureFields,
  createAddIPointFields,
  createRangeTemperatureFields,
} from "../add-installation-point-sidebar/add-installation-point-sidebar.utils";
import { SetStateAction } from "react";
import { useUpdateIPointMutation } from "../../../api/installation-point.api";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { IZone } from "../../../interfaces/zones";
import { TFunction } from "i18next";
import { createNotification } from "../../toaster/toaster.helper";

interface IFillEditFields {
  IPointRange: ITemperatureRange;
  t: TFunction<"translations", undefined>;
}

type UpdateIPointResult = ReturnType<typeof useUpdateIPointMutation>;
type UpdateIPointFunction = UpdateIPointResult[0];

interface IUpdateIPoint {
  iPointId: string;
  formData: {
    nameForm: {};
    rangeForm: {};
  };
  setIsSubmitting: (value: SetStateAction<boolean>) => void;
  updateIPoint: UpdateIPointFunction;
}

export const fillEditIPointFields = (
  iPointData: any,
  t: TFunction<"translations", undefined>
) =>
  createAddIPointFields(t).map((field) => {
    const value = iPointData[field.name as keyof IAddIPointFormFields] ?? "";
    return { ...field, value };
  });

export const fillEditFields = ({ IPointRange, t }: IFillEditFields) =>
  createRangeTemperatureFields(t).map((field) => {
    const { highGreen, highYellow, lowGreen, lowYellow } = IPointRange;
    let value = 0;
    switch (field.name) {
      case "lowAttention":
        value = lowYellow;
        break;
      case "lowNormal":
        value = lowGreen;
        break;
      case "highNormal":
        value = highGreen;
        break;
      case "highAttention":
        value = highYellow;
        break;
      default:
        break;
    }

    return { ...field, value };
  });

export const handleOnUpdateIPoint = ({
  iPointId,
  formData: { nameForm, rangeForm },
  setIsSubmitting,
  updateIPoint,
}: IUpdateIPoint) => {
  const { name } = nameForm as IAddIPointFormFields;
  const { lowNormal, highNormal, lowAttention, highAttention } =
    rangeForm as IAddRangeTemperatureFields;
  setIsSubmitting(true);

  updateIPoint({
    iPointData: {
      name,
      temperatureRange: {
        lowYellow: +lowAttention,
        lowGreen: +lowNormal,
        highGreen: +highNormal,
        highYellow: +highAttention,
      },
    },
    iPointId,
  })
    .unwrap()
    .then(({ name }) =>
      createNotification({
        message: `Installation Point ${name} was successfully updated!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};

export const createSelectOptionsFromZone = ({
  name,
  id,
}: IZone): ISelectOption[] => [{ label: name, value: id }];
