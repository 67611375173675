import { Link, useNavigate } from "react-router-dom";
import { logo } from "../../../assets";
import { Logo } from "../../../components/kyps-header/kyps-header.styles";
import LandingNav from "../../../components/kyps-nav/landing-nav";
import { KypsButton } from "../../../components/kyps-button/kyps-button";
import { LContainer } from "../landing-content.styles";
import {
  HeaderActions,
  LHeader,
  LHeaderContainer,
  LandingButtonWrapper,
  LandingSwitcherWrapper,
} from "./landing-header.styles";
import { useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import HeaderMobileMenu from "../components/header-mobile-menu/header-mobile-menu";
import LanguageSwitcher from "../../../components/language-switcher/language-switcher";
import { TFunction } from "i18next";

const LandingHeader = ({ t }: { t: TFunction }) => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleOnMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > 24 && isScrolled) {
      return;
    }

    if (scrollPosition > 24) {
      setIsScrolled(true);

      return;
    }

    setIsScrolled(false);
  }, [isScrolled]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <LHeader className={clsx(isScrolled && "has-shadow")}>
      <LContainer>
        <LHeaderContainer>
          <Link to="/">
            <Logo
              src={logo}
              alt="KYPS Logo"
              aria-label="KYPS Logo"
              width={106}
              height={32}
            />
          </Link>
          <LandingNav t={t} />
          <LandingSwitcherWrapper>
            <LanguageSwitcher />
          </LandingSwitcherWrapper>
          <HeaderActions>
            <LandingButtonWrapper
              href="/#contact-us"
              onClick={handleCloseMenu}
              tabIndex={-1}
            >
              <KypsButton
                placeholder={t("landing.header.buttons.contactUs")}
                onClick={() => {}}
                isLargerFont
              />
            </LandingButtonWrapper>
            <KypsButton
              placeholder={t("landing.header.buttons.logInToAccount")}
              onClick={() => {
                navigate("/dashboard");
                handleCloseMenu();
              }}
              variant="active"
              isLargerFont
            />
          </HeaderActions>
          <HeaderMobileMenu
            isMenuOpen={isMenuOpen}
            handleOnMenuOpen={handleOnMenuOpen}
            handleCloseMenu={handleCloseMenu}
            t={t}
          />
        </LHeaderContainer>
      </LContainer>
    </LHeader>
  );
};

export default LandingHeader;
