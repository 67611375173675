import styled from "@emotion/styled";

export const AttachTableWrapper = styled.div`
  margin-top: 24px;
`;

export const AttachTableCheckbox = styled.input`
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    cursor: auto;
  }
`;
