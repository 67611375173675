import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRoles, UserRolesEnum } from "../../interfaces/roles";

export interface IUser {
  role: UserRoles;
  token?: string;
  name?: string;
  email?: string;
  id?: string;
}

interface UserState {
  user: IUser | null;
}

const initialState: UserState = {
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, { payload }: PayloadAction<IUser | null>) => {
      return {
        ...state,
        user: payload,
      };
    },
    changeRole: (state, { payload }: PayloadAction<UserRolesEnum>) => {
      return {
        ...state,
        user: {
          ...state.user,
          role: payload,
        },
      };
    },
  },
});

export const { login, changeRole } = userSlice.actions;
export default userSlice.reducer;
