import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ModalOverlay = styled.div`
  ${({ theme: { palette, zIndex } }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${palette.text.default}be;
    opacity: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.visible {
      opacity: 1;
      pointer-events: visible;
      z-index: ${zIndex.modal};
      transition: opacity 0.3s ease;
    }
  `}
`;
