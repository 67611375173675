import { t } from "i18next";
import { DetachManagerFromCompanyFunction } from "../../../interfaces/delete-function";
import { ICompanyAdminState, IManagerDTO } from "../../../interfaces/managers";
import {
  AttachManagerToCompanyFunction,
  ToggleIsCompanyAdminFunction,
} from "../../../interfaces/update-functions";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { createNotification } from "../../toaster/toaster.helper";

interface ICheckIsAdmin {
  currentCompanyId: string;
  companies: ICompanyAdminState[];
}

interface IHandleOnDetachManager {
  managerName: string;
  managerId: string;
  companyId: string;
  detachManager: DetachManagerFromCompanyFunction;
}

interface IHandleOnAttachManager {
  admin: boolean;
  companyId: string;
  managerId: string;
  attachManagerToCompany: AttachManagerToCompanyFunction;
}

interface IHandleOnToggleAdmin {
  companyId: string;
  isAdmin: boolean;
  managerId: string;
  toggleIsAdmin: ToggleIsCompanyAdminFunction;
}

export const handleOnToggleAdmin = ({
  companyId,
  isAdmin,
  managerId,
  toggleIsAdmin,
}: IHandleOnToggleAdmin) =>
  toggleIsAdmin({ companyId, admin: isAdmin, managerId })
    .unwrap()
    .then(({ manager: { name } }) =>
      createNotification({
        message: isAdmin
          ? t("notification.setAdminRole", { userName: name })
          : t("notification.unsetAdminRole", { userName: name }),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const checkIsAdmin = ({ companies, currentCompanyId }: ICheckIsAdmin) =>
  companies.filter(({ companyId }) => companyId === currentCompanyId)[0].admin;

export const handleOnDetachManager = ({
  managerId,
  managerName,
  companyId,
  detachManager,
}: IHandleOnDetachManager) =>
  detachManager({
    companyId,
    managerId,
  })
    .unwrap()
    .then(() =>
      createNotification({
        message: `Manager ${managerName} was successfully detached!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const handleOnAttachManager = ({
  admin,
  companyId,
  managerId,
  attachManagerToCompany,
}: IHandleOnAttachManager) =>
  attachManagerToCompany({
    admin,
    companyId,
    managerId,
  })
    .unwrap()
    .then(({ manager: { name } }) =>
      createNotification({
        message: `Manager ${name} was successfully attached!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const createSelectCompanyUsersArray = (
  apiUsers: IManagerDTO[],
  currentUsers: IManagerDTO[]
): ISelectOption[] =>
  apiUsers.reduce((acc: ISelectOption[], apiUser) => {
    const isCurrentUser = currentUsers.some(
      (currentUser) => currentUser.id === apiUser.id
    );

    if (!isCurrentUser) acc.push({ label: apiUser.name, value: apiUser.id });

    return acc;
  }, []);
