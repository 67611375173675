import ContentLoader from "react-content-loader";
import {
  LoaderVariant,
  loaderFunctions,
  loaderHeights,
} from "../../interfaces/loaders";

interface ILoaders {
  variant: LoaderVariant;
}

const Loaders = ({ variant }: ILoaders) => {
  const height = loaderHeights[variant] || 400;
  const createLoader = loaderFunctions[variant]();

  return (
    <ContentLoader
      speed={1.4}
      width="100%"
      height={height}
      backgroundColor="#e9ebee"
      foregroundColor="#d1d5db"
    >
      {createLoader}
    </ContentLoader>
  );
};

export default Loaders;
