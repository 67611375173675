import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const RangeInput = styled.input`
  ${({ theme }) => css`
    display: block;
    background-color: ${theme.palette.background.default};
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.background.disable};
    border-radius: 6px;
    font-weight: 400;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    outline: none;
    height: 32px;
    padding: 6px 6px;
    line-height: 18px;
    font-size: 12px;

    @media (min-width: 768px) {
      padding: 9px 13px;
      line-height: 20px;
      font-size: 14px;
      height: auto;
    }
  `}
`;
