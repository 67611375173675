import { TFunction } from "i18next";
import { StyledBurger } from "./burger.styles";
import clsx from "clsx";

interface IBurger {
  isMenuOpen: boolean;
  t: TFunction<"translations", undefined>;
}

const Burger = ({ isMenuOpen, t }: IBurger) => (
  <StyledBurger
    className={clsx(isMenuOpen && "isOpen")}
    aria-label={
      isMenuOpen
        ? t("buttons.close.actionWithKey", { key: t("tables.menu") })
        : t("buttons.open.actionWithKey", { key: t("tables.menu") })
    }
  >
    <div />
    <div />
    <div />
  </StyledBurger>
);

export default Burger;
