import { useState, useMemo, useRef } from "react";
import {
  ExpandedState,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import { transformTreeTableData } from "./tree-table.helper";
import KypsModal from "../../kyps-modal/kyps-modal";
import useModal from "../../../hooks/useModal";
import { useGetCompleteStatsByCompanyIdQuery } from "../../../api/dashboard.api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { createTreeTableColumns } from "./tree-table.utils";
import useDeleteFunctions from "../../../hooks/useDeleteFunctions";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Loaders from "../../loaders/loaders";
import { UserRolesEnum } from "../../../interfaces/roles";
import { TFunction } from "i18next";
import useDropdownMenu from "../../../hooks/useDropdownMenu";
import DeleteConfirm from "../../delete-confirm/delete-confirm";
import useDeleteConfirm from "../../../hooks/useDeleteConfirm";
import DropdownMenu from "../../dropdown-menu/dropdown-menu";

interface ITreeTable {
  companyId: string | null;
  t: TFunction<"translations", undefined>;
}

const TreeTable = ({ companyId, t }: ITreeTable) => {
  const userRole = useAppSelector(({ user }) => user.user?.role);
  const [expanded, setExpanded] = useState<ExpandedState>(true);
  const [sorting] = useState<SortingState>([{ id: "name", desc: false }]);
  const [selectedId, setSelectedId] = useState<string>("");
  const tableRef = useRef<HTMLDivElement | null>(null);

  const {
    dropdownMenuPosition,
    isDropdownMenuOpen,
    menuItems,
    openDropdownMenu,
    setIsDropdownMenuOpen,
  } = useDropdownMenu(tableRef);
  const {
    closeDeleteConfirm,
    deleteConfirmName,
    deleteFunction,
    isDeleteConfirmOpen,
    openDeleteConfirm,
    openerParams,
  } = useDeleteConfirm(tableRef);
  const { closeAndResetModal, currentModal, isModalOpen, openModal } =
    useModal();

  const { deleteFunctions } = useDeleteFunctions();

  const { data: apiCompanyData, isLoading } =
    useGetCompleteStatsByCompanyIdQuery(companyId ?? skipToken);

  const treeTableData = useMemo(() => {
    if (apiCompanyData) return transformTreeTableData(apiCompanyData);

    return [];
  }, [apiCompanyData]);

  const columns = useMemo(
    () =>
      createTreeTableColumns({
        closeAndResetModal,
        deleteFunctions,
        openModal,
        selectedId,
        setSelectedId,
        userRole: userRole ?? UserRolesEnum.USER,
        openDropdownMenu,
        openDeleteConfirm,
        isMenuOpen: isDropdownMenuOpen,
        t,
      }),
    [
      closeAndResetModal,
      deleteFunctions,
      isDropdownMenuOpen,
      openDeleteConfirm,
      openDropdownMenu,
      openModal,
      selectedId,
      t,
      userRole,
    ]
  );

  const table = useReactTable({
    columns,
    data: treeTableData,
    autoResetPageIndex: false,
    state: { expanded, sorting, columnPinning: { right: ["actions"] } },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
  });

  const renderContent = () => (
    <>
      <KypsTable table={table} hidePagination hideSorting />
      <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
      {isDeleteConfirmOpen && (
        <DeleteConfirm
          closeDeleteConfirm={closeDeleteConfirm}
          isDeleteConfirmOpen={isDeleteConfirmOpen}
          openerParams={openerParams}
          deleteConfirmName={deleteConfirmName}
          deleteFunction={deleteFunction}
        />
      )}
      {isDropdownMenuOpen && (
        <DropdownMenu
          menuItems={menuItems}
          position={dropdownMenuPosition}
          isOpen={isDropdownMenuOpen}
          setIsDropdownMenuOpen={setIsDropdownMenuOpen}
        />
      )}
    </>
  );

  return isLoading ? <Loaders variant="table" /> : renderContent();
};

export default TreeTable;
