import { PagesPathsEnum } from "../utils/pages-paths";

export const getPathString = (pathName: string): string => {
  if (pathName.includes(PagesPathsEnum.LOCATION)) return "/dashboard/location";

  if (pathName.includes(PagesPathsEnum.DASHBOARD)) return "/dashboard/company";

  if (pathName.includes(PagesPathsEnum.REPORTS)) return "/reports";

  if (pathName.includes(PagesPathsEnum.MANAGEMENT)) return "/management";

  return pathName;
};
