import { TFunction } from "i18next";
import {
  SensorStateVariant,
  SensorStateVariantEnum,
} from "../../../interfaces/sensors";

interface IGetStatsName {
  variant: SensorStateVariant;
  t: TFunction<"translations", undefined>;
}

export const getStatsName = ({ variant, t }: IGetStatsName) => {
  switch (variant) {
    case SensorStateVariantEnum.ONLINE:
    case SensorStateVariantEnum.GREEN:
      return t("statsCards.sidebarNames", { state: t("statsCards.online") });
    case SensorStateVariantEnum.ORANGE:
      return t("statsCards.sidebarNames", { state: t("statsCards.attention") });
    case SensorStateVariantEnum.RED:
      return t("statsCards.sidebarNames", { state: t("statsCards.warning") });
    case SensorStateVariantEnum.OFF:
      return t("statsCards.sidebarNames", { state: t("statsCards.offline") });
    default:
      return t("statsCards.sidebarNames", { state: t("statsCards.offline") });
  }
};
