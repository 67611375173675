import clsx from "clsx";
import {
  SectionLabel,
  StyledH2,
  StyledP,
} from "../../components/section-description/section-description.styles";

interface ISectionDescription {
  label: string;
  heading: string;
  description: string[];
  anchor?: string;
  variant?: "light" | "dark";
}

const SectionDescription = ({
  label,
  heading,
  description,
  variant = "dark",
  anchor,
}: ISectionDescription) => {
  return (
    <>
      <SectionLabel
        className={clsx(variant, anchor && "anchor")}
        id={anchor && anchor}
      >
        {label}
      </SectionLabel>
      <StyledH2 className={variant}>{heading}</StyledH2>
      {description.map((d, i) => (
        <StyledP className={variant} key={i}>
          {d}
        </StyledP>
      ))}
    </>
  );
};

export default SectionDescription;
