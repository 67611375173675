import { createColumnHelper } from "@tanstack/react-table";
import IconButton from "../../icon-button/icon-button";
import { IAttachTableData } from "../../../interfaces/tables";
import { AttachTableCheckbox } from "./attach-user-table.styles";
import clsx from "clsx";
import { UserRoles, UserRolesEnum } from "../../../interfaces/roles";
import Icon from "../../icon/icon";
import LocationRoleSelect from "../../kyps-select/location-role-select/location-role-select";
import { TFunction } from "i18next";
import { getColumnWidth } from "../kyps-table.utils";
import TooltipCell from "../../kyps-tooltip/tooltip-cell/tooltip-cell";
import { ToggleNotificationField } from "./attach-user-table.helper";
import { IOpenRoleListProps } from "../../../hooks/useLocationRoleSelect";

export interface IOnDetachUser {
  managerId: string;
  locationId: string;
  managerName: string;
}

interface IAttachUserColumns {
  userId: string;
  userRole?: UserRoles;
  onNotificationToggle: (
    userData: IAttachTableData,
    changedField?: ToggleNotificationField
  ) => void;
  onDetachUser: (data: IOnDetachUser) => void;
  openRoleList: (openHereNewNameProps: IOpenRoleListProps) => void;
  t: TFunction<"translations", undefined>;
}

const { accessor, display } = createColumnHelper<IAttachTableData>();

export const attachUserColumns = ({
  userId,
  userRole,
  onNotificationToggle,
  onDetachUser,
  openRoleList,
  t,
}: IAttachUserColumns) => [
  accessor("user", {
    header: t("tables.user"),
    size: getColumnWidth("attachUser", "user"),
    cell: ({ getValue }) => <TooltipCell value={getValue()} variant="bold" />,
  }),
  accessor("email", {
    header: t("tables.email"),
    size: getColumnWidth("attachUser", "email"),
    cell: ({ getValue }) => <TooltipCell value={getValue()} />,
  }),
  accessor("role", {
    header: t("tables.userRole"),
    size: getColumnWidth("attachUser", "role"),
    cell: ({ getValue, row: { original } }) => {
      const isDisabled =
        userId === original.userId &&
        (userRole === UserRolesEnum.MANAGER || userRole === UserRolesEnum.USER);

      return (
        <LocationRoleSelect
          selectedOption={getValue()}
          isDisabled={isDisabled}
          openClick={(e) =>
            openRoleList({
              userData: original,
              openerParamsProp: e.currentTarget.getBoundingClientRect(),
            })
          }
        />
      );
    },
  }),
  accessor("emailNotification", {
    header: () => <Icon iconName="email" hasZeroMargin />,
    size: getColumnWidth("attachUser", "emailNotification"),
    cell: ({ getValue, row: { original } }) => (
      <AttachTableCheckbox
        name="emailNotification"
        type="checkbox"
        checked={getValue()}
        onChange={() =>
          onNotificationToggle(
            {
              ...original,
              emailNotification: !original.emailNotification,
            },
            "email"
          )
        }
        className={clsx(userRole === UserRolesEnum.USER && "disabled")}
        disabled={userRole === UserRolesEnum.USER}
      />
    ),
  }),
  accessor("telegramNotification", {
    header: () => <Icon iconName="telegram" hasZeroMargin />,
    size: getColumnWidth("attachUser", "telegramNotification"),
    cell: ({ getValue, row: { original } }) => (
      <AttachTableCheckbox
        name="telegramNotification"
        type="checkbox"
        checked={getValue()}
        onChange={() =>
          onNotificationToggle(
            {
              ...original,
              telegramNotification: !original.telegramNotification,
            },
            "telegram"
          )
        }
        className={clsx(userRole === UserRolesEnum.USER && "disabled")}
        disabled={userRole === UserRolesEnum.USER}
      />
    ),
  }),
  display({
    header: () =>
      userRole === UserRolesEnum.USER ? "" : t("buttons.detach.action"),
    size: getColumnWidth("attachUser", "actions"),
    cell: ({
      row: {
        original: { userId: currentUser, locationId, user },
      },
    }) => {
      if (
        userRole === UserRolesEnum.USER ||
        (currentUser === userId && userRole === UserRolesEnum.MANAGER)
      )
        return "";

      return (
        <IconButton
          hoverColor="darken"
          name="detach"
          onClick={() =>
            onDetachUser({
              managerId: currentUser,
              locationId,
              managerName: user,
            })
          }
          hasZeroMargin
        />
      );
    },
    id: "actions",
  }),
];
