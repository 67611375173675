import { SetStateAction, Dispatch } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  DeleteCellContent,
  DeleteCellName,
  DeleteLocationCell,
  DetailsNameCell,
} from "./location-details-table.styles";
import IconButton from "../../icon-button/icon-button";
import { ILocation } from "../../../interfaces/locations";
import { ISelectOption } from "../../kyps-select/kyps-select";
import {
  handleOnDeleteLocation,
  translateLocationDetailsCellName,
} from "./location-details-table.helper";
import AuthWrapper from "../../../auth/auth-wrapper";
import { adminRights } from "../../../interfaces/auth-wrapper";
import EditableDataCell from "../../editable-data-cell/editable-data-cell";
import EditableActionsCell from "../../editable-actions-cell/editable-actions-cell";
import { DeleteFunction } from "../../../interfaces/delete-function";
import { TFunction } from "i18next";
import {
  EditableLocationFieldsEnum,
  ILocationDetailsTableData,
} from "../../../interfaces/editable-cells";
import { IOpenDeleteConfirm } from "../../../hooks/useDeleteConfirm";
import clsx from "clsx";
import { getColumnWidth } from "../kyps-table.utils";

export interface ICreateLocationDetailsColumns {
  selectedId: string;
  locationId: string;
  apiLocationData: ILocation | undefined;
  isConfirmOpen: boolean;
  selectZone: Dispatch<SetStateAction<ISelectOption>>;
  setSelectedId: (value: SetStateAction<string>) => void;
  deleteLocation: DeleteFunction;
  closeAndResetSidebar: () => void;
  setManualSkipToken: Dispatch<SetStateAction<boolean>>;
  setParentManualSkipToken: Dispatch<SetStateAction<boolean>>;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  t: TFunction<"translations", undefined>;
}

const { accessor } = createColumnHelper<ILocationDetailsTableData>();
export const createLocationDetailsColumns = ({
  selectedId,
  locationId,
  apiLocationData,
  isConfirmOpen,
  selectZone,
  setSelectedId,
  deleteLocation,
  closeAndResetSidebar,
  setManualSkipToken,
  setParentManualSkipToken,
  openDeleteConfirm,
  t,
}: ICreateLocationDetailsColumns) => [
  accessor("name", {
    header: "",
    size: getColumnWidth("locationDetails", "name"),
    cell: ({ getValue }) => {
      if (getValue() === EditableLocationFieldsEnum.DELETE) return "";
      return (
        <DetailsNameCell>
          {translateLocationDetailsCellName({ cellName: getValue(), t })}:
        </DetailsNameCell>
      );
    },
    id: "name",
  }),
  accessor("value", {
    header: "",
    size: getColumnWidth("locationDetails", "value"),
    id: "value",
    cell: ({
      getValue,
      row: {
        id: rowId,
        original: { value, id, name },
      },
      table: {
        options: { meta },
      },
    }) => {
      if (getValue() === EditableLocationFieldsEnum.DELETE) return "";

      return (
        <EditableDataCell
          meta={meta}
          name={value}
          rowId={rowId}
          zoneId={id}
          asTextarea={name === EditableLocationFieldsEnum.COMMENTS}
          fieldName={name}
          isLocationCell
          locationData={apiLocationData}
        />
      );
    },
  }),
  accessor("id", {
    header: "",
    id: "id",
    size: getColumnWidth("locationDetails", "id"),
    cell: ({
      row: {
        id: rowId,
        original: { value, id, name },
      },
      table,
    }) => {
      if (name === EditableLocationFieldsEnum.DELETE)
        return (
          <AuthWrapper
            allowedRoles={adminRights}
            children={
              <DeleteLocationCell>
                <DeleteCellContent
                  className={clsx(isConfirmOpen && "active")}
                  onClick={(e) => {
                    openDeleteConfirm({
                      deleteConfirmName: apiLocationData?.name ?? "",
                      deleteFunction: () =>
                        handleOnDeleteLocation({
                          deleteLocation,
                          locationId,
                          locationName: apiLocationData?.name ?? "",
                          closeAndRedirect: closeAndResetSidebar,
                          setManualSkipToken,
                          setParentManualSkipToken,
                        }),
                      openerParamsProp: e.currentTarget.getBoundingClientRect(),
                    });

                    setSelectedId(id);
                  }}
                >
                  <IconButton name="bin" color="error" />
                  <DeleteCellName>
                    {t("buttons.remove.actionWithKey", {
                      key: t("entities.location"),
                    })}
                  </DeleteCellName>
                </DeleteCellContent>
              </DeleteLocationCell>
            }
          />
        );

      return (
        <EditableActionsCell
          rowId={rowId}
          id={id}
          name={value}
          table={table}
          selectIdToDelete={setSelectedId}
          selectedId={selectedId}
          hasNoIcons
          selectZone={selectZone}
          t={t}
        />
      );
    },
  }),
];
