import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import { useMemo, useState, ReactNode } from "react";
import { KypsButton } from "../../kyps-button/kyps-button";
import KypsSelect, { ISelectOption } from "../../kyps-select/kyps-select";
import { useGetLocationsByCompanyIdQuery } from "../../../api/location.api";
import {
  useAttachManagerByLocationIdMutation,
  useDetachManagerByLocationIdMutation,
  useUpdateManagerByLocationIdMutation,
} from "../../../api/location-manager.api";
import {
  createAttachLocationTableColumns,
  getUniqueLocations,
  locationsResponseTransformer,
} from "./attach-location-table.utils";
import { ILocationManager } from "../../../interfaces/location-managers";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { onAttachLocation } from "./attach-location-table.helper";
import { LocationUserRoles, locationRoles } from "../../../interfaces/roles";
import { handleOnSelect } from "../attach-user-table/attach-user-table.helper";
import { TFunction } from "i18next";
import { hasEmptyMessage } from "../location-table/location-table.helper";

interface IAttachLocationTable {
  currentManager: string;
  managerLocations: ILocationManager[];
  t: TFunction<"translations", undefined>;
}

const AttachLocationTable = ({
  currentManager,
  managerLocations,
  t,
}: IAttachLocationTable) => {
  const { selectedCompany } = useAppSelector((state) => state.company);

  const [sorting, setSorting] = useState<SortingState>([
    { id: "locationName", desc: false },
  ]);

  const [toggleLocationNotification] = useUpdateManagerByLocationIdMutation();
  const [attachManager] = useAttachManagerByLocationIdMutation();
  const transformedLocationsData = useMemo(() => {
    if (managerLocations) return locationsResponseTransformer(managerLocations);

    return [];
  }, [managerLocations]);

  const [selectedOption, setSelectedOption] = useState<ISelectOption | null>(
    null
  );
  const [selectedRoleOption, setSelectedRoleOption] =
    useState<ISelectOption | null>(locationRoles[1]);

  const { data: companyLocations } = useGetLocationsByCompanyIdQuery(
    selectedCompany?.value ?? skipToken
  );

  const locationsOptions = useMemo(() => {
    const uniqueLocations = companyLocations?.map((location) => ({
      label: location.name,
      value: location.id,
    }));

    if (uniqueLocations)
      return getUniqueLocations(uniqueLocations, managerLocations);

    return [];
  }, [companyLocations, managerLocations]);

  const [detachLocation] = useDetachManagerByLocationIdMutation();

  const table = useReactTable({
    data: transformedLocationsData,
    columns: createAttachLocationTableColumns({
      currentManager,
      detachFunction: detachLocation,
      toggleLocationNotification,
      t,
    }),
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    sortDescFirst: false,
  });

  const AttachLocationTableHeader: ReactNode[] = [
    <KypsSelect
      key="selectLocation"
      placeholder={`${t("buttons.select.actionWithKey", {
        key: t("entities.location"),
      })}...`}
      options={locationsOptions}
      onSelect={(option) => handleOnSelect({ option, setSelectedOption })}
      selectedOption={selectedOption}
      size="large"
    />,
    <KypsSelect
      key="selectRole"
      placeholder={`${t("buttons.select.actionWithKey", {
        key: t("entities.role"),
      })}...`}
      options={locationRoles}
      onSelect={(option) =>
        handleOnSelect({ option, setSelectedOption: setSelectedRoleOption })
      }
      selectedOption={selectedRoleOption}
      size="small"
    />,
    <KypsButton
      key="Attach location"
      placeholder={t("buttons.attach.userToLocation")}
      onClick={() =>
        onAttachLocation({
          attachManager,
          managerData: {
            managerId: currentManager,
            notifyEmail: true,
            notifyTelegram: false,
            role: selectedRoleOption?.value as LocationUserRoles,
          },
          selectedOption,
          setSelectedOption,
        })
      }
      size="large"
    />,
  ];

  return (
    <KypsTable
      table={table}
      tableName={t("attachTables.attachLocations.tableName")}
      tableHeaderElements={AttachLocationTableHeader}
      isTransparentBg
      hidePagination
      emptyMessage={hasEmptyMessage({
        data: transformedLocationsData,
        message: t("emptyMessage.text", {
          entity: t("emptyMessage.entities.location"),
        }),
        isSmall: true,
      })}
    />
  );
};

export default AttachLocationTable;
