import Icon from "../../../../components/icon/icon";
import { ContactItem, ContactsWrapper } from "./contacts.styles";
import ReactGA from "react-ga4";

const Contacts = () => (
  <ContactsWrapper>
    <ContactItem>
      <Icon iconName="email" isLight />
      <a
        href="mailto:tidisi.dt@gmail.com"
        onClick={() =>
          ReactGA.event({ action: "sendEmail", category: "contactUs" })
        }
      >
        : tidisi.dt@gmail.com
      </a>
    </ContactItem>
    <ContactItem>
      <Icon iconName="phone" />
      <a
        href="tel:+380976716663"
        onClick={() =>
          ReactGA.event({ action: "phoneNumber", category: "contactUs" })
        }
      >
        : +380 97 671 66 63
      </a>
    </ContactItem>
  </ContactsWrapper>
);

export default Contacts;
