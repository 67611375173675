import { StyledToaster } from "./toaster.styles";
import { ReactComponent as XCircle } from "../../assets/x-circle.svg";
import { ReactComponent as CheckCircle } from "../../assets/check-circle.svg";
import "react-toastify/dist/ReactToastify.css";
import { LoaderDots } from "../../styles/helper.styles";

const NotificationHolder = () => (
  <StyledToaster
    position="bottom-right"
    autoClose={4000}
    limit={3}
    closeOnClick
    bodyClassName="body"
    icon={({ type }) => {
      if (type === "success") return <CheckCircle />;
      if (type === "error") return <XCircle />;
      if (type === "default") return <LoaderDots className="left" />;
      return null;
    }}
  />
);

export default NotificationHolder;
