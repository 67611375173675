import { TFunction } from "i18next";
import { IUser } from "../../redux/user/reducers";
import LanguageSwitcher from "../language-switcher/language-switcher";
import {
  UserActionButton,
  UserName,
} from "../user-dropdown/user-dropdown.styles";
import UserIcon from "../user-icon/user-icon";
import {
  MenuUserBlockWrapper,
  MenuUserDataContainer,
  MenuLanguageSwitcher,
  UserActionButtonWrapper,
} from "./app-mobile-menu.styles";
import TooltipCell from "../kyps-tooltip/tooltip-cell/tooltip-cell";

interface IAppMobileMenu {
  user: IUser | null;
  companyDropdown: JSX.Element;
  t: TFunction<"translations", undefined>;
  logout: () => void;
}

const AppMobileMenu = ({
  user,
  companyDropdown,
  logout,
  t,
}: IAppMobileMenu) => (
  <>
    <MenuLanguageSwitcher>
      <LanguageSwitcher />
    </MenuLanguageSwitcher>
    <MenuUserBlockWrapper>
      <UserIcon userName={user?.name ?? ""} variant="large" />
      <MenuUserDataContainer>
        <UserName>{user?.name}</UserName>
        <TooltipCell
          variant="dark"
          value={user?.email ?? "Not found in Database"}
        />
      </MenuUserDataContainer>
    </MenuUserBlockWrapper>
    {companyDropdown}
    <UserActionButtonWrapper>
      <UserActionButton onClick={() => logout()}>
        {t("buttons.signOut.action")}
      </UserActionButton>
    </UserActionButtonWrapper>
  </>
);

export default AppMobileMenu;
