import { IAddZone, IUpdateZone, IZone } from "../interfaces/zones";
import { kypsApi } from "./kyps.api";

export const zoneControllerEndpoints = kypsApi.injectEndpoints({
  endpoints: (builder) => ({
    getZoneById: builder.query<IZone, string>({
      query: (zoneId) => ({
        url: `zones/${zoneId}`,
        method: "GET",
      }),
      providesTags: ["Zones"],
    }),

    addZone: builder.mutation<IZone, IAddZone>({
      query: ({ zoneName, locationId }) => ({
        url: `/locations/${locationId}/zones`,
        method: "POST",
        body: zoneName,
      }),
      invalidatesTags: ["Zones", "Dashboard"],
    }),

    updateZone: builder.mutation<IZone, IUpdateZone>({
      query: ({ zoneData, id }) => ({
        url: `/zones/${id}`,
        method: "PUT",
        body: zoneData,
      }),
      invalidatesTags: ["Zones", "Dashboard"],
    }),

    deleteZone: builder.mutation<void, string>({
      query: (zoneId) => ({
        url: `/zones/${zoneId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Zones", "Dashboard"],
    }),
  }),
});

export const {
  useGetZoneByIdQuery,
  useAddZoneMutation,
  useDeleteZoneMutation,
  useUpdateZoneMutation,
} = zoneControllerEndpoints;
