import { ReactNode, useEffect } from "react";
import { ModalOverlay } from "./kyps-modal.styles";
import clsx from "clsx";

interface IKypsModal {
  isModalOpen: boolean;
  modalContent?: ReactNode;
  className?: string;
}

const KypsModal = ({
  isModalOpen,
  modalContent,
  className,
}: IKypsModal): JSX.Element => {
  useEffect(() => {
    if (isModalOpen) document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "";
    };
  }, [isModalOpen]);

  return (
    <ModalOverlay className={clsx(isModalOpen && "visible", className)}>
      {modalContent}
    </ModalOverlay>
  );
};

export default KypsModal;
