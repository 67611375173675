import styled from "@emotion/styled";

export const BenefitWrapper = styled.div`
  position: relative;
  margin-top: 24px;
`;

export const BenefitIconWrapper = styled.div`
  position: absolute;
  top: -24px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 48px;
  width: 48px;
  background-color: #6366f1;
  border-radius: 6px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;

  @media (min-width: 768px) {
    font-size: 28px;
  }
`;

export const BenefitContent = styled.div`
  padding: 48px 16px 16px;
  background-color: #f9fafb;
  height: 100%;
  border-radius: 8px;

  @media (min-width: 768px) {
    padding: 48px 24px 16px;
  }

  @media (min-width: 992px) {
    padding: 48px 12px 16px;
  }
`;

export const BenefitHeading = styled.h3`
  color: #111827;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const BenefitDescription = styled.p`
  color: #6b7280;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  max-width: 500px;
  margin: 0 auto;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const SectionSummary = styled.p`
  margin-top: 32px;
  color: #6b7280;
  text-align: center;
  font-size: 24px;
  line-height: 28px;

  span {
    color: #6366f1;
    font-weight: 700;
  }

  @media (min-width: 768px) {
    font-size: 28px;
  }
`;
