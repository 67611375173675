import { createColumnHelper } from "@tanstack/react-table";
import { ICompany } from "../../../interfaces/companies";
import IconButton from "../../icon-button/icon-button";
import EditCompanySidebar from "../../kyps-sidebar/edit-company-sidebar/edit-company-sidebar";
import { checkDate } from "../../../helpers/check-date.helper";
import { ReactNode } from "react";
import AuthWrapper from "../../../auth/auth-wrapper";
import { superAdminRights, userRights } from "../../../interfaces/auth-wrapper";
import { UserRoles, UserRolesEnum } from "../../../interfaces/roles";
import { ICompanyAdminState } from "../../../interfaces/managers";
import { getIsAllowedCompany } from "./companies-table.helper";
import { TFunction } from "i18next";
import { getColumnWidth } from "../kyps-table.utils";
import { IOpenDeleteConfirm } from "../../../hooks/useDeleteConfirm";
import TooltipCell from "../../kyps-tooltip/tooltip-cell/tooltip-cell";

interface ICreateCompaniesTableColumns {
  userRole: UserRoles;
  authorizedUserData: ICompanyAdminState[];
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  onDeleteCompany: (id: string, name: string) => void;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  t: TFunction<"translations", undefined>;
}

const { accessor, display } = createColumnHelper<ICompany>();

export const createCompaniesTableColumns = ({
  userRole,
  authorizedUserData,
  openModal,
  closeAndResetModal,
  onDeleteCompany,
  openDeleteConfirm,
  t,
}: ICreateCompaniesTableColumns) => {
  const allColumns = [
    accessor("shortName", {
      header: t("tables.shortName"),
      size: getColumnWidth("company", "shortName"),
      cell: ({ getValue }) => <TooltipCell value={getValue()} />,
    }),
    accessor("fullName", {
      header: t("tables.fullName"),
      size: getColumnWidth("company", "fullName"),
      cell: ({ getValue }) => <TooltipCell value={getValue()} />,
    }),
    accessor("comment", {
      header: t("tables.comment"),
      size: getColumnWidth("company", "comment"),
      cell: ({ getValue }) => <TooltipCell value={getValue()} />,
    }),
    accessor("createdAt", {
      header: t("tables.created"),
      size: getColumnWidth("company", "createdAt"),
      cell: ({ getValue }) => <TooltipCell value={checkDate(getValue(), t)} />,
    }),
    display({
      size: getColumnWidth("company", "actions"),
      cell: ({
        row: {
          original: { fullName, id },
        },
      }) => (
        <>
          <AuthWrapper
            allowedRoles={userRights}
            additionalCheck={getIsAllowedCompany({
              authorizedUserData,
              currentCompanyId: id,
              userRole,
            })}
          >
            <IconButton
              name="pencil"
              hoverColor="info"
              key="pencil"
              onClick={() =>
                openModal(
                  <EditCompanySidebar
                    closeModal={closeAndResetModal}
                    currentCompanyId={id}
                    t={t}
                  />
                )
              }
            />
          </AuthWrapper>
          <AuthWrapper allowedRoles={superAdminRights}>
            <IconButton
              name="bin"
              hoverColor="warning"
              key="bin"
              onClick={(e) =>
                openDeleteConfirm({
                  deleteConfirmName: fullName,
                  openerParamsProp: e.currentTarget.getBoundingClientRect(),
                  deleteFunction: () => onDeleteCompany(id, fullName),
                })
              }
            />
          </AuthWrapper>
        </>
      ),
      id: "actions",
    }),
  ];

  return userRole === UserRolesEnum.SUPER_ADMIN
    ? allColumns
    : allColumns.slice(0, 2).concat(allColumns.slice(3));
};
