import { createColumnHelper } from "@tanstack/react-table";
import EditableActionsCell from "../../editable-actions-cell/editable-actions-cell";
import EditableTableCell from "../../editable-data-cell/editable-data-cell";
import { IZone } from "../../../interfaces/zones";
import { Dispatch, SetStateAction } from "react";
import { ISelectOption } from "../../kyps-select/kyps-select";
import AuthWrapper from "../../../auth/auth-wrapper";
import { managersRights } from "../../../interfaces/auth-wrapper";
import { TFunction } from "i18next";
import { IOpenDeleteConfirm } from "../../../hooks/useDeleteConfirm";

interface ICreateEditableColumns {
  selectedId: string;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  setSelectedId: Dispatch<SetStateAction<string>>;
  selectZone: Dispatch<SetStateAction<ISelectOption>>;
  t: TFunction<"translations", undefined>;
}

const { accessor } = createColumnHelper<IZone>();

export const createEditableColumns = ({
  selectedId,
  openDeleteConfirm,
  setSelectedId,
  selectZone,
  t,
}: ICreateEditableColumns) => [
  accessor("name", {
    header: t("forms.fieldNames.zoneName"),
    cell: ({
      row: {
        id: rowId,
        original: { name, id },
      },
      table: {
        options: { meta },
      },
      column: {
        columnDef: { header },
      },
    }) => (
      <EditableTableCell
        rowId={rowId}
        zoneId={id}
        name={name}
        meta={meta}
        fieldName={header as string}
      />
    ),
    meta: {
      type: "text",
    },
  }),
  accessor("id", {
    header: "",
    cell: ({
      row: {
        id: rowId,
        original: { id, name },
      },
      table,
    }) => (
      <AuthWrapper allowedRoles={managersRights}>
        <EditableActionsCell
          rowId={rowId}
          id={id}
          name={name}
          table={table}
          openDeleteConfirm={openDeleteConfirm}
          selectIdToDelete={setSelectedId}
          selectedId={selectedId}
          selectZone={selectZone}
          t={t}
        />
      </AuthWrapper>
    ),
  }),
];
