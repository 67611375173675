import { Chart as ChartJS, ChartTypeRegistry, BubbleDataPoint } from "chart.js";
import { Point } from "chart.js/dist/core/core.controller";
import { TFunction } from "i18next";

interface IGradientItem {
  colorNumber: number;
  color: string;
}

interface IGetColor {
  gradientBack: CanvasGradient;
  items: IGradientItem[];
}

interface ICreatePlugins {
  maxY: number;
  highAllow: number;
  yHeight: number;
  highSatisfy: number;
  lowSatisfy: number;
  lowAllow: number;
  isLoading: boolean;
  t: TFunction<"translations", undefined>;
}

interface IGetDays {
  today: string;
  startOfDay: Date;
}

const lightGreen = "#e2f5ea";
const lightYellow = "#f8f1db";
const lightRed = "#fbdddd";

export const createPlugins = ({
  highAllow,
  highSatisfy,
  lowAllow,
  lowSatisfy,
  maxY,
  yHeight,
  isLoading,
  t,
}: ICreatePlugins) => [
  {
    id: "bg",
    beforeDraw: (
      chart: ChartJS<
        keyof ChartTypeRegistry,
        (number | [number, number] | Point | BubbleDataPoint | null)[],
        unknown
      >
    ) => {
      const isDataEmpty = chart.data.datasets[0].data.length === 0;

      if (isLoading || yHeight === 0 || isDataEmpty) return;

      const { ctx, chartArea } = chart;

      const gradientBack = ctx.createLinearGradient(
        0,
        chartArea.top,
        0,
        chartArea.bottom
      );

      const topRedStop = (maxY - highAllow) / yHeight;
      const topYellowStop = (maxY - highSatisfy) / yHeight;
      const greenStop = (maxY - lowSatisfy) / yHeight;
      const bottomYellowStop = (maxY - lowAllow) / yHeight;

      getColor({
        gradientBack,
        items: [
          { color: lightRed, colorNumber: 0 },
          { color: lightRed, colorNumber: topRedStop },
          { color: lightYellow, colorNumber: topYellowStop },
          { color: lightGreen, colorNumber: greenStop },
          { color: lightYellow, colorNumber: bottomYellowStop },
          { color: lightRed, colorNumber: 1 },
        ],
      });

      ctx.fillStyle = gradientBack;
      ctx.fillRect(
        chartArea.left,
        chartArea.bottom,
        chartArea.right - chartArea.left,
        chartArea.top - chartArea.bottom
      );
    },
  },
  {
    id: "empty message",
    afterDraw: (
      chart: ChartJS<
        keyof ChartTypeRegistry,
        (number | [number, number] | Point | BubbleDataPoint | null)[],
        unknown
      >
    ) => {
      if (isLoading) return;

      if (chart.data.datasets[0].data.length === 0) {
        const ctx = chart.ctx;
        ctx.save();
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "24px Roboto";
        ctx.fillStyle = "gray";
        ctx.fillText(
          t("emptyMessage.noData"),
          chart.width / 2,
          chart.height / 2 - 20
        );
        ctx.restore();
      }
    },
  },
];

const getColor = ({ gradientBack, items }: IGetColor) => {
  let stop = false;

  items.forEach(({ color, colorNumber }, index) => {
    if (stop) return;

    const previousDataItem = items[index - 1];

    if (colorNumber > 1) {
      gradientBack.addColorStop(1, color);

      if (previousDataItem.colorNumber < 0 || previousDataItem.colorNumber > 1)
        return;

      gradientBack.addColorStop(previousDataItem.colorNumber, color);
      stop = true;

      return;
    }

    if (colorNumber < 0) return;

    gradientBack.addColorStop(colorNumber, color);

    if (index <= 0) return;

    if (previousDataItem.colorNumber < 0 || previousDataItem.colorNumber > 1)
      return;

    gradientBack.addColorStop(previousDataItem.colorNumber, color);
  });
};

export const getDays = (
  initialDate: Date,
  initialStartDate?: Date | null
): IGetDays => {
  const now = new Date();
  const endOfDayDate = new Date(initialDate);
  const startOfDayDate = new Date(initialDate);
  startOfDayDate.setHours(0, 0, 0, 0);

  if (endOfDayDate.getDate() === now.getDate()) {
    endOfDayDate.setHours(
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds()
    );
  }

  if (endOfDayDate.getDate() !== new Date().getDate()) {
    if (initialStartDate) {
      endOfDayDate.setHours(0, 0, 0, 0);
    } else {
      endOfDayDate.setHours(23, 59, 59, 999);
    }
  }

  if (initialStartDate) {
    const rangeEndDate = new Date(initialStartDate);
    rangeEndDate.setHours(23, 59, 59, 999);

    return {
      today: rangeEndDate.toISOString(),
      startOfDay: endOfDayDate,
    };
  }

  return {
    today: endOfDayDate.toISOString(),
    startOfDay: startOfDayDate,
  };
};

export const getStartTimestamp = (date: Date | string): number => {
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);

  return startDate.getTime();
};

export const getEndTimestamp = (date: Date | string): number => {
  const endDate = new Date(date);
  endDate.setHours(23, 59, 59, 999);

  return endDate.getTime();
};
