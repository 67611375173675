import LandingIcon from "../../components/landing-icon/landing-icon";
import { LContainer, SectionContainer } from "../../landing-content.styles";
import { StyledLandingImage } from "../image-section/image-section.styles";
import {
  FeedbackSectionWrapper,
  FullWidthColor,
  QuoteAuthor,
  QuoteComponent,
  QuoteContent,
  QuoteContentContainer,
  QuoteImageWrapper,
} from "./feedback-section.styles";
import quote_author_img from "../../../../assets/landing-images/quote-author.svg";
import { TFunction } from "i18next";

const FeedbackSection = ({ t }: { t: TFunction }) => (
  <FeedbackSectionWrapper>
    <SectionContainer>
      <FullWidthColor>
        <LContainer>
          <QuoteComponent>
            <QuoteContentContainer>
              <LandingIcon name="quotes" />
              <QuoteContent>{`"${t("landing.feedback.quote")}"`}</QuoteContent>
              <QuoteAuthor>
                <span>{t("landing.feedback.quoteAuthor")}</span>
                {t("landing.feedback.role")}
              </QuoteAuthor>
            </QuoteContentContainer>
            <QuoteImageWrapper>
              <StyledLandingImage
                src={quote_author_img}
                alt="quote_author_img"
                width={368}
                height={464}
              />
            </QuoteImageWrapper>
          </QuoteComponent>
        </LContainer>
      </FullWidthColor>
    </SectionContainer>
  </FeedbackSectionWrapper>
);

export default FeedbackSection;
