import { t } from "i18next";
import { AddZoneFunction } from "../../../interfaces/add-functions";
import { createNotification } from "../../toaster/toaster.helper";

interface IHandleOnSubmitAddZone {
  locationId: string;
  zoneName: string;
  addZone: AddZoneFunction;
  closeModal: () => void;
}

export const handleOnSubmitAddZone = ({
  locationId,
  zoneName,
  addZone,
  closeModal,
}: IHandleOnSubmitAddZone) => {
  closeModal();

  addZone({
    locationId,
    zoneName: { name: zoneName },
  })
    .unwrap()
    .then(({ name }) =>
      createNotification({
        message: t("notification.success", {
          action: t("notification.actions.created"),
          entity: `${t("entities.zone")} ${name}`,
        }),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};
