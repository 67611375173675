import { useRef, useMemo, useState, useEffect } from "react";
import "chartjs-adapter-moment";
import type { ChartData } from "chart.js";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
  TimeScale,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { Point } from "chart.js/dist/core/core.controller";
import { ChartWrapper, IconXWrapper } from "./kyps-chart.styles";
import IconButton from "../icon-button/icon-button";
import { useGetSensorHistoryQuery } from "../../api/sensor.api";
import {
  createPlugins,
  getDays,
  getEndTimestamp,
  getStartTimestamp,
} from "./kyps-chart.helper";
import { format } from "date-fns-tz";
import KypsDatePicker from "../kyps-datepicker/kyps-date-picker";
import { formatDateForDisplay } from "../kyps-datepicker/kyps-date-picker.helper";
import { TFunction } from "i18next";

interface IScatterData {
  x: number;
  y: number;
}

interface IKypsChart {
  closeModal: () => void;
  sensorNumber: string;
  range: number[];
  iPointName: string;
  minDate?: Date | null;
  t: TFunction<"translations", undefined>;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Legend,
  Title
);

const maxGapDuration = 1000 * 60 * 60 * 2;

export const KypsChart = ({
  closeModal,
  range,
  sensorNumber,
  minDate,
  iPointName,
  t,
}: IKypsChart) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [hasDataChanged, setHasDataChanged] = useState(false);

  const { startOfDay, today } = useMemo(() => {
    return getDays(startDate, endDate);
  }, [endDate, startDate]);

  const { data: apiData, isLoading } = useGetSensorHistoryQuery({
    sensorNumber,
    page: 0,
    size: 10000,
    to: today,
    from: startOfDay.toISOString(),
  });

  const scatterData: IScatterData[] = useMemo(() => {
    if (apiData && apiData.content.length) {
      const newData = apiData.content.map(({ sensorTemp, sensorTime }) => {
        const timestamp = new Date(sensorTime).getTime();

        return { x: timestamp, y: +sensorTemp.toFixed(2) };
      });

      setHasDataChanged(true);

      return newData;
    }
    setHasDataChanged(true);

    return [];
  }, [apiData]);

  useEffect(() => {
    setHasDataChanged(false);
  }, [scatterData]);

  const [lowAllow, lowSatisfy, highSatisfy, highAllow] = range;

  const minY = useMemo(() => {
    if (scatterData.length) {
      const mMin = Math.min(
        ...scatterData.map((point) => +point.y.toFixed() - 1)
      );

      if (mMin > lowAllow) return lowAllow;

      return mMin;
    }

    return 0;
  }, [scatterData, lowAllow]);

  const maxY = useMemo(() => {
    if (scatterData.length) {
      const mMax = Math.max(
        ...scatterData.map((point) => +point.y.toFixed() + 1)
      );

      if (mMax < highAllow) return highAllow;

      return mMax;
    }

    return 0;
  }, [scatterData, highAllow]);

  const yHeight = Math.abs(maxY - minY);
  const chartRef = useRef<ChartJS>(null);

  const data: ChartData<"scatter", (number | Point | null)[], unknown> = {
    datasets: [
      {
        data: scatterData,
        borderWidth: 1,
        borderColor: "gray",
        showLine: true,
        spanGaps: maxGapDuration,
      },
    ],
  };

  const chartHeadingValue = useMemo(() => {
    if (!endDate) return formatDateForDisplay(startDate, t);

    if (endDate) {
      return `${formatDateForDisplay(startDate, t)} - ${formatDateForDisplay(
        endDate,
        t
      )}`;
    }
  }, [endDate, startDate, t]);

  const chartName = `${sensorNumber} (${iPointName})`;

  return (
    <ChartWrapper>
      <IconXWrapper>
        <IconButton name="close" onClick={closeModal} />
      </IconXWrapper>
      <KypsDatePicker
        setStartDate={setStartDate}
        startDate={startDate}
        hasRange
        endDate={endDate}
        setEndDate={setEndDate}
        minDate={minDate}
        t={t}
      />
      <Chart
        height="100%"
        width="100%"
        ref={chartRef}
        type="scatter"
        data={data}
        redraw={hasDataChanged}
        plugins={createPlugins({
          highAllow,
          highSatisfy,
          lowAllow,
          lowSatisfy,
          maxY,
          yHeight,
          isLoading,
          t,
        })}
        options={{
          scales: {
            y: {
              suggestedMin: minY,
              suggestedMax: maxY,
              title: {
                display: true,
                text: `${t("tables.temperature")} (°C)`,
              },
              ticks: {
                stepSize: 1,
              },
            },
            x: {
              suggestedMin: getStartTimestamp(startOfDay),
              suggestedMax: getEndTimestamp(today),
              title: {
                display: true,
                text: `${t("tables.time")} (HH:mm)`,
              },
              type: "time",
              time: {
                displayFormats: {
                  hour: "HH mm",
                },
                unit: "hour",
              },
              ticks: {
                stepSize: 2,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              text: t("chart.title", { chartName, date: chartHeadingValue }),
              display: true,
              font: { family: "Roboto", size: 16, weight: 400 },
            },
            tooltip: {
              yAlign: "bottom",
              displayColors: false,
              callbacks: {
                title: () => {
                  return "";
                },
                label: ({ parsed: { x, y } }) => {
                  const timeStamp = x;
                  const temperature = `Value: ${y} °C`;
                  return [
                    format(new Date(timeStamp), "MMM d, yyyy, HH:mm:ss", {
                      timeZone: "UTC",
                    }),
                    temperature,
                  ];
                },
              },
            },
          },
          maintainAspectRatio: false,
        }}
      />
    </ChartWrapper>
  );
};
