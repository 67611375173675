import { Dispatch, SetStateAction } from "react";
import { UserRoles, UserRolesEnum } from "../../../interfaces/roles";
import CustomCheckbox from "../../kyps-custom-checkbox/custom-checkbox";
import { InputField } from "../../kyps-form/kyps-form-input";
import {
  CompanyAdminSwitcher,
  CompanyUserSwitcher,
  CompanyUserSwitchersHolder,
} from "../kyps-sidebar.styles";
import KypsSelect, { ISelectOption } from "../../kyps-select/kyps-select";
import { TFunction } from "i18next";

const emailPattern =
  /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

interface IGetCompanyUserSwitcher {
  userRole: UserRoles;
  isAttachedToCompany: boolean;
  isAdmin: boolean;
  companies: ISelectOption[] | undefined;
  selectedOption: ISelectOption | null;
  setIsAttachedToCompany: Dispatch<SetStateAction<boolean>>;
  setIsAdmin: Dispatch<SetStateAction<boolean>>;
  onSelect: (option: ISelectOption) => void;
  t: TFunction<"translations", undefined>;
}

export interface IAddManagerFields {
  name: string;
  phoneNumber: string;
  telegramId: string;
  email: string;
}

export const createAddManagerFields = (
  t: TFunction<"translations", undefined>
): InputField<IAddManagerFields>[] => [
  {
    label: t("tables.name"),
    name: "name",
    type: "text",
    rules: {
      required: t("forms.messages.isRequiredWithKey", {
        labelName: t("tables.name"),
      }),
    },
  },
  {
    label: t("tables.phoneNumber"),
    name: "phoneNumber",
    type: "tel",
  },
  {
    label: t("tables.telegramId"),
    name: "telegramId",
    type: "number",
    iconName: "at-sign",
  },
  {
    label: t("tables.email"),
    name: "email",
    type: "text",
    rules: {
      pattern: {
        value: emailPattern,
        message: t("forms.messages.enterValidEmail"),
      },
      required: t("forms.messages.isRequiredWithKey", {
        labelName: t("tables.email"),
      }),
    },
  },
];

export const getCompanyUserSwitcher = ({
  userRole,
  isAttachedToCompany,
  isAdmin,
  companies,
  selectedOption,
  onSelect,
  setIsAdmin,
  setIsAttachedToCompany,
  t,
}: IGetCompanyUserSwitcher) => {
  if (userRole === UserRolesEnum.SUPER_ADMIN)
    return (
      <CompanyUserSwitcher>
        <CompanyUserSwitchersHolder>
          <CustomCheckbox
            checkboxHeading={t("buttons.attach.userToCompany")}
            isChecked={isAttachedToCompany}
            onCheckboxClick={() => setIsAttachedToCompany(!isAttachedToCompany)}
          />
          <CustomCheckbox
            checkboxHeading={t("attachTables.companyUsers.setAdmin")}
            isChecked={isAdmin}
            isDisabled={!isAttachedToCompany}
            onCheckboxClick={() => setIsAdmin(!isAdmin)}
          />
        </CompanyUserSwitchersHolder>
        <KypsSelect
          onSelect={onSelect}
          options={companies}
          placeholder={t("buttons.select.actionWithKey", {
            key: t("entities.company"),
          })}
          selectedOption={selectedOption}
          isDisable={!isAttachedToCompany}
          size="large"
        />
      </CompanyUserSwitcher>
    );

  if (userRole === UserRolesEnum.ADMIN)
    return (
      <CompanyAdminSwitcher>
        <CustomCheckbox
          checkboxHeading={t("attachTables.companyUsers.setAdmin")}
          isChecked={isAdmin}
          isDisabled={!isAttachedToCompany}
          onCheckboxClick={() => setIsAdmin(!isAdmin)}
        />
      </CompanyAdminSwitcher>
    );

  return null;
};
