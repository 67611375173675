import keycloak from "../keycloak";
import { UserRoles, UserRolesEnum } from "../interfaces/roles";
import { userRights } from "../interfaces/auth-wrapper";

interface IGetUserRoles {
  handleOnChangeRole?: (role: UserRoles) => void;
  url: string;
  companyUrl?: string;
}

export const getUserRoles = ({
  handleOnChangeRole,
  url,
  companyUrl,
}: IGetUserRoles): UserRoles => {
  const rolesArray: string[] = keycloak.tokenParsed?.user_groups;

  if (!rolesArray) return UserRolesEnum.USER;

  if (isSuperAdmin(rolesArray)) {
    handleOnChangeRole?.(UserRolesEnum.SUPER_ADMIN);
    return UserRolesEnum.SUPER_ADMIN;
  }

  const foundValue = rolesArray.find((element) => element.includes(url));
  const foundCompanyValue = rolesArray.find((element) =>
    element.includes(companyUrl as string)
  );

  if (foundCompanyValue) {
    const higherRole = getHigherPriority(
      foundCompanyValue,
      foundValue as string
    );
    handleOnChangeRole?.(higherRole);

    return higherRole;
  }

  return checkRole(foundValue, handleOnChangeRole);
};

const isSuperAdmin = (rolesArray: string[]) =>
  rolesArray.includes(`/${UserRolesEnum.SUPER_ADMIN}`);

const checkRole = (
  foundValue: string | undefined,
  handleOnChangeRole?: (role: UserRoles) => void
) => {
  if (foundValue?.includes(UserRolesEnum.ADMIN)) {
    handleOnChangeRole?.(UserRolesEnum.ADMIN);
    return UserRolesEnum.ADMIN;
  }

  if (foundValue?.includes(UserRolesEnum.MANAGER)) {
    handleOnChangeRole?.(UserRolesEnum.MANAGER);
    return UserRolesEnum.MANAGER;
  }

  handleOnChangeRole?.(UserRolesEnum.USER);
  return UserRolesEnum.USER;
};

const checkCompanyRole = (foundValue: string | undefined) => {
  if (foundValue?.includes(UserRolesEnum.ADMIN)) return UserRolesEnum.ADMIN;

  if (foundValue?.includes(UserRolesEnum.MANAGER)) return UserRolesEnum.MANAGER;

  return UserRolesEnum.USER;
};

const getHigherPriority = (foundCompanyValue: string, foundValue: string) => {
  const companyRole: UserRoles = checkCompanyRole(foundCompanyValue);
  const locationRole: UserRoles = checkRole(foundValue);
  const smallerIndex = Math.min(
    userRights.indexOf(companyRole),
    userRights.indexOf(locationRole)
  );

  return userRights[smallerIndex];
};
