import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  RegisterOptions,
} from "react-hook-form";
import { RangeInput } from "./range-input.styles";
import { ErrorMessage } from "../kyps-form/kyps-form.styles";
import { HTMLInputTypeAttribute } from "react";
import { RangeItem, RangeType, TemperatureSign } from "./range-form.styles";
import { checkIsValidValue, translateRangeVariant } from "./range-input.helper";
import { useTranslation } from "react-i18next";

export enum RangeInputVariantsEnum {
  NORMAL = "normal",
  ATTENTION = "attention",
}

export interface IRangeInputField<T = string> {
  name: keyof T;
  type: HTMLInputTypeAttribute;
  value?: number;
  rules?: RegisterOptions;
  placeholder?: string;
  variant?: RangeInputVariantsEnum.NORMAL | RangeInputVariantsEnum.ATTENTION;
}

interface IInputBlockProps {
  name: string;
  control: Control<any>;
  errors: DeepMap<any, FieldError>;
  type: HTMLInputTypeAttribute;
  value?: string | number;
  rules?: RegisterOptions;
  placeholder?: string;
  variant?: RangeInputVariantsEnum.NORMAL | RangeInputVariantsEnum.ATTENTION;
  onBlur?: () => void;
  className?: string;
}

const RangeInputBlock = ({
  name,
  control,
  errors,
  type,
  value,
  rules,
  placeholder,
  variant,
  onBlur,
  className,
}: IInputBlockProps) => {
  const { t } = useTranslation();

  return (
    <RangeItem>
      {errors[name] && (
        <ErrorMessage className={"message"}>
          {errors[name].message}
        </ErrorMessage>
      )}
      <Controller
        name={name}
        defaultValue={value ?? ""}
        control={control}
        rules={rules}
        render={({ field }) => (
          <>
            <RangeInput
              id={name}
              className={className}
              type={type}
              autoComplete="false"
              placeholder={placeholder}
              step={0.1}
              onKeyDown={checkIsValidValue}
              max={99}
              {...field}
              onBlur={onBlur}
            />
            <TemperatureSign>°C</TemperatureSign>
            <RangeType className={variant}>
              {translateRangeVariant({ t, variant })}
            </RangeType>
          </>
        )}
      />
    </RangeItem>
  );
};

export default RangeInputBlock;
