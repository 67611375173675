import {
  createDashboardLoader,
  createReportLoader,
  createSidebarFormLoader,
  createTableLoader,
  createEditableSidebarLoader,
  createManagementLoader,
  createLocationLoader,
  createSensorCards,
} from "../components/loaders/loaders.helper";

export type LoaderVariant =
  | "sidebar"
  | "table"
  | "report"
  | "dashboard"
  | "editable"
  | "management"
  | "location"
  | "sensorCards";

export const loaderFunctions: Record<
  LoaderVariant,
  () => JSX.Element | JSX.Element[]
> = {
  sidebar: createSidebarFormLoader,
  table: createTableLoader,
  report: createReportLoader,
  dashboard: createDashboardLoader,
  editable: createEditableSidebarLoader,
  management: createManagementLoader,
  location: createLocationLoader,
  sensorCards: createSensorCards,
};

export const loaderHeights: Record<LoaderVariant, number> = {
  sidebar: 400,
  table: 400,
  report: 322,
  dashboard: 600,
  editable: 500,
  management: 500,
  location: 500,
  sensorCards: 300,
};
