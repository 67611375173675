import {
  StatsSectionDescription,
  StatsSectionWrapper,
  StatsTime,
  StatsTimeDescription,
} from "./stats-section.styles";
import { LContainer, SectionContainer } from "../../landing-content.styles";
import {
  StyledH2,
  StyledP,
} from "../../components/section-description/section-description.styles";
import { TFunction } from "i18next";

const StatsSection = ({ t }: { t: TFunction }) => {
  return (
    <StatsSectionWrapper>
      <SectionContainer>
        <LContainer>
          <StatsSectionDescription>
            <StyledH2 className="light">{t("landing.stats.heading")}</StyledH2>
            <StyledP>{t("landing.stats.text")}</StyledP>
            <>
              <StatsTime>24/7</StatsTime>
              <StatsTimeDescription>
                {t("landing.stats.dataCollection")}
              </StatsTimeDescription>
            </>
          </StatsSectionDescription>
        </LContainer>
      </SectionContainer>
    </StatsSectionWrapper>
  );
};

export default StatsSection;
