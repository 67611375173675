import {
  useAttachManagerByLocationIdMutation,
  useDetachManagerByLocationIdMutation,
} from "../../../api/location-manager.api";
import {
  IDeleteLocationManager,
  IManagerState,
} from "../../../interfaces/location-managers";
import { LocationUserRoles } from "../../../interfaces/roles";
import { UpdateManagerNotificationsFunction } from "../../../interfaces/update-functions";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { Dispatch, SetStateAction } from "react";
import { createNotification } from "../../toaster/toaster.helper";
import { ToggleNotificationField } from "../attach-user-table/attach-user-table.helper";
import { t } from "i18next";

type DetachManagerResult = ReturnType<
  typeof useDetachManagerByLocationIdMutation
>;
export type DetachFunction = DetachManagerResult[0];

type AttachManagerResult = ReturnType<
  typeof useAttachManagerByLocationIdMutation
>;
export type AttachFunction = AttachManagerResult[0];

interface IHandleOnToggleUserNotification {
  changedField: ToggleNotificationField;
  locationId: string;
  locationName: string;
  managerId: string;
  notifyEmail: boolean;
  notifyTelegram: boolean;
  role: LocationUserRoles;
  toggleLocationNotification: UpdateManagerNotificationsFunction;
}

interface IOnAttachManager {
  selectedOption: ISelectOption | null;
  managerData: IManagerState;
  attachManager: AttachFunction;
  setSelectedOption: Dispatch<SetStateAction<ISelectOption | null>>;
}

interface IOnDetachLocation {
  data: IDeleteLocationManager;
  locationName: string;
  detachFunction: DetachFunction;
}

interface IGetNotificationTranslation {
  locationName: string;
  notifyEmail: boolean;
  notifyTelegram: boolean;
  changedField?: ToggleNotificationField;
}

export const onDetachLocation = ({
  data,
  locationName,
  detachFunction,
}: IOnDetachLocation) =>
  detachFunction(data)
    .unwrap()
    .then(() =>
      createNotification({
        message: `${locationName} was successfully detached!`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

export const onAttachLocation = ({
  managerData,
  selectedOption,
  attachManager,
  setSelectedOption,
}: IOnAttachManager) => {
  if (selectedOption)
    attachManager({ data: managerData, locationId: selectedOption.value })
      .unwrap()
      .then(({ location: { name } }) => {
        setSelectedOption({ label: "", value: "" });
        createNotification({
          message: `${name} was attached`,
          variant: "success",
        });
      })
      .catch(({ data: { error } }) =>
        createNotification({
          message: `${error}`,
          variant: "error",
        })
      );
};

export const handleOnToggleUserNotification = ({
  changedField,
  locationId,
  locationName,
  managerId,
  notifyEmail,
  notifyTelegram,
  role,
  toggleLocationNotification,
}: IHandleOnToggleUserNotification) =>
  toggleLocationNotification({
    locationId,
    managerId,
    notifyEmail,
    notifyTelegram,
    role,
  })
    .unwrap()
    .then(() =>
      createNotification({
        message: getNotificationTranslation({
          locationName,
          notifyEmail,
          notifyTelegram,
          changedField,
        }),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );

const getNotificationTranslation = ({
  locationName,
  notifyEmail,
  notifyTelegram,
  changedField,
}: IGetNotificationTranslation) => {
  if (changedField === "email")
    return notifyEmail
      ? t("notification.userNotification.emailNotification.enable", {
          locationName,
        })
      : t("notification.userNotification.emailNotification.disable", {
          locationName,
        });

  return notifyTelegram
    ? t("notification.userNotification.telegramNotification.enable", {
        locationName,
      })
    : t("notification.userNotification.telegramNotification.disable", {
        locationName,
      });
};
