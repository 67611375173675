import { useState, useEffect, useCallback } from "react";
import { subDays } from "date-fns";

export enum RangesDaysOptionsEnum {
  TODAY = "today",
  YESTERDAY = "yesterday",
  DAYS3 = "3days",
  DAYS7 = "7days",
}
export type RangesDaysOptions =
  | RangesDaysOptionsEnum.TODAY
  | RangesDaysOptionsEnum.YESTERDAY
  | RangesDaysOptionsEnum.DAYS3
  | RangesDaysOptionsEnum.DAYS7;

interface UseDaysHook {
  startDate: Date | null;
  endDate: Date | null;
}

const useDays = (rangesDaysOptions: RangesDaysOptions): UseDaysHook => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const setDaysRange = useCallback(() => {
    const today = new Date();

    switch (rangesDaysOptions) {
      case RangesDaysOptionsEnum.TODAY:
        setStartDate(today);
        setEndDate(null);
        break;

      case RangesDaysOptionsEnum.YESTERDAY:
        setStartDate(subDays(today, 1));
        setEndDate(null);
        break;

      case RangesDaysOptionsEnum.DAYS3:
        setStartDate(subDays(today, 2));
        setEndDate(today);
        break;

      case RangesDaysOptionsEnum.DAYS7:
        setStartDate(subDays(today, 6));
        setEndDate(today);
        break;
    }
  }, [rangesDaysOptions]);

  useEffect(() => {
    setDaysRange();
  }, [rangesDaysOptions, setDaysRange]);

  return { startDate, endDate };
};

export default useDays;
