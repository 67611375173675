import {
  CheckboxBackground,
  CheckboxCheckmark,
  CheckboxComponentWrapper,
  CheckboxName,
  CheckboxWrapper,
} from "./custom-checkbox.styles";
import clsx from "clsx";

interface ICustomCheckbox {
  onCheckboxClick: () => void;
  isChecked: boolean;
  checkboxHeading: string;
  changeCheckFunction?: () => void;
  isDisabled?: boolean;
}

const CustomCheckbox = ({
  onCheckboxClick,
  isChecked,
  checkboxHeading,
  changeCheckFunction,
  isDisabled,
}: ICustomCheckbox) => (
  <CheckboxComponentWrapper className={clsx(isDisabled && "disabled")}>
    <CheckboxName>{checkboxHeading}</CheckboxName>
    <CheckboxWrapper
      className="custom-checkbox"
      onClick={(e) => {
        e.preventDefault();
        onCheckboxClick();
        changeCheckFunction?.();
      }}
    >
      <CheckboxBackground
        className={clsx(isChecked && "checked", isDisabled && "disabled")}
      >
        <CheckboxCheckmark className="checkbox-checkmark"></CheckboxCheckmark>
      </CheckboxBackground>
    </CheckboxWrapper>
  </CheckboxComponentWrapper>
);

export default CustomCheckbox;
