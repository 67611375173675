import { isEqual } from "lodash";
import { ITemperatureRange } from "../../../interfaces/dashboard";

interface IRangeFormData {
  lowAttention: number | string;
  lowNormal: number | string;
  highNormal: number | string;
  highAttention: number | string;
}

interface IGetIsDataChanged<T> {
  initialData: T;
  newData: T;
}

export const getIsDataChanged = <T,>({
  initialData,
  newData,
}: IGetIsDataChanged<T>): boolean => !isEqual(initialData, newData);

export const formDataToNumbers = (data: any) => {
  const { highGreen, highYellow, lowGreen, lowYellow } =
    data as ITemperatureRange;

  return {
    highAttention: highYellow,
    highNormal: highGreen,
    lowNormal: lowGreen,
    lowAttention: lowYellow,
  };
};

export const newRangeFormDataToNumbers = (data: {}) => {
  const { highAttention, highNormal, lowAttention, lowNormal } =
    data as IRangeFormData;

  if (
    "highAttention" in data &&
    "highNormal" in data &&
    "lowAttention" in data &&
    "lowNormal" in data
  )
    return {
      highAttention: +highAttention,
      highNormal: +highNormal,
      lowNormal: +lowNormal,
      lowAttention: +lowAttention,
    };

  return data;
};
