import { createColumnHelper } from "@tanstack/react-table";
import IconButton from "../../icon-button/icon-button";
import EditManagerSidebar from "../../kyps-sidebar/edit-manager-sidebar/edit-manager-sidebar";
import { IManagerDTO } from "../../../interfaces/managers";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { CellMaxContent } from "../kyps-table.styles";
import AuthWrapper from "../../../auth/auth-wrapper";
import {
  managersRights,
  superAdminRights,
} from "../../../interfaces/auth-wrapper";
import {
  ManagersTableActions,
  ManagersTableHeaderLeft,
  ManagersTableHeaderRight,
  ManagersTableHeaderWrapper,
} from "./managers-table.styles";
import { TFunction } from "i18next";
import { checkDate } from "../../../helpers/check-date.helper";
import { IOpenDeleteConfirm } from "../../../hooks/useDeleteConfirm";
import TooltipCell from "../../kyps-tooltip/tooltip-cell/tooltip-cell";
import { getColumnWidth } from "../kyps-table.utils";
import { Subheading } from "../../../styles/helper.styles";
import KypsSelect, { ISelectOption } from "../../kyps-select/kyps-select";
import { TableSearchWrapper } from "../../dashboard-components/location-dashboard/location-dashboard.styles";
import KypsSearchInput from "../../kyps-search-input/kyps-search-input";
import { KypsButton } from "../../kyps-button/kyps-button";
import AddManagerSidebar from "../../kyps-sidebar/add-manager-sidebar/add-manager-sidebar";
import { UserRoles } from "../../../interfaces/roles";

interface ICreateManagersTableHeader {
  companyId: string | null;
  isLoading: boolean;
  options: ISelectOption[];
  selectedOption: ISelectOption | null;
  userRole: UserRoles;
  closeAndResetModal: () => void;
  handleOnSelect: (option: ISelectOption | null) => void;
  openModal: (modalContent: ReactNode) => void;
  setTableSearchValue: Dispatch<SetStateAction<string>>;
  t: TFunction<"translations", undefined>;
}

interface ICreateManagersTableColumns {
  userId: string;
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  onDeleteManager: (id: string, name: string) => void;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  t: TFunction<"translations", undefined>;
}

const { accessor, display } = createColumnHelper<IManagerDTO>();
export const createManagersTableColumns = ({
  userId,
  openModal,
  closeAndResetModal,
  onDeleteManager,
  openDeleteConfirm,
  t,
}: ICreateManagersTableColumns) => [
  accessor("name", {
    header: t("tables.name"),
    size: getColumnWidth("managers", "name"),
    cell: ({ getValue }) => <TooltipCell value={getValue()} />,
  }),
  accessor("phoneNumber", {
    header: t("tables.phoneNumber"),
    size: getColumnWidth("managers", "phoneNumber"),
    cell: ({ getValue }) => <TooltipCell value={getValue()} />,
  }),
  accessor("telegramId", {
    header: t("tables.telegramId"),
    size: getColumnWidth("managers", "telegramId"),

    cell: ({ getValue }) => (
      <TooltipCell value={getValue() === 0 ? "" : getValue()} />
    ),
  }),
  accessor("email", {
    header: t("tables.email"),
    size: getColumnWidth("managers", "email"),
    cell: ({ getValue }) => <TooltipCell value={getValue()} />,
  }),
  accessor("createdAt", {
    header: t("tables.created"),
    size: getColumnWidth("managers", "createdAt"),
    cell: ({ getValue }) => (
      <CellMaxContent>{checkDate(getValue(), t)}</CellMaxContent>
    ),
  }),
  accessor("updatedAt", {
    header: t("tables.lastUpdate"),
    size: getColumnWidth("managers", "updatedAt"),
    cell: ({ getValue }) => (
      <CellMaxContent>{checkDate(getValue(), t)}</CellMaxContent>
    ),
  }),
  display({
    size: getColumnWidth("managers", "actions"),
    cell: ({
      row: {
        original: { name, email, phoneNumber, id, telegramId },
      },
    }) => (
      <ManagersTableActions>
        <IconButton
          name="pencil"
          hoverColor="info"
          key="pencil"
          hasZeroMargin
          onClick={() =>
            openModal(
              <EditManagerSidebar
                closeModal={closeAndResetModal}
                currentManager={{
                  id,
                  name,
                  email,
                  phoneNumber,
                  telegramId,
                }}
                t={t}
              />
            )
          }
        />
        <AuthWrapper
          allowedRoles={managersRights}
          additionalCheck={userId !== id}
        >
          <IconButton
            name="bin"
            hoverColor="warning"
            key="bin"
            hasZeroMargin
            onClick={(e) =>
              openDeleteConfirm({
                deleteConfirmName: name,
                deleteFunction: () => onDeleteManager(id, name),
                openerParamsProp: e.currentTarget.getBoundingClientRect(),
              })
            }
          />
        </AuthWrapper>
      </ManagersTableActions>
    ),
    id: "actions",
  }),
];

export const createManagersTableHeader = ({
  t,
  options,
  selectedOption,
  isLoading,
  companyId,
  userRole,
  closeAndResetModal,
  handleOnSelect,
  setTableSearchValue,
  openModal,
}: ICreateManagersTableHeader): ReactNode[] => [
  <ManagersTableHeaderWrapper key="ManagersTableHeaderWrapper">
    <ManagersTableHeaderLeft>
      <Subheading>{t("tabs.users")}</Subheading>
      <AuthWrapper
        allowedRoles={superAdminRights}
        children={
          <KypsSelect
            options={options}
            onSelect={handleOnSelect}
            placeholder={`${t("buttons.select.action")}...`}
            selectedOption={selectedOption}
          />
        }
      />
    </ManagersTableHeaderLeft>
    <ManagersTableHeaderRight>
      <TableSearchWrapper style={{ margin: "unset" }}>
        <KypsSearchInput
          placeholder={`${t("buttons.search.action")}...`}
          key="searchUser"
          setInputValue={setTableSearchValue}
          hasLoader
          hasIcon
          loading={isLoading}
        />
      </TableSearchWrapper>
      <KypsButton
        key="Add User"
        placeholder={t("buttons.add.actionWithKey", {
          key: t("entities.user"),
        })}
        onClick={() => {
          openModal(
            <AddManagerSidebar
              companyId={companyId}
              closeModal={closeAndResetModal}
              userRole={userRole}
              t={t}
            />
          );
        }}
        size="large"
      />
    </ManagersTableHeaderRight>
  </ManagersTableHeaderWrapper>,
];
