import { IPositionCoords } from "../interfaces/delete-confirm";

const menuItemHeight = 36;

export const checkElementVisibility = (
  openerSizeProperties: DOMRect,
  menuItemsCount: number
): IPositionCoords => {
  const navBottomHeight = window.innerWidth >= 768 ? 0 : 58;
  const screenHeight = window.innerHeight;
  const { left, bottom, height } = openerSizeProperties;

  const dropdownHeight = menuItemHeight * menuItemsCount;
  const dropdownTotalHeight = height + dropdownHeight;

  const positionX = left + window.scrollX;
  const positionY = bottom + window.scrollY;

  const visibleContentHeight = screenHeight - navBottomHeight;

  const y =
    bottom + dropdownHeight > visibleContentHeight
      ? positionY - dropdownTotalHeight
      : positionY;

  return { x: positionX, y };
};
