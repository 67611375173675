import styled from "@emotion/styled";

export const ImportantDescriptionWrapper = styled.div`
  margin-bottom: 40px;
  max-width: 600px;
`;

export const ImportantHeading = styled.h2`
  color: #111827;
  font-size: 26px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: -0.75px;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    font-size: 30px;
  }
`;

export const ImportantText = styled.p`
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }
`;
