import PageHeader from "../../components/page-header/page-header";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useGetLocationsByCompanyIdQuery } from "../../api/location.api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useMemo, useState } from "react";
import ReportComponent from "../../components/kyps-report-component/kyps-report-component";
import KypsDatePicker from "../../components/kyps-datepicker/kyps-date-picker";
import { useSearchParams } from "react-router-dom";
import CustomCheckbox from "../../components/kyps-custom-checkbox/custom-checkbox";
import AuthWrapper from "../../auth/auth-wrapper";
import { superAdminRights } from "../../interfaces/auth-wrapper";
import TablePagination from "../../components/kyps-table-pagination/kyps-table-pagination";
import { useGetCompanyByIdQuery } from "../../api/companies.api";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { UserRoles, UserRolesEnum } from "../../interfaces/roles";
import { getUserRoles } from "../../auth/get-user-roles.helper";
import { useTranslation } from "react-i18next";
import { ReportPageHeader } from "./reports.styles";
import { KypsButton } from "../../components/kyps-button/kyps-button";
import { useLazySendManualAllLocationsReportQuery } from "../../api/reports.api";
import { handleSendAllReports } from "./reports.helper";
import { format } from "date-fns";

const Reports = () => {
  const { selectedCompany } = useAppSelector((state) => state.company);
  const userRole = useAppSelector(({ user: { user } }) => user?.role);

  const { changeRole } = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isRaw, setIsRaw] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [startDate, setStartDate] = useState(new Date());

  const companyId = useMemo(() => searchParams.get("company"), [searchParams]);

  const { t } = useTranslation();

  useEffect(() => {
    const handleRole = (role: UserRoles) => changeRole(role);

    getUserRoles({
      url: companyId ?? selectedCompany?.value ?? "",
      handleOnChangeRole: handleRole,
    });

    if (!companyId && selectedCompany)
      setSearchParams(`company=${selectedCompany.value}`);
  }, [changeRole, companyId, selectedCompany, setSearchParams]);

  const { data: apiLocationsData } = useGetLocationsByCompanyIdQuery(
    companyId ?? skipToken
  );
  const { data: apiCompanyData } = useGetCompanyByIdQuery(
    companyId ?? skipToken
  );
  const [sendAllLocationReport] = useLazySendManualAllLocationsReportQuery();

  const companyCreatedDate = useMemo(() => {
    if (apiCompanyData) return new Date(apiCompanyData.createdAt);

    return null;
  }, [apiCompanyData]);

  const itemsPerPage = 7;
  const pageCount = useMemo(() => {
    if (apiLocationsData)
      return Math.ceil(apiLocationsData.length / itemsPerPage);

    return 0;
  }, [apiLocationsData]);

  const paginatedData = useMemo(() => {
    if (apiLocationsData) {
      const startIndex = currentPage * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      const sorted = [...apiLocationsData].sort(
        ({ name: aName }, { name: bName }) => aName.localeCompare(bName)
      );

      return sorted.slice(startIndex, endIndex);
    }
    return [];
  }, [apiLocationsData, currentPage]);

  const content = useMemo(() => {
    if (apiLocationsData)
      return paginatedData.map(({ id }) => (
        <ReportComponent
          key={id}
          id={id}
          date={startDate}
          raw={isRaw}
          t={t}
          userRole={userRole ?? UserRolesEnum.USER}
        />
      ));

    return null;
  }, [apiLocationsData, paginatedData, startDate, isRaw, t, userRole]);

  return (
    <>
      <PageHeader pageName={t("pages.reports.name")}></PageHeader>
      <ReportPageHeader>
        <AuthWrapper
          allowedRoles={superAdminRights}
          children={
            <CustomCheckbox
              isChecked={isRaw}
              onCheckboxClick={() => setIsRaw(!isRaw)}
              checkboxHeading={t("buttons.show.actionWithKey", {
                key: t("entities.rawData"),
              })}
            />
          }
        />
        <KypsDatePicker
          startDate={startDate}
          setStartDate={setStartDate}
          minDate={companyCreatedDate}
          t={t}
        />
        <AuthWrapper allowedRoles={superAdminRights}>
          <KypsButton
            onClick={() =>
              handleSendAllReports({
                date: format(startDate, "yyyy-MM-dd"),
                sendReports: sendAllLocationReport,
              })
            }
            placeholder={t("buttons.send.actionWithKey", {
              key: t("tables.dailyReportAllCompanies"),
            })}
            variant="active"
            iconType="email"
            hasIcon
          />
        </AuthWrapper>
      </ReportPageHeader>
      {content}
      {pageCount >= 2 && (
        <TablePagination
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageCount={pageCount}
        />
      )}
    </>
  );
};

export default Reports;
