import { useRef, useState, useEffect, useMemo, useCallback } from "react";
import KypsSidebar from "../kyps-sidebar";
import { KypsButton } from "../../kyps-button/kyps-button";
import KypsForm from "../../kyps-form/kyps-form";
import { useAddSensorMutation } from "../../../api/sensor.api";
import { createAddSensorFields } from "./add-sensor-sidebar.utils";
import KypsSelect, { ISelectOption } from "../../kyps-select/kyps-select";
import {
  useGetIPointByIdQuery,
  useGetIPointsByZoneIdQuery,
} from "../../../api/installation-point.api";
import { isEmpty } from "lodash";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  createIPointSelectOptions,
  fillRangeFields,
  handleOnAddSensor,
} from "./add-sensor-sidebar.helper";
import RangeForm, { ICustomErrorData } from "../../kyps-range-form/range-form";
import CustomCheckbox from "../../kyps-custom-checkbox/custom-checkbox";
import { IAddRangeTemperatureFields } from "../add-installation-point-sidebar/add-installation-point-sidebar.utils";
import { rangeTemperatureCheck } from "../add-installation-point-sidebar/add-installation-point-sidebar.helper";
import { SensorConfigsWrapper } from "../../kyps-custom-checkbox/custom-checkbox.styles";
import { UncontrolledFieldsHolder } from "../kyps-sidebar.styles";
import { TFunction } from "i18next";
import { ImperativeHandleRef } from "../../kyps-range-form/range-form.utils";

export interface IAddSensorFormFields {
  sensorNumber: string;
  sensorName: string;
}

interface IAddSensorSidebar {
  zonesOptions: ISelectOption[];
  defaultZone: ISelectOption;
  defaultIPoint?: ISelectOption;
  isFixedValue?: boolean;
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
}

const AddSensorSidebar = ({
  zonesOptions,
  defaultZone,
  defaultIPoint,
  isFixedValue,
  closeModal,
  t,
}: IAddSensorSidebar): JSX.Element => {
  const [formData, setFormData] = useState({
    nameForm: {},
    rangeForm: {},
  });
  const [customRangeErrors, setCustomRangeErrors] = useState<
    ICustomErrorData[]
  >([]);
  const [customIPointError, setCustomIPointError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedZoneOption, setSelectedZoneOption] =
    useState<ISelectOption | null>(null);
  const [selectedIPointOption, setSelectedIPointOption] =
    useState<ISelectOption | null>(null);
  const [isCustomConfig, setIsCustomConfig] = useState(false);
  const [showHumidity, setShowHumidity] = useState(false);

  const [addSensor] = useAddSensorMutation();
  const { data: IPointsByZoneId } = useGetIPointsByZoneIdQuery(
    selectedZoneOption && !isEmpty(selectedZoneOption.value)
      ? selectedZoneOption.value
      : skipToken
  );
  const { data: apiIPointInAllZoneData } = useGetIPointByIdQuery(
    !isEmpty(selectedIPointOption?.value) && selectedIPointOption
      ? selectedIPointOption?.value
      : skipToken
  );

  const iPointInAllZoneData = useMemo(
    () => apiIPointInAllZoneData ?? null,
    [apiIPointInAllZoneData]
  );

  const onAddSensor = useCallback(() => {
    if (isSubmitting) return;
    if (selectedIPointOption?.value) {
      handleOnAddSensor({
        addSensor,
        formData,
        isCustomConfig,
        selectedIPointOption,
        setIsSubmitting,
        showHumidity,
      });
    }
  }, [
    addSensor,
    formData,
    isCustomConfig,
    isSubmitting,
    selectedIPointOption,
    showHumidity,
  ]);

  useEffect(() => {
    if (!isEmpty(formData.nameForm) && !isEmpty(formData.rangeForm)) {
      closeModal();
      onAddSensor();
      setFormData({ nameForm: {}, rangeForm: {} });
    }
  }, [closeModal, formData, onAddSensor]);

  const sensorFormRef = useRef<HTMLFormElement>(null);
  const rangeFormRef = useRef<ImperativeHandleRef>(null);

  const handleAddSensorFormSubmit = () => {
    sensorFormRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
    rangeFormRef.current?.submit();

    if (!rangeFormRef.current) return setCustomIPointError(true);
  };

  const iPoints = useMemo(() => {
    if (defaultIPoint) return [defaultIPoint];
    setSelectedIPointOption({ label: "", value: "" });

    if (IPointsByZoneId) return createIPointSelectOptions(IPointsByZoneId);
    return [];
  }, [IPointsByZoneId, defaultIPoint]);

  useEffect(() => {
    setSelectedZoneOption(defaultZone);
    if (defaultIPoint) {
      setSelectedIPointOption(defaultIPoint);
    }
  }, [defaultIPoint, defaultZone]);

  const handleOnZoneSelect = (option: ISelectOption | null) => {
    if (option) {
      setSelectedZoneOption(option);
    }
  };

  const handleOnIPointSelect = (option: ISelectOption | null) => {
    if (option) {
      setSelectedIPointOption(option);
      setCustomIPointError(false);
      setIsCustomConfig(false);
    }
  };

  const editFields = useMemo(() => {
    if (iPointInAllZoneData)
      return fillRangeFields({
        range: iPointInAllZoneData.temperatureRange,
        t,
      });

    return [];
  }, [iPointInAllZoneData, t]);

  const onSubmitAddSensorForm = (data: IAddSensorFormFields) => {
    if (data) {
      setFormData((prev) => ({ ...prev, nameForm: data }));
    }
  };

  const onSubmitRangeTemperature = (data: IAddRangeTemperatureFields) => {
    if (data) {
      rangeTemperatureCheck({
        data,
        setCustomErrors: setCustomRangeErrors,
        setFormData,
        t,
      });
    }
  };

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: t("buttons.add.actionWithKey", { key: t("entities.sensor") }),
        buttons: [
          <KypsButton
            key="Cancel"
            placeholder={t(
              isFixedValue ? "buttons.back.action" : "buttons.cancel.action"
            )}
            onClick={closeModal}
            variant="light"
          />,
          <KypsButton
            key="Add sensor"
            placeholder={t("buttons.add.actionWithKey", {
              key: t("entities.sensor"),
            })}
            onClick={handleAddSensorFormSubmit}
          />,
        ],
      }}
      content={[
        <KypsForm
          fields={createAddSensorFields(t)}
          onSubmit={onSubmitAddSensorForm}
          ref={sensorFormRef}
          uncontrolledFields={
            <UncontrolledFieldsHolder>
              <KypsSelect
                key="selectZone"
                placeholder={`${t("buttons.select.action")}...`}
                options={zonesOptions}
                size="large"
                onSelect={handleOnZoneSelect}
                selectedOption={selectedZoneOption}
                label={t("tables.zone")}
                isDisable
              />
              <KypsSelect
                key="selectIPoint"
                placeholder={`${t("buttons.select.action")}...`}
                options={iPoints}
                size="large"
                onSelect={handleOnIPointSelect}
                selectedOption={selectedIPointOption}
                label={t("tables.iPoint")}
                isError={customIPointError}
                isDisable={isFixedValue}
              />
            </UncontrolledFieldsHolder>
          }
          key="AddSensorForm"
        />,
        <RangeForm
          key="RangeForm"
          fields={editFields}
          onSubmit={onSubmitRangeTemperature}
          ref={rangeFormRef}
          checkboxes={
            <SensorConfigsWrapper>
              <CustomCheckbox
                isChecked={showHumidity}
                onCheckboxClick={() => setShowHumidity(!showHumidity)}
                checkboxHeading={t("checkboxes.showHumidity")}
                key="Show humidity"
              />
              <CustomCheckbox
                isChecked={isCustomConfig}
                onCheckboxClick={() => {
                  setIsCustomConfig(!isCustomConfig);
                  rangeFormRef.current?.reset();
                }}
                checkboxHeading={t("checkboxes.setupCustomSettings")}
                key="Setup Custom Settings"
              />
            </SensorConfigsWrapper>
          }
          customErrorsData={customRangeErrors}
          showEmptyMessage={isEmpty(editFields)}
          isDisabled={!isCustomConfig}
        />,
      ]}
      subName={defaultIPoint?.label}
    />
  );
};

export default AddSensorSidebar;
