import styled from "@emotion/styled";

export const AboutUsSectionWrapper = styled.div`
  background-color: #1f2937;
`;

export const AboutUsDescriptionContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
