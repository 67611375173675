import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ContactsWrapper = styled.div``;

export const ContactItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    color: ${theme.palette.background.default};

    a {
      margin-left: 12px;
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }

      @media (min-width: 992px) {
        font-size: 18px;
      }
    }
  `}
`;
