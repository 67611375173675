import styled from "@emotion/styled";

export const ChartWrapper = styled.div`
  position: relative;
  height: 70vh;
  max-height: 600px;
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  padding: 20px 20px 40px;
  border-radius: 8px;
`;

export const IconXWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;

  > * {
    margin: 0;
  }
`;
