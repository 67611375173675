import { useEffect, useRef, useState } from "react";
import UserIcon from "../user-icon/user-icon";
import {
  UserActionButton,
  UserActionsWrapper,
  UserDataContainer,
  UserDataWrapper,
  UserDropdownMenu,
  UserDropdownOpener,
  UserDropdownWrapper,
  UserMail,
  UserName,
} from "./user-dropdown.styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface IUserDropdown {
  email: string;
  userName: string;
  dropdownOpener: JSX.Element;
  logout: () => void;
}

const UserDropdown = ({
  email,
  userName,
  dropdownOpener,
  logout,
}: IUserDropdown) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const handleOutsideClick = ({ target }: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <UserDropdownWrapper ref={dropdownRef}>
      <UserDropdownOpener onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        {dropdownOpener}
      </UserDropdownOpener>
      <UserDropdownMenu className={clsx(isDropdownOpen && "visible")}>
        <UserDataWrapper>
          <UserIcon userName={userName} variant="large" />
          <UserDataContainer>
            <UserName>{userName}</UserName>
            <UserMail>{email}</UserMail>
          </UserDataContainer>
        </UserDataWrapper>
        <UserActionsWrapper>
          <UserActionButton
            onClick={() => {
              setIsDropdownOpen(false);
              logout();
            }}
          >
            {t("buttons.signOut.action")}
          </UserActionButton>
        </UserActionsWrapper>
      </UserDropdownMenu>
    </UserDropdownWrapper>
  );
};

export default UserDropdown;
