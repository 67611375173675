import { TFunction } from "i18next";
import { FlagContent, FlagSide, FlagWrapper } from "./flag.styles";

const Flag = ({ t }: { t: TFunction }) => (
  <FlagWrapper>
    <FlagSide></FlagSide>
    <FlagSide className="yellow"></FlagSide>
    <FlagContent>{t("landing.flag")}</FlagContent>
  </FlagWrapper>
);

export default Flag;
