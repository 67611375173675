import { InputField } from "../components/kyps-form/kyps-form-input";

export enum ManagementTabsEnum {
  COMPANIES = "companies",
  USERS = "users",
  INFRASTRUCTURE = "infrastructure",
}

export interface IAddCompanyFields {
  fullName: string;
  shortName: string;
  comment: string;
}

export const addCompanyFormFields: InputField<IAddCompanyFields>[] = [
  {
    label: "Short name",
    name: "shortName",
    type: "text",
    rules: { required: "Is required" },
  },
  {
    label: "Full name",
    name: "fullName",
    type: "text",
    rules: { required: "Is required" },
  },
  {
    label: "Comment",
    name: "comment",
    type: "text",
    rules: {
      required: "Is required",
    },
  },
];
