import { css } from "@emotion/react";
import styled from "@emotion/styled";

const desktopPadding = "24px";
const mobilePadding = "16px";

export const Sidebar = styled.div`
  ${({ theme: { palette } }) => css`
    background-color: ${palette.background.secondary};
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    max-width: 900px;
    width: 100%;
    overflow: hidden auto;
  `}
`;

export const SidebarWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${mobilePadding};
    background-color: ${theme.palette.background.secondary};

    @media (min-width: 768px) {
      padding: ${desktopPadding};
    }
  `}
`;

export const SidebarHeader = styled.div`
  ${({ theme }) => css`
    padding: 12px ${mobilePadding};
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-between;
    align-items: flex-start;
    background: ${theme.palette.background.default};
    box-shadow: 0px 1px 2px 0px rgba(156, 163, 175, 0.06),
      0px 1px 3px 0px rgba(156, 163, 175, 0.1);

    &.reverse {
      flex-direction: row-reverse;

      h3 {
        margin-left: 6px;
      }
    }

    @media (min-width: 768px) {
      padding: 12px ${desktopPadding};
    }
  `}
`;

export const SidebarHeading = styled.h3`
  ${({ theme }) => css`
    flex: 1;
    color: ${theme.palette.text.primary};
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    min-width: 210px;
  `}
`;

export const SubName = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    font: inherit;
    color: ${theme.palette.text.default};

    &::after {
      content: "/";
      display: inline-block;
      padding: 0 4px;
      color: inherit;
    }
  `}
`;

export const ButtonsHolder = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  button {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export const UncontrolledFieldsHolder = styled.div`
  label {
    margin-bottom: 16px;
  }
`;

export const CompanyUserSwitcher = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-top: 16px;
`;

export const CompanyAdminSwitcher = styled.div`
  margin-top: 16px;
`;

export const CompanyUserSwitchersHolder = styled.div`
  display: flex;
  gap: 12px;
`;
