import KypsTooltip from "../kyps-tooltip";
import { FixedCell } from "./tooltip-cell.styles";

interface ITooltipCell {
  value: number | string;
  variant?: TooltipCellVariant;
}

type TooltipCellVariant = "link-cell" | "bold" | "dark" | "clickable";

const TooltipCell = ({ value, variant }: ITooltipCell) => (
  <KypsTooltip tooltipValue={value}>
    <FixedCell className={variant}>{value}</FixedCell>
  </KypsTooltip>
);

export default TooltipCell;
