import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ViewCheckboxWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: 1px solid ${theme.palette.background.light};
    border-radius: 4px;
    max-height: 34px;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: auto;

    @media (min-width: 768px) {
      max-height: 40px;
    }
  `}
`;

export const ViewCheckboxItem = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    background-color: ${theme.palette.background.default};

    svg path {
      fill: ${theme.palette.text.default};
    }

    &.active {
      cursor: pointer;
      background-color: ${theme.palette.text.default};

      svg path {
        stroke: ${theme.palette.background.default};
      }
    }

    > div {
      margin: 0;
    }

    @media (min-width: 768px) {
      width: 48px;
    }
  `}
`;
