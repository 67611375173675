import {
  IAddLocation,
  ILocation,
  ILocationData,
} from "../interfaces/locations";
import { kypsApi } from "./kyps.api";

export const locationControllerEndpoints = kypsApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocationsByCompanyId: builder.query<ILocation[], string>({
      query: (id) => ({
        url: `/companies/${id}/locations`,
        method: "GET",
      }),
      providesTags: ["Locations"],
    }),

    addLocationByCompanyId: builder.mutation<ILocationData, IAddLocation>({
      query: ({ id, data }) => ({
        url: `/companies/${id}/locations`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Locations", "Dashboard"],
    }),

    getLocationById: builder.query<ILocation, string>({
      query: (locationId) => ({
        url: `/locations/${locationId}`,
        method: "GET",
      }),
      providesTags: ["Locations"],
    }),

    updateLocationById: builder.mutation<ILocation, IAddLocation>({
      query: ({ id, data }) => ({
        url: `/locations/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Locations", "Dashboard"],
    }),

    deleteLocationById: builder.mutation<void, string>({
      query: (id) => ({
        url: `/locations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Locations", "Dashboard"],
    }),
  }),
});

export const {
  useGetLocationsByCompanyIdQuery,
  useAddLocationByCompanyIdMutation,
  useGetLocationByIdQuery,
  useUpdateLocationByIdMutation,
  useDeleteLocationByIdMutation,
} = locationControllerEndpoints;
