import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useAttachManagerToCompanyMutation,
  useDetachManagerFromCompanyMutation,
  useGetCompanyManagersByCompanyIdQuery,
  useToggleIsCompanyAdminMutation,
} from "../../../api/company-manager.api";
import { ReactNode, useMemo, useState } from "react";
import {
  PaginationState,
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { KypsTable } from "../kyps-table";
import { createCompanyManagersColumns } from "./company-managers-table.utils";
import {
  IManagerDTO,
  ISortByKeysCompanyManager,
} from "../../../interfaces/managers";
import {
  createSelectCompanyUsersArray,
  handleOnAttachManager,
  handleOnToggleAdmin,
} from "./company-managers-table.helper";
import KypsSelect, { ISelectOption } from "../../kyps-select/kyps-select";
import { KypsButton } from "../../kyps-button/kyps-button";
import { useSearchManagerAutocompleteQuery } from "../../../api/managers.api";
import { onSelectValueChange } from "../attach-user-table/attach-user-table.helper";
import CustomCheckbox from "../../kyps-custom-checkbox/custom-checkbox";
import AuthWrapper from "../../../auth/auth-wrapper";
import { superAdminRights } from "../../../interfaces/auth-wrapper";
import { CompanyManagersHeader } from "./company-managers.table.styles";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { TFunction } from "i18next";

interface ICompanyManagersTable {
  companyId: string | null;
  t: TFunction<"translations", undefined>;
}

const baseSortObj: {
  property: keyof ISortByKeysCompanyManager;
  direction: "ASC" | "DESC";
} = { property: "name", direction: "ASC" };

const CompanyManagersTable = ({ companyId, t }: ICompanyManagersTable) => {
  const userId = useAppSelector(({ user }) => user.user?.id ?? "");

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: baseSortObj.property,
      desc: baseSortObj.direction === "DESC",
    },
  ]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 7,
  });
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [selectInputValue, setSelectInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<ISelectOption | null>(
    null
  );

  const { data: apiCompanyManagers } = useGetCompanyManagersByCompanyIdQuery(
    companyId
      ? {
          companyId,
          page: pageIndex,
          size: pageSize,
          sort: [
            sorting.length
              ? {
                  property: sorting[0].id as keyof ISortByKeysCompanyManager,
                  direction: sorting[0].desc ? "DESC" : "ASC",
                }
              : baseSortObj,
          ],
        }
      : skipToken
  );
  const [toggleIsAdmin] = useToggleIsCompanyAdminMutation();
  const [attachManagerToCompany] = useAttachManagerToCompanyMutation();
  const [detachManager] = useDetachManagerFromCompanyMutation();
  const { data: foundData, isLoading: isSearchingUser } =
    useSearchManagerAutocompleteQuery(
      selectInputValue.length
        ? { value: selectInputValue, page: 0, size: pageSize }
        : skipToken
    );

  const tableData = useMemo(() => {
    if (apiCompanyManagers?.content)
      return [...apiCompanyManagers.content].sort(
        ({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB)
      );

    return [];
  }, [apiCompanyManagers?.content]);

  const selectData = useMemo(() => {
    if (foundData)
      return createSelectCompanyUsersArray(foundData.content, tableData);

    return [];
  }, [foundData, tableData]);

  const onToggleIsAdmin = (selectedManager: IManagerDTO) => {
    const { companies, id } = selectedManager;
    handleOnToggleAdmin({
      companyId: companyId as string,
      isAdmin: companies[0].admin,
      managerId: id,
      toggleIsAdmin,
    });
  };

  const handleOnSelect = (option: ISelectOption | null) => {
    if (option) setSelectedOption(option);
  };

  const onAttachManager = () => {
    if (selectedOption && companyId) {
      setIsAdmin(false);
      setSelectedOption(null);

      handleOnAttachManager({
        admin: isAdmin,
        companyId,
        managerId: selectedOption.value,
        attachManagerToCompany,
      });
    }
  };

  const AttachUsersTableHeader: ReactNode[] = [
    <AuthWrapper
      key="AttachUsersTableHeader"
      allowedRoles={superAdminRights}
      children={
        <CompanyManagersHeader key="CompanyManagersHeader">
          <KypsSelect
            placeholder={`${t("buttons.search.action")}...`}
            options={selectData}
            onSelect={handleOnSelect}
            selectedOption={selectedOption}
            onInputChange={(value) =>
              onSelectValueChange(value, setSelectInputValue)
            }
            isLoading={isSearchingUser}
            size="large"
          />
          <CustomCheckbox
            checkboxHeading={t("attachTables.companyUsers.setAdmin")}
            isChecked={isAdmin}
            onCheckboxClick={() => {
              setIsAdmin(!isAdmin);
            }}
          />
          <KypsButton
            placeholder={t("buttons.attach.actionWithKey", {
              key: t("entities.user"),
            })}
            onClick={onAttachManager}
            size="large"
          />
        </CompanyManagersHeader>
      }
    />,
  ];

  const table = useReactTable({
    data: tableData,
    columns: createCompanyManagersColumns({
      companyId: companyId as string,
      userId,
      onToggleIsAdmin,
      detachManager,
      t,
    }),
    state: {
      pagination: { pageIndex, pageSize },
      sorting,
    },
    manualPagination: true,
    pageCount: apiCompanyManagers?.totalPages ?? undefined,
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    sortDescFirst: false,
    enableSortingRemoval: false,
  });

  return (
    <KypsTable
      table={table}
      tableHeaderElements={AttachUsersTableHeader}
      tableName={t("attachTables.companyUsers.tableName")}
    />
  );
};

export default CompanyManagersTable;
