import { ReactNode, RefObject } from "react";
import {
  PageActionsSection,
  PageHeaderWrapper,
  PageHeading,
  PageInfoSection,
} from "./page-header.styles";

interface IPageHeader {
  pageName: string;
  pageActions?: ReactNode[];
  headerRef?: RefObject<HTMLDivElement>;
}

const PageHeader = ({
  pageName,
  pageActions,
  headerRef,
}: IPageHeader): JSX.Element => (
  <PageHeaderWrapper ref={headerRef}>
    <PageInfoSection>
      <PageHeading>{pageName}</PageHeading>
    </PageInfoSection>
    <PageActionsSection>{pageActions}</PageActionsSection>
  </PageHeaderWrapper>
);

export default PageHeader;
