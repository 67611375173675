import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/store";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useMemo } from "react";
import ActionCreators from "../redux/actions";

export const useAppDispatch = () => {
  const dispatch = useDispatch<AppDispatch>();
  const actionCreators = useMemo(
    () => bindActionCreators(ActionCreators, dispatch),
    [dispatch]
  );

  return actionCreators;
};
