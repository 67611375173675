import { TFunction } from "i18next";
import { LContainer, SectionContainer } from "../../landing-content.styles";
import { HeroDescription, HeroHeading } from "./hero-section.styles";

const HeroSection = ({ t }: { t: TFunction }) => (
  <LContainer>
    <SectionContainer className="small">
      <HeroHeading>
        {t("landing.hero.heading.base")}
        <span>{t("landing.hero.heading.important")}</span>
      </HeroHeading>
      <HeroDescription>{t("landing.hero.text")}</HeroDescription>
    </SectionContainer>
  </LContainer>
);

export default HeroSection;
