import { IconNames } from "./icon-names";

export enum AppLanguagesEnum {
  EN = "en",
  UA = "ua",
}

export type AppLanguages = AppLanguagesEnum.EN | AppLanguagesEnum.UA;

export const LanguageNames: Record<AppLanguages, string> = {
  en: "English",
  ua: "Українська",
};

export const LanguageIcons: Record<AppLanguages, IconNames> = {
  en: "gbFlag",
  ua: "uaFlag",
};
