import { createColumnHelper } from "@tanstack/react-table";
import { ILocationManager } from "../../../interfaces/location-managers";
import { IAttachLocationTableFields } from "../../../interfaces/tables";
import { ISelectOption } from "../../kyps-select/kyps-select";
import IconButton from "../../icon-button/icon-button";
import {
  DetachFunction,
  handleOnToggleUserNotification,
  onDetachLocation,
} from "./attach-location-table.helper";
import Icon from "../../icon/icon";
import { UpdateManagerNotificationsFunction } from "../../../interfaces/update-functions";
import { AttachTableCheckbox } from "../attach-user-table/attach-user-table.styles";
import { TFunction } from "i18next";
import TooltipCell from "../../kyps-tooltip/tooltip-cell/tooltip-cell";

interface ICreateAttachLocationTableColumns {
  currentManager: string;
  detachFunction: DetachFunction;
  toggleLocationNotification: UpdateManagerNotificationsFunction;
  t: TFunction<"translations", undefined>;
}

export const locationsResponseTransformer = (
  data: ILocationManager[]
): IAttachLocationTableFields[] =>
  data
    .map(
      ({
        location: { name, id, address },
        notifyEmail,
        notifyTelegram,
        role,
      }) => ({
        locationName: name,
        locationAddress: address,
        emailNotification: notifyEmail,
        telegramNotification: notifyTelegram,
        locationId: id,
        role,
      })
    )
    .sort(({ locationName: nameA }, { locationName: nameB }) =>
      nameA.localeCompare(nameB)
    );

export const getUniqueLocations = (
  allCompanyLocations: ISelectOption[],
  attachedLocations: ILocationManager[]
) =>
  allCompanyLocations.filter(
    ({ value }) => !attachedLocations.some((item) => item.location.id === value)
  );

const { accessor, display } = createColumnHelper<IAttachLocationTableFields>();

export const createAttachLocationTableColumns = ({
  currentManager,
  detachFunction,
  toggleLocationNotification,
  t,
}: ICreateAttachLocationTableColumns) => [
  accessor("locationName", {
    header: t("tables.location"),
    size: 240,
    cell: ({ getValue }) => <TooltipCell value={getValue()} variant="bold" />,
  }),
  accessor("role", {
    header: t("tables.userRole"),
    size: 170,
    cell: ({ getValue }) => <>{getValue()}</>,
  }),
  accessor("emailNotification", {
    header: () => <Icon iconName="email" hasZeroMargin />,
    size: 84,
    cell: ({
      getValue,
      row: {
        original: {
          emailNotification,
          telegramNotification,
          role,
          locationId,
          locationName,
        },
      },
    }) => (
      <AttachTableCheckbox
        name="emailNotification"
        type="checkbox"
        checked={getValue()}
        onChange={() =>
          handleOnToggleUserNotification({
            locationId,
            locationName,
            managerId: currentManager,
            notifyEmail: !emailNotification,
            notifyTelegram: telegramNotification,
            role,
            toggleLocationNotification,
            changedField: "email",
          })
        }
      />
    ),
  }),
  accessor("telegramNotification", {
    header: () => <Icon iconName="telegram" hasZeroMargin />,
    size: 84,
    cell: ({
      getValue,
      row: {
        original: {
          emailNotification,
          telegramNotification,
          role,
          locationId,
          locationName,
        },
      },
    }) => (
      <AttachTableCheckbox
        name="telegramNotification"
        type="checkbox"
        checked={getValue()}
        onChange={() =>
          handleOnToggleUserNotification({
            locationId,
            locationName,
            managerId: currentManager,
            notifyEmail: emailNotification,
            notifyTelegram: !telegramNotification,
            role,
            toggleLocationNotification,
            changedField: "telegram",
          })
        }
      />
    ),
  }),
  display({
    header: t("buttons.detach.action"),
    size: 118,
    cell: ({
      row: {
        original: { locationId, locationName },
      },
    }) => (
      <IconButton
        hoverColor="darken"
        name="detach"
        onClick={() =>
          onDetachLocation({
            data: { locationId, managerId: currentManager },
            detachFunction,
            locationName,
          })
        }
      />
    ),
    id: "actions",
  }),
];
