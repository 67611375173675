import { isEmpty } from "lodash";
import {
  IGetLocationFullStatsResponse,
  ILocationInstallationPointWithZone,
  ILocationZone,
  ISensorCardWithZone,
} from "../../../interfaces/dashboard";
import { ISelectOption } from "../../kyps-select/kyps-select";
import { getZoneData } from "../../kyps-tables/sensors-table/sensors-table.helper";
import { checkDate } from "../../../helpers/check-date.helper";
import { TFunction } from "i18next";
import { AllZonesValue } from "../../kyps-tables/location-table/location-table.helper";
import { sortingFunction } from "../../kyps-tables/kyps-table.helper";

export const createZonesSelectOptions = ({
  zones,
}: IGetLocationFullStatsResponse): ISelectOption[] => {
  if (isEmpty(zones)) return [];

  return zones.map(({ name, id }) => {
    return { label: name, value: id };
  });
};

export const getDefaultZoneSelectOption = (
  zones: ILocationZone[],
  t: TFunction<"translations", undefined>
): ISelectOption => {
  if (zones.length >= 2)
    return { label: t("tables.allZones"), value: AllZonesValue };
  return { label: zones[0].name, value: zones[0].id };
};

export const getAllZonesData = ({ zones }: IGetLocationFullStatsResponse) => {
  return getZoneData(getPointsWithZone(zones));
};

const getPointsWithZone = (
  zones: ILocationZone[]
): ILocationInstallationPointWithZone[] => {
  return zones.reduce(
    (accumulator: ILocationInstallationPointWithZone[], zone) => {
      const installationPointsWithZone = zone.installationPoints.map(
        (point) => ({
          ...point,
          zone: { label: zone.name, value: zone.id },
        })
      );

      return accumulator.concat(installationPointsWithZone);
    },
    []
  );
};

export const getAllZonesDataGrid = (
  zones: ILocationZone[]
): ISensorCardWithZone[] => {
  const pointsWithZone = getPointsWithZone(zones);

  const gridSensors = pointsWithZone.flatMap(
    ({ sensors, id: iPointId, name, zone }) => {
      return sensors.map((sensor) => {
        const {
          customConfig,
          id,
          lastDbm,
          lastHumidity,
          lastTemperature,
          lastUpdateDate,
          lastVoltage,
          sensorNumber,
          showHumidity,
          state,
          temperatureRange: { highGreen, highYellow, lowGreen, lowYellow },
          sensorName,
        } = sensor;

        const baseObj: ISensorCardWithZone = {
          battery: lastVoltage,
          currentState: state,
          currentTemperature: toRoundValue(lastTemperature),
          lowAttention: toRoundValue(lowYellow),
          lowNormal: toRoundValue(lowGreen),
          highNormal: toRoundValue(highGreen),
          highAttention: toRoundValue(highYellow),
          sensorName,
          sensorNumber,
          iPoint: {
            value: iPointId,
            label: name,
          },
          signal: lastDbm,
          time: checkDate(lastUpdateDate),
          sensorId: id,
          custom: customConfig,
          zone,
          showHumidity,
        };

        if (showHumidity)
          return {
            ...baseObj,
            currentHumidity: toRoundValue(lastHumidity),
          };

        return baseObj;
      });
    }
  );

  return gridSensors.sort(
    ({ iPoint: { label: nameA } }, { iPoint: { label: nameB } }) =>
      sortingFunction(nameA, nameB)
  );
};

export const toRoundValue = (value: number) => {
  return value ? +value.toFixed(1) : value;
};
