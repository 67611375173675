import styled from "@emotion/styled";

export const LandingIconWrapper = styled.div`
  &.clickable {
    cursor: pointer;

    &:hover {
      path {
        fill: #7a7a7e;
      }
    }
  }
`;
