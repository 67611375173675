import { ReactNode, useCallback, useMemo } from "react";
import { StatsItem } from "../../../stats-item/stats-item";
import { StatsItemHolder } from "../../../stats-item/stats-item.styles";
import {
  IGetCompanyFullStatsResponse,
  IGetCompanyStatsResponse,
} from "../../../../interfaces/dashboard";
import {
  SensorStateVariant,
  SensorStateVariantEnum,
} from "../../../../interfaces/sensors";
import StatsSidebar from "../../../kyps-sidebar/stats-sidebar/stats-sidebar";
import { TFunction } from "i18next";

interface ICompanyStats {
  apiCompanyStats: IGetCompanyStatsResponse | undefined;
  companyId: string | null;
  companyFullStats: IGetCompanyFullStatsResponse | undefined;
  companyCreatedDate: Date | null;
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  t: TFunction<"translations", undefined>;
}

const CompanyStats = ({
  companyId,
  apiCompanyStats,
  companyCreatedDate,
  companyFullStats,
  openModal,
  closeAndResetModal,
  t,
}: ICompanyStats) => {
  const onStatsCard = useCallback(
    (variant: SensorStateVariant) => {
      openModal(
        <StatsSidebar
          closeModal={closeAndResetModal}
          variant={variant}
          companyId={companyId}
          stateVariant={variant}
          companyLocations={companyFullStats?.content}
          minDate={companyCreatedDate}
        />
      );
    },
    [
      closeAndResetModal,
      companyCreatedDate,
      companyFullStats?.content,
      companyId,
      openModal,
    ]
  );

  const statsItems = useMemo(() => {
    if (apiCompanyStats) {
      const {
        sensorStats: { online, attention, total, outRange, offline },
      } = apiCompanyStats;

      return [
        <StatsItem
          name={t("statsCards.online")}
          value={online}
          variant={SensorStateVariantEnum.ONLINE}
          detail={total > 0 ? total : ""}
          onClick={onStatsCard}
          key="online"
        />,
        <StatsItem
          name={t("statsCards.attention")}
          value={attention}
          variant={SensorStateVariantEnum.ORANGE}
          onClick={onStatsCard}
          key="attention"
        />,
        <StatsItem
          name={t("statsCards.warning")}
          value={outRange}
          variant={SensorStateVariantEnum.RED}
          onClick={onStatsCard}
          key="warning"
        />,
        <StatsItem
          name={t("statsCards.offline")}
          value={offline}
          variant={SensorStateVariantEnum.OFF}
          onClick={onStatsCard}
          key="offline"
        />,
      ];
    }

    return [];
  }, [apiCompanyStats, onStatsCard, t]);

  return <StatsItemHolder>{statsItems}</StatsItemHolder>;
};

export default CompanyStats;
