import { TFunction } from "i18next";
import { useUpdateZoneMutation } from "../../../api/zone.api";
import { IUpdateZone } from "../../../interfaces/zones";
import { IAddZoneFormFields } from "../add-zone-sidebar/add-zone-sidebar";
import { createAddZoneFields } from "../add-zone-sidebar/add-zone-sidebar.utils";
import { createNotification } from "../../toaster/toaster.helper";

type EditZoneResult = ReturnType<typeof useUpdateZoneMutation>;
type EditZoneFunction = EditZoneResult[0];

interface IHandleOnEditZone {
  data: IUpdateZone;
  editZone: EditZoneFunction;
  closeModal: () => void;
}

export const handleOnEditZone = ({
  data,
  editZone,
  closeModal,
}: IHandleOnEditZone) => {
  closeModal();

  editZone(data)
    .unwrap()
    .then(({ name }) =>
      createNotification({
        message: `${name} was successfully edited`,
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};

export const fillEditZone = (
  zoneData: any,
  t: TFunction<"translations", undefined>
) =>
  createAddZoneFields(t).map((field) => {
    const value = zoneData[field.name as keyof IAddZoneFormFields] ?? "";
    return { ...field, value };
  });
