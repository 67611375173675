import { SectionQuoteWrapper } from "./section-quote.styles";

interface ISectionQuote {
  quoteContent: string;
}

const SectionQuote = ({ quoteContent }: ISectionQuote) => {
  return <SectionQuoteWrapper>{quoteContent}</SectionQuoteWrapper>;
};

export default SectionQuote;
