import { ReactNode } from "react";
import {
  EmptyPageHeading,
  EmptyPageMessage,
  EmptyPageWrapper,
  IconContainer,
  MessageContainer,
} from "./kyps-empty-page.styles";
import { PageIconNames } from "../../utils/empty-page-icons";
import clsx from "clsx";

export interface IEmptyPage {
  heading: string;
  iconName: PageIconNames;
  message?: string;
  actionButton?: ReactNode;
  isSmall?: boolean;
  hasMargin?: boolean;
}

const EmptyPage = ({
  actionButton,
  heading,
  message,
  iconName,
  isSmall,
  hasMargin,
}: IEmptyPage) => (
  <EmptyPageWrapper
    className={clsx(isSmall && "small", hasMargin && "has-margin")}
  >
    <IconContainer className={clsx(iconName)}>
      <MessageContainer>
        <EmptyPageHeading>{heading}</EmptyPageHeading>
        {message?.length && <EmptyPageMessage>{message}</EmptyPageMessage>}
      </MessageContainer>
      {actionButton}
    </IconContainer>
  </EmptyPageWrapper>
);

export default EmptyPage;
