import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { showMobileNavWidth } from "../kyps-nav/kyps-nav.styles";

export const bottomNavHeight = "58px";

export const BottomNavWrapper = styled.div`
  ${({ theme: { palette, zIndex } }) => css`
    position: sticky;
    bottom: 0;
    background-color: ${palette.background.default};
    box-shadow: 0px -2px 10px 1px #0000001a;
    z-index: ${zIndex.bottomNav};

    @media (min-width: ${showMobileNavWidth}) {
      display: none;
    }

    a {
      display: inline-block;
      padding: 8px 4px;
      border-bottom: none;
      border-top: 2px solid transparent;

      &:hover {
        border-color: ${palette.border.default};
        color: ${palette.text.primary};
        opacity: 0.8;
      }

      &.active {
        border-color: ${palette.border.default};
        color: ${palette.text.primary};

        &:hover {
          opacity: 1;
        }
      }
    }
  `}
`;

export const BottomNavLinksList = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    padding: 0 16px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-top: 2px solid transparent;
      height: ${bottomNavHeight};

      &:hover {
        border-color: ${theme.palette.border.default};
        color: ${theme.palette.text.primary};
        opacity: 0.8;
      }

      &.active {
        border-top-color: ${theme.palette.border.default};
        color: ${theme.palette.text.primary};

        &:hover {
          opacity: 1;
        }
      }
    }

    @media (min-width: 482px) {
      gap: 24px;
    }

    li:not(:last-child) {
      margin: 0;
    }
  `}
`;

export const BottomNavLinkItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 6px 0;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.background.light};
    border-radius: 0 0 6px 6px;
    cursor: pointer;
  `}
`;
