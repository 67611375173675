import styled from "@emotion/styled";

export const NotificationsSectionWrapper = styled.div`
  background-color: #f8fbfb;
`;

export const NotificationsDescriptionContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto 40px;
`;

export const DetailsContainer = styled.div``;

export const ImageDetailsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;

  &.reverse {
    @media (min-width: 992px) {
      flex-direction: row-reverse;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 56px;
  }

  & > :not(:last-of-type) {
    @media (min-width: 992px) {
      margin-right: 40px;
    }
  }

  &.reverse > :first-of-type {
    @media (min-width: 992px) {
      margin: 0 0 0 40px;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px -10px 10px -5px rgba(0, 0, 0, 0.04),
    0px 20px 25px -5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;

  &.has-padding {
    padding: 10px 10px 0;
  }
`;
