import clsx from "clsx";
import { CustomLabel } from "../kyps-tables/kyps-table.styles";
import KypsTooltip from "../kyps-tooltip/kyps-tooltip";
import {
  SensorStatsName,
  SensorStatsNameWrapper,
  SensorSubName,
} from "./sensor-range-component.styles";

interface ISensorNameComponent {
  name: string;
  subName?: string | null;
  isCustom?: boolean;
  isActive?: boolean;
  isClickable?: boolean;
  onClick?: () => void;
}

const SensorNameComponent = ({
  name,
  isCustom,
  subName,
  isActive = true,
  isClickable = true,
  onClick,
}: ISensorNameComponent) => (
  <SensorStatsNameWrapper>
    <SensorStatsName
      className={clsx(isActive && "active", isClickable && "clickable")}
    >
      <KypsTooltip tooltipValue={name} onClick={onClick} />
      {isCustom && <CustomLabel>Custom</CustomLabel>}
    </SensorStatsName>
    {subName && (
      <SensorSubName>
        <KypsTooltip tooltipValue={subName} />
      </SensorSubName>
    )}
  </SensorStatsNameWrapper>
);

export default SensorNameComponent;
