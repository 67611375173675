import { useMemo, useState } from "react";
import KypsSelect, { ISelectOption } from "../kyps-select";
import { t } from "i18next";
import {
  createOptions,
  getSensorState,
  handleOnUpdateSensorState,
} from "./sensor-state-select.helper";
import { SensorStateVariant } from "../../../interfaces/sensors";
import { useUpdateSensorStateMutation } from "../../../api/sensor.api";
import { SensorStateSelectWrapper } from "./sensor-state-select.styles";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { UserRolesEnum } from "../../../interfaces/roles";

interface ISensorStateSelect {
  sensorState: SensorStateVariant;
  sensorId: string;
}

const SensorStateSelect = ({ sensorState, sensorId }: ISensorStateSelect) => {
  const userRole =
    useAppSelector(({ user: { user } }) => user?.role) ?? UserRolesEnum.USER;
  const options = useMemo(() => createOptions(t), []);
  const [selectedOption, setSelectedOption] = useState<ISelectOption | null>(
    getSensorState({ options, sensorState, t })
  );

  const [updateSensorState] = useUpdateSensorStateMutation();

  const onUpdateSensorState = (select: ISelectOption) => {
    if (select.value === selectedOption?.value) return;

    setSelectedOption(select);
    handleOnUpdateSensorState({
      sensorId,
      state: select.value as SensorStateVariant,
      updateSensorState,
    });
  };

  return (
    <SensorStateSelectWrapper>
      <p>{`${t("statsCards.sensorState.heading")}: `}</p>
      <KypsSelect
        onSelect={onUpdateSensorState}
        options={options}
        placeholder={t("buttons.choose.action")}
        selectedOption={selectedOption}
        isDisable={userRole === UserRolesEnum.USER}
      />
    </SensorStateSelectWrapper>
  );
};

export default SensorStateSelect;
