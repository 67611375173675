import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 24px;
  gap: 12px;

  @media (min-width: 768px) {
    padding-bottom: 32px;
  }
`;

export const PageInfoSection = styled.div`
  display: flex;
  align-items: center;
`;

export const PageActionsSection = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  margin-left: auto;

  > :not(:last-child) {
    margin-right: 12px;
  }
`;

export const BreadCrumbs = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.default};
    font-weight: 500;
  `}
`;

export const PageHeading = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.primary};
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    max-width: 760px;
  `}
`;
