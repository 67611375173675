import { isArray } from "lodash";
import { toast } from "react-toastify";
import { ErrorMessageItem } from "./toaster.styles";

type SingleNotification = {
  variant: "success" | "error" | "loading";
  message: string;
};

type NotificationWithArray = {
  variant: "error";
  message: string[];
  heading: string;
};

type ICreateNotification = SingleNotification | NotificationWithArray;

const hasHeading = (
  props: ICreateNotification
): props is NotificationWithArray =>
  (props as { heading: string }).heading !== undefined;

export const createNotification = (props: ICreateNotification) => {
  if (props.variant === "success") return toast.success(props.message);
  if (props.variant === "loading") return toast.loading(props.message);

  if (isArray(props.message))
    if (hasHeading(props))
      return toast.error(
        <>
          <p>{props.heading}</p>
          {props.message.map((messageItem, index) => (
            <ErrorMessageItem key={index}>{messageItem}</ErrorMessageItem>
          ))}
        </>
      );

  return toast.error(props.message);
};
