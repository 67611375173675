import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const DeleteConfirmWrapper = styled.div`
  ${({ theme: { palette, zIndex } }) => css`
    position: absolute;
    z-index: ${zIndex.deleteConfirm};
    padding: 6px 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    border-radius: 8px;
    background-color: ${palette.background.default};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-width: calc(100vw - 24px);

    @media (min-width: 768px) {
      max-width: calc(100vw - 48px);
      padding: 10px 12px;
    }
  `}
`;

export const DeleteConfirmMessageWrapper = styled.div`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${theme.palette.text.primary};
    max-width: 220px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 22px;
      max-width: 300px;
    }
  `}
`;

export const ConfirmMessageText = styled.div`
  width: max-content;
`;

export const ConfirmMessageNameWrapper = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    color: ${theme.palette.text.default};
    max-width: 100%;
    word-wrap: break-word;

    span {
      color: ${theme.palette.text.primary};
    }
  `}
`;

export const ConfirmButtons = styled.div`
  display: flex;

  button:not(:last-child) {
    margin-right: 8px;
  }
`;
