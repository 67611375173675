import { TFunction } from "i18next";
import { InputField } from "../../kyps-form/kyps-form-input";

export interface ILocationFormData {
  name: string;
  address: string;
  comments: string;
}

export const createAddLocationFields = (
  t: TFunction<"translations", undefined>
): InputField<ILocationFormData>[] => {
  return [
    {
      label: t("forms.fieldNames.locationName"),
      name: "name",
      type: "text",
      rules: {
        required: `${t("forms.messages.isRequiredWithKey", {
          labelName: t("forms.fieldNames.locationName"),
        })}`,
      },
      placeholder: t("forms.fieldNames.locationName"),
    },
    {
      label: t("forms.fieldNames.address"),
      name: "address",
      type: "text",
      rules: {
        required: `${t("forms.messages.isRequiredWithKey", {
          labelName: t("forms.fieldNames.address"),
        })}`,
      },
      placeholder: t("forms.fieldNames.address"),
    },
    {
      label: t("forms.fieldNames.comments"),
      name: "comments",
      type: "text",
      placeholder: t("forms.fieldNames.comments"),
    },
  ];
};
