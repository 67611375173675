import {
  IAddCompany,
  IAddCompanyResponse,
  ICompany,
  IEditCompany,
  IPaginatedResponse,
  IPaginationQuery,
  ISortByKeysCompany,
} from "../interfaces/companies";
import { kypsApi } from "./kyps.api";

export const companyControllerEndpoints = kypsApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCompanies: builder.query<ICompany[], void>({
      query: () => ({
        url: "/companies",
        method: "GET",
      }),
      providesTags: ["Companies"],
    }),

    getPageOfCompanies: builder.query<
      IPaginatedResponse<ICompany>,
      IPaginationQuery<ISortByKeysCompany>
    >({
      query: ({
        page,
        size,
        sort = [{ direction: "DESC", property: "createdAt" }],
      }) => {
        const sortParams = sort
          .map(({ property, direction }) => `${property}%2C${direction}`)
          .join("&");

        return {
          url: `/companies/paginated?page=${page}&size=${size}&sort=${sortParams}`,
          method: "GET",
        };
      },
      providesTags: ["Companies"],
    }),

    getCompanyById: builder.query<ICompany, string>({
      query: (id) => ({
        url: `/companies/${id}`,
        method: "GET",
      }),
      providesTags: ["Companies"],
    }),

    updateCompanyById: builder.mutation<ICompany, IEditCompany>({
      query: (data) => ({
        url: `/companies/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Companies", "Dashboard"],
    }),

    deleteCompanyById: builder.mutation<void, string>({
      query: (id) => ({
        url: `/companies/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Companies"],
    }),

    addCompany: builder.mutation<IAddCompanyResponse, IAddCompany>({
      query: (data) => ({
        url: "/companies",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Companies"],
    }),
  }),
});

export const {
  useGetPageOfCompaniesQuery,
  useGetAllCompaniesQuery,
  useGetCompanyByIdQuery,
  useAddCompanyMutation,
  useDeleteCompanyByIdMutation,
  useUpdateCompanyByIdMutation,
} = companyControllerEndpoints;
