import { useRef } from "react";
import KypsSidebar from "../kyps-sidebar";
import KypsForm from "../../kyps-form/kyps-form";
import { KypsButton } from "../../kyps-button/kyps-button";
import { createAddZoneFields } from "./add-zone-sidebar.utils";
import { useAddZoneMutation } from "../../../api/zone.api";
import { TFunction } from "i18next";
import { handleOnSubmitAddZone } from "./add-zone-sidebar..helper";

export interface IAddZoneFormFields {
  name: string;
}

export interface IAddZoneSidebar {
  currentLocationId: string;
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
}

const AddZoneSidebar = ({
  currentLocationId,
  closeModal,
  t,
}: IAddZoneSidebar): JSX.Element => {
  const [addZone] = useAddZoneMutation();
  const formRef = useRef<HTMLFormElement>(null);
  const handleAddZoneFormSubmit = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmitAddZone = ({ name }: IAddZoneFormFields) =>
    handleOnSubmitAddZone({
      locationId: currentLocationId,
      zoneName: name,
      addZone,
      closeModal,
    });

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: t("buttons.add.actionWithKey", { key: t("entities.zone") }),
        buttons: [
          <KypsButton
            placeholder={t("buttons.cancel.action")}
            onClick={closeModal}
            variant="light"
            key="Cancel"
          />,
          <KypsButton
            placeholder={t("buttons.add.actionWithKey", {
              key: t("entities.zone"),
            })}
            onClick={handleAddZoneFormSubmit}
            key="Add zone"
          />,
        ],
      }}
      content={[
        <KypsForm
          fields={createAddZoneFields(t)}
          onSubmit={onSubmitAddZone}
          ref={formRef}
          key="AddZoneForm"
        />,
      ]}
    />
  );
};

export default AddZoneSidebar;
