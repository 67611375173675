import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Arrow from "../../../assets/Chevron.svg";

export const LocationRoleSelectWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    border: 1px solid ${theme.palette.background.light};
    border-radius: 4px;
    padding: 4px 24px 4px 8px;
    cursor: pointer;
    min-width: 120px;
    text-transform: capitalize;

    &.open::after,
    &:hover::after {
      filter: brightness(0.5);
    }

    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 8px;
      margin: auto 0;
      content: "";
      width: 12px;
      height: 6px;
      pointer-events: none;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${Arrow});
    }

    &.disabled {
      background-color: ${theme.palette.background.secondary};
      pointer-events: none;
      cursor: auto;
    }
  `}
`;

export const RoleSelectOptionHolder = styled.div`
  ${({ theme: { palette, zIndex } }) => css`
    position: absolute;
    z-index: ${zIndex.deleteConfirm};
    top: 100%;
    left: -1px;
    min-width: 120px;
    background-color: ${palette.background.default};
    border: 1px solid ${palette.background.light};
    border-radius: 4px;
    overflow: hidden;
    display: none;

    &.open {
      display: block;
    }
  `}
`;

export const RoleSelectOption = styled.div`
  ${({ theme }) => css`
    padding: 4px 8px;
    text-transform: capitalize;
    font-size: 12px;
    cursor: pointer;

    &.selected {
      background-color: ${theme.palette.background.disable};

      &:hover {
        background-color: ${theme.palette.background.disable};
      }
    }

    &:hover {
      background-color: ${theme.palette.background.light};
    }

    @media (min-width: 768px) {
      font-size: 14px;
    }
  `}
`;
