import clsx from "clsx";
import { PaginationItem } from "./pagination-button.styles";

export interface IPaginationButton {
  value: number | string;
  onClick?: () => void;
  className?: string;
}

const PaginationButton = ({
  onClick,
  value,
  className,
}: IPaginationButton): JSX.Element => {
  return (
    <PaginationItem className={clsx(className)} onClick={onClick}>
      {value}
    </PaginationItem>
  );
};

export default PaginationButton;
