import { IImageDetails } from "../../components/image-details/image-details";
import notification from "../../../../assets/landing-images/notification.svg";
import report from "../../../../assets/landing-images/report.svg";
import { TFunction } from "i18next";

export const createTranslatedNotificationsData = (
  t: TFunction<"translations", undefined>
): IImageDetails[] => [
  {
    imageSrc: notification,
    imageAlt: t("landing.notification.notificationDescription.label"),
    title: t("landing.notification.notificationDescription.label"),
    description: t("landing.notification.notificationDescription.description"),
    detailsItems: [
      {
        iconName: "bell",
        title: t("landing.notification.cards.bell.title"),
        description: t("landing.notification.cards.bell.description"),
      },
      {
        iconName: "shield",
        title: t("landing.notification.cards.shield.title"),
        description: t("landing.notification.cards.shield.description"),
      },
    ],
  },
  {
    imageSrc: report,
    imageAlt: "Report",
    title: t("landing.notification.analyticsDescription.label"),
    description: t("landing.notification.analyticsDescription.description"),
    detailsItems: [
      {
        iconName: "trend",
        title: t("landing.notification.cards.trend.title"),
        description: t("landing.notification.cards.trend.description"),
      },
    ],
    isReverse: true,
    hasPadding: true,
  },
];
