import { css } from "@emotion/react";
import styled from "@emotion/styled";
import calendar from "../../assets/Calendar.svg";
import chevronRight from "../../assets/Chevron-right.svg";

export const DatePickerWrapper = styled.div`
  ${({ theme: { palette, zIndex } }) => css`
    position: relative;
    z-index: ${zIndex.datepicker};
    max-width: 226px;
    width: 100%;

    @media (min-width: 768px) {
      max-width: 256px;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    input {
      width: 100%;
      background-image: url(${calendar});
      background-repeat: no-repeat;
      background-position: left 9px top 6px;
      background-size: 16px, 10px 6px;
      padding: 6px 9px 6px 32px;
      border: 1px solid ${palette.background.disable};
      color: ${palette.text.primary};
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      @media (min-width: 768px) {
        background-position: left 13px top 9px;
        background-size: 18px, 10px 6px;
        padding: 9px 13px 9px 40px;
        font-size: 14px;
        line-height: 20px;
      }

      &:focus-visible {
        outline-color: ${palette.background.dark};
      }

      ::placeholder {
        color: ${palette.text.default};
      }
    }

    &.with-range {
      .react-datepicker {
        display: flex;
      }

      .react-datepicker__navigation--next {
        left: 210px;
      }
    }

    .react-datepicker-popper {
      min-width: 100%;
    }
    .react-datepicker {
      width: 100%;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }
    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__children-container {
      width: 100%;
      padding: 8px;
      margin: 0;
    }
  `}
`;

export const PickerArrow = styled.div`
  ${({ theme }) => css`
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 16px;
    background-image: url(${chevronRight});
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &.back {
      right: 32px;
      background-image: url(${chevronRight});
      transform: rotate(180deg);
    }

    &:hover {
      filter: drop-shadow(0px 0px 8px ${theme.palette.text.primary});
      transition: all.3s ease;
    }

    &.disabled {
      cursor: auto;
      filter: opacity(0.5);
      pointer-events: none;
    }
  `}
`;

export const RangeWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;
