import { Dispatch, SetStateAction, ReactNode } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { FlexDiv } from "../../../styles/helper.styles";
import IconButton from "../../icon-button/icon-button";
import { TreeCellWrapper, TreeTableNameCell } from "./tree-table.styles";
import clsx from "clsx";
import {
  chooseIcon,
  chooseTooltipValue,
  createTreeDropdownMenuItems,
  getIsAllowedLocation,
  onDeleteHelper,
} from "./tree-table.helper";
import { CustomLabel } from "../kyps-table.styles";
import { ITreeTableData } from "../../../interfaces/tables";
import AuthWrapper from "../../../auth/auth-wrapper";
import { UserRoles } from "../../../interfaces/roles";
import { sortingFunction } from "../kyps-table.helper";
import { managersRights } from "../../../interfaces/auth-wrapper";
import { IDeleteFunctions } from "../../../interfaces/delete-function";
import { TFunction } from "i18next";
import KypsTooltip from "../../kyps-tooltip/kyps-tooltip";
import { IOpenDropdownMenu } from "../../../hooks/useDropdownMenu";
import { IOpenDeleteConfirm } from "../../../hooks/useDeleteConfirm";
import { checkElementVisibility } from "../../../helpers/check-element-visibility";
import { getColumnWidth } from "../kyps-table.utils";
import { DropdownOpenerWrapper } from "../../dropdown-menu/dropdown-menu.styles";

interface ICreateTreeTableColumns {
  deleteFunctions: IDeleteFunctions;
  selectedId: string;
  userRole: UserRoles;
  isMenuOpen: boolean;
  openModal: (modalContent: ReactNode) => void;
  setSelectedId: Dispatch<SetStateAction<string>>;
  closeAndResetModal: () => void;
  openDropdownMenu: (openDropdownMenuProps: IOpenDropdownMenu) => void;
  openDeleteConfirm: (openDeleteConfirmProps: IOpenDeleteConfirm) => void;
  t: TFunction<"translations", undefined>;
}

const setCellPadding = (depth: number) => {
  const baseDepthPadding =
    document.documentElement.clientWidth >= 768 ? 20 : 10;
  const basePadding = document.documentElement.clientWidth >= 768 ? 16 : 8;

  return depth * baseDepthPadding + basePadding + "px";
};

const { accessor, display } = createColumnHelper<ITreeTableData>();

export const createTreeTableColumns = ({
  deleteFunctions,
  selectedId,
  userRole,
  isMenuOpen,
  openModal,
  setSelectedId,
  closeAndResetModal,
  openDeleteConfirm,
  openDropdownMenu,
  t,
}: ICreateTreeTableColumns) => [
  accessor("name", {
    header: ({
      table: { getToggleAllRowsExpandedHandler, getIsAllRowsExpanded },
    }) => (
      <KypsTooltip
        tooltipValue={
          getIsAllRowsExpanded()
            ? t("buttons.collapse.actionWithKey", {
                key: t("entities.table"),
              })
            : t("buttons.expand.actionWithKey", { key: t("entities.table") })
        }
      >
        <FlexDiv
          className="clickable"
          onClick={getToggleAllRowsExpandedHandler()}
        >
          <IconButton name="toLine" hoverColor="darken" />
          {t("tables.name")}
        </FlexDiv>
      </KypsTooltip>
    ),
    sortingFn: (rowA, rowB) =>
      sortingFunction(rowA.original.name.value, rowB.original.name.value),
    size: getColumnWidth("treeTable", "name"),
    cell: ({
      row: { getCanExpand, getToggleExpandedHandler, getIsExpanded, depth },
      getValue,
    }) => (
      <div
        onClick={getToggleExpandedHandler()}
        style={{ paddingLeft: setCellPadding(depth) }}
      >
        <KypsTooltip
          tooltipValue={chooseTooltipValue({
            depthNumber: depth,
            value: getValue().value,
            t,
          })}
        >
          <TreeCellWrapper className={clsx(getCanExpand() && "clickable")}>
            {getCanExpand() && (
              <IconButton
                isExpanded={getIsExpanded()}
                name="caret"
                hoverColor="darken"
                hasZeroMargin
              />
            )}
            {chooseIcon(depth)}
            {depth ? (
              <TreeTableNameCell>{getValue().value}</TreeTableNameCell>
            ) : (
              getValue().value
            )}
            {getValue().isCustom && <CustomLabel>Custom</CustomLabel>}
          </TreeCellWrapper>
        </KypsTooltip>
      </div>
    ),
  }),
  display({
    size: getColumnWidth("treeTable", "actions"),
    cell: ({
      row: {
        depth,
        original: {
          id,
          name: { value },
        },
        getParentRow,
      },
    }) => (
      <AuthWrapper
        allowedRoles={managersRights}
        additionalCheck={getIsAllowedLocation({
          id,
          depth,
          userRole,
          getParentRow,
        })}
      >
        <DropdownOpenerWrapper
          className={clsx(isMenuOpen && selectedId === id && "open", "light")}
        >
          <IconButton
            name="vDots"
            hoverColor="none"
            color={depth > 0 ? "light" : ""}
            hasZeroMargin
            onClick={(e) => {
              e.stopPropagation();

              const openerParams = e.currentTarget.getBoundingClientRect();
              const menuItems = createTreeDropdownMenuItems({
                userRole,
                depth,
                openModal,
                id,
                closeModal: closeAndResetModal,
                name: value,
                parentId: getParentRow()?.original.id,
                parentName: getParentRow()?.original.name.value,
                grandParentName:
                  getParentRow()?.getParentRow()?.original.name.value,
                grandParentId: getParentRow()?.getParentRow()?.original.id,
                onDeleteButton: () =>
                  openDeleteConfirm({
                    deleteConfirmName: value,
                    deleteFunction: () => {
                      onDeleteHelper({
                        deleteFunctions,
                        depth,
                        id,
                        name: value,
                      });
                    },
                    openerParamsProp: openerParams,
                  }),
                t,
              });

              setSelectedId(id);

              openDropdownMenu({
                isDropdownMenuOpen: true,
                menuItems,
                position: checkElementVisibility(
                  openerParams,
                  menuItems.length
                ),
              });
            }}
          />
        </DropdownOpenerWrapper>
      </AuthWrapper>
    ),
    id: "actions",
  }),
];
