import { TFunction } from "i18next";
import { KypsButton } from "../../kyps-button/kyps-button";

interface ISidebarHeaderButtons {
  closeModal: () => void;
  handleEditLocationFormSubmit: () => void;
  t: TFunction<"translations", undefined>;
}

export const sidebarHeaderButtons = ({
  closeModal,
  handleEditLocationFormSubmit,
  t,
}: ISidebarHeaderButtons) => [
  <KypsButton
    variant="light"
    placeholder={t("buttons.cancel.action")}
    onClick={closeModal}
    key="Cancel"
  />,
  <KypsButton
    variant="default"
    placeholder={t("buttons.edit.actionWithKey", {
      key: t("entities.location"),
    })}
    onClick={handleEditLocationFormSubmit}
    key="Edit location"
  />,
];
