import { useCallback, useEffect, useRef, useState } from "react";
import KypsSidebar from "../kyps-sidebar";
import { KypsButton } from "../../kyps-button/kyps-button";
import KypsForm from "../../kyps-form/kyps-form";
import { useAddIPointMutation } from "../../../api/installation-point.api";
import {
  IAddRangeTemperatureFields,
  createAddIPointFields,
  createRangeTemperatureFields,
} from "./add-installation-point-sidebar.utils";
import RangeForm, { ICustomErrorData } from "../../kyps-range-form/range-form";
import { isEmpty } from "lodash";
import {
  handleOnAddIPoint,
  rangeTemperatureCheck,
} from "./add-installation-point-sidebar.helper";
import { TFunction } from "i18next";
import { ImperativeHandleRef } from "../../kyps-range-form/range-form.utils";

export interface IAddIPointFormFields {
  name: string;
}

export interface IAddIPointSidebar {
  zoneId: string;
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
}

const AddIPointSidebar = ({
  zoneId,
  closeModal,
  t,
}: IAddIPointSidebar): JSX.Element => {
  const [formData, setFormData] = useState({
    nameForm: {},
    rangeForm: {},
  });

  const [customErrors, setCustomErrors] = useState<ICustomErrorData[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [addIPoint] = useAddIPointMutation();

  const onAddIPoint = useCallback(() => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const { name } = formData.nameForm as IAddIPointFormFields;
    const { lowNormal, highNormal, lowAttention, highAttention } =
      formData.rangeForm as IAddRangeTemperatureFields;

    handleOnAddIPoint({
      addIPoint,
      highAttention,
      highNormal,
      lowAttention,
      lowNormal,
      name,
      zoneId,
    });
  }, [addIPoint, formData.nameForm, formData.rangeForm, isSubmitting, zoneId]);

  useEffect(() => {
    if (!isEmpty(formData.nameForm) && !isEmpty(formData.rangeForm)) {
      closeModal();
      onAddIPoint();
    }
  }, [closeModal, formData, onAddIPoint]);

  const nameFormRef = useRef<HTMLFormElement>(null);
  const rangeFormRef = useRef<ImperativeHandleRef>(null);

  const handleAddIPointFormSubmit = () => {
    nameFormRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
    rangeFormRef.current?.submit();
  };

  const onSubmitAddCompany = (data: IAddIPointFormFields) => {
    if (data) {
      setFormData((prev) => ({ ...prev, nameForm: data }));
    }
  };

  const onSubmitRangeTemperature = (data: IAddRangeTemperatureFields) => {
    if (data) {
      rangeTemperatureCheck({
        data,
        setCustomErrors,
        setFormData,
        t,
      });
    }
  };

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: t("buttons.add.actionWithKey", {
          key: t("entities.iPoint"),
        }),
        buttons: [
          <KypsButton
            key="Cancel"
            placeholder={t("buttons.cancel.action")}
            onClick={closeModal}
            variant="light"
          />,
          <KypsButton
            key="Add Installation Point"
            placeholder={t("buttons.add.actionWithKey", {
              key: t("entities.iPoint"),
            })}
            onClick={handleAddIPointFormSubmit}
          />,
        ],
      }}
      content={[
        <KypsForm
          fields={createAddIPointFields(t)}
          onSubmit={onSubmitAddCompany}
          ref={nameFormRef}
          key="AddCompanyForm"
        />,
        <RangeForm
          fields={createRangeTemperatureFields(t)}
          onSubmit={onSubmitRangeTemperature}
          ref={rangeFormRef}
          customErrorsData={customErrors}
          key="AddRangeTemperature"
          preventReset
        />,
      ]}
    />
  );
};

export default AddIPointSidebar;
