import { useTranslation } from "react-i18next";
import { NavLinkItem, NavLinksList } from "./kyps-nav.styles";
import { NavLink } from "react-router-dom";
import { PagesPathsEnum } from "../../utils/pages-paths";

const KypsNav = () => {
  const { t } = useTranslation();

  return (
    <NavLinksList>
      <NavLinkItem>
        <NavLink to={PagesPathsEnum.DASHBOARD}>
          {t("pages.dashboard.name")}
        </NavLink>
      </NavLinkItem>
      <NavLinkItem>
        <NavLink to={PagesPathsEnum.MANAGEMENT}>
          {t("pages.management.name")}
        </NavLink>
      </NavLinkItem>
      <NavLinkItem>
        <NavLink to={PagesPathsEnum.REPORTS}>{t("pages.reports.name")}</NavLink>
      </NavLinkItem>
    </NavLinksList>
  );
};

export default KypsNav;
