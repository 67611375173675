import styled from "@emotion/styled";

export const StatsSectionWrapper = styled.div`
  background-color: #1f2937;
`;

export const StatsSectionDescription = styled.div`
  max-width: 992px;
  margin: 0 auto;
`;

export const StatsTime = styled.h2`
  font-size: 32px;
  font-weight: 800;
  line-height: 100%;
  text-align: center;
  color: #fff;
  margin: 40px 0 8px;

  @media (min-width: 480px) {
    font-size: 36px;
  }

  @media (min-width: 768px) {
    font-size: 42px;
  }

  @media (min-width: 992px) {
    font-size: 48px;
  }
`;

export const StatsTimeDescription = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #c7d2fe;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;
