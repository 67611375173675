import styled from "@emotion/styled";

export const InstPointCell = styled.div`
  max-width: 280px;
  width: 100%;
  padding-right: 24px;
  display: flex;
  align-items: center;

  &.clickable {
    cursor: pointer;
  }

  &.sub-row {
    padding-left: 24px;
    max-width: 210px;
  }
`;

export const LocationSensorNameCell = styled.div`
  display: inline-block;
  max-width: 100%;
  padding-left: 22px;
`;
