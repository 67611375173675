import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ReportHeaderCellWrapper = styled.div`
  height: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;

  &.hours {
    width: 38px;
  }
`;

export const ReportHeaderCellContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-color: ${theme.palette.background.default};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;

    &.name {
      padding-left: 12px;
      justify-content: flex-start;
    }
  `}
`;

export const IdCell = styled.span`
  ${({ theme }) => css`
    max-width: 60px;
    color: ${theme.palette.border.primary};
    padding: 0 5px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
      cursor: auto;
      color: ${theme.palette.text.default};
    }
  `}
`;

export const ReportBodyCellWrapper = styled.div`
  padding: 1px;
  width: 100%;
  height: 100%;
`;

export const ReportBodyCellContent = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.default};
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    &.name-cell {
      padding: 10px 12px;
    }
  `}
`;

export const TemperatureReportData = styled.span`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    color: ${theme.palette.border.dark};
    background-color: ${theme.palette.background.disable};
    min-width: 36px;
    min-height: 36px;

    &.green {
      background-color: ${theme.palette.success.secondary};
    }

    &.yellow {
      background-color: ${theme.palette.info.primary};
    }

    &.red {
      background-color: ${theme.palette.error.disable};
    }

    &.not_applicable {
      background-color: ${theme.palette.border.light};
    }

    &.charging,
    &.maintenance {
      background-color: ${theme.palette.text.light};
    }
  `}
`;

export const RangeCell = styled.span`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.default};
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex: 1;
    border-radius: 2px;
    padding: 0 2px;
  `}
`;

export const ReportTableHeadingWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-self: baseline;
    justify-content: space-between;
    padding: 12px 10px;
    border-radius: 4px 4px 0 0;
    background-color: ${theme.palette.background.default};

    && {
      button {
        margin-left: 8px;
        font-size: 12px;
      }
    }
  `}
`;

export const ReportTableHeading = styled.h3`
  ${({ theme }) => css`
    && {
      font-size: 12px;
      font-weight: 700;
      color: ${theme.palette.text.primary};
      display: flex;
      align-items: center;
    }
  `}
`;
