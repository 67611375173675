import { FormData } from "../kyps-range-form/range-input.helper";
import { InputField } from "./kyps-form-input";

export const fillFormDefaultValues = (
  fields: InputField<string>[]
): FormData => {
  const defaultValues: FormData = {};

  fields.forEach((field) => {
    if (typeof field.name === "string")
      defaultValues[field.name] =
        field.value !== undefined ? field.value : undefined;
  });

  return defaultValues;
};
