import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetSensorsByStateQuery } from "../../../api/dashboard.api";
import { SensorStateVariant } from "../../../interfaces/sensors";
import IconButton from "../../icon-button/icon-button";
import SensorsStatsTable from "../../kyps-tables/sensors-stats-table/sensors-stats-table";
import KypsSidebar from "../kyps-sidebar";
import { useMemo } from "react";
import { IFullStatsContent } from "../../../interfaces/dashboard";
import { createSensorsStatsTableData } from "../../kyps-tables/sensors-stats-table/sensors-stats-table.helper";
import { getStatsName } from "./stats-sidebar.helper";
import { useTranslation } from "react-i18next";

interface IStatsSidebar {
  variant: SensorStateVariant;
  companyId: string | null;
  stateVariant: SensorStateVariant | null;
  closeModal: () => void;
  minDate?: Date | null;
  companyLocations?: IFullStatsContent[];
}
const StatsSidebar = ({
  variant,
  companyId,
  stateVariant,
  closeModal,
  companyLocations,
  minDate,
}: IStatsSidebar) => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetSensorsByStateQuery(
    companyId && stateVariant ? { companyId, stateVariant } : skipToken
  );

  const sensorsData = useMemo(() => {
    if (data && companyLocations)
      return createSensorsStatsTableData({
        data,
        companyLocations,
      });

    return [];
  }, [companyLocations, data]);

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: getStatsName({ variant, t }),
        buttons: [<IconButton name="close" onClick={closeModal} key="close" />],
      }}
      content={[
        <SensorsStatsTable
          key="SensorsStatsTable"
          data={sensorsData}
          isLoading={isLoading}
          minDate={minDate}
        />,
      ]}
    />
  );
};

export default StatsSidebar;
