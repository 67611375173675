import { PropsWithChildren } from "react";
import { TooltipValue } from "./kyps-tooltip.styles";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

interface IKypsTooltip {
  tooltipValue: string | number;
  onClick?: () => void;
}

const KypsTooltip = ({
  tooltipValue,
  onClick,
  children,
}: PropsWithChildren<IKypsTooltip>) => (
  <Tippy
    content={tooltipValue}
    delay={[300, 0]}
    touch="hold"
    arrow
    trigger={window.innerWidth >= 768 ? "mouseenter" : "click"}
    hideOnClick={"toggle"}
  >
    <TooltipValue onClick={onClick}>{children ?? tooltipValue}</TooltipValue>
  </Tippy>
);

export default KypsTooltip;
