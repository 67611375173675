import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const SensorHistoryActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  > div {
    margin: 0;
  }
`;

export const ChartOpener = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HeadingChartText = styled.h4`
  ${({ theme }) => css`
    color: ${theme.palette.text.primary};
    font-weight: 500;
    margin-right: 8px;
  `}
`;
