import { RefObject, useCallback, useEffect, useMemo, useRef } from "react";
import {
  RoleSelectOption,
  RoleSelectOptionHolder,
} from "./location-role-select.styles";
import clsx from "clsx";
import { LocationUserRoles, locationRoles } from "../../../interfaces/roles";
import { createPortal } from "react-dom";
import { SelectedDOMRect } from "../../../hooks/useDeleteConfirm";

interface ILocationRoleSelectList {
  isOpen: boolean;
  selectedValue: LocationUserRoles;
  handleOnSelect: (currentSelectValue: LocationUserRoles) => void;
  closeRoleSelectList: () => void;
  container: RefObject<HTMLDivElement>;
  openerParams: SelectedDOMRect;
}

const LocationRoleSelectList = ({
  isOpen,
  selectedValue,
  handleOnSelect,
  closeRoleSelectList,
  container,
  openerParams,
}: ILocationRoleSelectList) => {
  const roleSelectOptionHolderRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (
        roleSelectOptionHolderRef.current &&
        !roleSelectOptionHolderRef.current.contains(event.target as Node)
      )
        closeRoleSelectList();
    },
    [closeRoleSelectList]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, handleOutsideClick]);

  const content = useMemo(
    () => (
      <RoleSelectOptionHolder
        className={clsx(isOpen && "open")}
        ref={roleSelectOptionHolderRef}
        style={{
          left: openerParams.x,
          top: openerParams.y + openerParams.height,
        }}
      >
        {locationRoles.map(({ value }) => {
          const isSelectedRole = selectedValue === value;

          return (
            <RoleSelectOption
              key={value}
              className={clsx(isSelectedRole && "selected")}
              onClick={() => {
                closeRoleSelectList();

                if (isSelectedRole) return;

                handleOnSelect(value as LocationUserRoles);
              }}
            >
              {value.toLocaleLowerCase()}
            </RoleSelectOption>
          );
        })}
      </RoleSelectOptionHolder>
    ),
    [
      closeRoleSelectList,
      handleOnSelect,
      isOpen,
      openerParams.height,
      openerParams.x,
      openerParams.y,
      selectedValue,
    ]
  );

  return createPortal(content, container?.current ?? document.body);
};

export default LocationRoleSelectList;
