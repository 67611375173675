import React from "react";
import {
    ImportantDescriptionWrapper,
  ImportantHeading,
  ImportantText,
} from "./important-description.styles";
export interface IImportantDescription {
  title: string;
  description: string;
}

const ImportantDescription = ({
  title,
  description,
}: IImportantDescription) => {
  return (
    <ImportantDescriptionWrapper>
      <ImportantHeading>{title}</ImportantHeading>
      <ImportantText>{description}</ImportantText>
    </ImportantDescriptionWrapper>
  );
};

export default ImportantDescription;
