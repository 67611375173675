import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18next, { ResourceLanguage } from "i18next";
import enTranslations from "./locales/en/translations.json";
import uaTranslations from "./locales/ua/translations.json";
import { AppLanguages, AppLanguagesEnum } from "./utils/languages";

type ResourcesObject = Record<AppLanguages, Record<string, ResourceLanguage>>;

const resources: ResourcesObject = {
  [AppLanguagesEnum.EN]: {
    translations: enTranslations,
  },
  [AppLanguagesEnum.UA]: {
    translations: uaTranslations,
  },
};

export const defaultNS = "translations";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    fallbackLng: AppLanguagesEnum.EN,
    defaultNS,
    resources,
    interpolation: { escapeValue: false },
  });

export default i18next;
