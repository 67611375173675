import { Fragment } from "react";
import RangeInputBlock, { IRangeInputField } from "./range-input";
import { Control, FieldErrors, FieldValues } from "react-hook-form";
import clsx from "clsx";

export interface ImperativeHandleRef {
  submit: () => void;
  reset: () => void;
  defaultData: {};
}

interface ITemperatureFields {
  fields: IRangeInputField<any>[];
  errors: FieldErrors<FieldValues>;
  control: Control<FieldValues, any>;
  onInputBlur?: () => void;
}

export const lowTemperatureFields = ({
  fields,
  errors,
  control,
  onInputBlur,
}: ITemperatureFields) =>
  fields.map(({ name, type, placeholder, rules, value, variant }, index) => {
    if (index > 1) return <Fragment key={index}></Fragment>;
    return (
      <RangeInputBlock
        name={String(name)}
        control={control}
        type={type}
        errors={errors}
        placeholder={placeholder}
        rules={rules}
        value={value}
        variant={variant}
        key={String(name)}
        onBlur={onInputBlur}
        className={clsx(variant && variant.toString())}
      />
    );
  });

export const highTemperatureFields = ({
  fields,
  errors,
  control,
  onInputBlur,
}: ITemperatureFields) =>
  fields.map(({ name, type, placeholder, rules, value, variant }, index) => {
    if (index <= 1) return <Fragment key={index}></Fragment>;
    return (
      <RangeInputBlock
        name={String(name)}
        control={control}
        type={type}
        errors={errors}
        placeholder={placeholder}
        rules={rules}
        value={value}
        variant={variant}
        key={String(name)}
        onBlur={onInputBlur}
        className={clsx(variant && variant.toString())}
      />
    );
  });
