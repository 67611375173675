import { Dispatch, SetStateAction } from "react";
import { LabelValue, StyledInput } from "../kyps-input/kyps-input.styles";
import { onSelectValueChange } from "../kyps-tables/attach-user-table/attach-user-table.helper";
import { SearchInputLabel } from "./kyps-search-input.styles";
import clsx from "clsx";
import { LoaderDots } from "../../styles/helper.styles";

interface IKypsSearchInput {
  setInputValue: Dispatch<SetStateAction<string>>;
  label?: string;
  placeholder?: string;
  hasLoader?: boolean;
  loading?: boolean;
  hasIcon?: boolean;
}

const KypsSearchInput = ({
  label,
  placeholder,
  hasLoader,
  loading,
  hasIcon,
  setInputValue,
}: IKypsSearchInput) => (
  <SearchInputLabel className={clsx(hasLoader && "has-loader")}>
    {label && <LabelValue>{label}</LabelValue>}
    <StyledInput
      placeholder={placeholder}
      onChange={(e) => {
        onSelectValueChange(e.target.value, setInputValue);
      }}
      className={clsx(hasIcon && "with-icon")}
    />
    {loading && <LoaderDots />}
  </SearchInputLabel>
);

export default KypsSearchInput;
