import AddZoneSidebar from "../../kyps-sidebar/add-zone-sidebar/add-zone-sidebar";
import AddIPointSidebar from "../../kyps-sidebar/add-installation-point-sidebar/add-installation-point-sidebar";
import AddSensorSidebar from "../../kyps-sidebar/add-sensor-sidebar/add-sensor-sidebar";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { IInstallationPoint } from "../../../interfaces/installation-points";
import { KypsButton } from "../../kyps-button/kyps-button";
import LocationDetailsSidebar from "../../kyps-sidebar/location-details-sidebar/location-details-sidebar";
import AuthWrapper from "../../../auth/auth-wrapper";
import { managersRights } from "../../../interfaces/auth-wrapper";
import { getEditData } from "../../kyps-tables/editable-zones-table/editable-zones-table.helper";
import {
  CheckboxInputWrapper,
  LocationHeader,
  TableSearchWrapper,
} from "./location-dashboard.styles";
import ViewCheckbox from "../../view-checkbox/view-checkbox";
import KypsInput from "../../kyps-input/kyps-input";
import KypsSelect, { ISelectOption } from "../../kyps-select/kyps-select";
import { IGetLocationFullStatsResponse } from "../../../interfaces/dashboard";
import { isEmpty } from "lodash";
import { TFunction } from "i18next";
import { IMenuItems } from "../../../interfaces/dropdown-menu";
import { IOpenDropdownMenu } from "../../../hooks/useDropdownMenu";
import { DropdownOpenerWrapper } from "../../dropdown-menu/dropdown-menu.styles";
import { checkElementVisibility } from "../../../helpers/check-element-visibility";
import clsx from "clsx";

interface ICreateDropdownItems {
  verifiedLocationId: string;
  selectedZone: ISelectOption;
  zonesSelectOptions: ISelectOption[];
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  t: TFunction<"translations", undefined>;
  selectedZoneData?: IInstallationPoint[];
}

interface ICreateDashboardButtons {
  verifiedLocationId: string;
  dropdownMenuItems: IMenuItems[];
  locationSensorsData?: IGetLocationFullStatsResponse;
  isDropdownMenuOpen: boolean;
  openDropdownMenu: (openDropdownMenuProps: IOpenDropdownMenu) => void;
  openModal: (modalContent: ReactNode) => void;
  closeAndResetModal: () => void;
  setSelectedZone: Dispatch<SetStateAction<ISelectOption>>;
  setManualSkipToken: Dispatch<SetStateAction<boolean>>;
  t: TFunction<"translations", undefined>;
}

interface ICreateLocationDashboardHeader {
  dashboardZones: ISelectOption[];
  selectedZone: ISelectOption;
  listStyle: boolean;
  handleOnSelect: (option: ISelectOption | null) => void;
  setListStyle: Dispatch<SetStateAction<boolean>>;
  t: TFunction<"translations", undefined>;
}

export const createDropdownItems = ({
  verifiedLocationId,
  selectedZone,
  zonesSelectOptions,
  selectedZoneData,
  openModal,
  closeAndResetModal,
  t,
}: ICreateDropdownItems) => [
  {
    name: t("buttons.add.actionWithKey", { key: t("entities.zone") }),
    onItemClick: () =>
      openModal(
        <AddZoneSidebar
          closeModal={closeAndResetModal}
          currentLocationId={verifiedLocationId}
          t={t}
        />
      ),
  },
  {
    name: t("buttons.add.actionWithKey", { key: t("entities.iPoint") }),
    onItemClick: () =>
      openModal(
        <AddIPointSidebar
          closeModal={closeAndResetModal}
          zoneId={selectedZone.value}
          t={t}
        />
      ),
    isDisabled: isEmpty(selectedZone.value) || selectedZone.value === "All",
  },
  {
    name: t("buttons.add.actionWithKey", { key: t("entities.sensor") }),
    isDisabled: isEmpty(selectedZoneData),
    onItemClick: () =>
      openModal(
        <AddSensorSidebar
          closeModal={closeAndResetModal}
          defaultZone={selectedZone}
          zonesOptions={zonesSelectOptions}
          t={t}
        />
      ),
  },
];

export const createDashboardButtons = ({
  dropdownMenuItems,
  isDropdownMenuOpen,
  locationSensorsData,
  verifiedLocationId,
  openDropdownMenu,
  openModal,
  closeAndResetModal,
  setSelectedZone,
  setManualSkipToken,
  t,
}: ICreateDashboardButtons) => [
  <KypsButton
    key="Location detail"
    placeholder={t("locationDashboard.locationDetails")}
    variant="light"
    onClick={() =>
      openModal(
        <LocationDetailsSidebar
          locationId={verifiedLocationId}
          locationName={locationSensorsData?.name ?? ""}
          zones={getEditData(locationSensorsData)}
          closeModal={closeAndResetModal}
          selectZone={setSelectedZone}
          setManualSkipToken={setManualSkipToken}
          t={t}
        />
      )
    }
  />,
  <AuthWrapper
    key="Add"
    allowedRoles={managersRights}
    children={
      <DropdownOpenerWrapper className={clsx(isDropdownMenuOpen && "open")}>
        <KypsButton
          onClick={(e) => {
            e.stopPropagation();
            openDropdownMenu({
              isDropdownMenuOpen: true,
              menuItems: dropdownMenuItems,
              position: checkElementVisibility(
                e.currentTarget.getBoundingClientRect(),
                dropdownMenuItems.length
              ),
            });
          }}
          placeholder={t("buttons.add.action")}
          hasIcon
          iconType="plus"
        />
      </DropdownOpenerWrapper>
    }
  />,
];

export const createLocationDashboardHeader = ({
  dashboardZones,
  selectedZone,
  listStyle,
  handleOnSelect,
  setListStyle,
  t,
}: ICreateLocationDashboardHeader) => [
  <LocationHeader key="LocationHeader">
    <KypsSelect
      onSelect={handleOnSelect}
      selectedOption={selectedZone}
      options={dashboardZones}
      placeholder={`${t("buttons.select.action")}...`}
    />
    <CheckboxInputWrapper>
      <ViewCheckbox isActive={listStyle} setIsActive={setListStyle} />
      <TableSearchWrapper>
        <KypsInput
          inputValue=""
          type="search"
          name="search"
          placeholder={t("buttons.search.action")}
          hasIcon
        />
      </TableSearchWrapper>
    </CheckboxInputWrapper>
  </LocationHeader>,
];
