import { TFunction } from "i18next";
import { addLocationFunction } from "../../../interfaces/add-functions";
import { ILocationFormData } from "./add-location-sidebar.utils";
import { createNotification } from "../../toaster/toaster.helper";

interface IHandleOnSubmitAddLocation {
  data: ILocationFormData;
  gatewayNumbers: string[];
  companyId: string;
  addLocation: addLocationFunction;
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
}

export const handleOnSubmitAddLocation = ({
  data,
  gatewayNumbers,
  companyId,
  addLocation,
  closeModal,
  t,
}: IHandleOnSubmitAddLocation) => {
  closeModal();

  addLocation({
    data: { ...data, gatewayNumbers },
    id: companyId,
  })
    .unwrap()
    .then(({ name }) =>
      createNotification({
        message: t("notification.success", {
          action: t("notification.actions.created"),
          entity: `${t("entities.location")} ${name}`,
        }),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};
