import { IReportByDate } from "../interfaces/reports";
import { kypsApi } from "./kyps.api";

export const reportsControllerEndpoints = kypsApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportByLocationId: builder.query<
      IReportByDate,
      { id: string; date: string; raw?: boolean }
    >({
      query: ({ id, date, raw = false }) => ({
        url: `/reports/${id}?raw=${raw}&${date}`,
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }),
      providesTags: ["Reports"],
    }),
    sendManualLocationReport: builder.query<
      string,
      { locationId: string; date: string }
    >({
      query: ({ date, locationId }) => ({
        url: `/notification/reports/${locationId}?date=${date}`,
        method: "POST",
      }),
      providesTags: ["Reports"],
    }),
    sendManualAllLocationsReport: builder.query<string, { date: string }>({
      query: ({ date }) => ({
        url: `/notification/reports?date=${date}`,
        method: "POST",
      }),
      providesTags: ["Reports"],
    }),
  }),
});

export const {
  useGetReportByLocationIdQuery,
  useLazySendManualLocationReportQuery,
  useLazySendManualAllLocationsReportQuery,
} = reportsControllerEndpoints;
