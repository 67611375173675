import styled from "@emotion/styled";

export const StepsContainer = styled.div`
  padding-top: 80px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;

  @media (min-width: 992px) {
    gap: 32px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
