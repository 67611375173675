import { useRef } from "react";
import KypsSidebar from "../kyps-sidebar";
import { KypsButton } from "../../kyps-button/kyps-button";
import KypsForm from "../../kyps-form/kyps-form";
import { useAddLocationByCompanyIdMutation } from "../../../api/location.api";
import {
  ILocationFormData,
  createAddLocationFields,
} from "./add-location-sidebar.utils";
import GatewayCreator from "../../gateway-creator/gateway-creator";
import GatewayTable from "../../gateway-creator/gateway-table";
import useGateway from "../../../hooks/useGateway";
import { useTranslation } from "react-i18next";
import { handleOnSubmitAddLocation } from "./add-location-sidebar.helper";

interface IAddLocationSidebar {
  companyId: string;
  closeModal: () => void;
}

const AddLocationSidebar = ({
  companyId,
  closeModal,
}: IAddLocationSidebar): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null);
  const [addLocation] = useAddLocationByCompanyIdMutation();

  const { t } = useTranslation();

  const { gatewayNumbers, addGatewayNumber, removeGatewayNumber } =
    useGateway();

  const handleAddLocationFormSubmit = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmitAddLocation = (data: ILocationFormData) => {
    handleOnSubmitAddLocation({
      data,
      gatewayNumbers,
      companyId,
      addLocation,
      closeModal,
      t,
    });
  };

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: t("buttons.add.actionWithKey", {
          key: t("entities.location"),
        }),
        buttons: [
          <KypsButton
            key="Cancel"
            placeholder={t("buttons.cancel.action")}
            onClick={closeModal}
            variant="light"
          />,
          <KypsButton
            key="Add location"
            placeholder={t("buttons.add.actionWithKey", {
              key: t("entities.location"),
            })}
            onClick={handleAddLocationFormSubmit}
          />,
        ],
      }}
      content={[
        <KypsForm
          fields={createAddLocationFields(t)}
          onSubmit={onSubmitAddLocation}
          ref={formRef}
          key="AddLocationForm"
        />,
        <GatewayCreator
          creatorName={t("buttons.add.actionWithKey", {
            key: t("entities.gatewayNumber"),
          })}
          buttonPlaceholder={t("buttons.add.action")}
          onAddClick={addGatewayNumber}
          gatewayNumbers={gatewayNumbers}
          inputPlaceholder={`${t("entities.gatewayNumber")}...`}
          key="GatewayNumbers"
        />,
        <GatewayTable
          gatewayNumbers={gatewayNumbers}
          onDelete={removeGatewayNumber}
          key="GatewayTable"
        />,
      ]}
    />
  );
};

export default AddLocationSidebar;
