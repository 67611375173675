import { css } from "@emotion/react";

import "react-phone-input-2/lib/style.css";

import RobotoWoff from "../fonts/Roboto-Regular.woff";
import RobotoWoff2 from "../fonts/Roboto-Regular.woff2";
import RobotoMediumWoff from "../fonts/Roboto-Medium.woff";
import RobotoMediumWoff2 from "../fonts/Roboto-Medium.woff2";
import RobotoBoldWoff from "../fonts/Roboto-Bold.woff";
import RobotoBoldWoff2 from "../fonts/Roboto-Bold.woff";

export const globalStyles = css`
  @font-face {
    font-family: "Roboto";
    src: url(${RobotoWoff}) format("woff");
    src: url(${RobotoWoff2}) format("woff2");
    font-weight: 400;
  }
  @font-face {
    font-family: "Roboto";
    src: url(${RobotoMediumWoff}) format("woff");
    src: url(${RobotoMediumWoff2}) format("woff2");
    font-weight: 500;
  }
  @font-face {
    font-family: "Roboto";
    src: url(${RobotoBoldWoff}) format("woff");
    src: url(${RobotoBoldWoff2}) format("woff2");
    font-weight: 700;
  }
`;
