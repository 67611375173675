import { createColumnHelper } from "@tanstack/react-table";
import { checkBatteryLevel, checkSignalLevel } from "../kyps-table.helper";
import { CellMaxContent, TemperatureData } from "../kyps-table.styles";
import { checkDate } from "../../../helpers/check-date.helper";
import clsx from "clsx";
import KypsTooltip from "../../kyps-tooltip/kyps-tooltip";
import { ReactNode } from "react";
import SensorDetailsSidebar from "../../kyps-sidebar/sensor-details-sidebar/sensor-details-sidebar";
import {
  checkHumidity,
  getIsDisabledByLocationRole,
} from "./sensors-stats-table.helper";
import { ISensorsStatsData } from "../../../interfaces/tables";
import { TFunction } from "i18next";
import { UserRoles } from "../../../interfaces/roles";
import SensorNameComponent from "../../sensor-name-component/sensor-name-component";
import { SensorsStatsSubRowWrapper } from "./sensors-stats-table.styles";
import { getColumnWidth } from "../kyps-table.utils";

interface ICreateSensorsStatsTableColumns {
  userRole: UserRoles;
  openModal: (modalContent: ReactNode) => void;
  closeModal: () => void;
  minDate?: Date | null;
  t: TFunction<"translations", undefined>;
}

const { accessor } = createColumnHelper<ISensorsStatsData>();

export const createSensorsStatsTableColumns = ({
  openModal,
  closeModal,
  minDate,
  t,
  userRole,
}: ICreateSensorsStatsTableColumns) => [
  accessor("name", {
    header: t("tables.title"),
    size: getColumnWidth("sensorStats", "name"),
    cell: ({
      getValue,
      row: {
        depth,
        original: {
          isCustom,
          id,
          name,
          iPointId,
          iPointName,
          showHumidity,
          zone,
          sensorName,
        },
        getParentRow,
      },
    }) => {
      if (depth > 0)
        return (
          <SensorsStatsSubRowWrapper>
            <SensorNameComponent
              isCustom={isCustom}
              name={sensorName ? `${getValue()} (${sensorName})` : getValue()}
              onClick={() =>
                openModal(
                  <SensorDetailsSidebar
                    closeModal={closeModal}
                    defaultIPoint={{
                      label: iPointName as string,
                      value: iPointId as string,
                    }}
                    defaultZone={{
                      label: zone?.name as string,
                      value: zone?.id as string,
                    }}
                    sensorNumber={name}
                    showHumidity={!!showHumidity}
                    sensorId={id}
                    minDate={minDate}
                    key="SensorDetailsSidebar"
                    isDisabled={
                      !getIsDisabledByLocationRole({
                        id,
                        depth,
                        userRole,
                        getParentRow,
                      })
                    }
                  />
                )
              }
              subName={iPointName as string}
            />
          </SensorsStatsSubRowWrapper>
        );

      return <KypsTooltip tooltipValue={getValue()} />;
    },
  }),

  accessor("lastTemperature", {
    header: "🌡️°C",
    size: getColumnWidth("sensorStats", "lastTemperature"),
    cell: ({
      getValue,
      row: {
        depth,
        original: { state },
      },
    }) => {
      if (depth > 0)
        return (
          <TemperatureData className={clsx(state?.toLowerCase())}>
            {getValue() ?? "-"}
          </TemperatureData>
        );

      return null;
    },
  }),

  accessor("lastHumidity", {
    header: "💧%",
    size: getColumnWidth("sensorStats", "lastHumidity"),

    cell: ({
      getValue,
      row: {
        depth,
        original: { showHumidity },
      },
    }) => {
      if (depth > 0 && showHumidity)
        return <CellMaxContent>{checkHumidity(getValue())}</CellMaxContent>;

      return null;
    },
  }),

  accessor("battery", {
    header: "",
    size: getColumnWidth("sensorStats", "battery"),
    cell: ({ getValue, row: { depth } }) => {
      if (depth > 0) return checkBatteryLevel(getValue());

      return null;
    },
  }),

  accessor("signal", {
    header: "",
    size: getColumnWidth("sensorStats", "signal"),
    cell: ({ getValue, row: { depth } }) => {
      if (depth > 0) return checkSignalLevel(getValue());

      return null;
    },
  }),

  accessor("range", {
    header: `${t("tables.range")} (°C)`,
    size: getColumnWidth("sensorStats", "range"),
    cell: ({ getValue, row: { depth } }) => {
      if (depth > 0) return <CellMaxContent>{getValue()}</CellMaxContent>;

      return null;
    },
  }),

  accessor("lastUpdateDate", {
    header: t("tables.lastUpdate"),
    size: getColumnWidth("sensorStats", "lastUpdateDate"),
    cell: ({ getValue, row: { depth } }) => {
      const value = getValue();
      if (depth > 0)
        return value != null ? (
          <CellMaxContent>{checkDate(value, t)}</CellMaxContent>
        ) : (
          "-"
        );

      return null;
    },
  }),
];
