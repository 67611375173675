import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const GatewayListWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.background.default};
    border-radius: 4px;
    margin-bottom: 32px;
  `}
`;

export const GatewayListItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.background.disable};
    border-radius: 6px;

    &:not(:first-of-type)&:not(:last-of-type) {
      border-radius: 0;
      border-bottom: none;
    }

    &:first-of-type&:not(:last-of-type) {
      border-radius: 6px 6px 0 0;
      border-bottom: none;
    }

    &:last-of-type&:not(:first-of-type) {
      border-radius: 0 0 6px 6px;
    }

    @media (min-width: 768px) {
      padding: 16px 24px;
    }
  `}
`;

export const GatewayValue = styled.p``;

export const GatewayCreatorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
  width: 100%;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const GatewayListHeading = styled.h3`
  ${({ theme }) => css`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.background.secondary};
    padding-bottom: 8px;
  `}
`;
