import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const TabsWrapper = styled.ul`
  ${({ theme }) => css`
    margin: 0 0 10px;
    padding: 0;
    display: flex;
    border-bottom: 1px solid ${theme.palette.background.light};
  `}
`;

export const TabItem = styled.li`
  ${({ theme }) => css`
    display: inline-block;
    padding: 0 4px 16px;
    border-bottom: 2px solid transparent;
    position: relative;
    cursor: pointer;

    :not(:last-child) {
      margin-right: 12px;
    }

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 2px;
    }

    &.active {
      color: ${theme.palette.border.secondary};
      pointer-events: none;

      &::after {
        background-color: ${theme.palette.border.default};
      }
    }

    &:hover {
      color: ${theme.palette.border.secondary};
      opacity: 0.8;

      &::after {
        background-color: ${theme.palette.border.default};
        opacity: 0.8;
      }
    }

    @media (min-width: 768px) {
      :not(:last-child) {
        margin-right: 30px;
      }
    }
  `}
`;
