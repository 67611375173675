import { ReactNode, RefObject } from "react";
import {
  ButtonsHolder,
  Sidebar,
  SidebarHeader,
  SidebarHeading,
  SidebarWrapper,
  SubName,
} from "./kyps-sidebar.styles";
import clsx from "clsx";

export interface ISidebarHeader {
  heading: string;
  buttons?: React.ReactNode[] | React.ReactNode;
}

export interface ISidebar {
  sidebarHeader?: ISidebarHeader;
  content?: ReactNode[];
  subName?: string;
  isHeaderReverse?: boolean;
  sidebarRef?: RefObject<HTMLDivElement>;
}

const KypsSidebar: React.FC<ISidebar> = ({
  sidebarHeader,
  content,
  subName,
  isHeaderReverse,
  sidebarRef,
}) => (
  <Sidebar ref={sidebarRef}>
    <SidebarHeader className={clsx(isHeaderReverse && "reverse")}>
      <SidebarHeading>
        {subName && <SubName>{subName}</SubName>}
        {sidebarHeader?.heading}
      </SidebarHeading>
      <ButtonsHolder>{sidebarHeader?.buttons}</ButtonsHolder>
    </SidebarHeader>
    <SidebarWrapper>{content}</SidebarWrapper>
  </Sidebar>
);

export default KypsSidebar;
