import { useState, useEffect, useMemo, ReactNode, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useGetLocationSensorsDataByLocationIdQuery,
  useGetZoneStatsByZoneIdQuery,
} from "../../../api/dashboard.api";
import LocationTable from "../../kyps-tables/location-table/location-table";
import {
  AllZonesValue,
  getZoneSelectArray,
} from "../../kyps-tables/location-table/location-table.helper";
import PageHeader from "../../page-header/page-header";
import { KypsButton } from "../../kyps-button/kyps-button";
import EmptyPage from "../../kyps-empty/kyps-empty-page";
import { isEmpty } from "lodash";
import { IMenuItems } from "../../../interfaces/dropdown-menu";
import useModal from "../../../hooks/useModal";
import KypsModal from "../../kyps-modal/kyps-modal";
import AddZoneSidebar from "../../kyps-sidebar/add-zone-sidebar/add-zone-sidebar";
import { ISelectOption } from "../../kyps-select/kyps-select";
import {
  createZonesSelectOptions,
  getAllZonesData,
  getAllZonesDataGrid,
  getDefaultZoneSelectOption,
} from "./location-dashboard.helper";
import {
  createDashboardButtons,
  createDropdownItems,
  createLocationDashboardHeader,
} from "./location-dashboard.utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetIPointsByZoneIdQuery } from "../../../api/installation-point.api";
import AuthWrapper from "../../../auth/auth-wrapper";
import { managersRights } from "../../../interfaces/auth-wrapper";
import Loaders from "../../loaders/loaders";
import SensorsGrid from "../../sensors-grid/sensors-grid";
import {
  LocationDashboardWrapper,
  LocationDashboardHeader,
} from "./location-dashboard.styles";
import { getZoneData } from "../../kyps-tables/sensors-table/sensors-table.helper";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { UserRoles, UserRolesEnum } from "../../../interfaces/roles";
import { getUserRoles } from "../../../auth/get-user-roles.helper";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useGetCompanyByIdQuery } from "../../../api/companies.api";
import { useTranslation } from "react-i18next";
import DropdownMenu from "../../dropdown-menu/dropdown-menu";
import useDropdownMenu from "../../../hooks/useDropdownMenu";

const LocationDashboard = () => {
  const userRole = useAppSelector(({ user: { user } }) => user?.role);
  const currentCompany = useAppSelector(
    ({ company }) => company.selectedCompany
  );
  const { selectedCompany } = useAppSelector((state) => state.company);
  const { changeRole } = useAppDispatch();

  const { locationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [manualSkipToken, setManualSkipToken] = useState<boolean>(false);
  const [listStyle, setListStyle] = useState<boolean>(true);
  const [selectedZone, setSelectedZone] = useState<ISelectOption>({
    label: "",
    value: "",
  });
  const verifiedLocationId = (locationId as string).slice("location=".length);

  const dashboardHeaderRef = useRef<HTMLDivElement>(null);

  const {
    dropdownMenuPosition,
    isDropdownMenuOpen,
    menuItems,
    openDropdownMenu,
    setIsDropdownMenuOpen,
  } = useDropdownMenu(dashboardHeaderRef);

  const { data: apiCompanyData } = useGetCompanyByIdQuery(
    selectedCompany?.value ?? skipToken
  );

  const companyCreatedDate = useMemo(() => {
    if (apiCompanyData) return new Date(apiCompanyData.createdAt);

    return null;
  }, [apiCompanyData]);
  const urlCompanyId = searchParams.get("company");

  useEffect(() => {
    const handleRole = (role: UserRoles) => changeRole(role);

    if (verifiedLocationId)
      getUserRoles({
        url: verifiedLocationId,
        handleOnChangeRole: handleRole,
        companyUrl: currentCompany?.value ?? undefined,
      });

    if (!urlCompanyId && selectedCompany?.value)
      setSearchParams(`company=${selectedCompany.value}`);
  }, [
    verifiedLocationId,
    currentCompany?.value,
    changeRole,
    urlCompanyId,
    selectedCompany?.value,
    setSearchParams,
  ]);

  const { data: locationSensorsData, isLoading } =
    useGetLocationSensorsDataByLocationIdQuery(
      manualSkipToken ? skipToken : verifiedLocationId
    );

  const { data: selectedZoneData } = useGetIPointsByZoneIdQuery(
    isEmpty(selectedZone.value) || selectedZone.value === AllZonesValue
      ? skipToken
      : selectedZone.value
  );

  const { t, i18n } = useTranslation();

  const { closeAndResetModal, currentModal, isModalOpen, openModal } =
    useModal();

  i18n.on("languageChanged", () => {
    if (locationSensorsData?.zones && selectedZone.value === AllZonesValue)
      setSelectedZone(getDefaultZoneSelectOption(locationSensorsData.zones, t));
  });

  useEffect(() => {
    if (
      locationSensorsData &&
      !isEmpty(locationSensorsData?.zones) &&
      selectedZone.label === ""
    )
      setSelectedZone(getDefaultZoneSelectOption(locationSensorsData.zones, t));
  }, [locationSensorsData, selectedZone.label, t]);

  const zonesSelectOptions = useMemo(() => {
    if (locationSensorsData)
      return createZonesSelectOptions(locationSensorsData);

    return [];
  }, [locationSensorsData]);

  const allZonesDataGrid = useMemo(() => {
    if (locationSensorsData)
      return getAllZonesDataGrid(locationSensorsData.zones);

    return [];
  }, [locationSensorsData]);

  const allZonesData = useMemo(() => {
    if (locationSensorsData) return getAllZonesData(locationSensorsData);

    return [];
  }, [locationSensorsData]);

  const dashboardZones = useMemo(() => {
    if (locationSensorsData)
      return getZoneSelectArray(locationSensorsData.zones, t);

    return [];
  }, [locationSensorsData, t]);

  const { data: selectedZoneApiData, isLoading: isZoneLoading } =
    useGetZoneStatsByZoneIdQuery(
      isEmpty(selectedZone.value) ||
        isEmpty(dashboardZones) ||
        selectedZone.value === AllZonesValue
        ? skipToken
        : selectedZone.value
    );

  const tableSensorData = useMemo(() => {
    if (selectedZone.value === AllZonesValue && allZonesData)
      return allZonesData;

    if (selectedZoneApiData)
      return getZoneData(selectedZoneApiData.installationPoints);

    return [];
  }, [allZonesData, selectedZone.value, selectedZoneApiData]);

  const gridSensorsData = useMemo(() => {
    if (listStyle) return [];

    if (selectedZone.value === AllZonesValue && allZonesDataGrid)
      return allZonesDataGrid;

    if (selectedZoneApiData) return getAllZonesDataGrid([selectedZoneApiData]);

    return [];
  }, [allZonesDataGrid, listStyle, selectedZone.value, selectedZoneApiData]);

  const handleOnSelect = (option: ISelectOption | null) => {
    if (option) setSelectedZone(option);
  };

  const dropdownMenuItems: IMenuItems[] = createDropdownItems({
    verifiedLocationId,
    selectedZone,
    zonesSelectOptions,
    selectedZoneData,
    openModal,
    closeAndResetModal,
    t,
  });

  const buttons = createDashboardButtons({
    verifiedLocationId,
    dropdownMenuItems,
    locationSensorsData,
    openDropdownMenu,
    openModal,
    closeAndResetModal,
    setSelectedZone,
    setManualSkipToken,
    isDropdownMenuOpen,
    t,
  });

  const locationDashboardHeader: ReactNode[] = createLocationDashboardHeader({
    dashboardZones,
    selectedZone,
    listStyle,
    handleOnSelect,
    setListStyle,
    t,
  });

  const renderLocationData = useMemo(() => {
    if (listStyle)
      return (
        <LocationTable
          dashboardZones={dashboardZones}
          selectedZone={selectedZone}
          tableSensorData={tableSensorData}
          isLoading={isZoneLoading}
          minDate={companyCreatedDate}
          userRole={userRole ?? UserRolesEnum.USER}
          t={t}
        />
      );

    return (
      <SensorsGrid
        data={gridSensorsData}
        closeAndResetModal={closeAndResetModal}
        openModal={openModal}
        isLoading={isLoading || isZoneLoading}
        minDate={companyCreatedDate}
        userRole={userRole ?? UserRolesEnum.USER}
        t={t}
      />
    );
  }, [
    closeAndResetModal,
    companyCreatedDate,
    dashboardZones,
    gridSensorsData,
    isLoading,
    isZoneLoading,
    listStyle,
    openModal,
    selectedZone,
    t,
    tableSensorData,
    userRole,
  ]);

  const renderTableContent = useMemo(() => {
    if (locationSensorsData)
      return isEmpty(locationSensorsData.zones) ? (
        <EmptyPage
          iconName="clipboard"
          heading={t("emptyMessage.text", {
            entity: t("emptyMessage.entities.zone"),
          })}
          actionButton={
            <AuthWrapper
              allowedRoles={managersRights}
              key="Add Zone"
              children={
                <KypsButton
                  placeholder={t("buttons.add.actionWithKey", {
                    key: t("entities.zone"),
                  })}
                  onClick={() =>
                    openModal(
                      <AddZoneSidebar
                        closeModal={closeAndResetModal}
                        currentLocationId={verifiedLocationId}
                        t={t}
                      />
                    )
                  }
                />
              }
            />
          }
        />
      ) : (
        <LocationDashboardWrapper>
          <LocationDashboardHeader>
            {locationDashboardHeader}
          </LocationDashboardHeader>
          {renderLocationData}
        </LocationDashboardWrapper>
      );

    return null;
  }, [
    closeAndResetModal,
    locationDashboardHeader,
    locationSensorsData,
    openModal,
    renderLocationData,
    t,
    verifiedLocationId,
  ]);

  const content = useMemo(() => {
    if (isLoading) return <Loaders variant="location" />;

    if (locationSensorsData)
      return (
        <>
          <PageHeader
            pageName={`${locationSensorsData.name}`}
            pageActions={buttons}
            headerRef={dashboardHeaderRef}
          />
          {renderTableContent}
          <KypsModal isModalOpen={isModalOpen} modalContent={currentModal} />
          {isDropdownMenuOpen && (
            <DropdownMenu
              menuItems={menuItems}
              position={dropdownMenuPosition}
              isOpen={isDropdownMenuOpen}
              setIsDropdownMenuOpen={setIsDropdownMenuOpen}
            />
          )}
        </>
      );

    return null;
  }, [
    buttons,
    currentModal,
    dropdownMenuPosition,
    isDropdownMenuOpen,
    isLoading,
    isModalOpen,
    locationSensorsData,
    menuItems,
    renderTableContent,
    setIsDropdownMenuOpen,
  ]);

  return content;
};

export default LocationDashboard;
