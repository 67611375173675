import { useEffect, useMemo, useRef, useState } from "react";
import KypsSidebar from "../kyps-sidebar";
import { KypsButton } from "../../kyps-button/kyps-button";
import KypsForm from "../../kyps-form/kyps-form";
import { useAddManagerMutation } from "../../../api/managers.api";
import { IAddManagerDTO } from "../../../interfaces/managers";
import {
  createAddManagerFields,
  getCompanyUserSwitcher,
} from "./add-manager-sidebar.utils";
import { useAddCompanyManagerMutation } from "../../../api/company-manager.api";
import { ISelectOption } from "../../kyps-select/kyps-select";
import {
  getCompaniesOptions,
  handleOnAddCompanyManager,
  handleOnAddGlobalManager,
} from "./add-manager-sidebar.helper";
import { UserRoles } from "../../../interfaces/roles";
import { useGetAllCompaniesQuery } from "../../../api/companies.api";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { TFunction } from "i18next";

export interface IAddManagerSidebar {
  companyId: string | null;
  userRole: UserRoles;
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
}

const AddManagerSidebar = ({
  companyId,
  userRole,
  closeModal,
  t,
}: IAddManagerSidebar): JSX.Element => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isAttachedToCompany, setIsAttachedToCompany] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] = useState<ISelectOption | null>(
    null
  );

  const [addGlobalManager] = useAddManagerMutation();
  const [addCompanyManager] = useAddCompanyManagerMutation();
  const { data: apiCompanies } = useGetAllCompaniesQuery();

  const formRef = useRef<HTMLFormElement>(null);

  const selectedCompany = useAppSelector(
    ({ company }) => company.selectedCompany
  );

  const handleAddManagerFormSubmit = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const companies: ISelectOption[] = useMemo(
    () =>
      getCompaniesOptions({
        allCompanies: apiCompanies,
        selectedCompany,
        setSelectedOption,
      }),
    [apiCompanies, selectedCompany]
  );

  const onSelect = (option: ISelectOption) => {
    if (option) setSelectedOption(option);
  };

  const onSubmitAddManager = (data: IAddManagerDTO) => {
    closeModal();

    if (isAttachedToCompany && companyId) {
      handleOnAddCompanyManager({
        data,
        companyId,
        isAdmin,
        addCompanyManager,
      });

      return;
    }

    if (!isAttachedToCompany) {
      handleOnAddGlobalManager({ data, addGlobalManager });

      return;
    }
  };

  useEffect(() => {
    if (!isAttachedToCompany && selectedOption) {
      setSelectedOption(null);
      setIsAdmin(false);
    }

    if (isAttachedToCompany && !selectedOption && selectedCompany) {
      setSelectedOption(selectedCompany);
    }
  }, [companies, isAttachedToCompany, selectedCompany, selectedOption]);

  const companyUserSwitcher = getCompanyUserSwitcher({
    companies,
    isAdmin,
    isAttachedToCompany,
    userRole,
    selectedOption,
    onSelect,
    setIsAdmin,
    setIsAttachedToCompany,
    t,
  });

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: t("buttons.add.actionWithKey", { key: t("entities.user") }),
        buttons: [
          <KypsButton
            key="Cancel"
            placeholder={t("buttons.cancel.action")}
            onClick={closeModal}
            variant="light"
          />,
          <KypsButton
            key="Add manager"
            placeholder={t("buttons.add.actionWithKey", {
              key: t("entities.user"),
            })}
            onClick={handleAddManagerFormSubmit}
          />,
        ],
      }}
      content={[
        <KypsForm
          ref={formRef}
          fields={createAddManagerFields(t)}
          onSubmit={onSubmitAddManager}
          uncontrolledFields={companyUserSwitcher}
          uncontrolledFieldsPosition="bottom"
          key="AddManagerForm"
        />,
      ]}
    />
  );
};

export default AddManagerSidebar;
