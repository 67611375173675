import { isEmpty } from "lodash";
import { IInstallationPoint } from "../../../interfaces/installation-points";
import { ISelectOption } from "../../kyps-select/kyps-select";
import {
  IAddRangeTemperatureFields,
  createRangeTemperatureFields,
} from "../add-installation-point-sidebar/add-installation-point-sidebar.utils";
import { IAddSensorFormFields } from "./add-sensor-sidebar";
import { SetStateAction } from "react";
import { useAddSensorMutation } from "../../../api/sensor.api";
import { t, TFunction } from "i18next";
import { ITemperatureRange } from "../../../interfaces/dashboard";
import { createNotification } from "../../toaster/toaster.helper";

type AddSensorResult = ReturnType<typeof useAddSensorMutation>;
type AddSensorFunction = AddSensorResult[0];

interface IOnAddSensor {
  formData: {
    nameForm: {};
    rangeForm: {};
  };
  setIsSubmitting: (value: SetStateAction<boolean>) => void;
  selectedIPointOption: ISelectOption;
  isCustomConfig: boolean;
  showHumidity: boolean;
  addSensor: AddSensorFunction;
}

interface IFillRangeFields {
  range: ITemperatureRange;
  t: TFunction<"translations", undefined>;
}

export const createIPointSelectOptions = (
  iPoints: IInstallationPoint[]
): ISelectOption[] => {
  if (isEmpty(iPoints)) return [];

  return iPoints.map(({ name, id }) => ({ label: name, value: id }));
};

export const fillRangeFields = ({ range, t }: IFillRangeFields) =>
  createRangeTemperatureFields(t).map((field) => {
    const { highGreen, highYellow, lowGreen, lowYellow } = range;
    let value = 0;

    switch (field.name) {
      case "lowAttention":
        value = lowYellow;
        break;
      case "lowNormal":
        value = lowGreen;
        break;
      case "highNormal":
        value = highGreen;
        break;
      case "highAttention":
        value = highYellow;
        break;
      default:
        break;
    }

    return { ...field, value };
  });

export const handleOnAddSensor = ({
  formData: { nameForm, rangeForm },
  selectedIPointOption,
  isCustomConfig,
  showHumidity,
  addSensor,
  setIsSubmitting,
}: IOnAddSensor) => {
  const { sensorNumber, sensorName } = nameForm as IAddSensorFormFields;
  const { lowNormal, highNormal, lowAttention, highAttention } =
    rangeForm as IAddRangeTemperatureFields;
  setIsSubmitting(true);

  addSensor({
    installationPointId: selectedIPointOption?.value,
    sensorData: {
      customConfig: isCustomConfig,
      sensorNumber,
      showHumidity,
      temperatureRange: {
        lowYellow: +lowAttention,
        lowGreen: +lowNormal,
        highGreen: +highNormal,
        highYellow: +highAttention,
      },
      sensorName,
    },
  })
    .unwrap()
    .then(({ sensorNumber, sensorName }) =>
      createNotification({
        message: t("notification.success", {
          action: t("notification.actions.created"),
          entity: `${t("entities.sensor")} ${
            sensorName.length ? sensorName : sensorNumber
          }`,
        }),
        variant: "success",
      })
    )
    .catch(({ data: { error } }) =>
      createNotification({
        message: `${error}`,
        variant: "error",
      })
    );
};
