import { useRef, useMemo, useCallback } from "react";
import KypsSidebar from "../kyps-sidebar";
import { KypsButton } from "../../kyps-button/kyps-button";
import KypsForm from "../../kyps-form/kyps-form";
import {
  useGetZoneByIdQuery,
  useUpdateZoneMutation,
} from "../../../api/zone.api";
import { IAddZoneFormFields } from "../add-zone-sidebar/add-zone-sidebar";
import { handleOnEditZone, fillEditZone } from "./edit-zone-sidebar.helper";
import Loaders from "../../loaders/loaders";
import { useTranslation } from "react-i18next";

export interface IEditZoneSidebar {
  zoneId: string;
  closeModal: () => void;
}

const EditZoneSidebar = ({
  zoneId,
  closeModal,
}: IEditZoneSidebar): JSX.Element => {
  const [editZone] = useUpdateZoneMutation();
  const { data: apiZoneData, isLoading } = useGetZoneByIdQuery(zoneId);

  const { t } = useTranslation();

  const editFields = useMemo(() => {
    if (apiZoneData) return fillEditZone(apiZoneData, t);

    return [];
  }, [apiZoneData, t]);

  const formRef = useRef<HTMLFormElement>(null);
  const handleEditZoneFormSubmit = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const onSubmitEditCompany = useCallback(
    ({ name }: IAddZoneFormFields) => {
      handleOnEditZone({
        editZone,
        closeModal,
        data: { id: zoneId, zoneData: { name } },
      });
    },
    [closeModal, editZone, zoneId]
  );

  const sidebarContent = useMemo(() => {
    if (isLoading) return [<Loaders key="Loader" variant="sidebar" />];
    return [
      <KypsForm
        fields={editFields}
        onSubmit={onSubmitEditCompany}
        ref={formRef}
        key="EditZoneForm"
      />,
    ];
  }, [editFields, isLoading, onSubmitEditCompany]);

  return (
    <KypsSidebar
      sidebarHeader={{
        heading: `${t("buttons.edit.actionWithKey", {
          key: apiZoneData?.name ?? t("entities.zone"),
        })}`,
        buttons: [
          <KypsButton
            key="Cancel"
            variant="light"
            placeholder={t("buttons.cancel.action")}
            onClick={closeModal}
          />,
          <KypsButton
            key="Edit Zone"
            variant="default"
            placeholder={t("buttons.edit.actionWithKey", {
              key: t("entities.zone"),
            })}
            onClick={handleEditZoneFormSubmit}
          />,
        ],
      }}
      content={sidebarContent}
    />
  );
};

export default EditZoneSidebar;
