import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const DeleteLocationCell = styled.div`
  ${({ theme }) => css`
    padding: 0 24px;
    position: relative;
    width: 46px;
    flex: 1;
    height: 60px;
  `}
`;

export const DeleteCellContent = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: -88px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    cursor: pointer;
    padding: 4px;
    border: 1px solid transparent;

    &.active {
      border-color: ${theme.palette.error.secondary};
      border-radius: 4px;
    }
  `}
`;

export const DeleteCellName = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.error.secondary};
    display: inline-block;
    width: max-content;
    font-weight: 500;
    line-height: 20px;
  `}
`;

export const DetailsNameCell = styled.span`
  ${({ theme }) => css`
    text-transform: capitalize;
    color: ${theme.palette.text.default};
    font-weight: 500;
  `}
`;

export const DetailsTableHeading = styled.div`
  margin: 0 0 12px;
`;
